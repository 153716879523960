import RestApiClient from '../../../core/api/RestApiClient';
import { IInvoicesResult } from '../types/IInvoicesResult';
import { IInvoicePaymentSummary } from '../types/IInvoicePayment';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class DirectPaymentApi {
    public async getCaseInvoices(caseId: string, moduleInstanceId: number): Promise<IInvoicesResult> {
        return await RestApiClient.get<IInvoicesResult>(`modules/DirectPaymentModule/getInvoicesForCase/${caseId},${moduleInstanceId}`)
    }
    
    public async saveDirectPayment(moduleInstanceId: number, data: IInvoicePaymentSummary): Promise<IActionStatus> {
        return await RestApiClient.post(`modules/DirectPaymentModule/savePayment/direct`, {
            moduleInstanceId,
            data
        })
    }

    public async saveCreditNotePayment(moduleInstanceId: number, data: IInvoicePaymentSummary): Promise<IActionStatus> {
        return await RestApiClient.post(`modules/DirectPaymentModule/savePayment/creditNote`, {
            moduleInstanceId,
            data
        })
    }
}

export default new DirectPaymentApi();
import * as React from 'react';
import { useEffect, useState } from 'react';
import Loader from '../../core/components/Loading/Loader';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import { ModuleFactory } from '../../core/factories/ModuleFactory';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import InvoiceDetailsTabsService from './services/InvoiceDetailsTabsService';
import { InvoiceDetailsTabsSettings } from './types/InvoiceDetailsTabsSettings';

const initialSettings: InvoiceDetailsTabsSettings = {
    tabsSettings: []
}

export const InvoiceDetailsTabs = (props: IExtendedModuleProps) => {
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [hasAccessToModule, setHasAccessToModule] = useState(false);
    const [settings, setSettings] = useState(initialSettings);

    const loadData = async () => {
        setIsDataLoading(true);

        try {
            const accountNo = props.routeParameters.accountNo as string;
            const invoiceNo = props.routeParameters.invoiceNo as string;
            const creditorNo = props.routeParameters.creditorNo as string;
            const settings = await InvoiceDetailsTabsService.getModuleSettings({
                accountNo,
                invoiceNo,
                creditorNo,
                moduleInstanceId: props.module.id
            });

            setIsDataLoading(false);
            setHasAccessToModule(true);
            setSettings(settings);
        }
        catch (err) {
            setIsDataLoading(false);
            setHasAccessToModule(err.response && err.response.status ? !(err.response.status === 403) : false);
        }
    }

    const selectTab = (index: number) => {
        setSelectedTabIndex(index);
    }

    const selectedTabSetting = () => settings.tabsSettings[selectedTabIndex];

    useEffect(() =>  { 
        const load = async() => { await loadData(); }
        load();
        // eslint-disable-next-line
    }, [props.routeParameters]);

    if (!isDataLoading && !hasAccessToModule) {
        return (<NoPermissionsComponent />)
    }

    return (<div className="tab">
        <Loader isLoaded={!isDataLoading} />
        <ul className="tab__nav nav-tabs">
            {settings.tabsSettings.map((s, idx) =>
                <li className={`tab__nav-item ${selectedTabIndex === idx ? 'tab__nav-item--active' : ''}`} key={`tab-item_${idx}`}>
                    <p className="tab__nav-link" onClick={() => selectTab(idx)}>
                        {TranslationService.translateModule(s.name, props.module.name)}
                    </p>
                </li>
            )}
        </ul>
        <div className="tab__body">
            {selectedTabSetting() &&
                <ModuleFactory routeParameters={props.routeParameters} moduleIndex={0} modules={[selectedTabSetting().module]} />
            }
        </div>
    </div>)
}
import * as React from 'react';
import { IDebtorDetailsProps } from '../types/IDebtorDetailsProps';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { NavLink } from 'react-router-dom';

class DebtorDetails extends React.Component<IDebtorDetailsProps> {

    public render() {
        return (
            <div className="debtor-details-container">
                <dl className="row mb-0">
                    <dt className="col-4 text-nowrap">{TranslationService.translateModule('Debtor', ModuleNamesList.Messages)}</dt>
                    <dd className="col-8 text-nowrap">{this.getDebtorDetails()}</dd>

                    <dt className="col-4 text-nowrap">{TranslationService.translateModule('CaseNr', ModuleNamesList.Messages)}</dt>
                    <dd className="col-8 text-nowrap">{this.getCaseDetails()}</dd>

                    <dt className="col-4 text-nowrap">{TranslationService.translateModule('Creditor', ModuleNamesList.Messages)}</dt>
                    <dd className="col-8 text-nowrap">{this.getCreditorDetails()}</dd>

                    <dt className="col-4 text-nowrap">{TranslationService.translateModule('DueDate', ModuleNamesList.Messages)}</dt>
                    <dd className="col-8 text-nowrap">{DateTimeParser.toLocaleDateString(this.props.currentConversation.dueDate)}</dd>

                    <dt className="col-4 text-nowrap">{TranslationService.translateModule('RegDate', ModuleNamesList.Messages)}</dt>
                    <dd className="col-8 text-nowrap">{DateTimeParser.toLocaleDateString(this.props.currentConversation.registerDate)}</dd>
                </dl>
            </div>
        );
    }

    private getCaseDetails = () => {
        const link = `case?id=${this.props.currentConversation.caseDetails.caseNumber}`;
        const hasData = (this.props.currentConversation.caseDetails.caseNumber && this.props.currentConversation.caseDetails.caseNumber.trim() !== '')
        || (this.props.currentConversation.caseDetails.referenceNumber && this.props.currentConversation.caseDetails.referenceNumber.trim() !== '');
        return (
            hasData ? 
            <NavLink id="caseNumberLink" to={link} className="font--color-6 font-weight-bold">
                {this.props.currentConversation.caseDetails.caseNumber.trim()} {this.props.currentConversation.caseDetails.referenceNumber!==""?'/':''} {this.props.currentConversation.caseDetails.referenceNumber}
            </NavLink>
            : ""
        )
    };

    private getCreditorDetails = () => {
        return <>{this.props.currentConversation.caseDetails.creditor.name} {this.props.currentConversation.caseDetails.creditor.number!==""?'/':''} {this.props.currentConversation.caseDetails.creditor.number}</>;
    };

    private getDebtorDetails = (): string => {
        return `${this.props.currentConversation.debtor.firstName} ${this.props.currentConversation.debtor.lastName} / ${this.props.currentConversation.debtor.number}`;
    }
}

export default DebtorDetails;
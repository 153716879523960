import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import TranslationsApiClient from '../core/api/TranslationsApiClient';
import { ILanguage } from '../core/types/ILanguage';

const fetchingLanguagesInProgress = () => ({
    type: ACTIONS.FETCH_LANGUAGES_IN_PROGRESS
})

const fetchingLanguagesSucceeded = (languages: ILanguage[]) => ({
    type: ACTIONS.FETCH_LANGUAGES_SUCCEEDED,
    languages
})

const fetchingLanguagesFailed = () => ({
    type: ACTIONS.FETCH_LANGUAGES_FAILED
})

export default () => (dispatch: Dispatch<any>) => {
    return new Promise<void>(async (resolve, reject) => {
        dispatch(fetchingLanguagesInProgress());

        try {
            const languages = await TranslationsApiClient.GetLanguages();
            const selectedLanguage = localStorage.getItem('selectedLanguage');
            const userLocale = localStorage.getItem('user.locale');

            if (!selectedLanguage && selectedLanguage) {
                localStorage.setItem('selectedLanguage', selectedLanguage);
                dispatch({
                    type: ACTIONS.CHANGE_LANGUAGE,
                    language: selectedLanguage
                });
            }
            else if (!selectedLanguage && userLocale) {
                localStorage.setItem('selectedLanguage', userLocale);
                dispatch({
                    type: ACTIONS.CHANGE_LANGUAGE,
                    language: userLocale
                });
            }

            dispatch(fetchingLanguagesSucceeded(languages));
            resolve();
        } catch {
            dispatch(fetchingLanguagesFailed());
            reject();
        }
    });
}
import * as React from 'react';
import UserIdentityService from './core/services/UserIdentityService';
import Loader from './core/components/Loading/Loader';

export default class AuthCallback extends React.Component<any> {
    private userIdentityService: UserIdentityService;

    constructor(props: any) {
        super(props);
        this.userIdentityService = new UserIdentityService();
        this.Login();
    }

    public async Login() {
        const redirectUrl = await this.userIdentityService.CompleteAuthentication();
        window.location.href = redirectUrl as string;
    }

    public render() {
        return <Loader opacity={1} />;
    }
}
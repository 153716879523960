import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import TranslationService from '../core/services/TranslationService';

const fetchTranslationsFailed = () => ({
    type: ACTIONS.FETCH_TRANSLATIONS_FAILED
})

const fetchTranslationsSucceded = (translations: any) => ({
    type: ACTIONS.FETCH_TRANSLATIONS_SUCCEEDED,
    translations
})

const fetchTranslations = () => ({
    type: ACTIONS.FETCH_TRANSLATIONS_IN_PROGRESS
})

export default () => (dispatch: Dispatch<any>) => {
    return new Promise<void>(async (resolve, reject) => {
        dispatch(fetchTranslations());

        try {
            const translationsFromApi = await TranslationService.getTranslationsFromApi();
            dispatch(fetchTranslationsSucceded(translationsFromApi));
            resolve();
        } catch (e) {
            dispatch(fetchTranslationsFailed())
            reject();
        }
    });
}
import * as React from "react";
import CurrencyParser from "../../../../core/helpers/CurrencyParser";
import { IComponentTable } from "../../../../core/types/IComponentTable";
import { IComponentTableColumn } from "../../../../core/types/IComponentTableColumn";
import { ProductValuesType } from "../types/ProductValues";
import { ProductTableColumns } from "../types/ProductTableColumns";
import TranslationService from "../../../../core/services/TranslationService";
import { ModuleNamesList } from "../../../../core/lists/ModuleNamesList";

class OrderPaperTableConfigurator {
  public getPaperTableColumns = (
    OrderPaperTable: IComponentTable<ProductValuesType>,
    removeSupply: (productId: string) => void
  ) => {
    let columns: IComponentTableColumn[] = [];
    if (OrderPaperTable != null) {
      columns = [...OrderPaperTable.columns.map(c => ({ ...c, sort: true }))];
      this.configureCurrencyColumns(columns);
      this.addRemoveSupplyColumn(columns, removeSupply);

      return columns;
    }
    return columns;
  };

  private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
    const currencyColumns = [
      ProductTableColumns.Price,
      ProductTableColumns.Vat,
      ProductTableColumns.Total
    ];
    const currencyColumnsNames = currencyColumns.map(
      col =>
        ProductTableColumns[col].charAt(0).toLowerCase() +
        ProductTableColumns[col].slice(1)
    );
    const cols = columns.filter(
      col => currencyColumnsNames.indexOf(col.dataField) >= 0
    );
    const formatterFunc = (value: any, row: any) => {
      return CurrencyParser.toLocaleString(value);
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };

  private addRemoveSupplyColumn = (
    columns: IComponentTableColumn[],
    removeSupply: (productId: string) => void
  ) => {
    const formatterFunc = (value: any, row: ProductValuesType) => {
      return (
        <div onClick={() => removeSupply(row.id)} className="btn-remove">
          <i className="fas fa-trash" />
        </div>
      );
    };

    columns.push({
      dataField: "Remove",
      formatter: formatterFunc,
      sort: false,
      headerClasses: (col: any, colIndex: number) =>
        "bt-table__header bt-table__header--text-center",
      classes: (cell: any, row: any, rowIndex: number, colIndex: number) =>
        "bt-table__cell bt-table__cell--text-center",
      style: (cell: any, row: any, rowIndex: number, colIndex: number) =>
        undefined,
      headerStyle: (col: any, colIndex: number) => undefined,
      text: TranslationService.translateModule(
        "Remove",
        ModuleNamesList.DocumentsList
      )
    } as IComponentTableColumn);
  };
}

export default new OrderPaperTableConfigurator();

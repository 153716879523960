import * as React from 'react';
import { IConfirmationProps } from '../types/IConfirmationProps';
import TranslationService from '../../../core/services/TranslationService';
import * as yup from 'yup';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import Loader from '../../../core/components/Loading/Loader';
import RegisterUserApiClient from '../RegisterUserApiClient';
import { IConfirmationState } from '../types/IConfirmationState';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import AlertMessages from '../../../core/components/AlertMessages/AlertMessages';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import { Status } from '../../../core/api/Enums/Status';

class Confirmation extends React.Component<IConfirmationProps> {
    public state: IConfirmationState = {
        confirmations: {
            confirmationCodeEmail: '',
            confirmationCodePhone: '',
            phone: this.props.user.phone,
            email: this.props.user.email,
        },
        isPendingRequest: true,
        errorMessages: []
    }

    private validationMessages = {
        required: TranslationService.translate("ValidationMessageRequiredField"),
    }

    private validationSchema = yup.object().shape({
        confirmationCodePhone: this.props.confirmations.byPhone ? yup.string().required(this.validationMessages.required): yup.string(),
        confirmationCodeEmail: this.props.confirmations.byEmail ?yup.string().required(this.validationMessages.required): yup.string(),
    });

    public sendVeryficationCodes = async () => {
        this.setState({
            isPendingRequest: true
        });
        await RegisterUserApiClient.SendConfirmationCodes(this.props.user, this.props.moduleInstanceid);
        this.setState({
            isPendingRequest: false
        });
    }

    public componentDidMount = async() => {
        await this.sendVeryficationCodes();
    }

    public handleChange = (event: React.ChangeEvent<any>) => {
        this.setState({
            confirmations:{
                ...this.state.confirmations,
                [event.target.name]: event.target.value
            }
        })
    }
   
    public render() {
        return (
            <div>
                <Formik
                    initialValues={{
                        confirmationCodeEmail: this.props.user.confirmationCodeEmail,
                        confirmationCodePhone: this.props.user.confirmationCodePhone,
                    }}
                    onSubmit={async (values) => {
                        this.setState({ isPendingRequest: true });
                        const areCodesValid = await RegisterUserApiClient.ValidateConfirmationCodes(this.state.confirmations, this.props.moduleInstanceid);
                        if(areCodesValid.status === Status.Success)
                        {
                            this.props.updateConfirmationsCodes(values);
                            this.props.goToNextStep();
                        }
                        this.setState({ isPendingRequest: false, errorMessages: areCodesValid.messages });
                    }}                    
                    validationSchema={this.validationSchema}
                    >
                    {({ values,
                        errors,
                        touched,
                        handleChange
                    }) => {
                        return (
                            <>
                                <Form>
                                    <div className="step-content">
                                        { this.state.isPendingRequest ? <Loader opacity={1}/> : '' }
                                        <header className="step-header">
                                            <h2 className="step-title">{TranslationService.translateModule('ConfirmationHeader', ModuleNamesList.RegisterUserModuleName)}</h2>
                                            <p>
                                                <strong>{this.props.user.firstName} {this.props.user.lastName}</strong> 
                                                { this.props.confirmations.byPhone && this.props.confirmations.byEmail ? 
                                                    " "+ TranslationService.translateModule('ConfirmationContentEmailAndPhone', ModuleNamesList.RegisterUserModuleName)
                                                : 
                                                    " "+ TranslationService.translateModule('ConfirmationContentEmailOrPhone', ModuleNamesList.RegisterUserModuleName)
                                                }
                                            </p>
                                        </header>
                                        { this.state.errorMessages.length > 0 ?
                                            <AlertMessages messages={this.state.errorMessages} translate={true} moduleName={ModuleNamesList.RegisterUserModuleName} alertClass="alert-danger"/>
                                            : ""
                                        }
                                    
                                        { this.props.confirmations.byPhone ? 
                                            <div className="register-user-confirmation-phone-row">
                                                <div className="form-group row">
                                                    <label className="col-4 col-sm-3 col-md-2 col-form-label"><strong>{TranslationService.translateModule('ConfirmationPhone', ModuleNamesList.RegisterUserModuleName)}</strong></label>
                                                    <div className="col-8 col-sm-9 col-md-10">
                                                        <p className="form-control-plaintext">{this.props.user.phone}</p>
                                                    </div>
                                                    <label className="col-sm-6 col-form-label">
                                                        {TranslationService.translateModule('ConfirmationPhoneConfirmText', ModuleNamesList.RegisterUserModuleName)}    
                                                    </label>
                                                    <div className="col-sm-4 col-lg-3">
                                                        <Field type="text" name="confirmationCodePhone" id="confirmationCodePhone" value={values.confirmationCodePhone} 
                                                            className={"form-control" + ((errors.confirmationCodePhone && touched.confirmationCodePhone) ? " error" : "")} 
                                                            onChange={(e: any) => {handleChange(e); this.handleChange(e);}} />
                                                        <ErrorMessage name="confirmationCodePhone" component="div" className="error error-message" />
                                                    </div>
                                                </div>
                                            </div>
                                        : '' }
                                        { this.props.confirmations.byPhone && this.props.confirmations.byEmail ? <hr/> : '' }
                                        { this.props.confirmations.byEmail ? 
                                            <div className="register-user-confirmation-email-row">
                                                <div className="form-group row">
                                                    <label className="col-4 col-sm-3 col-md-2 col-form-label"><strong>{TranslationService.translateModule('ConfirmationEmail', ModuleNamesList.RegisterUserModuleName)}</strong></label>
                                                    <div className="col-8 col-sm-9 col-md-10">
                                                        <p className="form-control-plaintext">{this.props.user.email}</p>
                                                    </div>
                                                    <label className="col-sm-6 col-form-label">
                                                        {TranslationService.translateModule('ConfirmationEmailConfirmText', ModuleNamesList.RegisterUserModuleName)}    
                                                    </label>
                                                    <div className="col-sm-4 col-lg-3">
                                                        <Field type="text" name="confirmationCodeEmail" id="confirmationCodeEmail" value={values.confirmationCodeEmail} 
                                                            className={"form-control" + ((errors.confirmationCodeEmail && touched.confirmationCodeEmail) ? " error" : "")} 
                                                            onChange={(e: any) => {handleChange(e); this.handleChange(e);}} />
                                                        <ErrorMessage name="confirmationCodeEmail" component="div" className="error error-message" />
                                                    </div>
                                                </div>
                                            </div>
                                        : '' }
                                        <footer className="step-footer mb-3">
                                            <span className="fas fa-info-circle info-icon"/>
                                            {" " + TranslationService.translateModule('ConfirmationInformationText', ModuleNamesList.RegisterUserModuleName) + " "}
                                            <button type="button" id="generateNewCode" onClick={async() => {this.sendVeryficationCodes()}} className="btn btn-danger btn-sm">{TranslationService.translateModule('ConfirmationGenerateNewCodeButton', ModuleNamesList.RegisterUserModuleName)}</button>    
                                        </footer>
                                    </div>
                                    <StepListWizardButtons
                                        leftButtonOnClick={this.props.backToPrevStep}
                                        leftButtonClassName="float-left"
                                        leftButtonText={<>
                                            <i className="fas fa-angle-double-left back-button-arrow left"/>
                                            {" " + TranslationService.translateModule('ConfirmationButton1Text', ModuleNamesList.RegisterUserModuleName)}
                                        </>}
                                        rightButtonClassName="float-right"
                                        rightButtonText={<>
                                            {TranslationService.translateModule('ConfirmationButton2Text', ModuleNamesList.RegisterUserModuleName)}
                                        </>}
                                    />
                                </Form>
                            </> )
                        }
                    }
                </Formik>
            </div>
        )
    }
}

export default Confirmation;
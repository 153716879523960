import RestApiClient from '../../core/api/RestApiClient';
import { IPortfolioChartData } from './types/IPortfolioChartData';
import { CancelToken } from 'axios';

class CaseStatisticsPortfolioApiClient {
    public async GetData(cancelToken: CancelToken): Promise<IPortfolioChartData[]>{
        return await RestApiClient.get<IPortfolioChartData[]>(`modules/CaseStatisticsPortfolio/getData`, {}, cancelToken);
    }
}

export default new CaseStatisticsPortfolioApiClient();

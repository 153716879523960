import RestApiClient from '../../core/api/RestApiClient';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IReportElement } from './types/IReportElement';
import { IActionStatus } from '../../core/api/types/IActionStatus';

class ScheduleReportsApiClient {
    public async getReports(moduleId: number): Promise<IComponentTable<IReportElement>> {
        return await RestApiClient.get<IComponentTable<IReportElement>>(`modules/ScheduleReportsModule/getSchedulesReports/${moduleId}`);
    }
    public async removeScheduleReport(reportId: number): Promise<IActionStatus> {
        return await RestApiClient.get<IActionStatus>(`modules/ScheduleReportsModule/removeSchedule/${reportId}`);
    }
}

export default new ScheduleReportsApiClient();

import PaymentsApiClient from '../api/PaymentsApiClient';
import { IPaymentsHistory } from '../types/Payment';
import { CancelToken } from 'axios';

class PaymentsService {
    private apiClient: PaymentsApiClient;

    constructor(cancelToken: CancelToken) {
        this.apiClient = new PaymentsApiClient(cancelToken);
    }

    public async getPayments(moduleInstanceId: number) : Promise<IPaymentsHistory> {
        return await this.apiClient.getPayments(moduleInstanceId);
    }
}

export default PaymentsService;
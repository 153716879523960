import { IComponentTableColumn } from './IComponentTableColumn';

export interface IComponentTable<TModel = any> {
    columns: IComponentTableColumn[];
    values: TModel[];
}

export const emptyComponentTable: IComponentTable = {
    columns: [],
    values: []
}
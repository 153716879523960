import * as React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { INewMessage } from '../types/INewMessage';
import NewMessageForm from './NewMessageForm';
import { IMessagesModuleSettings } from '../types/IMessagesModuleSettings';

interface IProps {
  show: boolean,
  messageModuleSettings: IMessagesModuleSettings,
  isAddingNewConversation: boolean,
  onClose: () => void,
  onSendNewMessage: (message: INewMessage) => Promise<void>,
}

interface IState {
  isOpen: boolean;
}

export class NewMessageModal extends React.Component<IProps, IState> {
  state: IState = {
    isOpen: false,
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        isOpen: this.props.show
      })
    }
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen} backdrop={true} toggle={this.toggle} contentClassName="m-messages">
        <ModalHeader>
          {TranslationService.translateModule('Modal_AddNewMessage', ModuleNamesList.Messages)}
        </ModalHeader>
        
        <ModalBody className="new-message-modal-body">
          <NewMessageForm 
            mode="light"
            isAddingNewConversation={this.props.isAddingNewConversation}
            sendNewMessage={this.props.onSendNewMessage} 
            messageModuleSettings={this.props.messageModuleSettings} 
            key="newMessageForm_modal" 
            prefix="modal"
            onClose={this.toggle}
          />
        </ModalBody>
      </Modal> 
    )
  }

  toggle = () => {
    const isOpen = !this.state.isOpen;

    this.setState({
      isOpen
    })

    if (!isOpen) {
      this.props.onClose();
    }
  }
}
import RestApiClient from '../../core/api/RestApiClient';
import { ICasesRequest } from './types/ICasesRequest';
import { ICaseListResponse } from './types/ICaseListResponse';
import { ICaseInitialSettings } from './types/ICaseInitialSettings';
import { CancelToken } from 'axios';

class CaseListApiClient
{
    public async getCasesList(request: ICasesRequest, cancelToken: CancelToken): Promise<ICaseListResponse> {
        return await RestApiClient.post<ICaseListResponse>(`modules/CaseList/getCaseList`, request, undefined, undefined, undefined, undefined, cancelToken);
    }
    public async getCasesListFile(request: ICasesRequest): Promise<Blob> {
        return await RestApiClient.downloadBlob(`modules/CaseList/export`, request);
    }
    public async getInitialSettings(moduleInstanceId: number, cancelToken: CancelToken): Promise<ICaseInitialSettings> {
        return await RestApiClient.get(`modules/CaseList/getInitialSettings/${moduleInstanceId}`, {}, cancelToken);
    }
}

export default new CaseListApiClient()

import React from 'react';
import StyleWrapper from './../../core/components/StyleWrapper/StyleWrapperHOC';
import './LogoBanner.scss';

const LogoBanner = () => (
    <div className="sign-in__logo-container">
        <div className="sign-in__logo"></div>
    </div>
);

const styleConfig = [
    {
        searchMethod: 'element',
        instructions: {
            logo: '.sign-in__logo-container .sign-in__logo',
        }
    }
]

export default StyleWrapper('module:error', styleConfig)(LogoBanner);

import React, { useState, useEffect } from 'react';
import UserIdentityService from './core/services/UserIdentityService';
import Loader from './core/components/Loading/Loader';

const SilentRenew = () => {
  const userIdentityService = new UserIdentityService();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      userIdentityService.CompleteSilentAuthentication()
        .finally(() => {
          setIsLoading(false);
        });
      // eslint-disable-next-line
  }, []);

  return isLoading ? <Loader opacity={1} /> : <> OK </>
  
};

export default SilentRenew

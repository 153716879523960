import { IStore } from './IStore';

const currentCultureCode = localStorage.getItem('selectedLanguage') || '';

export const initialState: IStore = {
    applicationSettings: {},
    configurations: {},
    creditors: [],
    creditorsLoading: false,
    currency: 'NOK',
    currentCreditors: [],
    currentCultureCode,
    languages: [],
    pages: [],
    stylingSettings: {},
    userCreditorRoles: {},
    leftMenuModel: { items: [], groups: [] },
    permissions: {},
    tilesCounters: {}
};

import * as React from 'react';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { DebtorType } from '../types/DebtorType';
import { IDebtorDetails } from '../types/IDebtorDetails';
import DebtorForm from './DebtorForm';
import RadioSelectBoxes from './RadioSelectBoxes';
import { IDebtorSetup } from '../types/IDebtorSetup';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { IFieldsToggles } from '../types/FieldsToggles';
import { SearchDebtorModal } from './SearchDebtorModal';
import { connect } from 'react-redux';
import { IStore } from '../../../reducers/IStore';
import { ICreditor } from '../../../core/types/ICreditor';

interface IProps {
  creditors: ICreditor[];
  creditor: string;
  debtorSetup: IDebtorSetup;
  debtorDetails: IDebtorDetails;
  moduleInstanceId: number;
  fieldToggles: IFieldsToggles;
  backToPrevStep(): void;
  goToNextStep(): void;
  setDebtorDetails(debtorDetails: IDebtorDetails): void;
}

interface IState {
  isModalOpen: boolean;
}

class DebtorInfo extends React.Component<IProps, IState> {
  public state: IState = {
    isModalOpen: false,
  };

  private debtorTypes: INumberDictionary<string> = {
    [DebtorType.Private]: TranslationService.translateModule(
      'DebtorInfoDebtorTypePrivate',
      ModuleNamesList.CreateNewCase
    ),
    [DebtorType.Business]: TranslationService.translateModule(
      'DebtorInfoDebtorTypeBuisness',
      ModuleNamesList.CreateNewCase
    ),
  };

  public render() {
    const creditorName = this.props.creditors.find(creditor => creditor.domainId === this.props.creditor);

    return (
      <>
        <div className="mb-3">
          <h6 className="create-case-header">
            {TranslationService.translateModule(
              'DebtorInfoHeaderCreditorName',
              ModuleNamesList.CreateNewCase
            )}
          </h6>
          <SimpleInput
            id="filteredByValue"
            value={`(${creditorName?.domainId}) ${creditorName?.organizationName}`}
            onChange={() => {}}
            disabled={true}
          />
        </div>
        <h6 className="create-case-header">
          {TranslationService.translateModule(
            'DebtorInfoHeaderSelectDebtorType',
            ModuleNamesList.CreateNewCase
          )}
        </h6>

        <RadioSelectBoxes
          elements={this.debtorTypes}
          value={this.props.debtorDetails.debtorType.toString()}
          onChange={(value: any) => {
            this.props.setDebtorDetails({
              ...this.props.debtorDetails,
              debtorType: parseInt(value, 0),
            });
          }}
          name="debtorType"
        />

        <button
          className="btn btn-sm btn-color-2 text-transform-none float-right mt-m15"
          onClick={() => this.setState({ isModalOpen: true })}
        >
          {TranslationService.translateModule(
            'DebtorInfoSearchDebtor',
            ModuleNamesList.CreateNewCase
          )}
        </button>

        <h6 className="create-case-header bordered-bottom">
          {TranslationService.translateModule(
            'DebtorInfoHeaderDebtorDetails',
            ModuleNamesList.CreateNewCase
          )}
        </h6>

        <DebtorForm
          debtorDetails={this.props.debtorDetails}
          debtorSetup={this.props.debtorSetup}
          debtorType={this.props.debtorDetails.debtorType}
          fieldsToggles={this.props.fieldToggles}
          setDebtorDetails={this.props.setDebtorDetails}
          backToPrevStep={this.props.backToPrevStep}
          goToNextStep={() => {
            this.props.setDebtorDetails({
              ...this.props.debtorDetails,
              domainId: creditorName ? creditorName.domainId : this.props.debtorDetails.domainId,
            });

            this.props.goToNextStep();
          }}
        />

        <SearchDebtorModal
          show={this.state.isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
          moduleInstanceId={this.props.moduleInstanceId}
          onDebtorSelected={(debtorDetails: IDebtorDetails) => {
            this.setState({ isModalOpen: false });
            this.props.setDebtorDetails(debtorDetails);
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return { 
      creditors: state.creditors
  }
};

export default connect(mapStateToProps)(DebtorInfo);

import React from 'react';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import InvoicesAgeAnalysisService from './services/InvoicesAgeAnalysisService';
import { IInvoicesAgeAnalysisRequestFilters, emptyIInvoicesAgeAnalysisRequestFilters } from './types/IInvoicesAgeAnalysisRequestFilters';
import { IInvoicesAgeAnalysisSummary, emptyInvoicesAgeAnalysisSummary } from './types/IInvoicesAgeAnalysisSummary';
import { InvoicesAgeAnalysisColumns } from './types/InvoicesAgeAnalysisColumns';
import Filters from './components/Filters';
import Footer from './components/Footer';
import Loader from '../../core/components/Loading/Loader';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import InvoicesAgeAnalysisListTableConfigurator from './helpers/InvoicesAgeAnalysisListTableConfigurator';
import { IStore } from '../../reducers/IStore';
import { connect } from 'react-redux';
import { IInvoicesAgeAnalysisListItem } from './types/IInvoicesAgeAnalysisListItem';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import { INumberDictionary } from '../../core/types/IDictionary';
import InvoicesAgeAnalysisApi from './api/InvoicesAgeAnalysisApi';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { EventsList } from '../../core/lists/EventsList';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';

interface IState {
    filterByOptions: INumberDictionary<string>,
    filters: IInvoicesAgeAnalysisRequestFilters,
    hasAccessToModule: boolean,
    initialDataLoaded: boolean,
    isLoading: boolean,
    pageNumber: number,
    pageSize: number,
    sortBy: InvoicesAgeAnalysisColumns,
    sortOrder: string,
    summary: IInvoicesAgeAnalysisSummary
}

class InvoicesAgeAnalysis extends React.Component<IExtendedModuleProps & AbortRequestPropsType, IState> {
    private module: any = flatModuleOptions<any>(this.props.module as any);
    private moduleEvents: IModuleEventSubscription[] | undefined;
    public state: IState = {
        hasAccessToModule: true,
        initialDataLoaded: false,
        isLoading: false,
        pageNumber: 1,
        pageSize: 10,
        sortBy: InvoicesAgeAnalysisColumns.AccountNo,
        sortOrder: 'desc',
        filterByOptions: {},
        filters: {...emptyIInvoicesAgeAnalysisRequestFilters},
        summary: {...emptyInvoicesAgeAnalysisSummary}
    };

    async componentDidMount() {
        this.registerModuleEvents();
        await this.loadInitialSettings();
        this.loadData();
    }

    public componentWillUnmount() {
        if (this.moduleEvents)
            ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
    }

    render() {
        return (
            <article className="l-module">
                <section className="l-module__section l-module__section--head">
                    <h1 className="l-module__title">
                        <i className="fas fa-file-invoice" />
                        
                        {TranslationService.translateModule('LeftMenuInvoices', ModuleNamesList.InvoicesAgeAnalysis)}: 
                        
                        <strong className="l-module__title-highlighted">
                            {TranslationService.translateModule('AgeAnalysis', ModuleNamesList.InvoicesAgeAnalysis)}
                        </strong>
                    </h1>
                </section>

                <section className="l-module__section l-module__section--filter mb-5">
                    <Filters onFilter={this.onFilter} filterByOptions={this.state.filterByOptions} />
                </section>


                <section className={`l-module__section ${this.state.isLoading ? 'l-module__section--loading' : ''}`}>
                    {(!this.state.initialDataLoaded || this.state.isLoading) &&
                        <Loader opacity={0.5} />
                    }
                    
                    {this.state.hasAccessToModule ? (
                        <BootstrapTableContainer
                            remote={true}
                            data={this.state.summary.data.values}
                            paginationProps={{
                                page: this.state.pageNumber,
                                sizePerPage: this.state.pageSize,
                                totalSize: this.state.summary.total
                            }}
                            classes="bt__table bt-table"
                            keyField="id"
                            wrapperClasses="bt"
                            columns={InvoicesAgeAnalysisListTableConfigurator.getTableColumns(this.state.summary.data, this.module.AccountLinkUrl, this.openDebtorDetailsModal)}
                            onTableChange={this.onTableChange}
                        />
                    ) : (
                        <NoPermissionsComponent />
                    )}
                </section>

                {this.state.initialDataLoaded &&
                    <section className="l-module__section l-module__section--p-0 mt-3">
                        <Footer summary={this.state.summary} currency={this.props.currency} />
                    </section>
                }
            </article>
        )
    }

    private openDebtorDetailsModal = (debtorNo: string, creditorNo: string) => {
        ModuleEventSubscriber.emitEvent({
            name: getModalOpenEvent(this.module.DebtorDetailsModalModuleInstanceId), data: {
                modalData: {
                    MODAL_HEADER_TEXT: TranslationService.translateModule('DebtorDetailsModal', ModuleNamesList.InvoicesAgeAnalysis),
                    MODAL_HEADER_ICON: 'fas fa-user',
                },
                otherData: {
                    debtorNo,
                    creditorNo,
                }
            }
        });
    }

    private onTableChange = (type: any, { page, sizePerPage, sortField, sortOrder }: any) => {
        this.setState({
            pageNumber: page,
            pageSize: sizePerPage,
            sortBy: sortField ? this.convertToSortByNumber(sortField) : this.state.sortBy,
            sortOrder: sortOrder ? sortOrder : this.state.sortOrder
        }, this.loadData);
    };

    private onFilter = (filters: IInvoicesAgeAnalysisRequestFilters) => {
        this.setState({
            filters: {
                ...filters,
                creditor: filters.creditor === '0' ? '' : filters.creditor,
                filteredBy: filters.filteredBy && filters.filteredBy.toString() === 'all' ? undefined : filters.filteredBy
            },
            pageNumber: 1
        }, this.loadData);
    };

    private loadInitialSettings = async () => {
        this.setState({
            isLoading: true
        });

        const initialSettings = await InvoicesAgeAnalysisApi.getInitialSettings(this.props.module.id, this.props.cancelTokenSource.token);
        this.setState({
            filterByOptions: initialSettings.filterByOptions,
        })
    };

    private loadData = async () => {
        this.setState({
            isLoading: true
        });
        
        try {
            const result = await InvoicesAgeAnalysisService.getInvoicesAgeAnalysisData({
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                sortBy: this.state.sortBy,
                sortOrdering: this.state.sortOrder,
                filters: this.state.filters,
                moduleInstanceId: this.props.module.id
            }, this.props.cancelTokenSource.token);

            this.setState({
                hasAccessToModule: true,
                isLoading: false,
                initialDataLoaded: true,
                summary: {
                    ...result,
                    data: {
                        columns: result.data.columns,
                        values: result.data.values.map((x: IInvoicesAgeAnalysisListItem, idx: number) => ({ ...x, id: idx }))
                    }
                }
            })
        } catch (err) {
            const newState: IState = {
                ...this.state,
                isLoading: false,
                summary: {
                    ...this.state.summary,
                    ...emptyInvoicesAgeAnalysisSummary,
                    data: {
                        ...this.state.summary.data,
                        values: []
                    }
                }
            };

            if (err.response && err.response.status === 403) {
                newState.hasAccessToModule = false;
            }

            this.setState(newState);
        }
    };

    private convertToSortByNumber = (sortField: string) => {
        return InvoicesAgeAnalysisColumns[sortField.charAt(0).toUpperCase() + sortField.slice(1)];
    }

    private registerModuleEvents() {
        this.moduleEvents = [
            { name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.loadData }
        ];
        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        currency: state.currency
    }
};

export default connect(mapStateToProps)(withAbortRequest(InvoicesAgeAnalysis))
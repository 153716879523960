import * as React from 'react';
import { IStepListWizardButtons } from './types/IStepListWizardButtons';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';

class StepListWizardButtons extends React.Component<IStepListWizardButtons> {
    public render() {
        return (
            <div className="row justify-content-between mb-3 pb-3">
                <div className="col-auto">
                    {this.props.leftButtonText && (
                        <Button
                            id="stepWizardPrevStepBtn"
                            type="button"
                            variant={BUTTON_VARIANTS.PRIMARY}
                            size={BUTTON_SIZE.MD}
                            onClick={this.props.leftButtonOnClick}
                        >
                            {this.props.leftButtonText}
                        </Button>
                    )}
                </div>

                <div className="col-auto">
                    {this.props.rightButtonText && (
                        <Button
                            id="stepWizardNextStepBtn"
                            variant={BUTTON_VARIANTS.PRIMARY}
                            size={BUTTON_SIZE.MD}
                            onClick={this.props.rightButtonOnClick}
                        >
                            {this.props.rightButtonText}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default StepListWizardButtons;

import { Form, Formik } from 'formik';
import * as React from 'react';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { FormGroup, FORM_GROUP_VARIANTS } from "../../../core/components/Forms/FormGroup";
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { IModuleSettingsFilter } from '../types/IModuleSettingsFilter';
import * as yup from "yup";

interface IState {
    filterRequest: IModuleSettingsFilter;
}

interface IProps{
    filterSettings: (request: IModuleSettingsFilter) => void,
    modules: ISelectedValue[]
}

class Filter extends React.Component<IProps, IState> {
    state: IState = {
        filterRequest: {},
    };

    validationMessages = {
        required: TranslationService.translate("ValidationMessageRequiredField"),
    };

    validationSchema = yup.object().shape({
        moduleId: yup.string().required(this.validationMessages.required),
    });

    public render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={{moduleId: undefined}}
                onSubmit={(value: IModuleSettingsFilter) => {
                    this.props.filterSettings(value);
                }}
                validationSchema={this.validationSchema}
                >
                {({
                      values,
                      handleChange,
                  }) => {
                    return (
                        <Form className="c-form">
                            <div className="row">
                                <div className="col-12 col-lg-4 col-sm-12">
                                    <FormGroup 
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="moduleId"
                                        label={TranslationService.translateModule('ChooseModule', ModuleNamesList.TransferToDebtCollection)}
                                        render={(name) => (
                                            <SimpleSelect
                                                id="moduleId"
                                                enableFilter={true}
                                                name={name}
                                                value={values.moduleId ? values.moduleId.toString() : undefined}
                                                onChangeHandle={(value) => this.handleChange(parseInt(value.key, 10), name, handleChange)}
                                                emptyFirstRow={true}
                                                options={this.props.modules}/>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 align-self-end">
                                    <Button
                                        id="customerTransactionsFilterBtn"
                                        variant={BUTTON_VARIANTS.PRIMARY}
                                        size={BUTTON_SIZE.MD}
                                        label={TranslationService.translateModule('LoadSettings', ModuleNamesList.ModuleSettings)}
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        );
    }

    handleChange = (value: any, name: string, handleChange: any) => {
        const handleChangeEvent = {
            target: {
                id: name,
                name: name,
                value: value
            }
        } as React.ChangeEvent<any>;

        handleChange(handleChangeEvent);
    }
}

export default Filter;
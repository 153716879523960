export const timelineDataInit = {
    className: '',
    content: '',
    end: '' as null | string,
    id: 0,
    start: '',
    style: '',
    title: '',
}

export type TimelineDataType = typeof timelineDataInit;
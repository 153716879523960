import * as React from 'react';
import { ContainerFactory } from '../factories/ContainerFactory';
import { ISkinProps } from '../../core/types/ISkinProps';

export function FourContainersSkin(props: ISkinProps) {
    const containers = props.skin.containers;

    return (
        <>
            <div className="skin">
                <div className="skin__row">
                    <div className="skin__content container">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-xl-9 col-xxl-7">
                                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={0} />
                            </div>
                            <div className="col-12 col-lg-12 col-xl-3 col-xxl-2">
                                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={1} />
                            </div>
                            <div className="col-12 col-xxl-3">
                                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={4} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="skin__row skin__row--last">
                    <div className="skin__content">
                        <div className="row">
                            <div className="main-container col-xl-12">
                                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={2} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="skin__row skin__row--empty">
                    <div className="skin__content">
                        <div className="row">
                            <div className="main-container col-xl-12">
                                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={3} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

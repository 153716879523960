import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { GetInvoiceDetailsActivitiesRequest } from '../types/GetInvoiceDetailsActivitiesRequest';
import { IInvoiceDetailsActivityItem } from '../types/InvoiceDetailsActivityItem';

class InvoiceDetailsActivitiesApi {
    public async getInvoiceDetailsActivities(request: GetInvoiceDetailsActivitiesRequest): Promise<IComponentTable<IInvoiceDetailsActivityItem>> {
        return await RestApiClient.post('modules/InvoiceDetailsActivitiesModule', request);
    }
}

export default new InvoiceDetailsActivitiesApi();
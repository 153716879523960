import * as queryString from 'query-string';

class RouteParamsService {
    public getQueryParameters() : queryString.ParsedQuery{
        const params = queryString.parse(window.location.search)
        return params;
    }

    public getQueryParamsFromRoute(query: string): queryString.ParsedQuery{
        const cleanQuery = query.startsWith('/') || query.startsWith('?') ? query.substring(1) : query;
        const params = queryString.parse(cleanQuery);

        return params;
    }
}

export default new RouteParamsService();
export interface IMessagesModuleSettings {
    messagesFileSizeLimit: number;
    messagesMinLength: number;
    messagesMaxLength: number;
    messagesNumOfFilesLimit: number;
    isMobileViewOnly: boolean
}

export const emptyMessagesModuleSettings: IMessagesModuleSettings = {
    messagesFileSizeLimit: 0,
    messagesMinLength: 0,
    messagesMaxLength: 0,
    messagesNumOfFilesLimit: 0,
    isMobileViewOnly: false
}
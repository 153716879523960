import { useState } from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import * as React from 'react';
import { InputContainer } from '../../../core/components/Forms/InputContainer';
import { SettingsType } from '../../../core/Enums/SettingsType';
import { FORM_GROUP_VARIANTS, FormGroup } from '../../../core/components/Forms/FormGroup';
import { ICurrency } from '../types/ICurrency';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';

interface IProps {
    amount: number,
    maximumAmount: number, 
    currencies: ICurrency[], 
    selectedCurrency: string,
    onChange: (value: number) => void,
    onCurrencyChange: (value: string) => void
}

export const DirectPaymentAmount = (props: IProps) => {
    const [touched, setTouched] = useState(false);

    return (
        <>
            <FormGroup
                variant={FORM_GROUP_VARIANTS.INLINE}
                name="filteredBy"
                render={(name) => (
                    <div className="row">
                        <div className="col-6">
                            <InputContainer 
                                type={SettingsType.String}
                                id="directPaymentAmount"
                                onChange={props.onChange}
                                value={props.amount} 
                                onFocus={() => setTouched(true)}
                            />
                        </div>
                        <div className="col-6">
                           <SimpleSelect
                                id="customers-transactions-select-filter"
                                name="filteredBy"
                                value={props.selectedCurrency}
                                onChangeHandle={(selectedItem) => {
                                    props.onCurrencyChange(selectedItem.value);
                                }}
                                options={props.currencies.map(item => ({key: item.currencyId, value: item.currencyName}))}
                            />
                        </div>
                    </div>
                )}
            />
        
            <div className={`validation-error ${(props.maximumAmount >= props.amount && props.amount > 0) || !touched ? 'd-none' : null}`}>
                {(props.maximumAmount < props.amount) ? TranslationService.translateModule('PaidAmountMustNotExceedMaximumError', ModuleNamesList.DirectPayment) : null}
                {(props.amount <= 0) ? TranslationService.translateModule('PaidAmountMustBePositiveError', ModuleNamesList.DirectPayment) : null}
            </div>
        </>
    )
};
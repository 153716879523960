import RestApiClient from '../../core/api/RestApiClient';
import { IDictionary } from '../types/IDictionary';

class AppSettingsApiClient
{
    public async getApplicationSettings(): Promise<IDictionary<string>> {
        return await RestApiClient.get<IDictionary<string>>('appsettings/getApplicationSettings');
    }
}

export default new AppSettingsApiClient();
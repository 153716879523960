import React from 'react';
import TranslationService from '../../core/services/TranslationService';

import { ModuleNamesList } from '../../core/lists/ModuleNamesList';

import { IModule } from 'core/api/types/IModule';
import { CreateNewReportSteps } from './../Reports/types/CreateNewReportSteps';
import { StepIconsCss } from '../StepListWizard/types/StepIconCss';

// import { StepList } from './../StepListWizard/StepList';
import MultiStepForm from './../../core/components/MultiStepForm/MultiStepForm';
import StepForm from './../../core/components/MultiStepForm/StepForm';
import ReportType from '../Reports/components/ReportType';
import ReportParameters from './../Reports/components/ReportParameters';
import Loader from 'core/components/Loading/Loader';
import { IScheduleData } from 'modules/Reports/types/IScheduleData';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { IReportViewerParams } from '../ReportViewer/IReportViewerParams';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { INumberDictionary } from '../../core/types/IDictionary';
import { IStepListItem } from '../StepListWizard/types/IStepListItem';
import { IOnlineReportsFolder } from '../Reports/types/IOnlineReportsFolder';
import { IOnlineReport } from '../Reports/types/IOnlineReport';
import { IReportParametersShortVM } from '../ScheduleReport/types/IReportParametersShortVM';
import DateTimeParser from '../../core/helpers/DateTimeParser';
import OnlineReportsApiClient from './OnlineReportsApiClient';

interface IProps {
  module: IModule,
}

export interface IOnlineReportState {
  loading: boolean,
  creditorNumberType: number,
  currentStep: CreateNewReportSteps,
  stepList: INumberDictionary<IStepListItem>,
  formData: {
    folder?: IOnlineReportsFolder,
    report?: IOnlineReport,
    reportParameters?: IReportParametersShortVM[]
  },
  reportOutputdata: IScheduleData,
  reportViewerUrl: string,
  folders: IOnlineReportsFolder[]
}

class OnlineReports extends React.PureComponent<IProps, IOnlineReportState> {
  public module: any = flatModuleOptions<any>(this.props.module as any);

  state: IOnlineReportState = {
    loading: true,
    creditorNumberType: 1,
    currentStep: CreateNewReportSteps.Type,
    stepList: {},
    formData: {},
    reportOutputdata: {} as IScheduleData,
    reportViewerUrl: '',
    folders: []
  }

  multiStepLabels = {
    submit: TranslationService.translateModule('BtnSubmitReport', ModuleNamesList.OnlineReports)
  }

  componentDidMount() {
    this.getStepComponentsList();

    OnlineReportsApiClient.getReports(this.props.module.id)
      .then((response) => {
        this.setState({
          loading: false,
          folders: response.folders,
          creditorNumberType: response.creditorNumberType,
          reportViewerUrl: response.reportViewerUrl
        });
    });
  }

  render() {
    return (
      <article className="l-module">
        {this.state.loading ? (
          <Loader opacity={1} />
        ) : (
            <>
              <section className="l-module__section l-module__section--head">
                <h1>
                  <i className="fas fa-chart-bar mr-2" />
                  {TranslationService.translateModule('Reports', ModuleNamesList.OnlineReports)}:
                    <strong className="l-module__title-highlighted">
                    {TranslationService.translateModule('Online', ModuleNamesList.OnlineReports)}
                  </strong>
                </h1>
              </section>

              <section className="l-module__section mt-3">
                <div className="c-creator">
                  <div className="container container--force">
                    <MultiStepForm
                      stepList={this.state.stepList}
                      currentStep={this.state.currentStep}
                      labels={this.multiStepLabels}
                      onSubmit={this.onSubmit}
                      onUpdateStep={this.updateCurrentStep}
                    >
                      <StepForm hideNextBtn={true}>
                        <ReportType
                          folders={this.state.folders}
                          moduleId={this.props.module.id}
                          updateData={this.updateReportTypeData} 
                        />
                      </StepForm>

                      <StepForm>
                        <ReportParameters
                          creditorType={this.state.creditorNumberType}
                          moduleId={this.props.module.id}
                          formData={this.state.formData}
                          data={this.state.reportOutputdata}
                          updateData={this.updateData} />
                      </StepForm>
                    </MultiStepForm>
                  </div>
                </div>
              </section>
            </>
          )}
      </article>
    )
  }

  updateReportTypeData = (data: {
    reportViewerUrl?: string,
    formData: {
      folder?: IOnlineReportsFolder,
      report?: IOnlineReport
    },
  }) => {
    this.setState({
      reportViewerUrl: data.reportViewerUrl ? data.reportViewerUrl : this.state.reportViewerUrl,
      formData: data.formData
    });
  }

  updateData = (data: any) => {
    this.setState({
      formData: {
        ...this.state.formData,
        reportParameters: data.reportParameters
      }
    });
  }

  onSubmit = () => {
    const reportFormParams = this.prepareFormData();
    this.openReportViewerModal(this.state.reportViewerUrl, reportFormParams);
  }

  prepareFormData = () => {
    if (!this.state.formData.reportParameters){
      return [];
    }
    const param = this.state.formData.reportParameters.filter((param: any) => {
      return param.type !== 'Complex';
    });;

    const reportViewerParams: IReportViewerParams[] = [];

    if (this.state.formData.report) {
      reportViewerParams.push({
        name: 'ReportId',
        type: 'String',
        value: this.state.formData.report.id
      });
    }

    for (let i = 0; i < param.length; i++) {
      let { name, type, value } = param[i];
      const report: any = {};
      
      if (type === 'DateTime') {
        value = DateTimeParser.toDateFormat(value as string, 'YYYY-MM-DD');
      }

      value = JSON.stringify({
        Name: name,
        Type: type,
        Value: Array.isArray(value) ? value : [value]
      });

      report.type = 'hidden';
      report.name = name;
      report.value = value;

      reportViewerParams.push(report);
    }

    return reportViewerParams;
  }

  private openReportViewerModal = (reportViewerUrl: string, reportViewerParams: IReportViewerParams[]) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.ReportViewerModalModuleInstanceId), data: {
        modalData: {
          className: 'modal--full',
          MODAL_HEADER_TEXT: TranslationService.translateModule('Report', ModuleNamesList.ReportViewer),
          MODAL_HEADER_ICON: 'fas fa-credit-card',
        },
        otherData: {
          reportViewerUrl: reportViewerUrl,
          reportViewerParams: reportViewerParams
        }
      }
    });
  }

  updateCurrentStep = (currentStep: number) => {
    if (currentStep === 0) {
      this.setState({
        reportOutputdata: {} as IScheduleData
      });
    };

    this.setState({ currentStep });
  }

  private getStepComponentsList = () => {
    this.setState({
      loading: false,
      stepList: {
        [CreateNewReportSteps.Type]: {
          iconCss: StepIconsCss.NewFile,
          text: TranslationService.translateModule('StepHeaderType', ModuleNamesList.OnlineReports),
        },
        [CreateNewReportSteps.Parameters]: {
          iconCss: StepIconsCss.Profile,
          text: TranslationService.translateModule('StepHeaderParametersType', ModuleNamesList.OnlineReports),
        }
      }
    })
  }
}

export default OnlineReports;
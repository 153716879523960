/* global $ */
import React from 'react';
import { connect } from 'react-redux';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { IModuleProps } from '../../core/types/IModuleProps';
import UserIdentityService from '../../core/services/UserIdentityService';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../core/lists/EventsList';
import ChatApiClient from './api/ChatApiClient';
import { IChatMessage } from './types/IChatMessage';
import { IStore } from 'reducers/IStore';

const intelcomChatStarterClass = '.intelecomchatstarter';

interface IProps {
    currentCreditors: string[];
}

const loadScript = (src: string) => {
    const script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);
    return script;
}

class Chat extends React.Component<IProps & IModuleProps> {
    public module: any = flatModuleOptions<any>(this.props.module as any);
    public chatRef: React.Ref<HTMLDivElement>;
    public userIdentityService = new UserIdentityService();
    private moduleEvents: IModuleEventSubscription[] | undefined;

    constructor(props: IProps & IModuleProps) {
        super(props);
        this.chatRef = React.createRef<HTMLDivElement>();
    }

    public componentDidMount() {
        this.registerModuleEvents();

        if (this.isChatDisabled()) {
            this.disableChat();
            return;
        } // else load chat:

        const chatScriptSrc = 'https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.latest.min.js';

        if (!document.querySelector(`script[src="${chatScriptSrc}"]`)) {
            const chatLibs = loadScript('https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.libs.latest.min.js');
            chatLibs.addEventListener('load', () => {
                const chatScript = loadScript(chatScriptSrc);
                chatScript.addEventListener('load', this.initializeChat);
            });
        } else {
            this.initializeChat();
        }
    }

    initializeChat = () => {
        const userDetails = this.userIdentityService.GetUserDetails();

        // @ts-ignore
        $(this.chatRef.current).intelecomChat({
            customerKey: this.module.CustomerKey,
            queueKey: this.module.QueueKey,
            timeId: '21596_time2',
            timeOpenExits: ['Open', 'open', 'åpent', 'Åpent'],
            visualQueueId: parseInt(this.module.VisualQueueId),
            showStarter: false,
            nickName: `${userDetails.firstname} ${userDetails.lastname}`,
            chatId: userDetails.email,
            languageCode: this.module.LanguageCode,
            visualQueueExpression: (q: any) => {
                if (!q || q.AgentsLoggedIn <= 0) {
                    this.disableChat();
                }
                return (q && q.AgentsLoggedIn > 0);
            },
            onContactCenterClosed: () => { this.disableChat() }, // runs when closed
            horizontalStarterPosition: 'right', // position the chat starter circle
            verticalStarterPosition: 'bottom', // position the chat starter circle
            chatlink: '.chatstarter', // default class for chat button link
            onChatEnd: (arg1: any, arg2: any, arg3: any) => {
                arg3.ChatMessages.shift()
                const currentCreditor = this.props.currentCreditors[0];
                arg3.ChatMessages.forEach(function (item: IChatMessage) {
                    item['CreditorNumber'] = currentCreditor;
                });
                ChatApiClient.sendChatMessages(arg3.ChatMessages)
            },
        });

        ModuleEventSubscriber.emitEvent({
            name: EventsList.CHAT_LOADED
        });

        // if chat is still disabled, try to turn it on again:
        setTimeout(() => {
            if (!document.querySelector('.chat-dot')) {
                ModuleEventSubscriber.emitEvent({
                    name: EventsList.CHAT_LOADED
                });
            }
        }, 1000);
    }

    public componentWillUnmount() {
        if (this.moduleEvents) {
            ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
        }
    }

    public shouldComponentUpdate() {
        return false;
    }

    public render() {
        return <div className="intelecomchat" id="OnlineOfficeChatID" ref={this.chatRef} />;
    }

    private isChatDisabled() {
        return this.module.ChatDisabled === "true";
    }

    private registerModuleEvents() {
        this.moduleEvents = [
            { name: EventsList.OPEN_CHAT, callback: this.openChat }
        ];

        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }

    private disableChat() {
        ModuleEventSubscriber.emitEvent({
            name: EventsList.CHAT_DISABLED
        })
    }

    private openChat() {
        // @ts-ignore
        $(intelcomChatStarterClass).click()
    }
}

const mapStateToProps = (state: IStore) => ({
    currentCreditors: state.currentCreditors
});

export default connect(mapStateToProps)(Chat);

import React, { useEffect, useState } from "react";
import TranslationService from "../../../core/services/TranslationService";
import {ItemListType} from "../types/ItemListType";
import { toTitleCaseWithoutWhitespaces } from '../../../core/helpers/toTitleCaseWithoutWhitespaces';

type InputStringArrayProps = {
    error: {},
    name: string,
    label?: string,
    value: string[] | string,
    multiple?: boolean,
    availableSelectAll?: boolean,
    listInputs: ItemListType[],
    handleChange: (item: string[] | string) => void
}

const InputStringArray = (props: InputStringArrayProps) => {
    const {
        error,
        name,
        value,
        label,
        multiple,
        availableSelectAll,
        listInputs,
        handleChange
    } =  props;

    const [currentValue, setCurrentValue] = useState(value);
    const [allSelected, toggleAllSelected] = useState(false);
    const [filterValue, setFilterValue] = useState('');

    const onClickHandler = (item: ItemListType) => {
        const currentValue = item.value as string;
        let selectedValues;

        if (multiple) {
            selectedValues = value as string[];
            const indexExistingValue = selectedValues.indexOf(currentValue);

            if (indexExistingValue < 0) {
                selectedValues.push(currentValue);
            } else {
                selectedValues.splice(indexExistingValue, 1);
            }

            toggleAllSelected(availableSelectAll ? listInputs.length === selectedValues.length : false);
        } else {
            selectedValues = currentValue as string;
        }

        handleChange(selectedValues);
    };

    const handleOnAllSelect = () => {
        let selectedValues: string[] = [];

        if (!allSelected) {
            selectedValues = listInputs.map((input: ItemListType) => {
                return input.value as string;
            });
        }

        toggleAllSelected(listInputs.length === selectedValues.length);

        handleChange(selectedValues);
    };

    useEffect(() => {
        if (!Array.isArray((value))) {
            setCurrentValue([value]);
        } else {
            setCurrentValue(value)
        }

        toggleAllSelected(availableSelectAll ? listInputs.length === value.length : false);
    }, [value, listInputs, availableSelectAll]);

    return (
        <div className="c-select-list">
            <p className="c-select-list__label">{label ? TranslationService.translate(toTitleCaseWithoutWhitespaces(label || ''), label) : label}</p>

            <select
                name={name}
                multiple={multiple}
                defaultValue={currentValue}
            >
                {listInputs && listInputs.map((item: ItemListType, index: number) => {
                    const name = item.name || item.key;

                    return (
                        <option
                            key={index}
                            value={item.value}
                            className="c-creator-list__item">
                            {name}
                        </option>
                    )
                })}
            </select>

            <div className="c-select-list__filter">
                <input 
                    type="text" 
                    className="form-control" 
                    value={filterValue} 
                    placeholder={TranslationService.translate('Filter')}
                    onChange={(e) => setFilterValue(e.target.value.toLowerCase())} 
                />
            </div> 

            <ul className="c-list c-creator-list c-creator-list--2">
                {availableSelectAll &&
                    <li key="0" className="c-creator-list__item" onClick={handleOnAllSelect}>
                        {TranslationService.translate('SelectAll')}
                        {allSelected
                            ? <i className="far fa-check-square"/>
                            : <i className="far fa-square" />
                        }
                    </li>
                }

                {listInputs && listInputs.map((item: ItemListType, index: number) => {
                    const inputName = item.name || item.key;
                    const inputValue = item.value as string;

                    return inputName && inputName.toLowerCase().includes(filterValue) && (
                        <li
                            key={index}
                            className="c-creator-list__item"
                            onClick={() => onClickHandler(item)}
                        >
                            {inputName}

                            {value.indexOf(inputValue) > -1 ? (
                                <i className="far fa-check-square" />
                            ) : (
                                <i className="far fa-square" />
                            )}
                        </li>
                    )
                })}
            </ul>

            {error && <div className="c-input-error">{error}</div>}
        </div>
    )
};

InputStringArray.defaultProps = {
    label: '',
    multiple: false,
    availableSelectAll: false
};

export default InputStringArray;
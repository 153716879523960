import React from 'react';
import { IModuleProps } from '../../core/types/IModuleProps';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import { IReportElement } from './types/IReportElement';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { EventsList } from '../../core/lists/EventsList';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import InfoMessageService from '../../core/services/InfoMessageService';
import GeneratedReportsApiClient from './GeneratedReportsApiClient';
import { IComponentTable, emptyComponentTable } from '../../core/types/IComponentTable';
import GeneratedReportsTableConfigurator from './Helpers/GeneratedReportsTableConfigurator';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import Loader from '../../core/components/Loading/Loader';
import './GeneratedReports.scss';

interface IState {
    isDataLoading: boolean;
    currentPage: number;
    sizePerPage: number;
    reportsTable: IComponentTable<IReportElement>;
    sortBy: string;
    sortDirection: string;
}

class GeneratedReports extends React.Component<IModuleProps, IState> {
    public state: IState = {
        isDataLoading: true,
        currentPage: 1,
        sizePerPage: 20,
        reportsTable: {...emptyComponentTable},
        sortBy: '',
        sortDirection: ''
    }

    private moduleEvents: IModuleEventSubscription[];

    public componentDidMount() {
        this.registerModuleEvents();
        this.fetchData();
    }

    public componentWillUnmount() {
        ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
    }

    public render() {
        return (
            <>
                <article className="l-module">
                    <section className="l-module__section l-module__section--head">
                        <h1>
                            <i className="fas fa-chart-bar mr-2" />
                            {TranslationService.translateModule('Reports', ModuleNamesList.GeneratedReports)}:
                            <strong className="l-module__title-highlighted">
                                {TranslationService.translateModule('Inbox', ModuleNamesList.GeneratedReports)}
                            </strong>
                        </h1>
                    </section>

                    <section className="l-module__section mt-3">
                        {this.state.isDataLoading && <Loader opacity={0.5} />}
    
                        <BootstrapTableContainer
                            isDataLoading={this.state.isDataLoading}
                            remote={false}
                            wrapperClasses='bt'
                            classes='bt__table bt-table'
                            keyField='id'
                            data={this.state.reportsTable.values}
                            columns={GeneratedReportsTableConfigurator.getTableColumns(this.state.reportsTable, this.downloadDocument)}
                        /> 
                    </section>
                </article>
            </>
        )
    }

    private downloadDocument = async (reportId: string, creditorNumber: string) => {
        let reportsList = [...this.state.reportsTable.values];
        const documentIndex = reportsList.findIndex((elem: IReportElement) => elem.id === reportId);
        let document = {...reportsList[documentIndex]};
        document.isDownloading = true;
        reportsList[documentIndex] = document;
        this.setState({
            reportsTable: {
                ...this.state.reportsTable,
                values: reportsList
            }
        });
        try {
            await GeneratedReportsApiClient.downloadReport(reportId, creditorNumber);
        }
        finally
        {
            reportsList = [...this.state.reportsTable.values];
            document = {...reportsList[documentIndex]};
            document.isDownloading = false;
            reportsList[documentIndex] = document;
            this.setState({
                reportsTable: {
                    ...this.state.reportsTable,
                    values: reportsList
                }
            });
        }
    }

    private fetchData = async () => {
        this.setState({
            isDataLoading: true
        });

        try{
            const reportsTable = await GeneratedReportsApiClient.getReports(this.props.module.id);
            this.setState({
                isDataLoading: false,
                reportsTable
            });
        }
        catch{
            InfoMessageService.error(TranslationService.translate('ErrorLoadingData'))
            this.setState({
                isDataLoading: false,
                reportsTable: {...emptyComponentTable}
            });
        }
    }

    private registerModuleEvents() {
        this.moduleEvents = [
            { name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.fetchData }
        ];
        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }
}

export default GeneratedReports;
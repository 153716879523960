import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

export const ActionButtons = (props: { 
    onSave?: () => Promise<void>, 
    onCancel?: () => void 
}) => {
    return (
        <div className="action-buttons">
            {props.onCancel ? (
                <Button
                    type="button"
                    id="caseListFilterBtn"
                    className="mr-2"
                    icon={{
                        className: 'fas fa-times',
                        position: ''
                    }}
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTON_SIZE.SM}
                    label={TranslationService.translate('Cancel')}
                    onClick={props.onCancel} 
                />
            ) : null}

            {props.onSave ? (
                <Button
                    type="button"
                    id="caseListFilterBtn"
                    className="mr-2"
                    icon={{
                        className: 'fas fa-save',
                        position: ''
                    }}
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTON_SIZE.SM}
                    label={TranslationService.translate('Save')}
                    onClick={props.onSave} 
                />
            ) : null}
        </div>
    )
}
// @ts-ignore
import $ from 'jquery';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers/rootReducer';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'airbnb-js-shims';

import 'animate.css/animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'toastr/toastr.scss';
import './index.scss';
import './assets/styles/main.scss';

import { QueryClient, QueryClientProvider } from 'react-query';
import AppLoader from './AppLoader';

const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
const composeEnhancers =
  windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const queryClient = new QueryClient();

export const store = createStore(rootReducer, enhancer);

window['$'] = $;
window['jQuery'] = $;

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AppLoader />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement
);

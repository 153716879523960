import { CancelToken } from 'axios';
import { IDictionary } from '../../../core/types/IDictionary';
import RestApiClient from '../../../core/api/RestApiClient';

class TilesApiClient {
    private cancelToken: CancelToken;

    constructor(cancelToken: CancelToken) {
        this.cancelToken = cancelToken;
    }

    public async getCasesCounters(): Promise<IDictionary<number>> {
        return await RestApiClient.get<IDictionary<number>>('modules/TilesModule/getCasesCountersForCreditor', {}, this.cancelToken);
    }

    public async getModuleSettings(moduleInstanceId: number): Promise<any> {
        return await RestApiClient.get<any>(`modules/TilesModule/getInstanceSettings/${moduleInstanceId}`, {}, this.cancelToken);
    }
}

export default TilesApiClient;
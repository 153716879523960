import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { Button, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import AddPropertyForm from '../components/AddPropertyForm';
import ManageUserProperties from '../components/ManageUserProperties';
import { IUserProperty } from '../types/IUserProperty';
import { IGroupUser } from '../types/IGroupUser';
import { IUserPropertyDefinition } from '../types/IUserPropertyDefinition';
import ManageGroupUserApiClient from '../api/ManageGroupUserApiClient';
import { FORM_GROUP_VARIANTS, FormGroup } from '../../../core/components/Forms/FormGroup';
import { SettingsType } from '../../../core/Enums/SettingsType';
import { InputContainer } from '../../../core/components/Forms/InputContainer';
import Loader from '../../../core/components/Loading/Loader';
import InfoMessageService from 'core/services/InfoMessageService';
import { Status } from 'core/api/Enums/Status';

interface IState {
    isDataLoading: boolean,
    userProperties: IUserProperty[],
    userDetails: IGroupUser,
    propertyDefinitions: IUserPropertyDefinition[],
    showEmptyUsernameError: boolean
}

interface IProps {
    userUId?: string,
    hideModal: () => void,
    reloadList: () => void,
}

export default class ManageGroupUser extends React.Component<IProps, IState> {
    public state: IState = {
        isDataLoading: false,
        userProperties: [],
        propertyDefinitions: [],
        showEmptyUsernameError: false,
        userDetails: { userName: '' } as IGroupUser
    }

    public componentDidMount = async() => {
        await this.fetchInitialData();
    }

    public render() {
        return (
            <div>
                <Loader isLoaded={!this.state.isDataLoading} opacity={0.5}/>
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                        <FormGroup
                            variant={FORM_GROUP_VARIANTS.BLOCK}
                            name="userName"
                            label={TranslationService.translateModule('FormUserName', ModuleNamesList.GroupUsersList)}
                            render={(name: any) => (
                                <>
                                    <InputContainer
                                        name={name}      
                                        type={SettingsType.String}
                                        value={this.state.userDetails.userName}
                                        id="group-user-name"
                                        onChange={(value)=>this.setState({userDetails:{...this.state.userDetails, userName: value}, showEmptyUsernameError: !value || value.length === 0})}
                                    />
                                    {this.state.showEmptyUsernameError && <div className="error error-message">{TranslationService.translate("ValidationMessageRequiredField")}</div>}
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <AddPropertyForm
                            propertyDefinitions={this.state.propertyDefinitions} 
                            addProperty={this.addUserProperty}   
                            userPropertiesCount={this.state.userProperties.length}  
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ManageUserProperties 
                            userProperties={this.state.userProperties} 
                            removeUserProperty={this.removeUserProperty}
                        />
                    </div>
                </div>
                <Button
                    type="button"
                    label={TranslationService.translate('Save')}
                    className="float-right"
                    onClick={this.saveUserGroup}
                    variant={BUTTON_VARIANTS.PRIMARY}
                />
            </div>
        )
    }

    private fetchInitialData = async () => {
        this.setState({isDataLoading: true}, async () => {
            const initialData = await ManageGroupUserApiClient.getInitialData(this.props.userUId);
            this.setState({ 
                isDataLoading: false,
                userProperties: initialData.userProperties.map((x, index) => ({...x, index})),
                userDetails: initialData.userDetails,
                propertyDefinitions: initialData.propertyDefinitions
            });
        });
    }

    private removeUserProperty = (index: number) => {
        const userProperties = this.state.userProperties;
        if (!this.props.userUId) {
            userProperties.splice(index, 1);
        } else {
            userProperties[index] = {
                ...userProperties[index],
                isRemoved: true
            }
        }
        this.setState({userProperties});
    }

    private addUserProperty = (property: IUserProperty) => {
        const userProperties = this.state.userProperties;
        userProperties.push(property);
        this.setState({userProperties});
    }

    private saveUserGroup = async () => {

        if (this.state.userDetails.userName && this.state.userDetails.userName.length !== 0) {
            this.setState({isDataLoading: true});
            const userProperties = this.state.userProperties.filter(x => !x.isRemoved);
            const result = await ManageGroupUserApiClient.saveUserDetails({user: this.state.userDetails, userProperties});
            this.setState({isDataLoading: false}, async () => {
                if (result.status === Status.Success) {
                    InfoMessageService.success(TranslationService.translateModule('SuccessUserManagment', ModuleNamesList.GroupUsersList));
                    await this.props.reloadList();            
                    this.props.hideModal();        
    
                } else {
                    InfoMessageService.displayActionStatus(result);
                }
            });            
        } else {
            this.setState({showEmptyUsernameError: true})
        }
    }
}
import React from 'react';
import styles from '../Card.module.scss';
import classNames from 'classnames';

enum CardHeaderVariants {
    Secondary = 'secondary'
}

type CardHeaderPropsType = {
    children: React.ReactChild;
    variant?: CardHeaderVariants
}

const CardHeader = (props: CardHeaderPropsType) => {
    return (
        <div className={classNames(
            styles.card__header,
            props.variant && styles[`card__header--${props.variant}`]
        )}>
            {props.children}
        </div>
    )
}

export {
    CardHeader,
    CardHeaderVariants
};
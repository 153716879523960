// TODO: those url should be stored in some kind of global application settings rather than be hardcoded here
export const ROOT_PAGE_URL = '/'
export const CREATE_NEW_CREDITOR_URL = 'create-new-creditor'
export const REGISTER_USER_URL = 'register'
export const ERROR_URL = 'error'
export const CREDITOR_WAITING_FOR_APPROVAL_URL = 'creditorAwaitingApproval'

export const localeToCountryCode = {
    'da-dk': 'DA',
    'en-us': 'EN',
    'nb-no': 'NO',
    'sv-se': 'SV',
}
import * as React from 'react';
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import { IComponentTableColumn } from '../../types/IComponentTableColumn';
import TranslationService from '../../services/TranslationService';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';

interface ILocalBootstrapTable extends IBaseBootstrapTableProps {
  paginationProps?: IPaginationFactoryProps
}

interface IPaginationFactoryProps {
    custom?: boolean,
    page?: number;
    sizePerPage?: number;
    totalSize?: number;
    hideSizePerPage?: boolean;
    alwaysShowAllBtns?: boolean;
    hidePageListOnlyOnePage?: boolean;
}

interface IBaseBootstrapTableProps {
    classes: string;
    cellEdit?: any
    columns: IComponentTableColumn[];
    data: any[];
    defaultSorted?: any,
    selectRow?: any;
    expandRow?: any;
    keyField: string;
    nonEditableRows?: number[];
    onTableChange?: any;
    rowClasses?: (row: any) => string,
    wrapperClasses?: string;
    id?: string,
}

interface IRemoteBootstrapTable extends IBaseBootstrapTableProps {
    paginationProps: IPaginationFactoryProps;
}

const NoDataIndication = () => (
    <div className="alert alert-primary" role="alert">
        {TranslationService.translate('NoDataToDisplay')}
    </div>
);

export const RemoteBootstrapTable = (props: IRemoteBootstrapTable) => {
    return (
        (props.columns && props.columns.length) ? (
            <BootstrapTable
                wrapperClasses={props.wrapperClasses}
                remote={{ pagination: true }}
                classes={props.classes}
                keyField={props.keyField}
                alwaysShowAllBtns={true}
                data={props.data}
                rowClasses={props.rowClasses}
                columns={props.columns}
                onTableChange={props.onTableChange}
                expandRow={props.expandRow}
                noDataIndication={() => <NoDataIndication />}
                defaultSorted={props.defaultSorted}
                selectRow={props.selectRow}
            />
        ) : null
    )
};

export const LocalBootstrapTable = (props: ILocalBootstrapTable) => (
    <>
        <BootstrapTable
            wrapperClasses={props.wrapperClasses}
            classes={props.classes}
            keyField={props.keyField}
            data={props.data}
            id={props.id}
            columns={props.columns}
            pagination={paginationFactory({
            page: props.paginationProps ? props.paginationProps.page || undefined : undefined,
            sizePerPage: props.paginationProps ? props.paginationProps.sizePerPage || undefined : undefined,
            totalSize: props.paginationProps ? props.paginationProps.totalSize  || undefined : undefined,
            hideSizePerPage: props.paginationProps ? props.paginationProps.hideSizePerPage || undefined : undefined,
            alwaysShowAllBtns: props.paginationProps ? props.paginationProps.alwaysShowAllBtns || undefined : undefined,
            hidePageListOnlyOnePage: props.paginationProps ? props.paginationProps.hidePageListOnlyOnePage : undefined,
            })}
            selectRow={props.selectRow}
            noDataIndication={() => <NoDataIndication />}
            onTableChange={props.onTableChange}
            defaultSorted={props.defaultSorted}
            cellEdit={cellEditFactory(props.cellEdit)}
        />
    </>
);
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import fetchLeftMenu from '../../actionCreators/fetchLeftMenu';
import {Button, BUTTON_SIZE, BUTTON_VARIANTS} from '../../core/components/Button/Button';
import Select from '../../core/components/Forms/Select';
import Loader from '../../core/components/Loading/Loader';
import {Typography, TypographyVariants} from '../../core/components/Typography/Typography';
import {ModuleNamesList} from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import {ILeftMenuElement} from '../../core/types/ILeftMenuElement';
import {LeftMenuModel} from '../../core/types/LeftMenuModel';
import {IStore} from '../../reducers/IStore';
import ManageLeftMenuApiClient from './ManageLeftMenuApiClient';
import {IProfile} from './types/IProfile';
import {ILeftMenuGroup} from "../../core/types/ILeftMenuGroup";

enum MoveType {
  Up,
  Down
}

const ManageLeftMenu = (props: { fetchLeftMenu: () => Promise<any> }) => {
  const [isLoading, setIsLoading] = useState(false);
  // @ts-ignore
  const [originalMenu, setOriginalMenu] = useState<LeftMenuModel>();
  const [menu, setMenu] = useState<LeftMenuModel>();
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  // @ts-ignore
  const [selectedProfile, setSelectedProfile] = useState<string>();

  const load = async () => {
    setIsLoading(true);
    const profilesData = await ManageLeftMenuApiClient.getProfiles();
    setProfiles(profilesData);
    if (profilesData && profilesData.length > 0) {
      setSelectedProfile(profilesData[0].id);
      await loadMenu(profilesData[0].id);
    }
    setIsLoading(false);
  }

  const loadMenu = async (profileId: string) => {
    const menuData = await ManageLeftMenuApiClient.getMenu(profileId);
    setOriginalMenu(menuData)
    setMenu(menuData)
  }

  const toggleVisibility = (id: number, parentId?: number) => {
    if (menu) {
      let currentItem = menu.items.filter(g => g.id === id)[0];
      let currentItemIndex = menu.items.indexOf(currentItem);
      let newItems: ILeftMenuElement[] = [];

      if (parentId) {
        currentItem = menu.items.filter(g => g.id === parentId)[0];
        currentItemIndex = menu.items.indexOf(currentItem);

        const currentSublink = currentItem.items.filter(g => g.id === id)[0];
        const currentSublinkIndex = currentItem.items.indexOf(currentSublink);

        newItems = [
          ...menu.items.slice(0, currentItemIndex),
          {
            ...currentItem,
            items: [
              ...currentItem.items.slice(0, currentSublinkIndex),
              {...currentSublink, enabled: !currentSublink.enabled},
              ...currentItem.items.slice(currentSublinkIndex + 1)
            ],
          },
          ...menu.items.slice(currentItemIndex + 1)
        ]

      } else {
        newItems = [
          ...menu.items.slice(0, currentItemIndex),
          {
            ...currentItem,
            enabled: !currentItem.enabled,
            items: [...currentItem.items.map(s => ({...s, enabled: !currentItem.enabled}))]
          },
          ...menu.items.slice(currentItemIndex + 1),
        ]
      }

      setMenu({
        ...menu,
        items: newItems
      })
    }
  }

  const toggleGroupVisibility = (id: number) => {
    if (menu) {
      const currentGroup = menu.groups.filter(g => g.id === id)[0];
      const currentGroupIndex = menu.groups.indexOf(currentGroup);

      currentGroup.enabled = !currentGroup.enabled;

      const currentGroupItems = menu.items.filter(i => i.order > currentGroup.order && i.order < currentGroup.order + 1000);
      const allOtherItems = menu.items.filter(i => currentGroupItems.indexOf(i) === -1);

      setMenu({
        groups: [
          ...menu.groups.slice(0, currentGroupIndex),
          {...currentGroup},
          ...menu.groups.slice(currentGroupIndex + 1)
        ],
        items: [
          ...allOtherItems,
          ...currentGroupItems.map(i => ({
            ...i, enabled: currentGroup.enabled, items: [
              ...i.items.map(s => ({...s, enabled: currentGroup.enabled}))
            ]
          }))
        ]
      })
    }
  }

  useEffect(() => {
    load()
  }, []);

  const mapSublinks = (key: string, parentId: number, items: ILeftMenuElement[], visibilityEnabled: boolean) => (
    items && items.length ?
      <div>
        <div className="c-mng-menu c-mng-menu--sub">
          {items.map(i =>
            <div className="c-mng-menu__item--manage" key={`${i.id}_${parentId}`}>
              <div className="c-mng-menu__item nav-item">
                <span
                  className="c-mng-menu__link c-mng-menu__link--sub">{TranslationService.translateModule(i.translationKey, i.translationModuleName || '')}</span>
              </div>
              <div className="c-mng-menu__action-btns">
                <Button
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.SM}
                  className={"c-mng-menu__move-btn"}
                  icon={{className: i.enabled ? 'fas fa-eye' : 'fas fa-eye-slash', position: 'left'}}
                  onClick={() => {
                    toggleVisibility(i.id, parentId)
                  }}
                  disabled={!visibilityEnabled}
                />
                <Button
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.SM}
                  className="c-mng-menu__move-btn"
                  icon={{className: 'fas fa-chevron-up', position: 'left'}}
                  onClick={() => {
                    moveItemUp(i.id, parentId)
                  }}
                />
                <Button
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.SM}
                  className="c-mng-menu__move-btn"
                  icon={{className: 'fas fa-chevron-down', position: 'left'}}
                  onClick={() => {
                    moveItemDown(i.id, parentId)
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      : null
  )

  const mapItemsToElements = (key: string, items: ILeftMenuElement[], visibilityEnabled: boolean, parentOrder?: number) => (
    items && items.length ?
      <div key={key}>
        {items.map(i =>
          <div key={`${key}_${i.id}`}
               className={`${i.items && i.items.length ? 'c-mng-menu__item c-mng-menu__item--opened' : 'c-mng-menu__item'}`}>
            <div className="c-mng-menu__item--manage">
              <div className="c-mng-menu__link">
                {i.iconClass ? <i className={i.iconClass}/> : null}
                <span>
                  {TranslationService.translateModule(i.translationKey, i.translationModuleName || '')}
                </span>
              </div>
              <div className="c-mng-menu__action-btns">
                <Button
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.SM}
                  className={"c-mng-menu__move-btn"}
                  icon={{className: i.enabled ? 'fas fa-eye' : 'fas fa-eye-slash', position: 'left'}}
                  onClick={() => {
                    toggleVisibility(i.id)
                  }}
                  disabled={!visibilityEnabled}
                />
                <Button
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.SM}
                  className="c-mng-menu__move-btn"
                  icon={{className: 'fas fa-chevron-up', position: 'left'}}
                  onClick={() => {
                    moveGroupItem(MoveType.Up, i.id, parentOrder)
                  }}
                />
                <Button
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.SM}
                  className="c-mng-menu__move-btn"
                  icon={{className: 'fas fa-chevron-down', position: 'left'}}
                  onClick={() => {
                    moveGroupItem(MoveType.Down, i.id, parentOrder)
                  }}
                />
              </div>
            </div>
            {i.items && i.items.length ? mapSublinks(`${key}_${i.id}`, i.id, i.items, i.enabled && visibilityEnabled) : null}
          </div>)}
      </div>
      : null
  )

  const moveGroupUp = (id: number) => {
    if (menu) {
      const currentGroup = menu.groups.filter(g => g.id === id)[0];
      const currentGroupIndex = menu.groups.indexOf(currentGroup);

      if (currentGroupIndex !== 0) {
        const prevGroup = menu.groups[currentGroupIndex - 1];
        const currentOrder = currentGroup.order;
        const prevGroupOrder = prevGroup.order;

        currentGroup.order = prevGroup.order;
        prevGroup.order = currentOrder;

        const currentGroupItems = menu.items.filter(i => i.order > currentOrder && i.order < currentOrder + 1000);
        const prevGroupItems = menu.items.filter(i => i.order > prevGroupOrder && i.order < prevGroupOrder + 1000);
        const allOtherGroups = menu.groups.filter(g => g.id !== prevGroup.id && g.id !== currentGroup.id);
        const allOtherItems = menu.items.filter(i => currentGroupItems.indexOf(i) === -1 && prevGroupItems.indexOf(i) === -1);

        currentGroupItems.forEach((value, index) => value.order = currentGroup.order + 1 + index)
        prevGroupItems.forEach((value, index) => value.order = prevGroup.order + 1 + index)

        const newGroups = [
          ...allOtherGroups,
          {...currentGroup},
          {...prevGroup}
        ].sort((a, b) => {
          return a.order > b.order ? 1 : a.order < b.order ? -1 : 0
        });

        const newItems = [
          ...allOtherItems,
          ...prevGroupItems,
          ...currentGroupItems
        ].sort((a, b) => {
          return a.order > b.order ? 1 : a.order < b.order ? -1 : 0
        });

        setMenu({
          groups: newGroups,
          items: newItems
        })

      }

    }
  }
  const moveGroupDown = (id: number) => {
    if (menu) {
      const currentGroup = menu.groups.filter(g => g.id === id)[0];
      const currentGroupIndex = menu.groups.indexOf(currentGroup);

      if (currentGroupIndex !== menu.groups.length - 1) {
        const nextGroup = menu.groups[currentGroupIndex + 1];
        const currentOrder = currentGroup.order;
        const prevGroupOrder = nextGroup.order;

        currentGroup.order = nextGroup.order;
        nextGroup.order = currentOrder;

        const currentGroupItems = menu.items.filter(i => i.order > currentOrder && i.order < currentOrder + 1000);
        const nextGroupItems = menu.items.filter(i => i.order > prevGroupOrder && i.order < prevGroupOrder + 1000);
        const allOtherGroups = menu.groups.filter(g => g.id !== nextGroup.id && g.id !== currentGroup.id);
        const allOtherItems = menu.items.filter(i => currentGroupItems.indexOf(i) === -1 && nextGroupItems.indexOf(i) === -1);

        currentGroupItems.forEach((value, index) => value.order = currentGroup.order + 1 + index)
        nextGroupItems.forEach((value, index) => value.order = nextGroup.order + 1 + index)

        const newGroups = [
          ...allOtherGroups,
          {...currentGroup},
          {...nextGroup}
        ].sort((a, b) => a.order - b.order);

        const newItems = [
          ...allOtherItems,
          ...nextGroupItems,
          ...currentGroupItems
        ].sort((a, b) => a.order - b.order);

        setMenu({
          groups: newGroups,
          items: newItems
        })

      }
    }
  }

  const moveItemUp = (id: number, parentId: number) => {
    if (menu) {
      let newItems: ILeftMenuElement[] = [...menu.items];
      const parentItem = menu.items.filter(g => g.id === parentId)[0];
      const parentItemIndex = menu.items.indexOf(parentItem);
      const currentItem = parentItem.items.filter(g => g.id === id)[0];
      const currentItemIndex = parentItem.items.indexOf(currentItem);

      if (currentItemIndex !== 0) {
        const prevItem = parentItem.items[currentItemIndex - 1];
        const currentOrder = currentItem.order;

        currentItem.order = prevItem.order;
        prevItem.order = currentOrder;

        newItems = [
          ...menu.items.slice(0, parentItemIndex),
          {
            ...parentItem,
            items: [
              ...parentItem.items.slice(0, currentItemIndex - 1),
              {...currentItem},
              {...prevItem},
              ...parentItem.items.slice(currentItemIndex + 1),
            ]
          },
          ...menu.items.slice(parentItemIndex + 1),
        ].sort((a, b) => a.order - b.order);
      }

      setMenu({
        ...menu,
        items: [...newItems]
      })
    }
  }
  const moveItemDown = (id: number, parentId: number) => {
    if (menu) {
      let newItems: ILeftMenuElement[] = [...menu.items];

      const parentItem = menu.items.filter(g => g.id === parentId)[0];
      const parentItemIndex = menu.items.indexOf(parentItem);
      const currentItem = parentItem.items.filter(g => g.id === id)[0];
      const currentItemIndex = parentItem.items.indexOf(currentItem);

      if (currentItemIndex !== parentItem.items.length - 1) {
        const nextItem = parentItem.items[currentItemIndex + 1];
        const allOtherItems = parentItem.items.filter(i => i.id !== currentItem.id && i.id !== nextItem.id);

        if (nextItem.order - currentItem.order === 1) {
          currentItem.order = nextItem.order;
          nextItem.order = currentItem.order - 1;
        } else {
          currentItem.order = nextItem.order + 1;
        }

        const newSortedItems = [
          ...allOtherItems,
          {...currentItem},
          {...nextItem}
        ].sort((a, b) => a.order - b.order);

        newItems = [
          ...menu.items.slice(0, parentItemIndex),
          {
            ...parentItem,
            items: newSortedItems
          },
          ...menu.items.slice(parentItemIndex + 1),
        ].sort((a, b) => a.order - b.order);
      }

      setMenu({
        ...menu,
        items: [...newItems]
      })
    }
  }

  const moveGroupItem = (type: MoveType, id: number, groupOrder?: number) => {
    if (menu && groupOrder) {
      const currentGroupItem = menu.groups.filter(g => g.order === groupOrder)[0];
      const currentGroupIndex = menu.groups.indexOf(currentGroupItem);
      let newMenuItems = menu.items;
      let searchedGroupItem: ILeftMenuGroup = {} as ILeftMenuGroup;

      if (currentGroupIndex >= 0) {
        const currentItem = menu.items.filter(g => g.id === id)[0];
        const currentItemIndex = menu.items.indexOf(currentItem);

        if (type === 0) {
          const prevItem = menu.items[currentItemIndex - 1];

          if (!prevItem) {
            return;
          }

          if (Math.trunc(currentItem.order / 1000) === Math.trunc(prevItem.order / 1000)) {
            currentItem.order = prevItem.order;
            prevItem.order = currentItem.order + 1;

            setMenu({
              ...menu,
              items: [
                ...menu.items.slice(0, currentItemIndex - 1),
                {...currentItem},
                {...prevItem},
                ...menu.items.slice(currentItemIndex + 1),
              ].sort((a, b) => a.order - b.order)
            })

            return;
          }

          searchedGroupItem = menu.groups[currentGroupIndex - 1];
        } else {
          const nextItem = menu.items[currentItemIndex + 1];

          if (!nextItem) {
            return;
          }

          const allOtherItems = menu.items.filter(i => i.id !== currentItem.id && i.id !== nextItem.id);

          if (Math.trunc(currentItem.order / 1000) === Math.trunc(nextItem.order / 1000)) {
            currentItem.order = nextItem.order;
            nextItem.order = currentItem.order - 1;

            setMenu({
              ...menu,
              items: [
                ...allOtherItems,
                {...nextItem},
                {...currentItem}
              ].sort((a, b) => a.order - b.order)
            })

            return;
          }

          searchedGroupItem = menu.groups[currentGroupIndex + 1];
        }

        if (searchedGroupItem) {
          let lastItemOrderValue = searchedGroupItem.order + 1;

          newMenuItems = newMenuItems.map(i => {
            if (i.order >= searchedGroupItem.order && i.order < (searchedGroupItem.order + 1000)) {
              lastItemOrderValue = i.order;

              return {
                ...i,
                order: type === 0 ? i.order : i.order + 1
              }
            }

            return i;
          }).map(item => {
            if (item.id === id) {
              return {
                ...item,
                order: type === 0 ? lastItemOrderValue : searchedGroupItem.order + 1
              };
            }

            return item;
          });

          setMenu({
            ...menu,
            items: newMenuItems.sort((a, b) => a.order - b.order)
          })
        }
      }
    }
  };

  const save = async () => {
    if (menu) {
      try {
        const status = await ManageLeftMenuApiClient.saveMenu(menu);
        await props.fetchLeftMenu();
        InfoMessageService.displayActionStatus(status);
      } catch (err) {
        InfoMessageService.error(TranslationService.translate('Error'))
      }
    }
  }

  return (
    <article className="l-module">
      <div className="l-module__item">
        <section className="l-module__section l-module__section--head">
          <h1>
            <i className="fas fa-bars mr-2"/>
            {TranslationService.translateModule('LeftMenuItemApplicationSettings', ModuleNamesList.ApplicationSettings)}:
            <strong
              className="l-module__title-highlighted">{TranslationService.translateModule('ManageLeftMenu', ModuleNamesList.ManageLeftMenu)}</strong>
          </h1>
        </section>

        <div className="c-mng-menu">
          {isLoading && <Loader small={true}/>}
          <section className="l-module__section l-module__section--filter mb-3">
            {profiles && profiles.length &&
            <Select>
              <>
                <Select.Label>
                  <Typography variant={TypographyVariants.Paragraph}>
                    {TranslationService.translateModule('SelectProfile', ModuleNamesList.ManageLeftMenu)}
                  </Typography>
                </Select.Label>

                <Select.Options
                  name="profileSelect"
                  id="profileSelect"
                  disabled={!profiles.length}
                  options={profiles}

                  onChange={async (option: any) => {
                    setSelectedProfile(option.id);
                    await loadMenu(option.id);
                  }}
                />
              </>
            </Select>
            }
          </section>
          <section className="l-module__section">
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTON_SIZE.MD}
              label={TranslationService.translate('Save')}
              className="ml-4"
              onClick={save}
            />
            {menu ?
              <aside className="c-mng-menu-sidebar">
                <div className="c-mng-menu nav flex-column">
                  <div className="c-mng-menu__item c-mng-menu__item--group">
                    <div className="c-mng-menu-group__header">
                      <span>{TranslationService.translateModule('Ungrouped', ModuleNamesList.ManageLeftMenu)}</span>
                    </div>
                    {mapItemsToElements(`no_group`, menu.items.filter(i => (menu.groups && menu.groups.length ? i.order < menu.groups[0].order : true)), true)}
                  </div>

                  {menu.groups.sort((a, b) => {
                    return a.order > b.order ? 1 : a.order < b.order ? -1 : 0
                  }).map(g => (
                    <div className="c-mng-menu__item c-mng-menu__item--group flex-column" key={g.id}>
                      <div className="c-mng-menu-group__header">
                        <span>{TranslationService.translateModule(g.translationKey, g.translationModuleName || '')} </span>
                        <div className="c-mng-menu__action-btns">
                          <Button
                            variant={BUTTON_VARIANTS.ICON}
                            size={BUTTON_SIZE.SM}
                            className={"c-mng-menu__move-btn"}
                            icon={{className: g.enabled ? 'fas fa-eye' : 'fas fa-eye-slash', position: 'left'}}
                            onClick={() => {
                              toggleGroupVisibility(g.id)
                            }}
                          />
                          <Button
                            variant={BUTTON_VARIANTS.ICON}
                            size={BUTTON_SIZE.MD}
                            className="c-mng-menu__move-btn"
                            icon={{className: 'fas fa-chevron-up', position: 'left'}}
                            onClick={() => {
                              moveGroupUp(g.id)
                            }}
                          />
                          <Button
                            variant={BUTTON_VARIANTS.ICON}
                            size={BUTTON_SIZE.MD}
                            className="c-mng-menu__move-btn"
                            icon={{className: 'fas fa-chevron-down', position: 'left'}}
                            onClick={() => {
                              moveGroupDown(g.id)
                            }}
                          />
                        </div>
                      </div>
                      <div className="c-mng-menu__group-body">
                        {menu.items && mapItemsToElements(`${g.id}`, menu.items.filter(i => i.order > g.order && i.order < g.order + 1000), g.enabled, g.order)}
                      </div>
                    </div>))}
                </div>
              </aside> : null}
          </section>
        </div>
      </div>
    </article>);
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, AnyAction>) => ({
  fetchLeftMenu: () => dispatch(fetchLeftMenu())
});

export default connect(null, mapDispatchToProps)(ManageLeftMenu);
import RestApiClient from '../../../core/api/RestApiClient';
import { GetInvoiceDetailsPaymentsRequest } from '../types/GetInvoiceDetailsPaymentsRequest';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { InvoiceDetailsPaymentItem } from '../types/InvoiceDetialsPaymentItem';

class InvoiceDetailsPaymentsApi {
    public async getInvoiceDetailsPayments(request: GetInvoiceDetailsPaymentsRequest): Promise<IComponentTable<InvoiceDetailsPaymentItem>> {
        return await RestApiClient.post('modules/InvoiceDetailsPaymentsModule', request);
    }
}

export default new InvoiceDetailsPaymentsApi();
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
// import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { ICreditor } from '../../../core/types/ICreditor';
import { IStore } from '../../../reducers/IStore';
import { IInvoicesAgeAnalysisRequestFilters, emptyIInvoicesAgeAnalysisRequestFilters } from '../types/IInvoicesAgeAnalysisRequestFilters';
import { numberOnlyRegex } from '../../../core/helpers/utils';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import {ErrorMessage, Form, Formik} from 'formik';
import { IInvoicesAgeAnalysisSelectFilter } from '../types/IInvoicesAgeAnalysisSelectFilter';
import * as yup from "yup";
import { INumberDictionary } from '../../../core/types/IDictionary';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';

type PropsType = {
    currentCreditors: string[],
    filterByOptions: INumberDictionary<string>,
    onFilter: (filters: IInvoicesAgeAnalysisRequestFilters) => void,
    creditors: ICreditor[]
};


const Filters = (props: PropsType) => {
    const [formValues] = useState<IInvoicesAgeAnalysisRequestFilters>(emptyIInvoicesAgeAnalysisRequestFilters);
    const [creditorOptions, setCreditorOptions] = useState<any>([]);
    const [selectedCreditor, setSelectedCreditor] = useState<string>();

    const validationMessages = {
        required: TranslationService.translate("ValidationMessageRequiredField"),
        incorrectAccountNumber: TranslationService.translateModule('ValidationMessageIncorrectNumberFormat', ModuleNamesList.InvoicesAgeAnalysis), 
        toBigAccountNumber: TranslationService.translateModule('ValidationAccountNumberTooBig', ModuleNamesList.InvoicesAgeAnalysis)
    };

    const validationSchema = yup.object().shape({
        filterByValue: yup.string()
        .when("filteredBy", (val: string, schema: any) => {
            if (parseInt(val) === IInvoicesAgeAnalysisSelectFilter.AccountNumber) {
              return schema
                .required(validationMessages.required)
                .test('accNumber', 
                    validationMessages.incorrectAccountNumber,
                    (value: any) => numberOnlyRegex.test(value) )
                .test('accNumberLength', 
                    validationMessages.toBigAccountNumber,
                    (value: any) => numberOnlyRegex.test(value) && parseInt(value) <= (Math.pow(2, 31) - 1)  ) 
            } else if (val === undefined || val === 'all') {
                return schema.notRequired();
            }

            return schema.required(validationMessages.required);
        })
    });

    const onHandleChange = (value: string, name: string, handleChange: any) => {
        const handleChangeEvent = {
            target: {
                id: name,
                name: name,
                value: value
            }
        } as React.ChangeEvent<any>;

        handleChange(handleChangeEvent);
    };

    useEffect(() => {
        if (props.creditors) {
            let creditorsArray = props.creditors
                .filter(c => c.isAuthorized)
                .map(c => ({ 
                    key: c.domainId, 
                    value: `${c.organizationName} (${c.domainId})` 
                } as ISelectedValue));
            
            let creditorsFormValues = creditorsArray[0].key;

            if (creditorsArray.length < 100) {
                creditorsFormValues = '0';

                creditorsArray = [
                    { 
                        key: '0', 
                        value: TranslationService.translate('Any') 
                    }, 
                    ...creditorsArray
                ];

            }

            if (props.currentCreditors.length === 1) {
                creditorsFormValues = props.currentCreditors[0];
            }

            setSelectedCreditor(creditorsFormValues)
            setCreditorOptions(creditorsArray);
        }
        // eslint-disable-next-line
    }, [props.creditors]);

    useEffect(() => {
        props.onFilter({
            ...formValues,
            creditor: props.currentCreditors[0]
        });

        setSelectedCreditor(props.currentCreditors[0]);
        // eslint-disable-next-line
    }, [props.currentCreditors]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={formValues}
            onSubmit={(value: IInvoicesAgeAnalysisRequestFilters) => {
                props.onFilter(value);
            }}
            validationSchema={validationSchema}
        >
            {({
                values,
                handleChange,
                validateForm
            }) => {    
                return (
                    <Form className="c-form">
                        <div className="row">
                            <div className="col-12 col-lg-5 col-sm-12">
                                <FormGroup
                                    variant={FORM_GROUP_VARIANTS.BLOCK}
                                    name="filteredBy"
                                    label={TranslationService.translateModule('FilterBy', ModuleNamesList.InvoicesAgeAnalysis)}
                                    render={(name) => (
                                        <div className="row">
                                            <div className="col-6">
                                                <SimpleSelect
                                                    id="tranfert-dbcollectio-select-filter"
                                                    name={name}
                                                    value={values.filteredBy ? values.filteredBy.toString() : undefined}
                                                    onChangeHandle={(value) => { if (value.key === 'all') {  validateForm(); } onHandleChange(value.key, name, handleChange)}}
                                                    emptyFirstRow={true}
                                                    options={props.filterByOptions}/>
                                            </div>
                                            <div className="col-6">
                                                <SimpleInput
                                                    id="filterByValue"
                                                    name="filterByValue"
                                                    value={values.filterByValue}
                                                    onChange={(value) => onHandleChange(value, "filterByValue", handleChange)}/>
                                                <ErrorMessage name="filterByValue" component="div" className="error error-message" />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                            
                            <div className="col-12 col-lg-3 col-sm-6">
                                <FormGroup
                                    variant={FORM_GROUP_VARIANTS.BLOCK}
                                    name="creditor"
                                    label={TranslationService.translateModule('Creditor', ModuleNamesList.InvoicesAgeAnalysis)}
                                    render={(name) => (
                                        <>
                                            <SimpleSelect
                                                id={name}
                                                name={name}
                                                multiple={true}
                                                options={creditorOptions}
                                                selected={selectedCreditor}
                                                alwaysSelected={true}
                                                value={values.creditor}
                                                onChangeHandle={(selectedValue: ISelectedValue) => onHandleChange(selectedValue.key, name, handleChange)} 
                                            />

                                            <ErrorMessage name={name} component="div" className="error error-message" />
                                        </>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-lg-1 align-self-end">
                                <Button
                                    id="customerTransactionsFilterBtn"
                                    variant={BUTTON_VARIANTS.PRIMARY}
                                    size={BUTTON_SIZE.SM}
                                    type="submit"
                                    label={TranslationService.translateModule('Filter', ModuleNamesList.InvoicesAgeAnalysis)}
                                />
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
};

const mapStateToProps = (state: IStore) => ({
    creditors: state.creditors,
    currentCreditors: state.currentCreditors
});

export default connect(mapStateToProps)(Filters);
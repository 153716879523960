import * as React from 'react';
import { IAlertMessagesProps } from './types/IAlertMessagesProps';
import TranslationService from '../../../core/services/TranslationService';

class AlertMessages extends React.Component<IAlertMessagesProps> {
    
    public render() {
        return (
            <>
                { this.props.messages.map((message: string, index: number) => {
                    return <div className={ "alert " + this.props.alertClass } key={index}>
                        {this.props.translate ? 
                            <>{ 
                                this.props.moduleName ? 
                                    TranslationService.translateModule(message, this.props.moduleName)
                                : 
                                    TranslationService.translate(message)
                                }
                            </>
                            :
                            message
                        }
                    </div>
                }) }
            </>
        );
    }
}

export default AlertMessages;

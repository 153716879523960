import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { Status } from '../../core/api/Enums/Status';
import FormGroupDatePicker from '../../core/components/Forms/FormGroupDatePicker';
import FormGroupField from '../../core/components/Forms/FormGroupField';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import PostponeInvoiceService from './services/PostponeInvoiceService';
import Loader from '../../core/components/Loading/Loader';
import moment from 'moment';

interface IProps extends IExtendedModuleProps {
    accountNo: string,
    creditorNo: string,
    originalDueDate: Date,
    invoiceNo: string,
    onExit: () => {}
}

export default class PostponeInvoice extends React.Component<IProps> {
    private validationMessages = {
        required: TranslationService.translate('ValidationMessageRequiredField'),
        newDueDateAfterOriginalDueDate: TranslationService.translateModule('ValidationNewDueDate', ModuleNamesList.PostponeInvoice),
        noteTooLong: TranslationService.translateModule('ValidationNoteTooLong', ModuleNamesList.PostponeInvoice)
    }

    private validationSchema = yup.object().shape({
        dueDate: yup.date().required(this.validationMessages.required)
            .min(moment(this.props.originalDueDate).add(1, 'd').toDate(), this.validationMessages.newDueDateAfterOriginalDueDate),
        note: yup.string().required(this.validationMessages.required)
            .max(1000, this.validationMessages.noteTooLong)
    });

    public render() {
        return (
            <div className="pi__container">
                <Formik
                    initialValues={{
                        dueDate: moment(this.props.originalDueDate).add(1, 'd').add(12, 'h').toDate(),
                        note: ''
                    }}
                    onSubmit={async (value: { dueDate: Date, note: string }, { resetForm, setSubmitting }) => {
                        setSubmitting(true)
                        await this.onSubmit(value.dueDate, value.note, setSubmitting, resetForm);
                    }}
                    validationSchema={this.validationSchema}
                >{({ values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="pi__form-group">
                                        <FormGroupDatePicker
                                            errors={errors}
                                            fieldName="dueDate"
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            inputContainerClass="icon-calendar"
                                            label={TranslationService.translateModule('DueDate', this.props.module.name)}
                                            labelClass="empty"
                                            touched={touched}
                                            required={true}
                                            value={values.dueDate}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="pi__form-group">
                                        <FormGroupField
                                            component="textarea"
                                            errors={errors}
                                            fieldName="note"
                                            label={TranslationService.translateModule('Note', this.props.module.name)}
                                            labelClass="pi__label"
                                            inputContainerClass="col-12 pi__form-group"
                                            inputClassNames="pi__input--textarea"
                                            handleBlur={handleBlur}
                                            hideFormGroupDiv={true}
                                            handleChange={handleChange}
                                            hideLabel={true}
                                            touched={touched}
                                            value={values.note} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="pi__action-buttons">
                                        <Loader isLoaded={!isSubmitting} opacity={0.5} />
                                        <button type="button" disabled={isSubmitting} className="btn btn-default pi__save-button" onClick={() => handleSubmit()}><i className="fas fa-save" /> {TranslationService.translate('Save')}</button>
                                        <button type="button" disabled={isSubmitting} className="btn btn-default btn-cancel pi__cancel-button" onClick={this.onCancel}><i className="fas fa-times" /> {TranslationService.translate('Cancel')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </Formik>
            </div>
        )
    }

    private onCancel = () => {
        this.callExitHandler();
    }

    private onSubmit = async (dueDate: Date, note: string, 
        setSubmitting: (value: boolean) => void,
        resetForm: () => void) => {
        const result = await PostponeInvoiceService.postponeInvoice({
            accountNo: this.props.accountNo,
            creditorNo: this.props.creditorNo,
            dueDate: dueDate,
            invoiceNo: this.props.invoiceNo,
            moduleInstanceId: this.props.module.id,
            note: note
        })

        if (result.status === Status.Success) {
            InfoMessageService.success(TranslationService.translateModule('PostponeInvoiceSuccess', ModuleNamesList.PostponeInvoice))
            await this.callExitHandler();
        } else {
            InfoMessageService.error(TranslationService.translateModule('PostponeInvoiceFailed', ModuleNamesList.PostponeInvoice));
            setSubmitting(false);
            resetForm();
        }
    }

    private callExitHandler = async () => {
        if (this.props.onExit) {
            await this.props.onExit(); // Exit Handler
            this.props.exit(); // Modal Close
        }
    }
}
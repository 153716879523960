import * as React from 'react';
import { ErrorMessage } from 'formik';
import { IDictionary } from '../../types/IDictionary';
import { SimpleSelect } from './SimpleSelect';
import { ISelectedValue } from './types/ISelectedValue';

interface IFormGroupSelect {
    label: string;
    labelClass?: string;
    fieldName: string;
    inputContainerClass?: string;
    value: string;
    errors?: any;
    touched?: any;
    options: IDictionary<string>;
    onChange(value: ISelectedValue): void;
}

class FormGroupSelect extends React.Component<IFormGroupSelect> {

    public getErrorClass() {
        const properties: string[] = this.props.fieldName.split(".");
        if (properties.length === 1) {
            return (this.props.errors[this.props.fieldName] && this.props.touched[this.props.fieldName]) ? "error" : "";
        }

        let errorProperty = this.props.errors[properties[0]];
        let touchProperty = this.props.touched[properties[0]];
        if (!errorProperty || !touchProperty) {
            return "";
        }

        let iter = 1;
        while (iter < properties.length) {
            errorProperty = (errorProperty as any)![properties[iter]];
            touchProperty = (touchProperty as any)![properties[iter]];
            if (!errorProperty || !touchProperty) {
                return "";
            }
            iter++;
        }

        if (errorProperty && touchProperty) {
            return "error"
        }
        return "";
    }

    public getFormFieldObject = () =>{
        return (
            <div className={(this.props.inputContainerClass ? this.props.inputContainerClass : "col-sm-9")}>
                <SimpleSelect 
                    id={this.props.fieldName}
                    isNotStandard={true}
                    options={this.props.options}
                    value={this.props.value}
                    onChangeHandle={(value: ISelectedValue) => { this.props.onChange(value); } } />
                <ErrorMessage name={this.props.fieldName} component="div" className="error error-message" />
            </div>
        );
    }

    public getFormFieldLabel = () =>{
        return (
            <label className={(this.props.labelClass ? this.props.labelClass : "col-sm-3") + " col-form-label"} htmlFor={this.props.fieldName}>{this.props.label}</label>
        );
    }

    public render() {
        return (
            <>
                <div className={`form-group row`}>
                    {this.getFormFieldLabel()}
                    {this.getFormFieldObject()}
                </div>
            </>
        );
    }
}

export default FormGroupSelect;
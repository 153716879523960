import React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IModuleComplexField } from '../types/IModuleComplexField';
import SettingsTypeCellEditor from './SettingsTypeCellEditor';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { SettingsType } from '../../../core/Enums/SettingsType';

export const getComplexColumns = (
    moduleSettings: IModuleComplexField[], 
    isRemovable: boolean,
    handleChange: (value: any, id: number, type?: number) => void,
    handleRemoveRowClick: (row: any) => void
) => {
    const settingTypeOnBlur = [
        SettingsType.String, 
        SettingsType.Int, 
        SettingsType.HtmlString, 
        SettingsType.TextArea, 
        SettingsType.Dictionary
    ];

    const columns = [
        {
            dataField: 'name',
            sort: true,
            text: TranslationService.translateModule('Name', ModuleNamesList.ModuleSettings),
            editable: false,
            formatter: (cell: any, row: IModuleComplexField & { props: any}) => {
                if (cell) {
                    return cell;
                } else {
                    return (
                        <SettingsTypeCellEditor     
                            value={cell} 
                            id=""
                            type={1} 
                            row={row}
                            onUpdate={(value) => {}}
                            onBlur={(value) => {
                                handleChange(value, 1);
                            }}
                            onKeyUp={({ keyCode, target: { value }}) => {
                                if (keyCode === 13) {
                                    handleChange(value, 1);
                                }
                            }}
                        />
                    )
                }
            }
        }
    ] as IComponentTableColumn[];

    Object.getOwnPropertyNames(moduleSettings[0].complexFieldsSettings).forEach(settingName => {
        columns.push({
            dataField: settingName,
            sort: false,
            text: settingName,
            editable: true,
            formatter: (cell: any, row: IModuleComplexField & { props: any}, rowIndex: number) => {
                return (
                    <SettingsTypeCellEditor     
                        value={cell} 
                        id={settingName}
                        type={row.props[settingName].type} 
                        row={row} 
                        onBlur={(value) => {
                          if (settingTypeOnBlur.includes(row.props[settingName].type)) {
                                handleChange(value, row.props[settingName].id);
                            }
                        }}
                        onKeyUp={({ keyCode, target }) => {
                            if (keyCode === 13) {
                                target.blur();
                            }
                        }}
                        onUpdate={(value) => {
                            if (!settingTypeOnBlur.includes(row.props[settingName].type)) {
                                handleChange(value, row.props[settingName].id);
                            }
                        }}
                    />
                );
            }
        } as IComponentTableColumn);
    });

    if (isRemovable) {
        columns.push({
            dataField: 'actions',
            sort: false,
            text: '',
            editable: false,
            formatter: (cell: any, row: IModuleComplexField & { props: any}) => {
                return (
                    <>
                        <Button 
                            type="button"
                            id="caseListMoreFiltersBtn"
                            variant={BUTTON_VARIANTS.ICON}
                            size={BUTTON_SIZE.SM}
                            icon={{
                                className: 'far fa-trash-alt',
                                position: '',
    
                            }}
                            onClick={() => handleRemoveRowClick(row)} 
                        />
                    </>
                )
            }
        });
    }

    return columns;
}
import * as React from 'react';
import StickyFooter from '../../modules/StickyFooter/StickyFooter';
import Header from '../components/Header/Header';
import { ContainerFactory } from '../factories/ContainerFactory';
import { SkinFactory } from '../factories/SkinFactory';
import { ILayoutProps } from '../types/ILayoutProps';
import Topbar from '../../modules/Topbar/Topbar';
import StyleWrapper from '../../core/components/StyleWrapper/StyleWrapperHOC';

const CentralContainerForNotLoggedUser = (props: ILayoutProps) => {
    const containers = props.layout.containers;

    return (
        <>
            <div className="no-footer-content-wrapper">
                <Topbar />
                <Header />
                <SkinFactory skin={props.layout.skin} routeParameters={props.routeParameters} />
                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={0} />
            </div>
            <StickyFooter />
        </>
    )
}

const styleConfig = [
  {
      searchMethod: 'module',
      instructions: {
          backgroundColor: '.layout'
      }
  }
]

export default StyleWrapper('module:skin', styleConfig)(CentralContainerForNotLoggedUser);

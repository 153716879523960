import RestApiClient from '../../../core/api/RestApiClient';
import { IInitialSettings } from '../types/IInitialSettings';
import { IStandardSearch } from '../types/IStandardSearch';
import { ISearchDebtorListElement } from '../types/ISearchDebtorListElement';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IDebtorDetails } from '../types/IDebtorDetails';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class CreateNewCaseApiClient {
    public async getInitialSettings(moduleInstanceId: number): Promise<IInitialSettings> {
        return await RestApiClient.post<IInitialSettings>(
            'modules/CreateNewCase/getInitialSettings',
            { ModuleInstanceId: moduleInstanceId }
        );
    }
    public async searchForDebtors(
        searchForm: IStandardSearch,
        moduleInstanceId: number
    ): Promise<IComponentTable<ISearchDebtorListElement>> {
        return await RestApiClient.post<IComponentTable<ISearchDebtorListElement>>(
            'modules/CreateNewCase/searchForDebtors',
            {
                StandardSearch: {
                    ...searchForm,
                    debtorType:
                        searchForm.debtorType !== 2 ? searchForm.debtorType : undefined,
                },
                moduleInstanceId,
            }
        );
    }
    public async getDebtorDetails(domainId: string): Promise<IDebtorDetails> {
        return await RestApiClient.get<IDebtorDetails>(
            `modules/CreateNewCase/getDebtorDetails/${domainId}`
        );
    }
    public async createNewCase(formData: FormData): Promise<IActionStatus> {
        const headers = {
            'Content-Type': 'multipart/form-data;',
        };
        return await RestApiClient.post<IActionStatus>(
            `modules/CreateNewCase/createNewCase`,
            formData,
            headers,
            false
        );
    }
    public async doesCreditorAccessToDebtAdmin(
        creditorNo: string
    ): Promise<IActionStatus> {
        return await RestApiClient.get<IActionStatus>(
            `modules/CreateNewCase/doesCreditorAccessToDebtAdmin/${creditorNo}`
        );
    }
}

export default new CreateNewCaseApiClient();

import React from 'react';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IModule } from '../../core/api/types/IModule';

type IModuleWithSettings = {TrackingId: string} & IModule

export default class GoogleAnalytics extends React.Component<IModuleProps> {
  module = flatModuleOptions<IModuleWithSettings>(this.props.module as any);
  
  componentDidMount() {
    let gaModule = window.document.getElementById('ga-module') as HTMLScriptElement;
    if (!gaModule) {
      gaModule = window.document.createElement('script') as HTMLScriptElement;
      gaModule.async = true;
      gaModule.id = 'ga-module';
      const firstScript = window.document.getElementsByTagName('script')[0] as HTMLScriptElement;
      // @ts-ignore
      firstScript.parentNode.insertBefore(gaModule, firstScript);
    }

    const trackingId = this.module.TrackingId;

    if (!gaModule.src) {
      gaModule.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    }
  }

  public shouldComponentUpdate() {
    return false;
  }

  render() {
    const trackingId = this.module.TrackingId;

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    function gtag() { window.dataLayer.push(arguments); }
    // @ts-ignore
    gtag('config', trackingId, { 'page_path': window.location.pathname + (window.location.search || '')});

    return <></>
  }
}
import React from 'react';
import { INumberDictionary } from '../../../core/types/IDictionary';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { IPortalUsersFilterRequest, emptyPortalUsersFilterRequest } from '../types/IPortalUsersFilterRequest';
import { PortalUsersFilterOption } from '../types/PortalUsersFilterOption';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import { ErrorMessage } from 'formik';

interface IState {
    filterRequest: IPortalUsersFilterRequest;
}

interface IProps{
    filter: (request: IPortalUsersFilterRequest) => void,
}

class PortalUserListFilter extends React.Component<IProps, IState> {
    state: IState = {
        filterRequest: {...emptyPortalUsersFilterRequest},
    }

    filterByOptions: INumberDictionary<string> = {
        [PortalUsersFilterOption.Username]: TranslationService.translateModule('FilterByUsername', ModuleNamesList.PortalUsersList),
        [PortalUsersFilterOption.Name]: TranslationService.translateModule('FilterByName', ModuleNamesList.PortalUsersList),
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12 col-sm-8 col-md-8 col-lg-6">
                        <FormGroup
                            variant={FORM_GROUP_VARIANTS.BLOCK}
                            name="filterBy"
                            label={TranslationService.translate('FilterBy')}
                            render={(name) => (
                                <div className="row">
                                    <div className="col">
                                        <SimpleSelect
                                            id="portal-users-filter-options"
                                            value={this.state.filterRequest.filterByOption ? PortalUsersFilterOption[this.state.filterRequest.filterByOption].toString() : undefined}
                                            onChangeHandle={(selectedValue: ISelectedValue) => { 
                                                this.setState({ filterRequest: { 
                                                    ...this.state.filterRequest, 
                                                    filterByOption: PortalUsersFilterOption[selectedValue.key] 
                                                }}); 
                                            }}
                                            emptyFirstRow={true}
                                            options={this.filterByOptions}
                                        />

                                        <ErrorMessage name={name} component="div" className="error error-message" />
                                    </div>

                                    <div className="col">
                                        <FormGroup
                                            variant={FORM_GROUP_VARIANTS.BLOCK}
                                            name="filteredByValue"
                                            render={(name) => (
                                                <>
                                                    <SimpleInput
                                                        id="filteredByValue"
                                                        value={this.state.filterRequest.filteredByValue}
                                                        onChange={(value: string) => { 
                                                            this.setState({ filterRequest: { ...this.state.filterRequest, filteredByValue: value } }); 
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Button
                            type="button"
                            id="caseListFilterBtn"
                            className="mr-2"
                            variant={BUTTON_VARIANTS.PRIMARY}
                            size={BUTTON_SIZE.SM}
                            label={TranslationService.translateModule('Filter', ModuleNamesList.PortalUsersList)}
                            onClick={() => this.props.filter(this.state.filterRequest)} 
                        />
                    </div>
                </div>
            </>
        )
    }   
}

export default PortalUserListFilter;
import React, { useState, useEffect } from 'react';
import Loader from './core/components/Loading/Loader';
import ConfigurationService from './core/services/ConfigurationService';
import UserIdentityService from './core/services/UserIdentityService';
import RouteParamsService from './core/services/RouteParamsService';

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);

  const configurationService = new ConfigurationService();
  const userIdentityService = new UserIdentityService();

  const redirect = async () => {
    const identityServerUrl = await configurationService.getIdentityServerUrl()
    const returnUrl = await configurationService.getWebsiteUrl();
    const queryString = RouteParamsService.getQueryParameters();

    if (userIdentityService.IsLoggedIn()) {
      window.location.href = returnUrl;
      setIsLoading(false);
      return;
    }

    let providerId;

    Object.keys(queryString).forEach(key => {
      if (key.toLocaleLowerCase() === 'providerid') {
        providerId = queryString[key] as string;
      }
    });

    if (!!providerId) {
      window.location.href = `${identityServerUrl}/account/loginExternal?providerId=${providerId}&returnUrl=${encodeURIComponent(returnUrl)}`;
    } else {
      window.location.href = returnUrl
    }

    setIsLoading(false);
  }

  useEffect(() => {
    redirect();
    // eslint-disable-next-line
  }, []);

  return isLoading ? <Loader opacity={1} /> : <></>
};

export default Login

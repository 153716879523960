import * as React from 'react';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IRoleVm } from '../types/IRoleVm';

class RolesAssignmentTableConfigurator {
    public getTableColumns = (baseColumns: IComponentTableColumn[]) => {
        const columns = [...baseColumns];
        this.configureSquareCheckbox(columns);
        return columns;
    }

    private configureSquareCheckbox = (columns: IComponentTableColumn[]) => {
        columns.push({
            dataField: 'selected',
            formatter: (value: any, item: IRoleVm) => this.actionsRowData(),
            sort: false,
            text: ''
        } as IComponentTableColumn);
    }

    private actionsRowData = () => {
        return <i className="far fa-square"/>;
    }
}

export default new RolesAssignmentTableConfigurator();
import RestApiClient from '../../../core/api/RestApiClient';
import { InvoiceDetailsTabsRequest } from '../types/InvoiceDetailsTabsRequest';
import { InvoiceDetailsTabsSettings } from '../types/InvoiceDetailsTabsSettings';

class InvoiceDetailsTabsApi {
    public async getModuleSettings(request: InvoiceDetailsTabsRequest): Promise<InvoiceDetailsTabsSettings> {
        return await RestApiClient.post<InvoiceDetailsTabsSettings>('modules/InvoiceDetailsTabsModule/getTabs', request);
    }
}

export default new InvoiceDetailsTabsApi();
import RestApiClient from '../../core/api/RestApiClient';
import { IInitialDataResponse } from "./types/IInitialDataResponse";
import { IBaseCreditorDetails } from './types/IBaseCreditorDetails';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { IRole } from './types/IRole';


class CreditorsAssignmentApiClient {
    public async updateCreditorRoles(userUId: string, domainId: string, rolesUIds: string[]): Promise<IActionStatus> {
        const request = { userUId, domainId, rolesUIds }
        return await RestApiClient.post<IActionStatus>(`modules/CreditorsAssignment/updateCreditorRoles`, request);
    }
    
    public async getInitialData(moduleInstanceId: number, userUId: string): Promise<IInitialDataResponse> {
        return await RestApiClient.get<IInitialDataResponse>(`modules/CreditorsAssignment/getInitialData/${moduleInstanceId}/${userUId}`, null, null, true);
    }
    
    public async removeCreditorAssignment(creditorDomainIds: string[], userUId: string): Promise<IActionStatus> {
        const request = {
            creditorDomainIds,
            userUId
        }
        return await RestApiClient.post<IActionStatus>(`modules/CreditorsAssignment/removeCreditorAssignment`, request);
    }

    public async getUserCreditorRoles(domainId: string, userUId: string): Promise<IRole[]> {
        return await RestApiClient.get<IRole[]>(`modules/CreditorsAssignment/getUserCreditorRoles/${userUId}/${domainId}`);
    }
    
    public async assignCreditors(userUId: string, creditors: IBaseCreditorDetails[], roles: string[]): Promise<IActionStatus> {
        const request = {
            userUId, 
            roles,
            creditors: creditors.map(item => item.domainId)
        }
        return await RestApiClient.post<IActionStatus>(`modules/CreditorsAssignment/assignCreditors`, request);
    }
}

export default new CreditorsAssignmentApiClient();

import * as React from 'react';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';

interface IProps {
    totalCapital: number,
    totalBalance: number,
    total: number,
    currency: string;
}

class Footer extends React.Component<IProps> {

    public render() {
        return (
            <>
                <div className="ctl__footer ctl-footer">
                    <div className="row ctl-footer__row">
                        <div className="col-12 col-sm-6 col-lg-3 mb-2 mt-bg-0">
                            <div className="ctl-footer__box green">
                                <div className="icon">
                                    <i className="far fa-file-alt" />
                                </div>
                                <div className="box">
                                    <h3>{this.props.total}</h3>
                                    <h4>{TranslationService.translateModule('FooterTotalItems', ModuleNamesList.CustomerTransactionsList)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-2 mt-bg-0">
                            <div className="ctl-footer__box">
                                <div className="icon">
                                    <i className="fas fa-coins" />
                                </div>
                                <div className="box">
                                    <div className="ctl-footer__amounts">
                                        <h3>{CurrencyParser.toLocaleString(this.props.totalBalance)}</h3>
                                        <h5>{this.props.currency}</h5>
                                    </div>
                                    <h4>{TranslationService.translateModule('FooterTotalBalance', ModuleNamesList.CustomerTransactionsList)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-sm-0">
                            <div className="ctl-footer__box">
                                <div className="icon">
                                    <i className="fas fa-coins" />
                                </div>
                                <div className="box">
                                    <div className="ctl-footer__amounts">
                                        <h3>{CurrencyParser.toLocaleString(this.props.totalCapital)}</h3>
                                        <h5>{this.props.currency}</h5>
                                    </div>
                                    <h4>{TranslationService.translateModule('FooterTotalCapital', ModuleNamesList.CustomerTransactionsList)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Footer;
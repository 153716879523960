import * as React from 'react';
import './AutoOnboardingErrorSkin.scss';
import LogoBanner from "../../../modules/LogoBanner/LogoBanner";
import { ISkinProps } from '../../types/ISkinProps';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../components/Button/Button';
import TranslationService from '../../services/TranslationService';
import RouteParamsService from '../../services/RouteParamsService';
import { IStore } from '../../../reducers/IStore';
import { connect } from 'react-redux';
import { ICreditor } from '../../types/ICreditor';

interface ICreditorsListProps {
    creditors: ICreditor[]
}

class AutoOnboardingErrorSkin extends React.Component<ISkinProps & ICreditorsListProps> {

    public GoToHomePage() {
        const queryString = RouteParamsService.getQueryParameters();
        let redirectUrl = '/';
        if (queryString['token']) {
            redirectUrl += 'token=' + queryString['token']
        }

        if (this.props.creditors) {
            window.location.href = '/';
        } else {
            window.location.href = redirectUrl;
        }
    }

    public render() {
        return (
            <div className="error-container">
                <LogoBanner />

                <div className="error-window">
                    <p className="error-window-title">
                        {TranslationService.translate('AutoOnboardingErrorPageTitle')}
                    </p>

                    <p className="error-window-content">
                        {TranslationService.translate('AutoOnboardingErrorPageContent')}
                    </p>

                    <Button
                        variant={BUTTON_VARIANTS.PRIMARY}
                        size={BUTTON_SIZE.SM}
                        type="button"
                        label="GO HOME"
                        onClick={() => { this.GoToHomePage(); }}
                        icon={{ className: "fas fa-home", position: 'left' }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        creditors: state.creditors
    }
}

export default connect(mapStateToProps)(AutoOnboardingErrorSkin);
import React from 'react';
import IReportParameter from './../types/IReportParameter';
import EParamsType from './../enums/ParamsType';
import SimpleInput from './SimpleInput';
import NumberInput from './NumberInput';
import Loader from '../../../core/components/Loading/Loader';
import { ParameterNames } from '../types/ParameterNames';
import { HandleChangeDataType } from '../types/HandleChangeDataType';
import InputStringArray from './InputStringArray';
import { Field } from 'formik';
import TranslationService from '../../../core/services/TranslationService';

interface IProps {
  value: any;
  formData: any;
  params: IReportParameter;
  availableSelectAll?: boolean;
  handleChange: (data: HandleChangeDataType) => void;
}

interface IState {
  allCreditorChecked: boolean;
  selectedValues: any[];
  isLoading: boolean;
}

export class StringTmp extends React.PureComponent<IProps, IState> {
  public state: IState = {
    allCreditorChecked: false,
    selectedValues: [],
    isLoading: true,
  };

  componentDidMount() {
    let selectedValues = this.props.value[this.props.params.name];

    if (selectedValues && !Array.isArray(selectedValues)) {
      selectedValues = [selectedValues];
    }

    this.setState({
      selectedValues: selectedValues || this.props.params.defaultValues || [],
      isLoading: false,
    });
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <section className="c-creator__box">
              {this.props.params.hasManyValues ||
              this.props.params.name === ParameterNames.CreditorNo ? (
                <Field
                  name={this.props.params.name}
                  validate={this.validateInputStringArray}
                  render={({ field, form }: any) => {
                    const error =
                      form.errors[field.name] && form.touched[field.name]
                        ? form.errors[field.name]
                        : false;

                    if (
                      this.props.params.multiValue &&
                      !Array.isArray(field.value)
                    ) {
                      field.value = [field.value];
                    }

                    return (
                      <InputStringArray
                        error={error}
                        name={field.name}
                        value={field.value}
                        label={this.props.params.prompt}
                        multiple={this.props.params.multiValue}
                        availableSelectAll={
                          this.props.params.availableSelectAll || false
                        }
                        listInputs={this.props.params.validValuesList}
                        handleChange={(value: string[]) =>
                          this.handleInputStringArrayClick(
                            value,
                            this.props.params.name
                          )
                        }
                      />
                    );
                  }}
                />
              ) : this.props.params.itemParameters &&
                this.props.params.itemParameters.length ? (
                this.props.params.itemParameters.map(
                  (item: any, index: number) => (
                    <InputTypeGenerator
                      key={index}
                      controlParams={item}
                      value={this.props.value[item.name]}
                      type={this.props.params.type}
                      context={this}
                    />
                  )
                )
              ) : (
                <InputTypeGenerator
                  type={this.props.params.type}
                  controlParams={this.props.params}
                  value={this.props.value[this.props.params.name]}
                  context={this}
                />
              )}
            </section>
          </>
        )}
      </>
    );
  }

  handleInputStringArrayClick = (value: string[], name: string) => {
    this.props.handleChange({ name, type: 'String', value });
  };

  handleChange = (data: HandleChangeDataType) => {
    this.props.handleChange(data);
  };

  validateInputStringArray = (value: string | []) => {
    let result;

    if ((!value || Array.isArray(value)) && !value.length) {
      result = TranslationService.translate('ValidationMessageMinSelection');
    }

    return result;
  };
}

const getInputTypeGenerator = (params: any, value: string, context: any) => ({
  [EParamsType.String]: (
    <SimpleInput
      name={params.name}
      prompt={params.prompt}
      type="text"
      dataType={params.type}
      defaultValues={value || params.defaultValues}
      onChange={context.handleChange}
      required={!params.allowBlank}
    />
  ),
  [EParamsType.NumFill]: (
    <NumberInput
      name={params.name}
      prompt={params.prompt}
      type="text"
      dataType={params.type}
      defaultValues={value || params.defaultValues}
      handleChange={context.handleChange}
      required={!params.allowBlank}
    />
  ),
});

const InputTypeGenerator = (params: any) =>
  getInputTypeGenerator(params.controlParams, params.value, params.context)[
    params.type
  ];

export default StringTmp;

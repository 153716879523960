import * as React from 'react';
import {ContainerFactory} from '../factories/ContainerFactory';
import {ISkinProps} from '../../core/types/ISkinProps';

export function OneColumnSkin(props: ISkinProps) {
    const containers = props.skin.containers;

    return (
        <div className={`skin ${props.staticHeight ? 'skin--fixed-height' : ''}`}>
            {containers.map((_, index) => {
                return (
                    <div className="skin__row skin__row--full" key={index}>
                        <div className="skin__content">
                            <ContainerFactory containers={containers} moduleIndex={index} key={index} routeParameters={props.routeParameters}/>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

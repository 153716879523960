import * as React from 'react';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IReportElement } from '../types/IReportElement';

class GeneratedReportsTableConfigurator {
    public getTableColumns = (reporstElements: IComponentTable<IReportElement>, downloadDocument: (reportId: string, creditorNumber: string) => Promise<void>) => {
        let columns: IComponentTableColumn[] = [];
        if (reporstElements != null) {
            columns = reporstElements.columns.map(c => ({ ...c, sort: true }));
            this.configureDateColumns(columns);
            this.configureDownloadColumn(columns, downloadDocument);

            return columns;
        }

        return columns;
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = ['date', 'readDate'];
        const cols = columns.filter(col => dateColumns.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            if(value !== null && value !== '')
            {
                return DateTimeParser.toLocaleDateString(value);
            }
            return '';
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureDownloadColumn = (columns: IComponentTableColumn[], downloadDocument: (reportId: string, creditorNumber: string) => Promise<void>) => {
        const column = columns.find(col => col.dataField === 'download');
        if(column)
        {
            const formatterFunc = (value: any, row: IReportElement) => {
                return <div onClick={async () => await downloadDocument(row.id, row.creditorNumber)} className="btn-download">
                            <i className="fas fa-file-download" />
                            { row.isDownloading &&
                            <div className="ml-1 spinner-border spinner-border-sm text-success" role="status">
                                <span className="sr-only"/>
                            </div> }
                        </div>
            }
            column.formatter = formatterFunc;
        }
    }
}

export default new GeneratedReportsTableConfigurator();
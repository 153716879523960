import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { InvoiceDetailsNoteItem } from '../types/InvoiceDetailsNoteItem';
import { InvoiceDetailsNotesColumns } from './InvoiceDetailsNotesColumns';

class InvoiceDetailsNotesTableConfigurator {
    public getColumns = (invoicesListTable: IComponentTable<InvoiceDetailsNoteItem>) => {
        let columns: IComponentTableColumn[] = [];
        if (invoicesListTable != null) {
            columns = [...invoicesListTable.columns];
            this.configureDateColumns(columns);

            return columns;
        }
        return columns;
    }

        private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = [InvoiceDetailsNotesColumns.RegistrationDate];
        const currencyColumnsNames = dateColumns.map(col => InvoiceDetailsNotesColumns[col].charAt(0).toLowerCase() + InvoiceDetailsNotesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return DateTimeParser.toLocaleDateString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }
}

export default new InvoiceDetailsNotesTableConfigurator();
import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { IDebtorDetails } from '../types/IDebtorDetails';

interface IProps {
    debtor: IDebtorDetails
}

class DebtorDetails extends React.Component<IProps> {
    public render() {
        return (
            <>
                <h6>{TranslationService.translateModule('DebtorDetails', ModuleNamesList.PrintCase)}</h6>
                <table className='table table-bordered table-sm mb-0'>
                    <tbody>
                        <tr>
                            <td>{TranslationService.translateModule('DebtorName', ModuleNamesList.PrintCase)}</td>
                            <td>{this.props.debtor.name}</td>
                        </tr>
                        <tr>
                            <td>{TranslationService.translateModule('DebtorTelephone', ModuleNamesList.PrintCase)}</td>
                            <td>{this.props.debtor.phone}</td>
                        </tr>
                        <tr>
                            <td>{TranslationService.translateModule('DebtorEmail', ModuleNamesList.PrintCase)}</td>
                            <td>{this.props.debtor.debtorEmail}</td>
                        </tr>
                        <tr>
                            <td>{TranslationService.translateModule('DebtorAddress', ModuleNamesList.PrintCase)}</td>
                            <td>{this.props.debtor.address}</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default DebtorDetails;
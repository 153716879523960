import RestApiClient from '../../../core/api/RestApiClient';
import { IActionStatus } from '../../../core/api/types/IActionStatus';
import { GetDirectPaymentInvoicesRequest } from '../types/GetDirectPaymentInvoicesRequest';
import { IInvoiceListElement } from '../types/InvoiceElement';
import { SaveInvoiceDetailsDirectPaymentRequest } from '../types/SaveInvoiceDetailsDirectPaymentRequest';
import { IComponentTable } from '../../../core/types/IComponentTable';

class InvoiceDetailsDirectPaymentApi {
    public async getInvoices(request: GetDirectPaymentInvoicesRequest): Promise<IComponentTable<IInvoiceListElement>> {
        return await RestApiClient.post<IComponentTable<IInvoiceListElement>>(`modules/InvoiceDetailsDirectPaymentModule/getInvoices`, request)
    }
    
    public async saveInvoiceDetailsDirectPayment(request: SaveInvoiceDetailsDirectPaymentRequest): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>(`modules/InvoiceDetailsDirectPaymentModule/saveDirectPayment`, request)
    }
}

export default new InvoiceDetailsDirectPaymentApi();
import * as React from 'react';
import TranslationService from '../../core/services/TranslationService';
import { IModuleProps } from '../../core/types/IModuleProps';
import CasePaymentsService from './services/CasePaymentsService';
import { IAmountsStructure } from './types/IAmountsStructure';
import { BalanceType } from './types/BalanceType';
import { AmountType } from './types/AmountType';
import './CasePayments.scss';
import { IStore } from '../../reducers/IStore';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Loader from '../../core/components/Loading/Loader';
import CurrencyParser from '../../core/helpers/CurrencyParser';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../core/lists/EventsList';

interface IProps extends IModuleProps {
    locale: string
}
interface IState {
    casePayments: IAmountsStructure,
    isDataLoaded: boolean
}

const emptyBalanceValues = {
    [AmountType.Main]: 0,
    [AmountType.Interests]: 0,
    [AmountType.Fees]: 0,
    [AmountType.Total]: 0,
}

const emptyCasePayments = {
    [BalanceType.Original]: emptyBalanceValues,
    [BalanceType.Paid]: emptyBalanceValues,
    [BalanceType.Balance]: emptyBalanceValues
}

class CasePayments extends React.Component<IProps, IState> {
    moduleEvents: IModuleEventSubscription[] | undefined;
    state: IState = {
        casePayments: emptyCasePayments,
        isDataLoaded: false
    }

    async componentDidMount() {
        this.registerModuleEvents();
        await this.loadData();
    }

    componentWillUnmount() {
        if(this.moduleEvents) {
            ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
        }
    }

    loadData = async() => {
        const { id } = this.props.routeParameters;
        const casePayments = await CasePaymentsService.getCasePayments(id as string);

        this.setState({
            casePayments,
            isDataLoaded: true
        })
    }

    registerModuleEvents() {
        this.moduleEvents = [
            { name: EventsList.CASE_DATA_UPDATED, callback: this.loadData }
        ];

        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }

    render() {
        if (!this.hasRequiredUrlParams()) {
            return <Redirect to="/error" />
        }

        if (!this.state.isDataLoaded) {
            return <Loader />
        }

        return (<div className="case-payments">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" />
                        <th className="align-to-right" scope="col">{TranslationService.translateModule('Original', this.props.module.name)}</th>
                        <th className="align-to-right" scope="col">{TranslationService.translateModule('Paid', this.props.module.name)}</th>
                        <th className="align-to-right" scope="col">{TranslationService.translateModule('Balance', this.props.module.name)}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{TranslationService.translateModule('MainAmount', this.props.module.name)}</th>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Original.Main)}</td>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Paid.Main)}</td>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Balance.Main)}</td>
                    </tr>
                    <tr>
                        <th scope="row">{TranslationService.translateModule('Interest', this.props.module.name)}</th>
                        <td className="align-to-right">{this.state.casePayments.Original.Interests.toLocaleString(this.props.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td className="align-to-right">{this.state.casePayments.Paid.Interests.toLocaleString(this.props.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td className="align-to-right">{this.state.casePayments.Balance.Interests.toLocaleString(this.props.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                    <tr>
                        <th scope="row">{TranslationService.translateModule('Fees', this.props.module.name)}</th>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Original.Fees)}</td>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Paid.Fees)}</td>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Balance.Fees)}</td>
                    </tr>
                    <tr className="total">
                        <th scope="row">{TranslationService.translateModule('Total', this.props.module.name)}</th>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Original.Total)}</td>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Paid.Total)}</td>
                        <td className="align-to-right">{CurrencyParser.toLocaleString(this.state.casePayments.Balance.Total)}</td>
                    </tr>
                </tbody>
            </table>
        </div>)
    }

    hasRequiredUrlParams = () => {
        const { id } = this.props.routeParameters;
        return id;
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        locale: state.currentCultureCode,
    }
}

export default connect(mapStateToProps)(CasePayments);
import * as React from 'react';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import { IBaseCreditorDetails } from '../../CreditorsAssignment/types/IBaseCreditorDetails';
import UsersApiClient from '../api/UsersApiClient';
import ColumnsConfigurator from '../helpers/ColumnsConfigurator';
import { ICreditorUserVm } from '../types/ICreditorUserVm';
import { IUsersModuleCreditorVm } from '../types/IUsersModuleCreditorVm';
import Loader from '../../../core/components/Loading/Loader';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';

export interface IUserListProps {
    creditors: IUsersModuleCreditorVm[],
    moduleId: number,
    onOpenModal: (userUId: string, creditors: IBaseCreditorDetails[]) => void,
    refresh: () => void
}

export interface IUserListState {
    users: ICreditorUserVm[],
    columns: IComponentTableColumn[],
    isDataLoading: boolean,
    currentPage: number
}

class UsersTable extends React.Component<IUserListProps, IUserListState> {
    constructor(props: IUserListProps) {
        super(props);

        this.state = {
            columns: [],
            users: [],
            isDataLoading: false,
            currentPage: 1
        }
    }

    public componentDidMount = async () => {
        this.setState({isDataLoading: true})
        
        const data = await UsersApiClient.getUsers(this.props.moduleId);
        const columns = ColumnsConfigurator.getColumns(
            this.props.creditors.map(c => (
                { domainId: c.creditorId, organizationName: c.name }
            )), 
            this.props.onOpenModal
        );

        this.setState({
            columns,
            users: data.data,
            isDataLoading: false,
        });
        
    }

    public render() {
        const { users, columns } = this.state;

        return (
            <>
                {this.state.isDataLoading && <Loader opacity={0.5} />}
                
                <BootstrapTableContainer
                    remote={false}
                    wrapperClasses="bt bt--no-overflow"
                    classes="bt__table bt-table"
                    keyField='userId'
                    data={users}
                    columns={columns} 
                />
            </>
        );
    }
}

export default UsersTable
import React, { useState, useEffect } from 'react';
import { Card } from '../../../core/components/Card/Card';
import { CardContent } from '../../../core/components/Card/components/CardContent';
import { CardHeader } from '../../../core/components/Card/components/CardHeader';
import { connect } from 'react-redux';
import { ErrorMessage, Form } from 'formik';
import { IStore } from '../../../reducers/IStore';
import { FormFieldset } from '../../../core/components/Forms/FormFieldset';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import OrderPaperService from './services/OrderPaperService';
import { ProductType } from './types/Product';
import { OrderProductType } from './types/OrderProduct';
import { ImageType } from './types/Image';
import { CaruselImageType } from './types/CaruselIamge';
import { IModuleProps } from '../../../core/types/IModuleProps';
import Loader from '../../../core/components/Loading/Loader';
import { IDictionary } from '../../../core/types/IDictionary';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import styles from './OrderPaper.module.scss';
import InfoMessageService from 'core/services/InfoMessageService';
import { Status } from 'core/api/Enums/Status';
import { ISelectedValue } from 'core/components/Forms/types/ISelectedValue';
import classNames from 'classnames';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import { OrderLinesType } from './types/OrderLines';
import { ProductTableType } from './types/ProductTable';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import OrderPaperTableConfigurator from './Helpers/OrderPaperTableConfigurator';
import { ProductValuesType } from './types/ProductValues';
import UserIdentityService from '../../../core/services/UserIdentityService';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { ICreditor } from '../../../core/types/ICreditor';

interface IProps {
    creditors: ICreditor[],
    currentCreditors: string[];
    currency: string;
}

var items: CaruselImageType[] = [];

const OrderPaper = (props: IProps & IModuleProps) => {
    const getProductList = () => {
        const mappedProducts: IDictionary<string> = {};

        productsList.forEach((products: ProductType) => (
            mappedProducts[products.productCode] = products.productName)
        );

        return mappedProducts;
    };

    const getCreditorsList = () => {
        const mappedProducts: IDictionary<string> = {};

        props.creditors.forEach((creditor: ICreditor) => (
            mappedProducts[creditor.domainId] = `${creditor.organizationName} ${creditor.domainId}`)
        );

        return mappedProducts
    };

    const productTypeInit: ProductType[] = [
        {
            productCode: '',
            productName: '',
            productDescription: '',
            unitPriceVatExclusive: 0,
            vatPercentage: 0,
            images: [
                {
                    name: '',
                    description: '',
                    href: '',
                },
            ],
        },
    ];

    const orderProductInit: OrderProductType[] = [
        {
            orderId: '',
            creditorId: '',
            createdAt: 0,
            confirmationEmail: '',
            orderLines: [],
            sumVatExclusive: 0,
            sumVatInclusive: 0,
        },
    ];

    const productTableInit: ProductTableType = {
        columns: [
            {
                dataField: 'productName',
                text: TranslationService.translateModule('ProductName', ModuleNamesList.OrderPaper),
                sort: false,
                export: true,
            },
            {
                dataField: 'quantity',
                text: TranslationService.translateModule('Quantity', ModuleNamesList.OrderPaper),
                sort: false,
                export: true,
            },
            {
                dataField: 'price',
                text: TranslationService.translateModule('Price', ModuleNamesList.OrderPaper),
                sort: false,
                export: true,
            },
            {
                dataField: 'vat',
                text: TranslationService.translateModule('Vat', ModuleNamesList.OrderPaper),
                sort: false,
                export: true,
            },
            {
                dataField: 'total',
                text: TranslationService.translateModule('Total', ModuleNamesList.OrderPaper),
                sort: false,
                export: true,
            },
        ],
        values: [],
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [productsList, setProductsList] = useState<ProductType[]>(productTypeInit);
    const [currentProduct, setCurrentProduct] = useState<ProductType>(productTypeInit[0]);
    const [simpleSelectValue, setSimpleSelectValue] = useState<string>('0');
    const [productTable, setProductTable] = useState<ProductTableType>(productTableInit);
    const [orderProduct, setOrderProduct] = useState<OrderProductType[]>(orderProductInit);
    const [quantity, setQuantity] = useState<number>(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [userEmail, setUserEmail] = useState<string>('');
    const [creditor, setCreditor] = useState<any>();

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: any) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
                <img src={item.src} alt={item.altText} />
                <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </CarouselItem>
        );
    });

    const getUserDetails = async () => {
        setIsLoading(true);

        const userIdentityService: UserIdentityService = new UserIdentityService();
        const userDetails = userIdentityService.GetUserDetails();

        setUserEmail(userDetails.email);
        try {
            setProductTable(productTableInit);

            const products = await OrderPaperService.getProducts(props.module.id);
            setCurrentProduct(products[0]);
            setProductsList(products);
            setSimpleSelectValue(products[0].productCode);

            items = [];

            products[0].images.forEach((image: ImageType) => {
                var item: CaruselImageType = {
                    src: '',
                    altText: '',
                    caption: '',
                };

                item.src = image.href;
                item.caption = image.name;
                item.altText = image.description;
                items.push(item);
            });

            let currentCreditor = {}

            props.creditors.forEach((creditor: ICreditor) => {
                if (creditor.domainId === props.currentCreditors[0]) {
                    currentCreditor = { ...creditor };
                }
            });

            setCreditor(currentCreditor);

            setIsLoading(false);
        } catch {
            InfoMessageService.error(TranslationService.translateModule('ErrorMessage', ModuleNamesList.OrderPaper));
        }
    };

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line
    }, []);

    const removeSupply = (productId: string) => {
        var updatedProductTable = productTable;
        var updatedProdcutTableValues = productTable.values.filter((value) => value.id !== productId);
        updatedProductTable.values = updatedProdcutTableValues;
        setProductTable({ ...updatedProductTable });

        var removedOrderLine = orderProduct[0].orderLines.filter((orderLine) => orderLine.productCode === productId);

        var updatedOrderProduct = orderProduct[0];

        updatedOrderProduct.sumVatExclusive = parseFloat(
            (updatedOrderProduct.sumVatExclusive - removedOrderLine[0].lineSumVatExclusive).toFixed(2)
        );
        updatedOrderProduct.sumVatInclusive = parseFloat(
            (updatedOrderProduct.sumVatInclusive - removedOrderLine[0].lineSumVatInclusive).toFixed(2)
        );

        var updatedOrderProductOrderLines = orderProduct[0].orderLines.filter(
            (orderLine) => orderLine.productCode !== productId
        );

        updatedOrderProduct.orderLines = updatedOrderProductOrderLines;
        orderProduct[0] = updatedOrderProduct;
        setOrderProduct(orderProduct);
    };

    const handleChangeProduct = (data: ISelectedValue) => {
        var selectedProduct = productsList.filter((x) => x.productCode === data.key);

        setCurrentProduct(selectedProduct[0]);
        setSimpleSelectValue(data.key);

        items = [];

        selectedProduct[0].images.forEach((image: ImageType) => {
            var item: CaruselImageType = {
                src: '',
                altText: '',
                caption: '',
            };

            item.src = image.href;
            item.caption = image.name;
            item.altText = image.description;
            items.push(item);
        });
    };

    const handleAddToBasket = () => {
        var item: OrderLinesType = {
            productCode: currentProduct.productCode,
            productName: currentProduct.productName,
            unitPriceVatExclusive: currentProduct.unitPriceVatExclusive,
            quantity: quantity,
            lineSumVatExclusive: parseFloat((quantity * currentProduct.unitPriceVatExclusive).toFixed(2)),
            lineSumVatInclusive: parseFloat(
                (
                    (currentProduct.unitPriceVatExclusive +
                        (currentProduct.unitPriceVatExclusive * currentProduct.vatPercentage) / 100) *
                    quantity
                ).toFixed(2)
            ),
            vatPercentage: currentProduct.vatPercentage,
            vatAmount: parseFloat(
                (((currentProduct.unitPriceVatExclusive * currentProduct.vatPercentage) / 100) * quantity).toFixed(2)
            ),
        };

        var item2: ProductValuesType = {
            id: currentProduct.productCode,
            productName: currentProduct.productName,
            quantity: quantity,
            price: parseFloat((currentProduct.unitPriceVatExclusive * quantity).toFixed(2)),
            vat: currentProduct.vatPercentage,
            total: parseFloat(
                (
                    (currentProduct.unitPriceVatExclusive +
                        (currentProduct.unitPriceVatExclusive * currentProduct.vatPercentage) / 100) *
                    quantity
                ).toFixed(2)
            ),
        };

        if (orderProduct[0].orderLines.length === 0) {
            orderProduct[0].orderLines.push(item);
        } else {
            orderProduct[0].orderLines.forEach((order: OrderLinesType) => {
                if (order.productCode === currentProduct.productCode) {
                    order.quantity = order.quantity + quantity;
                    order.lineSumVatInclusive = parseFloat(
                        (order.lineSumVatInclusive + item.lineSumVatInclusive).toFixed(2)
                    );
                    order.lineSumVatExclusive = parseFloat(
                        (order.lineSumVatExclusive + item.lineSumVatExclusive).toFixed(2)
                    );
                } else {
                    orderProduct[0].orderLines.push(item);
                }
            });
        }

        orderProduct[0].sumVatExclusive = parseFloat(
            (orderProduct[0].sumVatExclusive + item.lineSumVatExclusive).toFixed(2)
        );
        orderProduct[0].sumVatInclusive = parseFloat(
            (orderProduct[0].sumVatInclusive + item.lineSumVatInclusive).toFixed(2)
        );

        setOrderProduct(orderProduct);

        if (productTable.values.length === 0) {
            productTable.values.push(item2);
        } else {
            var existingProducts = productTable.values.filter((x) => x.id === currentProduct.productCode);
            if (existingProducts.length === 0) {
                productTable.values.push(item2);
            } else {
                productTable.values.forEach((row: ProductValuesType) => {
                    if (row.id === currentProduct.productCode) {
                        row.quantity = row.quantity + quantity;
                        parseFloat((row.total = row.total + item2.total).toFixed(2));
                    }
                });
            }
        }

        setProductTable({ ...productTable });
        setQuantity(0);
    };

    const handleClickSummary = () => {
        setCurrentStep(1);
    };

    const handlePreviousClick = () => {
        setCurrentStep(0);
    };

    const handleQuantityChange = (quantityValue: string) => {
        const regexp = new RegExp(`[0-9]`);

        if (regexp.test(quantityValue)) {
            setQuantity(parseInt(quantityValue));
        } else {
            setQuantity(0);
        }
    };

    const handleClickCheckout = async () => {
        orderProduct[0].creditorId = creditor.organizationNumber;
        orderProduct[0].confirmationEmail = userEmail;
        orderProduct[0].createdAt = Date.now();

        try {
            setIsLoading(true);
            const result = await OrderPaperService.orderPaper(orderProduct[0], props.module.id);
            if (result.status === Status.Success) {
                InfoMessageService.success(
                    TranslationService.translateModule('SuccessPaperWasOrdered', ModuleNamesList.OrderPaper)
                );
            } else {
                InfoMessageService.error(
                    TranslationService.translateModule('ErrorMessage', ModuleNamesList.OrderPaper)
                );
            }
        } catch {
            InfoMessageService.error(TranslationService.translateModule('ErrorMessage', ModuleNamesList.OrderPaper));
        } finally {
            setIsLoading(false);
            setCurrentStep(0);
            setOrderProduct(orderProductInit);
            setProductTable(productTableInit);
        }
    };

    const handleChangeCreditor = async (select: any) => {
        let currentCreditor = {};

        props.creditors.forEach((creditor: ICreditor) => {
            if (creditor.domainId === select.key) {
                currentCreditor = { ...creditor };
            }
        });

        setCreditor(currentCreditor);
    };

    return (
        <>
            {isLoading ? (
                <div className={classNames(styles['center'])}>
                    <Loader />
                </div>
            ) : (
                <>
                    {currentStep === 0 ? (
                        <Card class="mt-3">
                            <>
                                <CardHeader>
                                    <h1 className="c-heading">
                                        {TranslationService.translateModule(
                                            'OrderSupplies',
                                            ModuleNamesList.OrderPaper
                                        )}
                                    </h1>
                                </CardHeader>

                                <CardContent>
                                    <Form className="c-form">
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <FormFieldset
                                                    label={TranslationService.translateModule(
                                                        'SelectProduct',
                                                        ModuleNamesList.OrderPaper
                                                    )}
                                                    render={() => (
                                                        <FormGroup
                                                            variant={FORM_GROUP_VARIANTS.INLINE}
                                                            name="invoiceNoFrom"
                                                            render={(name) => (
                                                                <>
                                                                    <SimpleSelect
                                                                        id="case-list-select-filter"
                                                                        value={simpleSelectValue}
                                                                        onChangeHandle={handleChangeProduct}
                                                                        disabled={false}
                                                                        emptyFirstRow={false}
                                                                        options={getProductList()}
                                                                    />
                                                                    <ErrorMessage
                                                                        name={name}
                                                                        component="div"
                                                                        className="error error-message"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    )}
                                                />

                                                <div className="border-top mt-3 pt-3">
                                                    <div className="row justify-content-between">
                                                        <div className={classNames('col-6', styles['billing-address'])}>
                                                            {currentProduct.images.length > 0 ? (
                                                                <Carousel
                                                                    activeIndex={activeIndex}
                                                                    next={next}
                                                                    previous={previous}
                                                                    interval={100000}
                                                                >
                                                                    <CarouselIndicators
                                                                        items={items}
                                                                        activeIndex={activeIndex}
                                                                        onClickHandler={goToIndex}
                                                                    />

                                                                    {slides}

                                                                    <CarouselControl
                                                                        direction="prev"
                                                                        directionText="Previous"
                                                                        onClickHandler={previous}
                                                                    />

                                                                    <CarouselControl
                                                                        direction="next"
                                                                        directionText="Next"
                                                                        onClickHandler={next}
                                                                    />
                                                                </Carousel>
                                                            ) : (
                                                                <div className={classNames(styles['notification'])}>
                                                                    {TranslationService.translateModule(
                                                                        'NoProductImages',
                                                                        ModuleNamesList.OrderPaper
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="col-6">
                                                            <div className={styles.productCode}>
                                                                {TranslationService.translateModule(
                                                                    'ProductCode',
                                                                    ModuleNamesList.OrderPaper
                                                                )}
                                                                {': '}
                                                                {currentProduct.productCode}
                                                            </div>

                                                            <div className={styles.productName}>
                                                                {currentProduct.productName}
                                                            </div>

                                                            <div
                                                                className={styles.productDescription}
                                                            >
                                                                {currentProduct.productDescription}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.productValueGroup}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className={classNames(
                                                                'float-right',
                                                                styles['productValue']
                                                            )}>
                                                                {CurrencyParser.toLocaleString(
                                                                    currentProduct.unitPriceVatExclusive
                                                                )}{' '}
                                                                {props.currency}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col">
                                                            <div className={classNames('float-right', styles['vat'])}>
                                                                {'+ '}
                                                                {currentProduct.vatPercentage}
                                                                {'% '}
                                                                {TranslationService.translateModule(
                                                                    'Vat',
                                                                    ModuleNamesList.OrderPaper
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        <FormGroup
                                                            variant={FORM_GROUP_VARIANTS.BLOCK}
                                                            label={TranslationService.translateModule(
                                                                'Quantity',
                                                                ModuleNamesList.OrderPaper
                                                            )}
                                                            name="invoiceNoFrom"
                                                            render={(name) => (
                                                                <>
                                                                    <SimpleInput
                                                                        id="quantityValue"
                                                                        value={quantity.toString()}
                                                                        onChange={handleQuantityChange}
                                                                    />

                                                                    <ErrorMessage
                                                                        name={name}
                                                                        component="div"
                                                                        className="error error-message"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        <Button
                                                            type="button"
                                                            id="AddToBasketBtn"
                                                            variant={BUTTON_VARIANTS.PRIMARY}
                                                            size={BUTTON_SIZE.SM}
                                                            label={TranslationService.translateModule(
                                                                'AddToBasket',
                                                                ModuleNamesList.OrderPaper
                                                            )}
                                                            disabled={quantity === 0}
                                                            onClick={handleAddToBasket}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-lg-6 mt-3">
                                                <BootstrapTableContainer
                                                    remote={false}
                                                    wrapperClasses="bt"
                                                    paginationProps={{ hideSizePerPage: true }}
                                                    variant="order-paper"
                                                    classes="bt__table bt-table"
                                                    keyField="id"
                                                    data={productTable.values}
                                                    columns={
                                                        productTable.columns.length === 0
                                                            ? []
                                                            : OrderPaperTableConfigurator.getPaperTableColumns(
                                                                  productTable,
                                                                  removeSupply
                                                              )
                                                    }
                                                />

                                                {(orderProduct[0].sumVatInclusive > 0) && (
                                                    <>
                                                        <div className={classNames(
                                                            'row justify-content-end',
                                                            styles.productName
                                                        )}>
                                                            <div className="col-auto">
                                                                <div className={classNames(styles.amount)}>
                                                                    {CurrencyParser.toLocaleString(
                                                                        orderProduct[0].sumVatExclusive
                                                                    )}{' '}
                                                                    {props.currency}
                                                                </div>

                                                                <div className={classNames(styles.annotation)}>
                                                                    {TranslationService.translateModule(
                                                                        'TotalTaxExcluded',
                                                                        ModuleNamesList.OrderPaper
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-auto">
                                                                <div className={classNames(styles['amount'])}>
                                                                    {CurrencyParser.toLocaleString(
                                                                        orderProduct[0].sumVatInclusive
                                                                    )}{' '}
                                                                    {props.currency}
                                                                </div>

                                                                <div
                                                                    className={classNames(styles.annotation)}
                                                                >
                                                                    {TranslationService.translateModule(
                                                                        'TotalTaxIncluded',
                                                                        ModuleNamesList.OrderPaper
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="border-top pt-3">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <FormGroup
                                                                        variant={FORM_GROUP_VARIANTS.BLOCK}
                                                                        label={TranslationService.translateModule(
                                                                            'Creditor',
                                                                            ModuleNamesList.OrderPaper
                                                                        )}
                                                                        name="invoiceNoFrom"
                                                                        render={(name) => (
                                                                            <>
                                                                                <SimpleSelect
                                                                                    id="case-list-select-filter"
                                                                                    disabled={false}
                                                                                    emptyFirstRow={false}
                                                                                    value={creditor.domainId}
                                                                                    options={getCreditorsList()}
                                                                                    onChangeHandle={handleChangeCreditor}
                                                                                />

                                                                                <ErrorMessage
                                                                                    name={name}
                                                                                    component="div"
                                                                                    className="error error-message"
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>

                                                                <div className="col-6">
                                                                    <FormGroup
                                                                        variant={FORM_GROUP_VARIANTS.BLOCK}
                                                                        label={TranslationService.translateModule(
                                                                            'UserEmail',
                                                                            ModuleNamesList.OrderPaper
                                                                        )}
                                                                        name="userEmail"
                                                                        render={(name) => (
                                                                            <>
                                                                                <SimpleInput
                                                                                    id={name}
                                                                                    value={userEmail}
                                                                                    onChange={setUserEmail}
                                                                                />

                                                                                <ErrorMessage
                                                                                    name={name}
                                                                                    component="div"
                                                                                    className="error error-message"
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row row justify-content-end">
                                                            <div className="col-auto">
                                                                <Button
                                                                    type="button"
                                                                    variant={BUTTON_VARIANTS.PRIMARY}
                                                                    disabled={!userEmail.length}
                                                                    size={BUTTON_SIZE.SM}
                                                                    label={TranslationService.translateModule(
                                                                        'GoToSummary',
                                                                        ModuleNamesList.OrderPaper
                                                                    )}
                                                                    onClick={handleClickSummary}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Form>
                                </CardContent>
                            </>
                        </Card>
                    ) : (
                        <div>
                            <Card class="mt-3">
                                <>
                                    <CardHeader>
                                        <h1 className="c-heading">
                                            {TranslationService.translateModule(
                                                'OrderSuppliesSummary',
                                                ModuleNamesList.OrderPaper
                                            )}
                                        </h1>
                                    </CardHeader>

                                    <CardContent>
                                        <>
                                            <BootstrapTableContainer
                                                remote={false}
                                                wrapperClasses="bt"
                                                paginationProps={{}}
                                                classes="bt__table bt-table"
                                                keyField="id"
                                                data={productTable.values}
                                                columns={
                                                    productTable.columns.length === 0
                                                        ? []
                                                        : OrderPaperTableConfigurator.getPaperTableColumns(
                                                              productTable,
                                                              removeSupply
                                                          )
                                                }
                                            />

                                            <div className={classNames('row justify-content-end', styles.productName)}>
                                                <div className="col-auto">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className={classNames(styles['amount'])}>
                                                                {CurrencyParser.toLocaleString(
                                                                    orderProduct[0].sumVatExclusive
                                                                )}{' '}
                                                                {props.currency}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className={classNames(styles['annotation'])}>
                                                                {TranslationService.translateModule(
                                                                    'TotalTaxExcluded',
                                                                    ModuleNamesList.OrderPaper
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-auto">
                                                    <div className={classNames(styles['amount'])}>
                                                        {CurrencyParser.toLocaleString(
                                                            orderProduct[0].sumVatInclusive
                                                        )}{' '}
                                                        {props.currency}
                                                    </div>
                                                    <div className={classNames(styles['annotation'])}>
                                                        {TranslationService.translateModule(
                                                            'TotalTaxIncluded',
                                                            ModuleNamesList.OrderPaper
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row justify-content-between">
                                                <div className="col-auto">
                                                    <Button
                                                        variant={BUTTON_VARIANTS.PRIMARY}
                                                        size={BUTTON_SIZE.SM}
                                                        type="button"
                                                        label={TranslationService.translateModule(
                                                            'Previous',
                                                            ModuleNamesList.OrderPaper
                                                        )}
                                                        onClick={handlePreviousClick}
                                                    />
                                                </div>

                                                <div className="col-auto">
                                                    <div className={classNames('float-right')}>
                                                        <Button
                                                            type="button"
                                                            variant={BUTTON_VARIANTS.PRIMARY}
                                                            disabled={productTable.values.length === 0}
                                                            size={BUTTON_SIZE.SM}
                                                            label={TranslationService.translateModule(
                                                                'Checkout',
                                                                ModuleNamesList.OrderPaper
                                                            )}
                                                            onClick={handleClickCheckout}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </CardContent>
                                </>
                            </Card>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        creditors: state.creditors,
        currentCreditors: state.currentCreditors,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(OrderPaper);

import * as React from 'react';
import {Link} from "react-router-dom";
import './NewUserBox.scss';

const NewUserBox = () => {
    return (
        <div className="card sign-in__card new-user-box">
            <span className="card-icon new-user-icon"/>
            <div className="card-body">
                <h5 className="card-title">
                    New user
                </h5>
                <p>
                    This portal will support our fast and
                    efficient handling of your claims.
                </p>
                <p>
                    If you would like to become
                    a new customer, we offer a fast
                    and easy self-registration.
                </p>
                <p>
                    <Link className="btn btn-secondary registerButton" to="/">
                        <span className="fas fa-user-plus"/> Register
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default NewUserBox;

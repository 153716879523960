import { DebtorType } from './DebtorType';

export interface IDebtorDetails {
    domainId: string;
    address: string;
    address2: string;
    city: string;
    companyNameOrLastName: string;
    country: string;
    debtorType: DebtorType;
    email: string;
    firstName: string;
    identity: string;
    phoneNumber: string;
    postalCode: string;
    houseNo: string;
    houseNo2: string;
    referenceNo: string;
}

export const emptyDebtorDetails: IDebtorDetails = {
    domainId: '',
    address: '',
    address2: '',
    city: '',
    companyNameOrLastName: '',
    country: '',
    debtorType: DebtorType.Private,
    email: '',
    firstName: '',
    identity: '',
    phoneNumber: '',
    postalCode: '',
    houseNo: '',
    houseNo2: '',
    referenceNo: ''
}
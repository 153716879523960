import RestApiClient from '../../core/api/RestApiClient';
import { ISumOfAllCasesData } from './types/ISumOfAllCasesData';
import { CancelToken } from 'axios';

class CaseStatisticsSumOfAllCasesApiClient {
    public async getData(cancelToken: CancelToken): Promise<ISumOfAllCasesData[]>{
        return await RestApiClient.get<ISumOfAllCasesData[]>(`modules/CaseStatisticsSumOfAllCases/getData`, {}, cancelToken);
    }
}

export default new CaseStatisticsSumOfAllCasesApiClient();

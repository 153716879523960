import * as React from 'react';
import './StickyFooter.scss';
import StyleWrapper from '../../core/components/StyleWrapper/StyleWrapperHOC';

const StickyFooter = () => (
    <footer className="sticky-footer">
        <div className="sticky-footer__body float-right"/>
    </footer>
);

const styleConfig = [
    {
        searchMethod: 'module',
        instructions: {
            backgroundColor: '.sticky-footer',
            imgUrl: '.sticky-footer .sticky-footer__body',
            imgDisabled: '.sticky-footer .sticky-footer__body',
        }
    }
]

export default StyleWrapper('module:footer', styleConfig)(StickyFooter);
import { store } from "../../index";
import TranslationsApiClient from '../api/TranslationsApiClient';
import { IDictionary } from '../types/IDictionary';
import { ITranslationCollection } from '../types/ITranslationCollection';
const DefaultLanguageCode = "en-us";

class TranslationService {

    public async getTranslationsFromApi() : Promise<IDictionary<ITranslationCollection>>{
        return await this.dispatchTranslations();
    }

    public translateModule(translationKey: string, moduleName: string): string
    {
        const state = store.getState();
        const currentLocale = state.currentCultureCode.toLowerCase();
        const translations = state.translations as IDictionary<ITranslationCollection>;

        if (!translations || !translations.hasOwnProperty(currentLocale) || !translations[currentLocale].modules.hasOwnProperty(moduleName))
        {
            return this.getDefaultModuleTralsation(translationKey, translations, moduleName);
        }

        const moduleTranslations = translations[currentLocale].modules[moduleName];
        return moduleTranslations.hasOwnProperty(translationKey) ? moduleTranslations[translationKey] : this.translate(translationKey);
    }

    public translate(translationKey: string, valueToReturnIfNoTranslationFound?: string) : string
    {
        return this.getStoreTranslation(translationKey, valueToReturnIfNoTranslationFound);
    }

    public translateFormat(translationKey: string, ...args: [string]) : string
    {
        const translation = this.getStoreTranslation(translationKey);
        return translation
            .split("{0}")
            .reduce((agg, p, i) =>
            agg + p + (args[i] || ""), "");
    }

    private getStoreTranslation(translationKey: string, valueToReturnIfNoTranslationFound?: string): string
    {
        const state = store.getState();
        const currentLocale = state.currentCultureCode.toLowerCase();
        const translations = state.translations as IDictionary<ITranslationCollection>;
        if (!translations || !translations.hasOwnProperty(currentLocale) || !translations[currentLocale].global.hasOwnProperty(translationKey))
        {
            return this.getDefaultTralsation(translationKey, translations, valueToReturnIfNoTranslationFound);             
        }

        return translations[currentLocale].global[translationKey];
    }

    private async dispatchTranslations() : Promise<IDictionary<ITranslationCollection>>
    {
        const translations = await TranslationsApiClient.GetAllTranslations();

        return translations;
    }

    private getDefaultTralsation(translationKey: string, translations: IDictionary<ITranslationCollection>, valueToReturnIfNoTranslationFound?: string): string
    {
        if (!!translations && translations.hasOwnProperty(DefaultLanguageCode) && translations[DefaultLanguageCode].global.hasOwnProperty(translationKey))
        {
            return translations[DefaultLanguageCode].global[translationKey];
        }
        
        return valueToReturnIfNoTranslationFound || translationKey;
    }

    private getDefaultModuleTralsation(translationKey: string, translations: IDictionary<ITranslationCollection>, moduleName: string) : string
    {
        if (!translations || !translations.hasOwnProperty(DefaultLanguageCode) || !translations[DefaultLanguageCode].modules.hasOwnProperty(moduleName))
        {
            return this.translate(translationKey);
        }

        const moduleTranslations = translations[DefaultLanguageCode].modules[moduleName];
        return moduleTranslations.hasOwnProperty(translationKey) ? moduleTranslations[translationKey] : this.translate(translationKey);
    }
}

export default new TranslationService();
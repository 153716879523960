import RestApiClient from '../../core/api/RestApiClient';
import {ICreditorRegistrationConfiguration} from './types/ICreditorRegistrationConfiguration';
import {ICompanyForm} from './types/ICompanyForm';
import {IActionStatus} from '../../core/api/types/IActionStatus';
import {IConfirmationCodes} from './types/IConfirmationCodes';

class CreditorRegistrationApiClient {
    public async GetModuleSettings(moduleInstanceId: number): Promise<ICreditorRegistrationConfiguration> {
        return await RestApiClient.get<ICreditorRegistrationConfiguration>(`modules/CreditorRegistrationModule/getInstanceSettings/${moduleInstanceId}`);
    }

    public async CreateNewCreditor(creditorDetails: ICompanyForm, moduleInstanceId: number): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>(`modules/CreditorRegistrationModule/createCreditor/${moduleInstanceId}`, creditorDetails);
    }

    public async GenerateAgreement(creditorDetails: ICompanyForm): Promise<string> {
        return await RestApiClient.post<string>(`modules/CreditorRegistrationModule/generateAgreement`, creditorDetails);
    }

    public async SendConfirmationCodes(moduleInstanceId: number): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>(`modules/CreditorRegistrationModule/sendConfirmationCodes/${moduleInstanceId}`);
    }

    public async ValidateConfirmationCodes(confirmationCodes: IConfirmationCodes, moduleInstanceId: number): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>(`modules/CreditorRegistrationModule/validateConfirmationCodes/${moduleInstanceId}`, confirmationCodes);
    }

    public async ValidateIsUserEmailExists(email: string): Promise<boolean> {
        return await RestApiClient.get<boolean>(`modules/CreditorRegistrationModule/validateUserEmailExists/${email}`);
    }

    public async ValidateIsCompanyNumberExists(companyNumber: string): Promise<boolean> {
        return await RestApiClient.get<boolean>(`modules/CreditorRegistrationModule/validateOrgNumberExists/${companyNumber}`);
    }

    public async GetCompanyDataFromExternalProvider(moduleInstanceId: number, orgNumber: string): Promise<IActionStatus<ICompanyForm>> {
        return await RestApiClient.get<IActionStatus<ICompanyForm>>(`modules/CreditorRegistrationModule/getCompanyDataFromExternalProvider/${moduleInstanceId}/${orgNumber}`);
    }
}

export default new CreditorRegistrationApiClient();

import * as React from 'react';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { FormGroup, FORM_GROUP_VARIANTS } from "../../../core/components/Forms/FormGroup";
import { FormFieldset } from "../../../core/components/Forms/FormFieldset";
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import {ErrorMessage, Form, Formik} from 'formik';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import * as yup from "yup";
import { ITransferInvoiceFilterRequest } from '../types/ITransferInvoiceFilterRequest';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { TransferInvoicesSelectFilter } from '../types/TransferInvoicesSelectFilter';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { numberOnlyRegex } from '../../../core/helpers/utils';

interface IState {
    filterRequest: ITransferInvoiceFilterRequest;
}

interface IProps{
    filterCases: (request: ITransferInvoiceFilterRequest) => void,
    baseRequest: ITransferInvoiceFilterRequest,
    filterByOptions: INumberDictionary<string>
}

class TransferInvoicesFilter extends React.Component<IProps, IState> {
    state: IState = {
        filterRequest: {},
    };

    validationMessages = {
        required: TranslationService.translate("ValidationMessageRequiredField"),
        incorrectNumberFormat: TranslationService.translate("ValidationMessageIncorrectNumberFormat"),
        incorrectAccountNumber: TranslationService.translateModule('ValidationMessageIncorrectNumberFormat', ModuleNamesList.CustomerTransactionsList),
        toBigAccountNumber: TranslationService.translateModule('ValidationAccountNumberTooBig', ModuleNamesList.CustomerTransactionsList)
    };

    validationSchema = yup.object().shape({
        invoiceNoFrom: yup.number().typeError(this.validationMessages.incorrectNumberFormat),
        invoiceNoTo: yup.number().typeError(this.validationMessages.incorrectNumberFormat),
        banalceFrom: yup.number().typeError(this.validationMessages.incorrectNumberFormat),
        balanceTo: yup.number().typeError(this.validationMessages.incorrectNumberFormat),
        filterByValue: yup.string()
        .when("filteredBy", (val: string, schema: any) => {
            const mappedValue: any = TransferInvoicesSelectFilter[val];
            if (mappedValue === TransferInvoicesSelectFilter.AccountNumber) {
              return schema
                .required(this.validationMessages.required)
                .test('accNumber', 
                    this.validationMessages.incorrectAccountNumber,
                    (value: any) => numberOnlyRegex.test(value) )
                .test('accNumberLength', 
                    this.validationMessages.toBigAccountNumber,
                    (value: any) => numberOnlyRegex.test(value) && parseInt(value) <= (Math.pow(2, 31) - 1)  ) 
            } else if (val === undefined) {
                return schema.notRequired();
            }
            return schema.required(this.validationMessages.required);
        })
    });

    public render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    filterByValue: '',
                    invoiceNoFrom: '',
                    invoiceNoTo: '',
                    balanceFrom: '',
                    balanceTo: ''
                }}
                onSubmit={(value: ITransferInvoiceFilterRequest) => {
                    this.props.filterCases(value);
                }}
                validationSchema={this.validationSchema}
                >
                {({
                      values,
                      handleChange,
                  }) => {
                    return (
                        <Form className="c-form">
                            <div className="row">
                                <div className="col-12 col-lg-4 col-sm-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.BLOCK}
                                        name="filteredBy"
                                        label={TranslationService.translateModule('FilterBy', ModuleNamesList.TransferToDebtCollection)}
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-6">
                                                    <SimpleSelect
                                                        id="tranfert-dbcollectio-select-filter"
                                                        name={name}
                                                        value={values.filteredBy ? TransferInvoicesSelectFilter[values.filteredBy].toString() : undefined}
                                                        onChangeHandle={(value) => this.handleChange(TransferInvoicesSelectFilter[value.key], name, handleChange)}
                                                        emptyFirstRow={true}
                                                        options={this.props.filterByOptions}/>
                                                </div>
                                                <div className="col-6">
                                                    <SimpleInput
                                                        id="filterByValue"
                                                        name="filterByValue"
                                                        value={values.filterByValue}
                                                        onChange={(value) => this.handleChange(value, "filterByValue", handleChange)}/>
                                                    <ErrorMessage name="filterByValue" component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 col-xl-3 col-sm-6">
                                    <FormFieldset
                                        className="bl br"
                                        label={TranslationService.translateModule('InvoiceNo', ModuleNamesList.TransferToDebtCollection)}
                                        render={() => (
                                            <div className="row">
                                                <div className="col">
                                                    <FormGroup
                                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                                        name="invoiceNoFrom"
                                                        label={TranslationService.translateModule('From', ModuleNamesList.TransferToDebtCollection)}
                                                        render={(name) => (
                                                            <>
                                                                <SimpleInput
                                                                    id="invoiceNoFrom"
                                                                    name={name}
                                                                    value={values.invoiceNoFrom}
                                                                    onChange={(value) => this.handleChange(value, name, handleChange)}
                                                                />

                                                                <ErrorMessage name={name} component="div" className="error error-message" />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <FormGroup
                                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                                        name="invoiceNoTo"
                                                        label={TranslationService.translateModule('To', ModuleNamesList.TransferToDebtCollection)}
                                                        render={(name) => (
                                                            <>
                                                                <SimpleInput
                                                                    id="invoiceNoTo"
                                                                    name={name}
                                                                    value={values.invoiceNoTo}
                                                                    onChange={(value) => this.handleChange(value, name, handleChange)}
                                                                />

                                                                <ErrorMessage name={name} component="div" className="error error-message" />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 col-xl-3 col-sm-6">
                                    <FormFieldset
                                        label={TranslationService.translateModule('BalanceRange', ModuleNamesList.TransferToDebtCollection)}
                                        render={() => (
                                            <div className="row">
                                                <div className="col">
                                                    <FormGroup
                                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                                        name="balanceFrom"
                                                        label={TranslationService.translateModule('From', ModuleNamesList.TransferToDebtCollection)}
                                                        render={(name) => (
                                                            <>
                                                                <SimpleInput
                                                                    id="balanceFrom"
                                                                    name={name}
                                                                    value={values.balanceFrom}
                                                                    onChange={(value) => this.handleChange(value, name, handleChange)}
                                                                />

                                                                <ErrorMessage name={name} component="div" className="error error-message" />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <FormGroup
                                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                                        name="balanceTo"
                                                        label={TranslationService.translateModule('To', ModuleNamesList.TransferToDebtCollection)}
                                                        render={(name) => (
                                                            <>
                                                                <SimpleInput
                                                                    id="balanceTo"
                                                                    name={name}
                                                                    value={values.balanceTo}
                                                                    onChange={(value) => this.handleChange(value, name, handleChange)}
                                                                />

                                                                <ErrorMessage name={name} component="div" className="error error-message" />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 align-self-end">
                                    <Button
                                        id="customerTransactionsFilterBtn"
                                        variant={BUTTON_VARIANTS.PRIMARY}
                                        size={BUTTON_SIZE.MD}
                                        label={TranslationService.translateModule('Filter', ModuleNamesList.CustomerTransactionsList)}
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        );
    }

    handleChange = (value: string, name: string, handleChange: any) => {
        const handleChangeEvent = {
            target: {
                id: name,
                name: name,
                value: value
            }
        } as React.ChangeEvent<any>;

        handleChange(handleChangeEvent);
    }
}

export default TransferInvoicesFilter;
import {Form, Formik} from 'formik';
import * as React from 'react';
import FormGroupField from '../../../core/components/Forms/FormGroupField';
import {ModuleNamesList} from '../../../core/lists/ModuleNamesList';
import * as yup from 'yup';
import TranslationService from '../../../core/services/TranslationService';
import {IDebtorDetails} from '../types/IDebtorDetails';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import {IDebtorSetup} from '../types/IDebtorSetup';
import {IDictionary} from '../../../core/types/IDictionary';
import {ICountryDetails} from '../types/ICountryDetails';
import {SimpleSelect} from '../../../core/components/Forms/SimpleSelect';
import {DebtorType} from '../types/DebtorType';
import {ISelectedValue} from '../../../core/components/Forms/types/ISelectedValue';
import {IFieldsToggles} from '../types/FieldsToggles';
import {withoutSpaces, phoneRegex} from "../../../core/helpers/utils";

interface IProps {
  debtorDetails: IDebtorDetails;
  debtorSetup: IDebtorSetup;
  debtorType: DebtorType;
  fieldsToggles: IFieldsToggles,

  backToPrevStep(): void;

  goToNextStep(): void;

  setDebtorDetails(invoice: IDebtorDetails): void;
}

class DebtorForm extends React.Component<IProps> {
  private validationMessages = {
    required: TranslationService.translate("ValidationMessageRequiredField"),
    email: TranslationService.translate("ValidationMessageIncorrentEmailFormat"),
    phone: TranslationService.translate("ValidationMessageIncorrectPhoneNoFormat"),
    incorrectZipCodeFormat: TranslationService.translate("ValidationMessageIncorrentZipCodeFormat")
  };

  private validationSchema = yup.object().shape({
    city: yup.string().required(this.validationMessages.required),
    email: yup.string().email(this.validationMessages.email),
    firstName: yup.lazy(() => {
      return this.props.debtorType === DebtorType.Private && !this.props.fieldsToggles.disableFirstName ? yup.string().required(this.validationMessages.required) : yup.string().notRequired();
    }),
    companyNameOrLastName: yup.string().required(this.validationMessages.required),
    phoneNumber: yup.string().matches(phoneRegex, this.validationMessages.phone),
    postalCode: yup.string().typeError(this.validationMessages.incorrectZipCodeFormat)
      .transform((value, originalValue) => {
        return withoutSpaces.test(originalValue) ? value: NaN;
      })
      .required(this.validationMessages.required),
    country: yup.string().required(this.validationMessages.required),
    identity: yup.number().typeError(this.validationMessages.incorrectZipCodeFormat)
  });

  private getAdressCellsClasses = (): string[] => {
    let postalCodeClass = 'col-5 pr-1';
    let cityClass = 'col-7 pl-1';
    let houseNoClass = '';
    let houseNo2Class = '';

    if (!this.props.fieldsToggles.disableHouseNo && !this.props.fieldsToggles.disableHouseNo) {
      postalCodeClass = 'col-4 pr-1'
      cityClass = 'col-4 pl-1'
      houseNoClass = 'col-2'
      houseNo2Class = 'col-2'
    } else if (!this.props.fieldsToggles.disableHouseNo && this.props.fieldsToggles.disableHouseNo2) {
      postalCodeClass = 'col-4 pr-1'
      cityClass = 'col-5 pl-1'
      houseNoClass = 'col-3'
    } else if (this.props.fieldsToggles.disableHouseNo && !this.props.fieldsToggles.disableHouseNo2) {
      postalCodeClass = 'col-4 pr-1'
      cityClass = 'col-5 pl-1'
      houseNo2Class = 'col-3'
    }

    return [postalCodeClass, cityClass, houseNoClass, houseNo2Class];
  }

  public render() {
    const adressClasses = this.getAdressCellsClasses();
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{...this.props.debtorDetails}}
        onSubmit={(value: IDebtorDetails) => {
          value.debtorType = this.props.debtorType;
          this.props.setDebtorDetails(value);
          this.props.goToNextStep();
        }}
        validationSchema={this.validationSchema}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setTouched,
            submitForm
          }) => {
          return (
            <Form>
              <div className="row justify-content-between">
                <div className="col-12 col-md-5">
                  {this.props.debtorType === DebtorType.Private && !this.props.fieldsToggles.disableFirstName &&
                  <FormGroupField
                    errors={errors}
                    fieldName="firstName"
                    formGroupClass="mb-1 mb-sm-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    inputContainerClass="col-12 col-sm-8"
                    label={TranslationService.translateModule('DebtorInfoFirstName', ModuleNamesList.CreateNewCase)}
                    labelClass="col-sm-4"
                    touched={touched}
                    required={true}
                    value={values.firstName}
                  />
                  }

                  <FormGroupField
                    errors={errors}
                    fieldName="companyNameOrLastName"
                    formGroupClass="mb-1 mb-sm-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    inputContainerClass="col-12 col-sm-8"
                    label={TranslationService.translateModule(this.props.debtorType === DebtorType.Private ? 'DebtorInfoLastName' : 'DebtorInfoCompanyName', ModuleNamesList.CreateNewCase)}
                    labelClass="col-sm-4"
                    touched={touched}
                    required={true}
                    value={values.companyNameOrLastName}
                  />

                  <FormGroupField
                    errors={errors}
                    fieldName="email"
                    formGroupClass="mb-1 mb-sm-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    inputContainerClass="col-12 col-sm-8"
                    label={TranslationService.translateModule('DebtorInfoEmail', ModuleNamesList.CreateNewCase)}
                    labelClass="col-sm-4"
                    touched={touched}
                    // required={true}
                    value={values.email}
                  />

                  <FormGroupField
                    errors={errors}
                    fieldName="identity"
                    formGroupClass="mb-1 mb-sm-3"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    inputContainerClass="col-12 col-sm-8"
                    label={TranslationService.translateModule(this.props.debtorType === DebtorType.Private ? 'DebtorInfoPersonalId' : 'DebtorInfoOrgId', ModuleNamesList.CreateNewCase)}
                    labelClass="col-sm-4"
                    touched={touched}
                    // required={true}
                    value={values.identity}
                  />

                  <FormGroupField
                    errors={errors}
                    formGroupClass="mb-1 mb-sm-3"
                    fieldName="address"
                    handleChange={handleChange}
                    label={TranslationService.translateModule('DebtorInfoAddress', ModuleNamesList.CreateNewCase)}
                    handleBlur={handleBlur}
                    touched={touched}
                    labelClass="col-sm-4"
                    inputContainerClass="col-12 col-sm-8"
                    //required={true}
                    value={values.address}
                  />

                  {!this.props.fieldsToggles.disableReferenceNo && <FormGroupField
                    errors={errors}
                    formGroupClass="mb-1 mb-sm-3"
                    fieldName="referenceNo"
                    handleChange={handleChange}
                    label={TranslationService.translateModule('DebtorInfoReferenceNo', ModuleNamesList.CreateNewCase)}
                    handleBlur={handleBlur}
                    touched={touched}
                    labelClass="col-sm-4"
                    inputContainerClass="col-12 col-sm-8"
                    required={false}
                    value={values.referenceNo}
                  />}
                </div>

                <div className="col-12 col-md-6">

                  <FormGroupField
                    errors={errors}
                    formGroupClass="mb-1 mb-sm-3"
                    fieldName="address2"
                    handleChange={handleChange}
                    label={TranslationService.translateModule('DebtorInfoAddress2', ModuleNamesList.CreateNewCase)}
                    handleBlur={handleBlur}
                    touched={touched}
                    labelClass="col-sm-4"
                    inputContainerClass="col-12 col-sm-8"
                    //required={true}
                    value={values.address2}
                  />

                  <div className="c-control mb-1 mb-sm-3">
                    <div className="row">
                      <label className="col-sm-4 c-control__label" htmlFor="postalCode">
                        {TranslationService.translateModule('DebtorInfoPostalCode', ModuleNamesList.CreateNewCase)}
                        <span className="c-control__required-sign"/>
                      </label>

                      <div className="col-12 col-sm-8">
                        <div className="row">
                          <FormGroupField
                            errors={errors}
                            fieldName="postalCode"
                            handleBlur={handleBlur}
                            hideFormGroupDiv={true}
                            handleChange={handleChange}
                            hideLabel={true}
                            inputContainerClass={adressClasses[0]}
                            touched={touched}
                            required={true}
                            value={values.postalCode}
                          />

                          <FormGroupField
                            errors={errors}
                            fieldName="city"
                            handleBlur={handleBlur}
                            hideFormGroupDiv={true}
                            handleChange={handleChange}
                            placeholder={TranslationService.translateModule('DebtorInfoCity', ModuleNamesList.CreateNewCase)}
                            hideLabel={true}
                            inputContainerClass={adressClasses[1]}
                            touched={touched}
                            required={true}
                            value={values.city}
                          />

                          {!this.props.fieldsToggles.disableHouseNo && <FormGroupField
                            errors={errors}
                            fieldName="houseNo"
                            handleBlur={handleBlur}
                            hideFormGroupDiv={true}
                            handleChange={handleChange}
                            placeholder={TranslationService.translateModule('DebtorInfoHouseNo', ModuleNamesList.CreateNewCase)}
                            hideLabel={true}
                            inputContainerClass={adressClasses[2]}
                            touched={touched}
                            required={false}
                            value={values.houseNo}
                          />}

                          {!this.props.fieldsToggles.disableHouseNo2 && <FormGroupField
                            errors={errors}
                            fieldName="houseNo2"
                            handleBlur={handleBlur}
                            hideFormGroupDiv={true}
                            handleChange={handleChange}
                            placeholder={TranslationService.translateModule('DebtorInfoHouseNo2', ModuleNamesList.CreateNewCase)}
                            hideLabel={true}
                            inputContainerClass={adressClasses[3]}
                            touched={touched}
                            required={false}
                            value={values.houseNo2}
                          />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="c-control row mb-1 mb-sm-3">
                    <label className="col-sm-4 c-control__label" htmlFor="amount">
                      {TranslationService.translateModule('DebtorInfoCountry', ModuleNamesList.CreateNewCase)}
                      <span className="c-control__required-sign"/>
                    </label>

                    <div className="col-12 col-sm-8">
                      <SimpleSelect
                        id="available-countries"
                        name="country"
                        isNotStandard={true}
                        options={this.availableCountries()}
                        value={values.country}
                        onChangeHandle={(selected: ISelectedValue) => {
                          const handleChangeEvent = {
                            target: {
                              id: 'country',
                              name: 'country',
                              value: selected.key
                            }
                          } as React.ChangeEvent<any>;

                          setTouched({'country': true});
                          handleChange(handleChangeEvent);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <FormGroupField
                    errors={errors}
                    formGroupClass="mb-1 mb-sm-3"
                    fieldName="phoneNumber"
                    handleChange={handleChange}
                    label={TranslationService.translateModule('DebtorInfoPhoneNumber', ModuleNamesList.CreateNewCase)}
                    handleBlur={handleBlur}
                    touched={touched}
                    labelClass="col-sm-4"
                    inputContainerClass="col-12 col-sm-8"
                    // required={true}
                    value={values.phoneNumber}
                  />
                </div>
              </div>

              <hr className="mt-2 mb-3"/>

              <StepListWizardButtons
                leftButtonOnClick={this.props.backToPrevStep}
                leftButtonClassName="float-left small text-transform-none"
                leftButtonText={
                  <>
                    <i className="fas fa-chevron-left left"/>
                    {' ' + TranslationService.translateModule('DebtorInfoBackButton', ModuleNamesList.CreateNewCase)}
                  </>
                }
                rightButtonClassName="c-btn--icon-right"
                rightButtonOnClick={() => {
                  submitForm();
                }}
                rightButtonText={
                  <>
                    {TranslationService.translateModule('DebtorInfoNextButton', ModuleNamesList.CreateNewCase) + ' '}
                    <i className="fas fa-chevron-right right"/>
                  </>
                }
              />
            </Form>
          )
        }}
      </Formik>
    );
  }

  private availableCountries = (): IDictionary<string> => {
    const currencies = this.props.debtorSetup.countries;
    const mappedCountries: IDictionary<string> = {};

    currencies.forEach((countryDetails: ICountryDetails) =>
      mappedCountries[countryDetails.code] = countryDetails.name.charAt(0).toUpperCase() + countryDetails.name.toLowerCase().slice(1));

    return mappedCountries;
  }
}

export default DebtorForm;
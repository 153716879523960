import React, { useState } from 'react';
import ExternalLoginProvidersForm from './ExternalLoginProvidersForm';
import { ExternalLoginProviderType, emptyExternalLoginProviderData } from '../types/ExternalLoginProviderType';
import { LoginProviderType } from '../types/LoginProviderType';
import { SettingsType } from 'core/Enums/SettingsType';
import { InputContainer } from 'core/components/Forms/InputContainer';
import { IDictionary } from 'core/types/IDictionary';
import { FORM_GROUP_VARIANTS, FormGroup } from 'core/components/Forms/FormGroup';
import { ModuleNamesList } from 'core/lists/ModuleNamesList';
import TranslationService from 'core/services/TranslationService';
import { LoginProviderTypeId } from '../types/LoginProviderTypeId';
import NETSLoginProviderForm from './NETSLoginProviderForm';
import OidcLoginProviderForm from './OidcLoginProviderForm';

type AddNewProviderModalType = {
    providerTypes: LoginProviderType[],
    onClose: () => void,
    onSubmit: (data: ExternalLoginProviderType) => void,
}

const AddNewProviderModal = ({ providerTypes, onClose, onSubmit}: AddNewProviderModalType) => {
    const [selectedProviderType, setSelectedProviderType] = useState<LoginProviderType | undefined>(undefined)

    const options = () => {
        const options: IDictionary<string> = {};
        providerTypes.forEach(item => options[item.id] = item.displayName);
        return options;
    }
    
    const withUsePkce = (data: ExternalLoginProviderType) => {
        return {...data, usePkce: true}
    }

    const getProviderForm = () => {
        if(!selectedProviderType)
        {
            return <></>
        }

        switch (selectedProviderType.id)
        {
            case LoginProviderTypeId.AzureAd:
                return <ExternalLoginProvidersForm data={{...emptyExternalLoginProviderData}} onClose={onClose} onSubmit={(values) => onSubmit({...values, loginProviderTypeId: selectedProviderType.id})} />
            case LoginProviderTypeId.NETS:
                return <NETSLoginProviderForm data={{...emptyExternalLoginProviderData}} onClose={onClose} onSubmit={(values) => onSubmit({...values, loginProviderTypeId: selectedProviderType.id})}/>;
            case LoginProviderTypeId.Oidc:
                return <OidcLoginProviderForm data={withUsePkce({...emptyExternalLoginProviderData})} onClose={onClose} onSubmit={(values) => onSubmit({...values, loginProviderTypeId: selectedProviderType.id})}/>;
                
            default:
                return <></>
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <FormGroup
                        variant={FORM_GROUP_VARIANTS.INLINE}
                        name="loginProvider"
                        render={(name) => (
                            <div className="row">
                                <div className="col-5 d-flex align-items-center justify-content-end">
                                    {TranslationService.translateModule('ChooseProviderType', ModuleNamesList.ExternalLoginProviders)}
                                </div>
                                <div className="col-7">
                                    <InputContainer
                                        id={"selectedProviderType"}
                                        name={"selectedProviderType"}
                                        type={SettingsType.Select}
                                        options={options()}
                                        value={selectedProviderType ? selectedProviderType.id.toString() : undefined}
                                        onChange={(value)=> {
                                            setSelectedProviderType(providerTypes.find(item => item.id.toString() === value.toString()));
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    />
                </div>
            </div>
            {getProviderForm()}
        </>
    )
}

export default AddNewProviderModal;
// @ts-ignore
import toastr from 'toastr';
import { IActionStatus } from '../api/types/IActionStatus';
import { Status } from '../api/Enums/Status';
import TranslationService from './TranslationService';
import { IActionStatusTyped } from '../api/types/IActionStatusTyped';

class InfoMessageService {

    constructor() {
        toastr.options.closeButton = true;
        toastr.options.timeOut = 6000;
    }

    public success(message: string, title?: string, options?: any) {
        toastr.success(message, title ? title : TranslationService.translate("InfoMessageSuccessTitle"), options)
    }

    public error(message: string, title?: string, options?: any) {
        toastr.error(message, title ? title : TranslationService.translate("InfoMessageErrorTitle"), options)
    }

    public warning(message: string, title?: string, options?: any) {
        toastr.warning(message, title ? title : TranslationService.translate("InfoMessageWarningTitle"), options)
    }

    public displayActionStatus(status: IActionStatus, translate?: boolean, options?: any) {
        let completeMessage = '';
        if (status.messages) {
            status.messages.map((message) => {
            return completeMessage += (translate ? TranslationService.translate(message) : message)+ "\n";
            });
        }

        switch (status.status) {
            case Status.Error:
                this.error(completeMessage, TranslationService.translate("InfoMessageErrorTitle"), options);
                break;
            case Status.Success:
                this.success(completeMessage, TranslationService.translate("InfoMessageSuccessTitle"), options);
                break;
            case Status.Warning:
            default:
                this.warning(completeMessage, TranslationService.translate("InfoMessageWarningTitle"), options);
                break;
        }
    }

    public displayActionStatusTyped(status: IActionStatusTyped<any>, translate?: boolean, options?: any) {
        let completeMessage = '';
        if (status.messages) {
            status.messages.map((message: string) => {
            return completeMessage += (translate ? TranslationService.translate(message) : message)+ "\n";
            });
        }

        switch (status.status) {
            case Status.Error:
                this.error(completeMessage, TranslationService.translate("InfoMessageErrorTitle"), options);
                break;
            case Status.Success:
                this.success(completeMessage, TranslationService.translate("InfoMessageSuccessTitle"), options);
                break;
            case Status.Warning:
            default:
                this.warning(completeMessage, TranslationService.translate("InfoMessageWarningTitle"), options);
                break;
        }
    }
}

export default new InfoMessageService();
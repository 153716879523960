import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import CreditorsService from '../core/services/CreditorsService';

const fetchCreditorsInProgress = () => ({
    type: ACTIONS.FETCH_CREDITORS_IN_PROGRESS
})

const fetchCreditorsSucceeded = (data: any) => ({
    type: ACTIONS.FETCH_CREDITORS_SUCCEEDED,
    creditors: data
})

const fetchCreditorsFailed = () => ({
    type: ACTIONS.FETCH_CREDITORS_FAILED
})

export default () => (dispatch: Dispatch<any>) => {
    return new Promise<void>((resolve, reject) => {
    dispatch(fetchCreditorsInProgress());
    return CreditorsService.getCreditorsForLoggedUser()
        .then(data => {
            dispatch(fetchCreditorsSucceeded(data));
            resolve();
        })
        .catch(_ =>  {
            dispatch(fetchCreditorsFailed())
            reject();
        })
    })
}

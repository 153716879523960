import * as React from 'react';
import { Link } from 'react-router-dom';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IInvoicesAgeAnalysisListItem } from '../types/IInvoicesAgeAnalysisListItem';
import { InvoicesAgeAnalysisColumns } from '../types/InvoicesAgeAnalysisColumns';

class InvoicesAgeAnalysisListTableConfigurator {
    public getTableColumns = (
        table: IComponentTable<IInvoicesAgeAnalysisListItem>,
        linkUrl: string,
        openDebtorDetailsModal: (debtorNo: string, creditorNo: string) => void) => {
        let columns: IComponentTableColumn[] = [];
        if (table != null) {
            columns = [...table.columns];
            this.configureCurrencyColumns(columns);
            this.configureDebtorLinkColumn(columns, openDebtorDetailsModal);
            this.configureAccountNoLinkColumn(columns, linkUrl);

            return columns;
        }
        return columns;
    };

    private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
        const currencyColumns = [InvoicesAgeAnalysisColumns.NotDue, InvoicesAgeAnalysisColumns.Balance, InvoicesAgeAnalysisColumns.ZeroTo30, InvoicesAgeAnalysisColumns.ThirtyTo60, InvoicesAgeAnalysisColumns.SixtyTo90, InvoicesAgeAnalysisColumns.NinetyPlus];
        const currencyColumnsNames = currencyColumns.map(col => InvoicesAgeAnalysisColumns[col].charAt(0).toLowerCase() + InvoicesAgeAnalysisColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => CurrencyParser.toLocaleString(value);

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    };

    private configureDebtorLinkColumn = (columns: IComponentTableColumn[], openDebtorDetailsModal: (debtorNo: string, creditorNo: string) => void) => {
        const columnsList = [InvoicesAgeAnalysisColumns.DebtorName];
        const columnsNames = columnsList.map(col => InvoicesAgeAnalysisColumns[col].charAt(0).toLowerCase() + InvoicesAgeAnalysisColumns[col].slice(1));
        const cols = columns.filter(col => columnsNames.indexOf(col.dataField) >= 0);

        const formatterFunc = (value: any, row: any) => <Link to="#" onClick={() => openDebtorDetailsModal(row.debtorNo, row.creditorNo)} className="font-color-1 font-weight-bold">{row.debtorName}</Link>

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    };

    private configureAccountNoLinkColumn = (columns: IComponentTableColumn[], linkUrl: string) => {
        const columnsList = [InvoicesAgeAnalysisColumns.AccountNo];
        const columnsNames = columnsList.map(col => InvoicesAgeAnalysisColumns[col].charAt(0).toLowerCase() + InvoicesAgeAnalysisColumns[col].slice(1));
        const cols = columns.filter(col => columnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => <Link to={linkUrl.replace("%accountNo%", row.accountNo)} className="font-color-1 font-weight-bold">{row.accountNo}</Link>

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }
}

export default new InvoicesAgeAnalysisListTableConfigurator();
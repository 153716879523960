import * as React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { IConversationsOrderByType } from '../enums/IConversationsOrderByType';
import { IOrderByType } from '../../../core/Enums/IOrderByType';
import { IConversationsListHeaderProps } from '../types/IConversationsListHeaderProps';

interface IConversationsListState{
  searchValue: string;
}
class ConversationsListHeader extends React.Component<IConversationsListHeaderProps, IConversationsListState> {
  
  public render = () => {
    return (
      <div className="m-conversations__list-header">
        <div className="m-conversations__sort m-conversations__sort--type-1 container-fluid">
          <div className="row">
            <div className="col-md-2">
              <p onClick={() => this.props.changeSortType(IConversationsOrderByType.DueDate)}>{TranslationService.translateModule('MessagesTableDueDate', ModuleNamesList.Messages)}
                {this.getOrderIcon(IConversationsOrderByType.DueDate)}
              </p>
            </div>
            <div className="col-9 col-md-10">
              <div className="row">
                <div className="col-md-6">
                  <p onClick={() => this.props.changeSortType(IConversationsOrderByType.Description)}>{TranslationService.translateModule('MessagesTableDescription', ModuleNamesList.Messages)}
                    {this.getOrderIcon(IConversationsOrderByType.Description)}
                  </p>
                </div>
                <div className="col-md-3">
                  <p onClick={() => this.props.changeSortType(IConversationsOrderByType.CaseNo)}>{TranslationService.translateModule('MessagesTableCaseReference', ModuleNamesList.Messages)}
                    {this.getOrderIcon(IConversationsOrderByType.CaseNo)}
                  </p>
                </div>
                <div className="col-md-3">
                  <p onClick={() => this.props.changeSortType(IConversationsOrderByType.Category)}>{TranslationService.translateModule('MessagesTableCategory', ModuleNamesList.Messages)}
                    {this.getOrderIcon(IConversationsOrderByType.Category)}
                  </p>  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="m-conversations__sort m-conversations__sort--type-2">
          <div className={"m-conversations__sort-btn" + (this.props.orderedBy === IConversationsOrderByType.DueDate ? " active" : "")} onClick={() => this.props.changeSortType(IConversationsOrderByType.DueDate)}>
            {TranslationService.translateModule('MessagesTableDueDate', ModuleNamesList.Messages)}
            {this.getOrderIcon(IConversationsOrderByType.DueDate)}
          </div>
          <div className={"m-conversations__sort-btn" + (this.props.orderedBy === IConversationsOrderByType.Description ? " active" : "")} onClick={() => this.props.changeSortType(IConversationsOrderByType.Description)}>
            {TranslationService.translateModule('MessagesTableDescription', ModuleNamesList.Messages)}
            {this.getOrderIcon(IConversationsOrderByType.Description)}    
          </div>
          <div className={"m-conversations__sort-btn" + (this.props.orderedBy === IConversationsOrderByType.CaseNo ? " active" : "")} onClick={() => this.props.changeSortType(IConversationsOrderByType.CaseNo)}>
            {TranslationService.translateModule('MessagesTableCaseReference', ModuleNamesList.Messages)}
            {this.getOrderIcon(IConversationsOrderByType.CaseNo)}    
          </div>
          <div className={"m-conversations__sort-btn" + (this.props.orderedBy === IConversationsOrderByType.Category ? " active" : "")} onClick={() => this.props.changeSortType(IConversationsOrderByType.Category)}>
            {TranslationService.translateModule('MessagesTableCategory', ModuleNamesList.Messages)}
            {this.getOrderIcon(IConversationsOrderByType.Category)}    
          </div>
        </div>
      </div>
    );
  }
  
  private getOrderIcon = (orderBy: IConversationsOrderByType) => {
      if (this.props.orderedBy !== orderBy) {
          return;
      }
      return <i className={"fas" + ( this.props.orderDirection === IOrderByType.Descendent ? " fa-sort-down" : " fa-sort-up")}/>;
  }
}

export default ConversationsListHeader;
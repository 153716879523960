import React from 'react';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { EventsList } from '../../core/lists/EventsList';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IReportElement } from './types/IReportElement';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import { Status } from '../../core/api/Enums/Status';
import InfoMessageService from '../../core/services/InfoMessageService';
import Modal from '../../core/components/Modal/Modal';
import ModalConfirmation from '../../core/components/Modal/components/ModalConfirmation';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import SchedluesTableConfigurator from './helpers/SchedluesTableConfigurator';
import ScheduleReportsApiClient from './ScheduleReportsApiClient';
import TranslationService from '../../core/services/TranslationService';
import Loader from '../../core/components/Loading/Loader';

export interface IManageReportsModuleState {
    isDataLoading: boolean,
    isOpenModal: boolean,
    idScheduleModal: number,
    reportsTable: IComponentTable<IReportElement>
}

class ScheduleReports extends React.Component<IModuleProps, IManageReportsModuleState> {
    public state = {
        isDataLoading: true,
        isOpenModal: false,
        idScheduleModal: 0,
        reportsTable: {...emptyComponentTable}
    }
    
    private moduleEvents: IModuleEventSubscription[];
    
    public componentDidMount() {
        this.registerModuleEvents();
        this.fetchData();
    }

    public componentWillUnmount() {
        ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
    }

    public render() {
        return (
            <>
                <article className="l-module">
                    <section className="l-module__section l-module__section--head">
                        <h1>
                            <i className="fas fa-chart-bar mr-2" />
                            {TranslationService.translateModule('Reports', ModuleNamesList.OnlineReports)}:
                            <strong className="l-module__title-highlighted">
                                {TranslationService.translateModule('Schedules', ModuleNamesList.OnlineReports)}
                            </strong>
                        </h1>
                    </section>

                    <section className="l-module__section mt-3">
                        {this.state.isDataLoading && <Loader opacity={0.5} />}

                        <BootstrapTableContainer
                            isDataLoading={this.state.isDataLoading}
                            remote={false}
                            wrapperClasses='bt'
                            classes='bt__table bt-table'
                            keyField='id'
                            data={this.state.reportsTable.values}
                            columns={SchedluesTableConfigurator.getTableColumns(
                                this.state.reportsTable,
                                this.openRemoveScheduleModal
                            )} 
                        /> 
                    </section>
                </article>

                <Modal 
                    isOpen={this.state.isOpenModal}
                    modalData={{
                        headerText: TranslationService.translateModule('ModalHeaderTextRemove', ModuleNamesList.ScheduleReports),
                        content: <ModalConfirmation 
                            text={TranslationService.translateModule('ModalBodyText', ModuleNamesList.ScheduleReports)}
                            btnSubmitLabel={TranslationService.translateModule('ModalSubmitBtnLabel', ModuleNamesList.ScheduleReports)}
                            btnCloseLabel={TranslationService.translateModule('ModalCloseBtnLabel', ModuleNamesList.ScheduleReports)}
                            onSubmit={this.onSubmitModal} 
                            onClose={this.onCloseModal}
                        />
                    }} 
                    onClose={this.onCloseModal}
                />
            </>
        )
    }

    onSubmitModal = () => {
        this.removeSchedule();
        this.toggleRemoveScheduleModal();
    }

    onCloseModal = () => {
        this.toggleRemoveScheduleModal();
    }

    openRemoveScheduleModal = (id: number) => {
        this.setState({ idScheduleModal: id })
        this.toggleRemoveScheduleModal();
    }

    toggleRemoveScheduleModal = () => {
        this.setState((prevState) => ({
            isOpenModal: !prevState.isOpenModal
        }));
    }

    private removeSchedule = async (): Promise<any> => {
        const idSchedule = this.state.idScheduleModal;
        const {status, messages} = await ScheduleReportsApiClient.removeScheduleReport(idSchedule);

        if (status === Status.Success) {
            let reportsTableValues = this.state.reportsTable.values;
            const index = reportsTableValues.findIndex((report: IReportElement) => report.id === idSchedule);

            InfoMessageService.success('ReportRemovedWithSuccess', ModuleNamesList.ScheduleReports);
            
            reportsTableValues.splice(index, 1);
            
            this.setState({
                reportsTable: {
                    ...this.state.reportsTable,
                    values: reportsTableValues
                }
            })
        } else {
            InfoMessageService.error(TranslationService.translateModule(messages.pop()!, ModuleNamesList.ScheduleReports));
        }
    }

    private fetchData = async () => {
        this.setState({
            isDataLoading: true
        });

        try {
            const reportsTable = await ScheduleReportsApiClient.getReports(this.props.module.id);
            
            this.setState({
                isDataLoading: true,
                reportsTable
            });
        } finally {
            this.setState({
                isDataLoading: false
            });
        }

    }

    private registerModuleEvents() {
        this.moduleEvents = [
            { name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.fetchData }
        ];
        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }
}

export default ScheduleReports;

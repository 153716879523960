import RestApiClient from '../../core/api/RestApiClient';
import { IReportElement } from './types/IReportElement';
import { IComponentTable } from '../../core/types/IComponentTable';

class GeneratedReportsApiClient {
    public async getReports(moduleId: number): Promise<IComponentTable<IReportElement>> {
        return await RestApiClient.get<IComponentTable<IReportElement>>(`modules/GeneratedReportsModule/getReports/${moduleId}`);
    }

    public async downloadReport(reportId: string, creditorNumber: string): Promise<any> {
        return await RestApiClient.downloadFile(`modules/GeneratedReportsModule/downloadReport/${reportId}/${creditorNumber}`);
    }
}

export default new GeneratedReportsApiClient();

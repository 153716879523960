import * as React from "react";
import TranslationService from "../../../core/services/TranslationService";
import { ModuleNamesList } from "../../../core/lists/ModuleNamesList";
import { IComponentTable } from "../../../core/types/IComponentTable";
import { IUserDetails } from "../types/IUserDetails";
import { BootstrapTableContainer } from "../../../core/components/BootstrapTable/BootstrapTableContainer";
import UsersTableConfigurator from "../helpers/UsersTableConfigurator";
import { IDBConnectionDetails } from "../types/IDBConnectionDetails";
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from "../../../core/components/Button/Button";
import SimpleCheckbox from "../../../core/components/Forms/SimpleCheckbox";
import { FormGroup, FORM_GROUP_VARIANTS } from "../../../core/components/Forms/FormGroup";
import SimpleInput from "../../../core/components/Forms/SimpleInput";
// @ts-ignore
import paginationFactory from "react-bootstrap-table2-paginator";

interface IProps {
  connectionDetails: IDBConnectionDetails,
  usersTable: IComponentTable<IUserDetails>,
  moduleInstanceId: number,
  migrationResult: MigrationResultType,
  onMigrate: (selectedUsers: any) => void
}

interface IState {
  filterValue: string,
  showAllUsers: boolean,
  selectedUsers: IUserDetails[],
  migrationTableData: any[],
  isDataLoading: boolean,
  hasMigrationInProgress: boolean,
  page: number,
  sizePerPage: number
}

type MigrationResultType = {
  endWithSuccess: number,
  endWithError: number,
  errors: {}
}

export default class MigrateUsersList extends React.Component<IProps, IState> {
  public state: IState = {
    filterValue: "",
    showAllUsers: false,
    selectedUsers: [],
    migrationTableData: [],
    isDataLoading: false,
    hasMigrationInProgress: false,
    page: 1,
    sizePerPage: 10
  };

  componentDidMount() {
    this.showUsersBasedOnFilters();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.migrationResult !== this.props.migrationResult) {
      const migrationTableData = this.state.selectedUsers.map((value) => {
        if (this.props.migrationResult.errors[value.userUId]) {
          value.error = this.props.migrationResult.errors[value.userUId].messages[0];
        } else {
          value.isMigrated = true;
        }

        return { ...value, selected: false };
      });

      this.setState({
        hasMigrationInProgress: false,
        migrationTableData
      });
    }
  };

  public render() {
    return (
      <>


        {!this.props.migrationResult &&
        <>
          <div className="c-form row mb-4">
            <div className="col-3">
              <FormGroup
                variant={FORM_GROUP_VARIANTS.INLINE}
                name="filterValue"
                label={TranslationService.translate("Search")}
                render={(name) => (
                  <SimpleInput
                    id="filterValue"
                    name={name}
                    value={this.state.filterValue}
                    onChange={(value) => this.setState({ filterValue: value }, this.showUsersBasedOnFilters)}
                  />
                )}
              />
            </div>

            <div className="col-auto ml-auto">
              <Button
                className="float-right ml-3"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTON_SIZE.SM}
                label={TranslationService.translateModule("SelectAll", ModuleNamesList.DnnMigration)}
                onClick={this.toggleAll}
              />

              <SimpleCheckbox
                displayInline={true}
                value={this.state.showAllUsers ? ["showAllUsers"] : []}
                options={{ showAllUsers: TranslationService.translateModule("ShowAllUsersCheckbox", ModuleNamesList.DnnMigration) }}
                onChange={this.updateShowAllUsersCheckbox}
              />
            </div>
          </div>
        </>
        }

        <div className="row">
          <div className="col-12">
            <BootstrapTableContainer
              remote={false}
              wrapperClasses="bt"
              classes="bt__table bt-table bt--small bt-table--stripped bt--selectable"
              selectRow={{
                hideSelectColumn: true,
                mode: "checkbox",
                classes: "bt bt-table bt-table__row bt-table__row--selected",
                clickToSelect: true,
                onSelect: this.onCreditorSelect,
                selected: this.state.selectedUsers.map((item) => item.userUId)
              }}
              keyField="userUId"
              data={this.state.migrationTableData}
              columns={UsersTableConfigurator.getTableColumns(this.props.usersTable)}
              paginationProps={
                paginationFactory({
                  page: this.state.page,
                  sizePerPage: this.state.sizePerPage,
                  totalSize: this.state.migrationTableData.length
                })
              }
            />
          </div>
          <div className="col-12">
            <Button
              className="float-right"
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTON_SIZE.MD}
              label={`${TranslationService.translateModule("MigrateUsers", ModuleNamesList.DnnMigration)} (${this.state.selectedUsers.length})`}
              disabled={this.state.selectedUsers.length === 0 || this.state.hasMigrationInProgress}
              showLoader={this.state.hasMigrationInProgress}
              onClick={this.migrateSelectedUsers}
            />
          </div>
        </div>
      </>
    );
  };

  showAllUsersList = () => {
    this.showUsersBasedOnFilters();
  };

  updateShowAllUsersCheckbox = () => {
    this.setState({ showAllUsers: !this.state.showAllUsers }, this.showUsersBasedOnFilters);
  };

  showUsersBasedOnFilters = () => {
    let users = this.props.usersTable.values;

    if (!this.state.showAllUsers) {
      users = users.filter(x => !x.isMigrated);
    }

    if (this.state.filterValue !== "") {
      users = users.filter(x => x.userName.includes(this.state.filterValue) || x.email.includes(this.state.filterValue));
    }

    this.setState({
      page: 1,
      migrationTableData: users || []
    });
  };

  private onCreditorSelect = async (element: IUserDetails) => {
    if (!element.isMigrated) {
      let selectedUsers = this.state.selectedUsers;
      if (selectedUsers.includes(element)) {
        selectedUsers.splice(selectedUsers.indexOf(element), 1);
      } else {
        selectedUsers.push(element);
      }

      this.setState({
        selectedUsers
      });
    }
  };

  private toggleAll = () => {
    const allUIds = this.state.migrationTableData.filter(item => !item.isMigrated).map(item => item.userUId);
    if (this.state.selectedUsers.length === allUIds.length) {
      this.setState({ selectedUsers: [] });
    } else {
      this.setState({ selectedUsers: this.state.migrationTableData.filter(item => !item.isMigrated) });
    }
  };

  private migrateSelectedUsers = async () => {
    if (this.state.selectedUsers.length > 0) {
      const selectedUsers = this.state.selectedUsers.map(item => item.userUId);

      this.props.onMigrate(selectedUsers);

      this.setState({ isDataLoading: true }, async () => {
        this.setState({
          selectedUsers: [],
          hasMigrationInProgress: true,
          filterValue: ""
        });
      });
    }
  };
}
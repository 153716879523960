import React from 'react';

export default (props: { label: string, values: string[], uniqueKey: string, boldColumns?: number[] }) => (
  <tr key={props.uniqueKey}>
    <th>{props.label}</th>
    {props.values.map((v: string, index: number) => 
      <td
        key={`cell_key_${index}`}
        className={`c-table__cell c-table__cell--text-right c-table__cell--padded-left ${(props.boldColumns && props.boldColumns.includes(index))?'c-table__cell--text-bold':''}`}>
        {v}
      </td>
    )}
  </tr>
)
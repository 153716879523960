import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LocalBootstrapTable } from '../../core/components/BootstrapTable/RemoteBootstrapTable';
import Loader from '../../core/components/Loading/Loader';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import RouteParamsService from '../../core/services/RouteParamsService';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import InvoiceDetailsNotesTableConfigurator from './helpers/InvoiceDetailsNotesTableConfigurator';
import InvoiceDetailsNotesService from './services/InvoiceDetailsNotesService';
import { InvoiceDetailsNoteItem } from './types/InvoiceDetailsNoteItem';

export const InvoiceDetailsNotes = (props: IExtendedModuleProps) => {
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState<IComponentTable<InvoiceDetailsNoteItem>>({ columns: [], values: [] });
    const [hasAccessToModule, setHasAccessToModule] = useState(true);

    const fetchInvoiceDetailsNotes = async () => {
        setIsLoading(true);
        try {
            const result = await InvoiceDetailsNotesService.getInvoiceDetailsNotes({
                moduleInstanceId: props.module.id,
                accountNo: props.accountNo,
                creditorNo: props.creditorNo,
                invoiceNo: props.invoiceNo
            })
            
            setDataTable(result);
            setIsInitialDataLoaded(true);
            setHasAccessToModule(true);
        } catch {
            setHasAccessToModule(false);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await fetchInvoiceDetailsNotes()
        };

        load();
        // eslint-disable-next-line
    }, [props.routeParameters]);

    if (!hasAccessToModule) return <NoPermissionsComponent />

    return (<div>
        <Loader opacity={1} isLoaded={!isLoading} />
        {isInitialDataLoaded && dataTable.columns.length > 0 && <LocalBootstrapTable
            keyField="RegDate"
            wrapperClasses="bt"
            classes="bt__table bt-table"
            data={dataTable.values}
            columns={InvoiceDetailsNotesTableConfigurator.getColumns(dataTable)} />}
    </div>)
};

const mapRouteParamsToProps = () => {
    const routeParams = RouteParamsService.getQueryParameters();
    return {
        accountNo: routeParams.accountNo as string,
        creditorNo: routeParams.creditorNo as string,
        invoiceNo: routeParams.invoiceNo as string
    }
}

export default connect(mapRouteParamsToProps)(InvoiceDetailsNotes);
import * as React from 'react';
import * as Reactstrap from 'reactstrap';
import RolesManagementApiClient from '../RolesManagementApiClient';
import Loader from '../../../core/components/Loading/Loader';
import { IRoleDetails, emptyRole } from '../types/IRoleDetails';
import ListInputs from '../../../core/components/Forms/ListInputs';
import { IPermissionDetails } from '../types/IPermissionDetails';
import { IListInputElement } from '../../../core/components/Forms/types/IListInputElement';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import FormGroupField from '../../../core/components/Forms/FormGroupField';
import FormGroupSelect from '../../../core/components/Forms/FormGroupSelect';
import { IDictionary } from '../../../core/types/IDictionary';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';

interface IState {
    availablePermissions: IPermissionDetails[];
    selectedPermissionsIds: string[];
    role: IRoleDetails;
    isDataLoading: boolean;
    authorizationTypes: IDictionary<string>;
}

interface IProps {
    roleUId: string;
    isVisible: boolean;
    isLoading: boolean;
    toogleVisibility(): void;
    closeModal(): void;
    updateRole(selectedRolesIds: string[], value: IRoleDetails): void;
}

class ManageRole extends React.Component<IProps, IState> {
    public state: IState = {
        availablePermissions: [],
        selectedPermissionsIds: [],
        isDataLoading: true,
        role: { ...emptyRole },
        authorizationTypes: {}
    }

    private validationMessages = {
        required: TranslationService.translate("ValidationMessageRequiredField"),
        uppercase: TranslationService.translate("ValidationMessageUppercaseField"),
        hasNoWhiteSpaces: TranslationService.translate("ValidationMessageNasNoWhitespacesField")
    }

    private validationSchema = yup.object().shape({
        key: yup.string().strict(false).uppercase(this.validationMessages.uppercase).test(
            'hasNoWhiteSpaces',
            this.validationMessages.hasNoWhiteSpaces,
            value => !/\s/.test(value),
          ).required(this.validationMessages.required),
        displayName: yup.string().required(this.validationMessages.required),
        authorizationTypeCode: yup.string().required(this.validationMessages.required),
    });

    public componentDidMount = async () => {
        await this.fetchData();
    }

    public componentDidUpdate = async (prevProps: IProps) => {
        if(prevProps.roleUId !== this.props.roleUId)
        {
            await this.fetchData();
        }
    }

    public render() {
        return (
            <Reactstrap.Modal isOpen={this.props.isVisible} className="flex-modal modal-module modal-lg" toggle={this.props.toogleVisibility}>
                <Reactstrap.ModalHeader className="modal-header">
                   {`${TranslationService.translateModule('ManageRoleModalHeader', ModuleNamesList.RolesManagement)} ${this.state.role.displayName}`}
                </Reactstrap.ModalHeader> 
                <Reactstrap.ModalBody>
                    <Loader isLoaded={!this.state.isDataLoading && !this.props.isLoading} opacity={0.6} />
                    <Formik
                        initialValues={{ ...this.state.role }}
                        onSubmit={(value: IRoleDetails, { setSubmitting, setErrors, resetForm }) => {
                            value.authorizationTypeName = this.state.authorizationTypes[value.authorizationTypeCode];
                            this.props.updateRole(this.state.selectedPermissionsIds, value);
                        }}
                        validationSchema={this.validationSchema}
                        enableReinitialize={true}
                        >
                        {({ values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur
                        }) => {
                            return (
                                <Form>
                                    <h6 className="mb-3">{TranslationService.translateModule('RoleDetailsHeader', ModuleNamesList.RolesManagement)}</h6>
                                    <FormGroupField 
                                        errors={errors} 
                                        fieldName="key" 
                                        handleChange={handleChange} 
                                        label={TranslationService.translateModule('ManageRoleFormRoleKey', ModuleNamesList.RolesManagement)} 
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        labelClass="col-sm-4"
                                        inputContainerClass="col-12 col-sm-8"
                                        value={values.key} />
                                    <FormGroupField 
                                        errors={errors} 
                                        fieldName="displayName" 
                                        handleChange={handleChange} 
                                        label={TranslationService.translateModule('ManageRoleFormRoleDisplayName', ModuleNamesList.RolesManagement)} 
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        labelClass="col-sm-4"
                                        inputContainerClass="col-12 col-sm-8"
                                        value={values.displayName} />
                                    <FormGroupSelect 
                                        label={TranslationService.translateModule('ManageRoleFormAuthorizationType', ModuleNamesList.RolesManagement)}
                                        labelClass="col-sm-4"
                                        inputContainerClass="col-12 col-sm-8"
                                        fieldName="authorizationTypeCode" 
                                        options={this.availableAuthorizationTypes()}
                                        value={values.authorizationTypeCode}
                                        onChange={(selectedValue: ISelectedValue) => { handleChange( { target: { value: selectedValue.key, name:"authorizationTypeCode" } }); } } />
                                    <h6 className="mb-3">{TranslationService.translateModule('AssignPermissionsHeader', ModuleNamesList.RolesManagement)}</h6>
                                    <ListInputs 
                                        listInputs={this.state.availablePermissions.map(item => ({key: item.id.toString(), value: item.displayName}))} 
                                        selectedInputs={this.state.selectedPermissionsIds}
                                        markWithActiveClass={true}
                                        onSelect={this.toggleRolePermission}
                                        col={3} />
                                    <div className='row'>
                                        <div className='col-12 mt-3'>
                                            <button className="btn btn-sm btn-color-2 text-transform-none float-right" type='submit'>
                                                {TranslationService.translate('Save')}
                                            </button>
                                            <button className="btn btn-sm btn-color-3 text-transform-none float-right mr-1" onClick={this.props.closeModal}>
                                                {TranslationService.translate('Cancel')}
                                            </button>
                                        </div>    
                                    </div>
                                </Form>)
                            }
                        }
                    </Formik>
                </Reactstrap.ModalBody>
            </Reactstrap.Modal>
        )
    }

    private fetchData = async () => {
        this.setState({ isDataLoading: true })
        const settings = await RolesManagementApiClient.getManageRoleRequest(this.props.roleUId);
        this.setState({
            availablePermissions: settings.permissions,
            selectedPermissionsIds: settings.role.permissions.map(item => item.id.toString()),
            role: settings.role,
            isDataLoading: false,
            authorizationTypes: settings.authorizationTypes
        })
    }

    private toggleRolePermission = (value: IListInputElement) => {
        const rolePermissions = this.state.selectedPermissionsIds;
        const index = rolePermissions.findIndex(item => value.key === item);
        if (index === -1) {
            rolePermissions.push(value.key)
        } else {
            rolePermissions.splice(index, 1);
        }
        this.setState({ selectedPermissionsIds: [...rolePermissions] });
    }

    private availableAuthorizationTypes = (): IDictionary<string> => {
        return this.state.authorizationTypes;
    }
}

export default ManageRole;
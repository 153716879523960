import RestApiClient from '../../../core/api/RestApiClient';
import { IActionStatus } from '../../../core/api/types/IActionStatus';
import { IPostponeInvoiceRequest } from '../types/IPostponeInvoiceRequest';

class PostponeInvoiceApi {
    public async postponeInvoice(request: IPostponeInvoiceRequest): Promise<IActionStatus> {
        return await RestApiClient.post('modules/PostponeInvoiceModule', request);
    }
}

export default new PostponeInvoiceApi();
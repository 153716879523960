import RestApiClient from '../../core/api/RestApiClient';
import { LeftMenuModel } from '../../core/types/LeftMenuModel';
import { IProfile } from './types/IProfile';
import { IActionStatus } from '../../core/api/types/IActionStatus';

class ManageLeftMenuApiClient {
    public async getMenu(profileId: string) : Promise<LeftMenuModel> {
        return await RestApiClient.get<LeftMenuModel>(`modules/ManageLeftMenuModule/${profileId}`);
    }
    public async getProfiles() : Promise<IProfile[]> {
        return await RestApiClient.get<IProfile[]>(`modules/ManageLeftMenuModule/profiles`);
    }
    public async saveMenu(model: LeftMenuModel) : Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>('modules/ManageLeftMenuModule/saveMenu', model);
    }
}

export default new ManageLeftMenuApiClient();
import React, { useState, useRef, useEffect } from 'react';
import styles from './ConversationMsgs.module.scss';
import classNames from 'classnames';
import { MessageType } from '../../types/ConversationType';
import DateTimeParser from '../../../../core/helpers/DateTimeParser';
import { INewMessage } from '../../types/INewMessage';
import TranslationService from '../../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';

type PropsType = {
    messages: MessageType[],
    onSubmit: (msgValue: INewMessage) => void
};

const ConversationMsgs = (props: PropsType) => {
    const messageBodyRef = useRef<HTMLUListElement>(null);

    const [msgValue, setMsgValue] = useState<string>('');

    const scrollToBottom = () => {
      if (messageBodyRef.current) {
        messageBodyRef.current.scrollTo(0, messageBodyRef.current.clientHeight);
      }
    }

    const handleSubmit = () => {
        props.onSubmit({
            attachedFiles: [],
            content: msgValue,
            sendCopyToEmail: false
        });
        setMsgValue('');
    };

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            props.onSubmit({
                attachedFiles: [],
                content: msgValue,
                sendCopyToEmail: false
            });
            setMsgValue('');
        }
    };

    useEffect(() => {
        if (props.messages.length) {
            scrollToBottom();
        }
    }, [props.messages]);

    return (
        <div className={styles.wrapper}>
            <ul ref={messageBodyRef} className={styles.list}>
                {props.messages.map((m, i) => (
                    <li
                        key={i.toString()}
                        className={classNames(
                            styles.listItem,
                            m.type === 1 ? styles.sender : styles.addressee
                        )}
                    >
                        <section className={styles.message}>
                            <h1 className={styles.messageAuthor}>{m.senderDetails}</h1>

                            <p className={styles.messageContent}>
                                {m.content}
                            </p>

                            <span className={styles.messageCreateDate}>
                                {DateTimeParser.toLocaleDateString(m.createDate)}
                            </span>
                        </section>
                    </li>
                ))}
            </ul>

            <div className={styles.textInputPanel}>
                <input
                    type="text"
                    value={msgValue}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setMsgValue(e.target.value)}
                    placeholder={TranslationService.translateModule(
                      'TypeMessageInput',
                      ModuleNamesList.HomeMessages
                    )}
                />

                <div className={styles.textInputNav}>
                    <div
                        onClick={handleSubmit}
                        className={classNames(styles.textInputNavGroup, styles.textInputNavGroupEnd)}
                    >
                        <i className="fas fa-paper-plane" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ConversationMsgs);

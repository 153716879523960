import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { INotesListElement } from '../types/INotesListElement';
import { NotesColumns } from '../types/NotesColumns';

class NotesListTableConfigurator {
    public getNotesListTableColumns = (invoicesListTable: IComponentTable<INotesListElement>) => {
        let columns: IComponentTableColumn[] = [];
        if (invoicesListTable != null) {
            columns = [...invoicesListTable.columns.map(c => ({ ...c, sort: true }))];
            this.configureDateColumns(columns);
            this.configureMinWidthColumns(columns);
            this.configureWideColumns(columns);

            return columns;
        }

        return columns;
    }

    private configureMinWidthColumns = (columns: IComponentTableColumn[]) => {
        const notesSolumns = [NotesColumns.CreatedBy];
        const columnNames = notesSolumns.map(col => NotesColumns[col].charAt(0).toLowerCase() + NotesColumns[col].slice(1));
        const cols = columns.filter(col => columnNames.indexOf(col.dataField) >= 0);

        for (const col of cols) {
            col.headerClasses = () => 'bt-table__header bt-table__header--fixed-width';
        }
    }

    private configureWideColumns = (columns: IComponentTableColumn[]) => {
        const notesSolumns = [NotesColumns.Text];
        const columnNames = notesSolumns.map(col => NotesColumns[col].charAt(0).toLowerCase() + NotesColumns[col].slice(1));
        const cols = columns.filter(col => columnNames.indexOf(col.dataField) >= 0);

        for (const col of cols) {
            col.headerClasses = () => 'bt-table__header bt-table__header--note-text-wide';
        }
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = [NotesColumns.Date];
        const dateColumnsNames = dateColumns.map(col => NotesColumns[col].charAt(0).toLowerCase() + NotesColumns[col].slice(1));
        const cols = columns.filter(col => dateColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return DateTimeParser.toLocaleDateString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }
}

export default new NotesListTableConfigurator();
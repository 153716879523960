import React from 'react';
import { IModuleSetting } from '../types/IModuleSetting';
import { IUpdateModuleSettingRequest } from '../types/IUpdateModuleSettingRequest';
import ComplexSettingsTable from './ComplexSettingsTable';

interface IProps {
    moduleSettings: IModuleSetting[],
    resetSettings: (instanceSettingId: number) => void,
    updateModuleSetting: (setting: IModuleSetting | IUpdateModuleSettingRequest, moduleSettingsId: number) => void
}

const ComplexSettingsTableWrapper = (props: IProps) => {
    const handleUpdateModuleSetting = (settings: IModuleSetting | IUpdateModuleSettingRequest, moduleSettingsId: number) => {
        props.updateModuleSetting(settings, moduleSettingsId);
    };

    return (
        <>
            {props.moduleSettings.map(complexModuleSetting => (
                <ComplexSettingsTable 
                    key={complexModuleSetting.name}
                    moduleSettings={complexModuleSetting} 
                    updateModuleSetting={handleUpdateModuleSetting}
                    resetSettings={props.resetSettings}
                />
            ))}
        </>
    )
}

export default ComplexSettingsTableWrapper;
import React, { ReactNode, useState } from 'react';
import styles from './Input.module.scss';

type RadioWrapperPropsType = {
    children: ReactNode
}

const RadioWrapper = (props: RadioWrapperPropsType) => {
    const [checkedValue, setCheckedValue] = useState<string>('');

    const handleRadioChange = (value: string) => {
        setCheckedValue(value);
    }

    return (
        <div className={styles.radioWrapper}>
            {React.Children.map(props.children, (child: any) => {
                return React.cloneElement(child, {
                    checkedValue,
                    onChange: (value: string) => {
                        child.props.onChange();
                        handleRadioChange(value);
                    }
                });
            })}
        </div>
    )
}

export default RadioWrapper;
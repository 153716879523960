import { IDictionary } from '../../../core/types/IDictionary';
import TilesApiClient from '../api/TilesApiClient';
import { ITilesTableModuleSettings } from '../types';
import { CancelToken } from 'axios';

class TilesService {
    private apiClient: TilesApiClient;

    constructor(cancelToken: CancelToken) {
        this.apiClient = new TilesApiClient(cancelToken);
    }

    public async getCasesCounters(): Promise<IDictionary<number>> {
        return await this.apiClient.getCasesCounters();
    }

    public async getModuleSettings(moduleInstanceId: number): Promise<ITilesTableModuleSettings> {
        return new Promise<ITilesTableModuleSettings>((resolve, reject) => {
            this.apiClient.getModuleSettings(moduleInstanceId)
                .then(data => resolve(data))
                .catch(err => reject())
        });
    }

}

export default TilesService;
import * as React from 'react';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IReportElement } from '../types/IReportElement';
import Dropdown from 'react-bootstrap/Dropdown';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { NavLink } from 'react-router-dom';

class SchedluesTableConfigurator {
    public getTableColumns = (
        reporstElements: IComponentTable<IReportElement>,
        openRemoveScheduleModal: (id: number) => void
    ) => {
        let columns: IComponentTableColumn[] = [];
        if (reporstElements != null) {
            columns = reporstElements.columns.map(c => ({ ...c, sort: true }));
            this.configureDateColumns(columns);
            this.configureActionsColumn(columns, openRemoveScheduleModal);

            return columns;
        }

        return columns;
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = ['lastRunDate', 'nextRunDate'];
        const cols = columns.filter(col => dateColumns.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            if(value !== null && value !== '')
            {
                return DateTimeParser.toLocaleDateTimeString(value);
            }
            return '';
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureActionsColumn = (
        columns: IComponentTableColumn[],
        openRemoveScheduleModal: (id: number) => void
    ) => {
        const formatterFunc = (value: any, row: IReportElement) => {
            return (
                <Dropdown className="button-table">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {TranslationService.translateModule('Actions', ModuleNamesList.ScheduleReports)}
                    </Dropdown.Toggle>
    
                    <Dropdown.Menu>                        
                        <NavLink className="dropdown-item" to={`/schedule-report?scheduleId=${row.id}`}>
                            {<i className="far fa-edit"/>} {TranslationService.translateModule('ActionsEdit', ModuleNamesList.ScheduleReports)}
                        </NavLink>               
                        <Dropdown.Item onClick={() => openRemoveScheduleModal(row.id)}>
                            <i className="fas fa-trash-alt"/> {TranslationService.translateModule('ActionsRemove', ModuleNamesList.ScheduleReports)}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
        columns.push({
            dataField: 'actions',
            formatter: formatterFunc,
            sort: false,
            headerClasses: (col: any, colIndex: number) => 'download-header',
            classes: (cell: any, row: any, rowIndex: number, colIndex: number) => 'c-table__cell c-table__cell--actions',
            style: (cell: any, row: any, rowIndex: number, colIndex: number) => undefined,
            headerStyle: (col: any, colIndex: number) => undefined,
            text: ''
        } as IComponentTableColumn);
    }
}

export default new SchedluesTableConfigurator();
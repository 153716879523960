import * as React from 'react';
import { ICaseListSummary } from '../types/ICaseListSummary';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import CurrencyParser from '../../../core/helpers/CurrencyParser';

interface IProps {
    summary: ICaseListSummary;
    currency: string;
}

class CaseListFooter extends React.Component<IProps> {

    public render() {
        return (
            <>
            {this.props.summary ? 
                <div className="case-list-table-footer">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3 mb-2 mt-bg-0">
                            <div className="case-summary-box green">
                                <div className="icon">
                                    <i className="far fa-file-alt"/>
                                </div>
                                <div className="box">
                                    <h3>{this.props.summary.count}</h3>
                                    <h4>{TranslationService.translateModule('FooterTotalCases', ModuleNamesList.CaseList)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-2 mt-bg-0">
                            <div className="case-summary-box">
                                <div className="icon">
                                    <i className="fas fa-coins"/>
                                </div>
                                <div className="box">
                                    <div className="case-sumary-amounts">
                                        <h3>{CurrencyParser.toLocaleString(this.props.summary.totalBalance)}</h3>
                                        <h5>{this.props.currency}</h5>
                                    </div>
                                    <h4>{TranslationService.translateModule('FooterTotalBalance', ModuleNamesList.CaseList)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-sm-0">
                            <div className="case-summary-box">
                                <div className="icon">
                                    <i className="fas fa-coins"/>
                                </div>
                                <div className="box">
                                    <div className="case-sumary-amounts">
                                        <h3>{CurrencyParser.toLocaleString(this.props.summary.paidBalance)}</h3>
                                        <h5>{this.props.currency}</h5>
                                    </div>
                                    <h4>{TranslationService.translateModule('FooterPaidBalance', ModuleNamesList.CaseList)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <div className="case-summary-box">
                                <div className="icon">
                                    <i className="fas fa-receipt"/>
                                </div>
                                <div className="box">
                                    <div className="case-sumary-amounts">
                                        <h3>{CurrencyParser.toLocaleString(this.props.summary.remainBalance)}</h3>
                                        <h5>{this.props.currency}</h5>
                                    </div>
                                    <h4>{TranslationService.translateModule('FooterRemainBalance', ModuleNamesList.CaseList)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : ''}
            </>
        );
    }
}

export default CaseListFooter;
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { InvitationCallback } from './InvitationCallback';
import { IStore } from './reducers/IStore';
import { UserManager } from 'oidc-client';
import AuthCallback from './AuthCallback';
import Login from './Login';
import OidcProvider from './OidcProvider';
import PageRenderer from './PageRenderer';
import SilentRenew from './SilentRenew';
import StyleWrapper from './core/components/StyleWrapper/StyleWrapperHOC';
import IEWarning from './core/components/IEWarning/IEWarning';
import CookieDisclaimer from './modules/CookieDisclaimer/CookieDisclaimer';
import moment from 'moment';

const App = (props: {userManager : UserManager, locale: string}) => {
  useEffect(() => {
    moment.locale(props.locale.split('-')[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <>
        <IEWarning />
        <CookieDisclaimer />
        <OidcProvider userManager={props.userManager}>
          <BrowserRouter>
            <Switch>
              <Route path="/login" exact={true} component={Login} />
              <Route path="/auth/callback" exact={true} component={AuthCallback} />
              <Route path="/silent-renew" exact={true} component={SilentRenew} />
              <Route path="/invitation" exact={true} component={InvitationCallback} />
              <Route path="/:page" exact={true} component={PageRenderer} />
              <Route path="/" exact={true} component={PageRenderer} />
              <Route render={() => <Redirect to='/error' />} />
            </Switch>
          </BrowserRouter>
        </OidcProvider>
      </>
    );
}

const styleConfig = [
  {
    searchMethod: 'element',
    instructions: {
      colors: 'body',
      fonts: 'body',
      favicon: 'head',
      'btn:primary': '.c-btn--primary',
      'btn:primary:hover': '.c-btn--primary:hover',
      'btn:secondary': '.c-btn--secondary',
      'btn:secondary:hover': '.c-btn--secondary:hover',
      'table:head': '.bt-table tr th',
      'table:body': '.bt-table tr td'
    }
  }
]

const mapStateToProps = (state: IStore) => {
  return {
      locale: state.currentCultureCode,
  }
}

export default connect(mapStateToProps)(StyleWrapper('general', styleConfig)(App));

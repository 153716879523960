import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { InvoiceDetailsActivitiesColumns } from './InvoiceDetailsActivitiesColumns';
import { IInvoiceDetailsActivityItem } from '../types/InvoiceDetailsActivityItem';

class InvoiceDetailsActivitiesTableConfigurator {
    public getColumns = (invoicesListTable: IComponentTable<IInvoiceDetailsActivityItem>) => {
        let columns: IComponentTableColumn[] = [];
        if (invoicesListTable != null) {
            columns = [...invoicesListTable.columns];
            this.configureCurrencyColumns(columns);
            this.configureDateColumns(columns);

            return columns;
        }
        return columns;
    }

    private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
        const currencyColumns = [InvoiceDetailsActivitiesColumns.Paid,InvoiceDetailsActivitiesColumns.Claim,InvoiceDetailsActivitiesColumns.Fee,InvoiceDetailsActivitiesColumns.Interest];
        const currencyColumnsNames = currencyColumns.map(col => InvoiceDetailsActivitiesColumns[col].charAt(0).toLowerCase() + InvoiceDetailsActivitiesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            if (value) {
                return CurrencyParser.toLocaleString(value);
            }

            return value;
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = [InvoiceDetailsActivitiesColumns.RegDate,InvoiceDetailsActivitiesColumns.ActionDate];
        const currencyColumnsNames = dateColumns.map(col => InvoiceDetailsActivitiesColumns[col].charAt(0).toLowerCase() + InvoiceDetailsActivitiesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return DateTimeParser.toLocaleDateString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }
}

export default new InvoiceDetailsActivitiesTableConfigurator();
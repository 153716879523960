import { NavBarFilterOption } from '../../../types/NavBarFilterOption';

export enum CaseListSelectFilter {
    CaseNumber = 0,
    InvoiceNumber = 1, 
    DebtorNumber = 2, 
    DebtorName = 3,
    UnitId = 4,
    NameId = 5,
    ExternalVoucherNo = 6,
    DebtorSsn = 7,
    CaseReference = 8,
    Ref2Number = 9
}

export const getCaseListSelectFilter = (navBarFilterOptionString: string) : CaseListSelectFilter => {
    const mapping = {
        [NavBarFilterOption.CaseNumber]: CaseListSelectFilter.CaseNumber,
        [NavBarFilterOption.InvoiceNumber]: CaseListSelectFilter.InvoiceNumber,
        [NavBarFilterOption.DebtorNumber]: CaseListSelectFilter.DebtorNumber,
        [NavBarFilterOption.DebtorName]: CaseListSelectFilter.DebtorName,
        [NavBarFilterOption.UnitId]: CaseListSelectFilter.UnitId,
        [NavBarFilterOption.NameId]: CaseListSelectFilter.NameId,
        [NavBarFilterOption.ExternalVoucherNo]: CaseListSelectFilter.ExternalVoucherNo,
        [NavBarFilterOption.DebtorSsn]: CaseListSelectFilter.DebtorSsn,
        [NavBarFilterOption.CaseReference]: CaseListSelectFilter.CaseReference,
        [NavBarFilterOption.Ref2Number]: CaseListSelectFilter.Ref2Number
    };

    return mapping[navBarFilterOptionString];
}
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { IConfirmationCodes } from '../types/IConfirmationCodes';
import { IConfirmationProps } from '../types/IConfirmationProps';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import React, { useState } from 'react';
import * as yup from 'yup';
import AlertMessages from '../../../core/components/AlertMessages/AlertMessages';
import CreditorRegistrationApiClient from '../CreditorRegistrationApiClient';
import Loader from '../../../core/components/Loading/Loader';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import TranslationService from '../../../core/services/TranslationService';
import { Status } from '../../../core/api/Enums/Status';
import useEffectAsync from "../../../core/hooks/useEffectAsync";
import useLocalStorageState from "../../../core/hooks/useLocalStorageState";

const Confirmation = (props: IConfirmationProps) => {
    const [isPendingRequest, setIsPendingRequest] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const [initialValues, handleUpdateForm] = useLocalStorageState({
        key: "CreditorForm",
        value: {
            confirmationCodeEmail: '',
            confirmationCodePhone: ''
        }
    });

    const validationMessages = {
        required: TranslationService.translate("ValidationMessageRequiredField"),
    }

    const validationSchema = yup.object().shape({
        confirmationCodePhone: props.configuration.confirmations.byPhone ? yup.string().required(validationMessages.required): yup.string(),
        confirmationCodeEmail: props.configuration.confirmations.byEmail ?yup.string().required(validationMessages.required): yup.string(),
    });

    const sendVeryficationCodes = async () => {
        setIsPendingRequest(true);

        await CreditorRegistrationApiClient.SendConfirmationCodes(props.moduleInstanceid);

        setIsPendingRequest(false);
    }

    const validateConfirmationCodes = async (codes: IConfirmationCodes) => {
        setIsPendingRequest(true);

        const validationResult = await CreditorRegistrationApiClient.ValidateConfirmationCodes(codes, props.moduleInstanceid);

        if(validationResult.status === Status.Error)
        {
            setIsPendingRequest(false);
            setErrorMessages(validationResult.messages);
        }
        else
        {
            props.goToNextStep();
        }
    }

    const handleOnValidateForm = (values: any) => {
        handleUpdateForm(values);
    };

    useEffectAsync(async () => {
        await sendVeryficationCodes();
    }, []);

    return (
      <div className="step-content">
          { isPendingRequest ? <Loader opacity={1}/> : '' }
          <header className="step-header">
              <h2 className="step-title">{TranslationService.translateModule('ConfirmationHeader', ModuleNamesList.CreditorRegistration)}</h2>
              <div dangerouslySetInnerHTML={{ __html: TranslationService.translateModule('ConfirmationDescription', ModuleNamesList.CreditorRegistration) }}/>
          </header>
          { errorMessages.length > 0 ?
            <AlertMessages messages={errorMessages} translate={true} moduleName={ModuleNamesList.CreditorRegistration} alertClass="alert-danger"/>
            : ""
          }
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                await validateConfirmationCodes(values);
            }}
            validationSchema={validationSchema}
            validate={handleOnValidateForm}
          >
              {({ values,
                    errors,
                    touched,
                    handleChange,
                    submitForm
                }) => {
                  return (
                    <Form>
                        { props.configuration.confirmations.byPhone ?
                          <div className="register-user-confirmation-phone-row">
                              <div className="form-group row">
                                  <label className="col-4 col-sm-3 col-md-2 col-form-label"><strong>{TranslationService.translateModule('ConfirmationPhone', ModuleNamesList.CreditorRegistration)}</strong></label>
                                  <div className="col-8 col-sm-9 col-md-10">
                                      <p className="form-control-plaintext">{props.configuration.userPhone}</p>
                                  </div>
                                  <label className="col-sm-6 col-form-label">
                                      {TranslationService.translateModule('ConfirmationPhoneConfirmText', ModuleNamesList.CreditorRegistration)}
                                  </label>
                                  <div className="col-sm-4 col-lg-3">
                                      <Field type="text" name="confirmationCodePhone" id="confirmationCodePhone" value={values.confirmationCodePhone}
                                             className={"form-control" + ((errors.confirmationCodePhone && touched.confirmationCodePhone) ? " error" : "")}
                                             onChange={handleChange} />
                                      <ErrorMessage name="confirmationCodePhone" component="div" className="error error-message" />
                                  </div>
                              </div>
                          </div>
                          : '' }
                        { props.configuration.confirmations.byPhone && props.configuration.confirmations.byEmail ? <hr/> : '' }
                        { props.configuration.confirmations.byEmail ?
                          <div className="register-user-confirmation-email-row">
                              <div className="form-group row">
                                  <label className="col-4 col-sm-3 col-md-2 col-form-label"><strong>{TranslationService.translateModule('ConfirmationEmail', ModuleNamesList.CreditorRegistration)}</strong></label>
                                  <div className="col-8 col-sm-9 col-md-10">
                                      <p className="form-control-plaintext">{props.configuration.userEmail}</p>
                                  </div>
                                  <label className="col-sm-6 col-form-label">
                                      {TranslationService.translateModule('ConfirmationEmailConfirmText', ModuleNamesList.CreditorRegistration)}
                                  </label>
                                  <div className="col-sm-4 col-lg-3">
                                      <Field type="text" name="confirmationCodeEmail" id="confirmationCodeEmail" value={values.confirmationCodeEmail}
                                             className={"form-control" + ((errors.confirmationCodeEmail && touched.confirmationCodeEmail) ? " error" : "")}
                                             onChange={handleChange} />
                                      <ErrorMessage name="confirmationCodeEmail" component="div" className="error error-message" />
                                  </div>
                              </div>
                          </div>
                          : '' }
                        <footer className="step-footer">
                            <span className="fas fa-info-circle info-icon"/>
                            {" " + TranslationService.translateModule('ConfirmationInformationText', ModuleNamesList.CreditorRegistration) + " "}
                            <button onClick={sendVeryficationCodes} type="button" className="btn btn-danger btn-sm">{TranslationService.translateModule('ConfirmationGenerateNewCodeButton', ModuleNamesList.CreditorRegistration)}</button>
                        </footer>
                        <hr/>
                        <StepListWizardButtons
                          leftButtonOnClick={props.backToPrevStep}
                          leftButtonClassName="float-left"
                          leftButtonText={<>
                              <i className="fas fa-angle-double-left back-button-arrow left"/>
                              {" " + TranslationService.translate('Back')}
                          </>}
                          rightButtonClassName="float-right"
                          rightButtonOnClick={()=>{}}
                          rightButtonText={<>
                              {TranslationService.translate('Next') + " "}
                              <i className="fas fa-angle-double-right next-button-arrow right"/>
                          </>}
                        />
                    </Form>)
              }
              }
          </Formik>
      </div>
    )
}

export default Confirmation;
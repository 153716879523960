import React from "react";
import { IStepListProps } from "./types/IStepListProps";
import "./StepListWizard.scss";
import classNames from 'classnames';
import SvgIcon from '../../core/components/SvgIcons/SvgIcon';
import { SvgIconsNames } from '../../core/components/SvgIcons/enums/SvgIconsNames';

const StepList = (props: IStepListProps) => {
  return (
    <div className={classNames(
      'c-step-list',
      props.center && 'c-step-list--center'
    )}>
      {Object.keys(props.stepList).map((step: any, index: number) => {
        return (
          <div
            key={index}
            className={classNames(
              'c-step-list__item',
              `c-step-list__item--${props.stepList[step].iconCss}`,
              props.currentStep > index && 'c-step-list__item--visited',
              props.currentStep === index && 'c-step-list__item--active',

            )}
          >
            {props.stepList[step].iconSvg
              ? <SvgIcon icon={props.stepList[step].iconSvg as SvgIconsNames} isActive={props.currentStep === index} visited={props.currentStep > index} />
              : <i className={`c-step-list__item-icon ${props.stepList[step].iconCss}`} />
            }
            {props.stepList[step].text}
          </div>
        )
      })}
    </div>
  );
}

export default StepList;
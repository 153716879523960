import React, { useEffect } from "react";
import { ModuleNamesList } from "../../../core/lists/ModuleNamesList";
import TranslationService from "../../../core/services/TranslationService";
import { Formik, Form } from "formik";
import FormGroupField from "../../../core/components/Forms/FormGroupField";
import * as yup from "yup";
import { IFinancialFormProps } from "../types/IFinancialFormProps";
import FormGroupRadioButton from "../../../core/components/Forms/FormGroupRadioButton";
import StepListWizardButtons from "../../../modules/StepListWizard/StepListWizardButtons";
import useLocalStorageState from "../../../core/hooks/useLocalStorageState";
import { IStore } from "../../../reducers/IStore";
import { connect } from 'react-redux';

const FinancialsForm = (props: IFinancialFormProps) => {
  const [initialValues, handleUpdateForm] = useLocalStorageState({
    key: "FinancialsForm",
    value: props.accountancyInformation
  });

  const validationMessages = {
    max: TranslationService.translate("ValidationMessageValueIsTooLong"),
    required: TranslationService.translate("ValidationMessageRequiredField"),
    numberFormat: TranslationService.translate("ValidationMessageIncorrectNumberFormat")
  };

  const validationSchema = yup.object().shape({
    accountNumber: yup.string()
      .matches(props.applicationSettings.IsCms === "true" ? /^[-, 0-9]*$/ : /^[0-9]*$/, validationMessages.numberFormat)
      .max(20, validationMessages.max)
      .required(validationMessages.required),
    vatLiable: yup.boolean().required(validationMessages.required)
  });

  const handleOnValidateForm = (values: any) => {
    handleUpdateForm(values);
  };

  useEffect(() => {
    if (sessionStorage.getItem('FinancialsFormFilled') === null) {
      handleUpdateForm(props.accountancyInformation, true);
    }
  }, [props.accountancyInformation]);

  return (
    <div className="step-content">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          props.onFinancialsDataFilled(values);
        }}
        validationSchema={validationSchema}
        validate={handleOnValidateForm}
        enableReinitialize={true}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            submitForm
          }) => {
          return (
            <Form>
              <header className="step-header">
                <h2
                  className="step-title">{TranslationService.translateModule("FinancialHeader", ModuleNamesList.CreditorRegistration)}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: TranslationService.translateModule("FinancialDescription", ModuleNamesList.CreditorRegistration) }} />
              </header>

              <div className="small-form small-form-60">
                <FormGroupField
                  errors={errors}
                  fieldName="accountNumber"
                  handleChange={handleChange}
                  label={TranslationService.translateModule("FinancialBankAccountNumber", ModuleNamesList.CreditorRegistration)}
                  touched={touched}
                  labelClass="col-sm-5"
                  inputContainerClass="col-sm-7"
                  handleBlur={handleBlur}
                  required={true}
                  value={values.accountNumber}
                />


                <FormGroupRadioButton
                  errors={errors}
                  fieldName="vatLiable"
                  handleChange={handleChange}
                  options={
                    [
                      { label: TranslationService.translate("Yes"), value: "true" },
                      { label: TranslationService.translate("No"), value: "false" }
                    ]
                  }
                  label={TranslationService.translateModule("FinancialVatLiable", ModuleNamesList.CreditorRegistration)}
                  touched={touched}
                  labelClass="col-sm-8"
                  handleBlur={handleBlur}
                  inputContainerClass="col-sm-4"
                  disabled={props.prefilledFields}
                  value={values.vatLiable}
                />
              </div>
              <hr />
              <StepListWizardButtons
                leftButtonOnClick={props.backToPrevStep}
                leftButtonClassName="float-left"
                leftButtonText={<>
                  <i className="fas fa-angle-double-left back-button-arrow left" />
                  {" " + TranslationService.translate("Back")}
                </>}
                rightButtonClassName="float-right"
                rightButtonText={<>
                  {TranslationService.translate("Next") + " "}
                  <i className="fas fa-angle-double-right next-button-arrow right" />
                </>}
                rightButtonOnClick={() => {
                  sessionStorage.setItem('FinancialsFormFilled', 'true');
                  }}
              />
            </Form>);
        }
        }
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  applicationSettings: state.applicationSettings
})

export default connect(mapStateToProps)(FinancialsForm);

import { ICreditorContactDetails } from './ICreditorContactDetails';
import { ICreditorGeneralInformation } from './ICreditorGeneralInformation';
import { ICreditorAddress } from './ICreditorAddress';
import { ICreditorAccountancyInformation } from './ICreditorAccountancyInformation';

export interface ICompanyForm {
    organizationName: string;
    organizationNumber: string;
    industryCode: string;
    contactDetails: ICreditorContactDetails;
    generalInformation: ICreditorGeneralInformation;
    address: ICreditorAddress;
    accountancyInformation: ICreditorAccountancyInformation;
    templateCode: string;
    feedbackUrl: string;
    refId: string;
}

export const emptyCompany: ICompanyForm = {
    organizationName: '',
    organizationNumber: '',
    industryCode: '',
    contactDetails: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
    },
    generalInformation: {
        website: '',
        email: '',
        phoneNumber: '',
        creditorNumber: 0
    },
    address:{
        street: '',
        address2: '',
        postalCode: '',
        city: '',
    },
    accountancyInformation:{
        accountNumber: '',
        vatLiable: false,
    },
    templateCode: '',
    feedbackUrl: '',
    refId: ''
};
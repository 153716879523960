import * as React from 'react';
import './ErrorSkin.scss';
import LogoBanner from '../../../modules/LogoBanner/LogoBanner';
import { ISkinProps } from '../../types/ISkinProps';
import {
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZE,
} from '../../components/Button/Button';
import TranslationService from '../../services/TranslationService';
import StyleWrapper from '../../../core/components/StyleWrapper/StyleWrapperHOC';

const ErrorSkin = (props: ISkinProps) => {
  const goToHomePage = () => {
    window.location.href = '/';
  };

  return (
    <div className="error-container">
      <LogoBanner />

      <div className="error-window">
        <p className="error-window-title">
          {TranslationService.translate('ErrorPageTitle')}
        </p>

        <p className="error-window-content">
          {TranslationService.translate('ErrorPageContent')}
        </p>

        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTON_SIZE.SM}
          type="button"
          label={TranslationService.translate('GoHome')}
          onClick={goToHomePage}
          icon={{ className: 'fas fa-home', position: 'left' }}
        />
      </div>
    </div>
  );
};

const styleConfig = [
  {
      searchMethod: 'element',
      instructions: {
        background: '.error-container',
        titleColor: '.error-window-title'
      }
  }
]

export default StyleWrapper('errorPage:general', styleConfig)(ErrorSkin);

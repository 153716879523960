import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import TranslationService from "./../../../core/services/TranslationService";
import OnlineReportsApiClient from "./../../OnlineReports/OnlineReportsApiClient";
import { ICreditor } from "../../../core/types/ICreditor";
import EParamsType from "./../enums/ParamsType";
import Date from "./../views/Date";
import DateRange from "./../views/DateRange";
import Input from "./../views/Input";
import Loader from "../../../core/components/Loading/Loader";
import IReportParameter from "../types/IReportParameter";
import { IOnlineReportsFolder } from "../types/IOnlineReportsFolder";
import { IOnlineReport } from "../types/IOnlineReport";
import { IReportParametersShortVM } from "../../ScheduleReport/types/IReportParametersShortVM";
import { IDictionary } from "../../../core/types/IDictionary";
import { ParameterNames } from "../types/ParameterNames";
import { IScheduleData } from "../types/IScheduleData";
import {
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZE
} from "../../../core/components/Button/Button";
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { CreditorNumberType } from '../../../core/components/Header/components/CreditorSwitcher/types/CreditorNumberType';
import CreditorAccessValidator from '../../../core/services/CreditorAccessValidator';
import { PermissionNames } from '../../../core/helpers/PermissionNames';

interface IState {
  isDataLoading: boolean;
  parameters: IReportParameter[];
  formData: {
    folder?: IOnlineReportsFolder;
    report?: IOnlineReport;
    reportParameters?: IDictionary<string>;
  };
  outputData: IReportParametersShortVM[];
  creditors: any[];
  dateFrom: string;
  dateRange: string[];
}

interface IProps {
  data: IScheduleData;
  creditorType: number,
  visitedSteps?: number[];
  moduleId: number;
  creditors: ICreditor[];
  displaySubmit?: boolean;
  isEditMode?: boolean;
  formData: {
    folder?: IOnlineReportsFolder;
    report?: IOnlineReport;
  };
  updateData: (data: {
    reportParameters: IReportParametersShortVM[];
    parameters: IReportParameter[];
  }) => void;
  goToNextStep?: (stepData: {}, lastStep: boolean) => void;
  goToPreviousStep?: () => void;
}

class ReportParameters extends React.PureComponent<IProps, IState> {
  state: IState = {
    isDataLoading: true,
    outputData: [],
    parameters: [],
    formData: {
      ...this.props.formData,
      reportParameters: {}
    },
    creditors: [],
    dateFrom: "",
    dateRange: []
  };

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={this.state.formData.reportParameters}
        onSubmit={() => {
          this.submitFormHandler();
        }}
      >
        {({ errors, touched, values, handleChange, setTouched }) => {
          return (
            <Form>
              <div className="c-creator__body">
                {this.state.isDataLoading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      {this.state.parameters.map((control: IReportParameter, index: number) => (
                        <ViewParamsGenerator
                          key={index}
                          formData={this.state.formData.reportParameters}
                          value={values}
                          touched={touched}
                          controlParams={control}
                          handleChange={(data: IReportParametersShortVM) => {
                            this.onChangeHandler(data, handleChange)
                          }}
                          context={this}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`c-creator__footer c-creator__footer--btn-action ${
                  this.props.isEditMode ? "c-creator__footer--right" : ""
                }`}
              >
                {!this.props.isEditMode && (
                  <Button
                    type="button"
                    id="reportBackBtn"
                    label={TranslationService.translate("Back")}
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTON_SIZE.MD}
                    onClick={this.props.goToPreviousStep}
                  />
                )}

                {this.props.displaySubmit ? (
                  <>
                    <Button
                      id="viewReportBtn"
                      label={TranslationService.translateModule('ViewReport', ModuleNamesList.OnlineReports)}
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTON_SIZE.MD}
                    />
                  </>
                ) : (
                  this.props.goToNextStep && (
                    <Button
                      id="reportNextBtn"
                      label={TranslationService.translate("Next")}
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTON_SIZE.MD}
                    />
                  )
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  submitFormHandler = () => {
    const isLastStep = this.props.displaySubmit || false;

    const outputData = this.state.outputData;
    const complexFormParams = this.state.parameters.filter(
      p => p.type === "_DateRange_" || p.type === "_DateFrom_"
    );

    complexFormParams.forEach(p => {
      const isComplex = this.state.outputData.filter(
        data => data.type === "Complex" && data.name === `${p.name}${p.type}`
      );
      if (isComplex.length === 0) {
        outputData.push({
          name: `${p.name}${p.type}`,
          type: "Complex",
          value: null
        });
      }
    });

    if (this.props.goToNextStep) {
      this.props.updateData({
        reportParameters: outputData,
        parameters: this.state.parameters
      });

      this.props.goToNextStep({}, isLastStep);
    }
  };

  onChangeHandler = (
    data: IReportParametersShortVM,
    handleChangeFormik: (data: React.ChangeEvent<any>) => void
  ) => {
    const { name, value } = data;
    let { type } = data;

    if (type === "_DateFrom_" || type === "_DateRange_") {
      this.fetchDate(value as string, type);

      type = "Complex";
    } else {
      const handleChangeEvent = {
        target: {
          id: name,
          name: name,
          value: value
        }
      } as React.ChangeEvent<any>;

      handleChangeFormik(handleChangeEvent);
    }

    this.setState((prevState: IState) => {
      const outputData = prevState.outputData.filter(data => {
        return data.name !== name || data.type !== type;
      });

      outputData.push({ name, type, value });

      return { outputData };
    });
  };

  fetchDate(value: string, type: string) {
    if (type === "_DateFrom_") {
      OnlineReportsApiClient.getParameterDateValue(
        this.props.moduleId,
        value
      ).then((data: string) => {
        this.setState({
          dateFrom: data
        });
      });
    } else if (type === "_DateRange_") {
      OnlineReportsApiClient.getParameterDateRange(
        this.props.moduleId,
        value
      ).then((data: IDictionary<string>) => {
        const dateRangeArr: string[] = [];

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            dateRangeArr.push(data[key]);
          }
        }

        this.setState({
          dateRange: dateRangeArr
        });
      });
    }
  }

  fetchData() {
    return OnlineReportsApiClient.getReportParameters(
      this.props.moduleId,
      this.props.formData.report ? this.props.formData.report.id : ""
    )
      .then(this.prepareData)
      .catch(this.fetchFailed);
  }

  prepareOutpuData = (parameters: IReportParameter[]) => {
    const complexItems = [];
    const items = [];

    for (let i = 0; i < parameters.length; i++) {
      const p = parameters[i];

      if (p.allowBlank) {
        if (p.type === "_DateRange_" || p.type === "_DateFrom_") {

          complexItems.push({
            name: `${p.name}${p.type}`,
            type: "Complex",
            value: null
          });
        }

        if (p.itemParameters) {
          for (let i = 0; i < p.itemParameters.length; i++) {
            const itemParameter = p.itemParameters[i];

            items.push({
              name: itemParameter.name,
              type: itemParameter.type,
              value: itemParameter.nullable ? null : (itemParameter.multiValue ? [] : '')
            });
          }
        } else {
          items.push({
            name: p.name,
            type: p.type,
            value: p.nullable ? null : (p.multiValue ? [] : '')
          });
        }
      }
    }

    return [
      ...items,
      ...complexItems
    ]
  };

  public getDisplayName = (creditor: ICreditor) => {
    let value = creditor.organizationName;

    if (this.props.creditorType !== CreditorNumberType.NoDisplay) {
        switch (this.props.creditorType) {
            case CreditorNumberType.DomainId:
                value += creditor.domainId && creditor.domainId !== ''  ? ` (${creditor.domainId})` : '';
                break;
            case CreditorNumberType.ExternalRef:
                value += creditor.externalNo && creditor.externalNo !== ''  ? ` (${creditor.externalNo})` : '';
                break;
            default:
                break;
        }
    }

    return value;
  };

  prepareData = async (data: IReportParameter[]) => {
    const parameters = data.map(control => {
      const creditors = this.props.creditors;      
      const filteredCreditors = creditors.filter(c => CreditorAccessValidator.hasPermissionTo(c.domainId, PermissionNames.CanViewReport))
      const creditorsList = filteredCreditors.map((creditor: ICreditor) => {
        const creditorName = this.getDisplayName(creditor);

        return {
          name: creditorName,
          value: creditor.domainId
        };
      });

      if (
        control.name === ParameterNames.CreditorNo &&
        control.type === "String"
      ) {
        control.availableSelectAll = true;
        control.validValuesList = creditorsList;
      }

      return control;
    });

    const outputDataPrepared = this.prepareOutpuData(parameters);

    const formData = this.prepareFormData(
      this.props.data.reportParameters || parameters
    );

    this.setState({
      outputData: (this.props.data.reportParameters as any) || outputDataPrepared,
      formData: {
        ...this.state.formData,
        reportParameters: formData
      },
      parameters: data.filter(reportParam => !reportParam.hidden),
      isDataLoading: false
    });
  };

  fetchFailed = () => {};

  prepareFormData = (data: any) => {
    let formData = {};

    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        const param = data[i];

        if (param.itemParameters) {
          for (let i = 0; i < param.itemParameters.length; i++) {

            const paramItem = param.itemParameters[i];
            const value = paramItem.defaultValues && paramItem.defaultValues.length ? paramItem.defaultValues[0] : '';
            
            formData[paramItem.name] = value;
          }
        } else {
          let value = param.value || "";
          const name =
            param.type === "Complex"
              ? `${param.name}${param.type}`
              : param.name;

          if (param.multiValue) {
            value = [];
          }

          formData[name] = value;
        }
      }
    } else {
      formData = data;
    }

    return formData as IDictionary<string>;
  };
}

const getViewParamsGenerator = (
  value: any,
  formData: any,
  params: any,
  context: any,
  handleChange: (data: any) => void
) => ({
  [EParamsType.String]: (
    <Input
      formData={formData}
      value={value}
      params={params}
      handleChange={handleChange}
    />
  ),
  [EParamsType.NumFill]: (
    <Input
      formData={formData}
      value={value}
      params={params}
      handleChange={handleChange}
    />
  ),
  [EParamsType.DateRange]: (
    <DateRange
      value={value}
      data={params}
      dateRange={context.state.dateRange}
      handleChange={handleChange}
    />
  ),
  [EParamsType.DateFrom]: (
    <Date
      data={params}
      values={value}
      dateForm={context.state.dateFrom}
      onChangeHandler={context.onChangeHandler}
      handleChange={handleChange}
    />
  )
});

function ViewParamsGenerator(params: any) {
  return (
    <>
      {
        getViewParamsGenerator(
          params.value,
          params.formData,
          params.controlParams,
          params.context,
          params.handleChange,
        )[params.controlParams.type]
      }
    </>
  );
}

const mapStateToProps = (state: IState) => {
  return {
    creditors: state.creditors.filter(c => c.isAuthorized)
  };
};

export default connect(mapStateToProps)(ReportParameters);

import NavbarSearchForm from 'modules/NavbarSearchForm/NavbarSearchForm';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import UserIdentityService from '../../../core/services/UserIdentityService';
import { getModalOpenEvent } from '../../helpers/Modal/ModalEvents';
import ModuleEventSubscriber from '../../helpers/ModuleEventSubscriber';
import { EventsList } from '../../lists/EventsList';
import { ModuleNamesList } from '../../lists/ModuleNamesList';
import TranslationService from '../../services/TranslationService';
import { IModuleEventSubscription } from '../../types/IModuleEventSubscription';
import { IUserDetails } from '../../types/IUserDetails';
import StyleWrapper from './../../../core/components/StyleWrapper/StyleWrapperHOC';
import CreditorSwitcher from './components/CreditorSwitcher/CreditorSwitcher';
import LanguageSwitcher from './components/LanguageSwitcher';
import { IHeaderState } from './types/IHeaderState';
import { defaultHederModuleSettings } from './types/IHeaderModuleSettings';
import HeaderModuleApi from './HeaderModuleApi';
import './_Header.scss';
import { connect } from 'react-redux';
import { IStore } from '../../../reducers/IStore';
import { IDictionary } from '../../types/IDictionary';

export interface IHeaderProps {
    isDcaProfile?: boolean;
    onClickMobileTogglerMenu?: (isExpanded: boolean) => void;
    applicationSettings: IDictionary<string>;
}

class Header extends React.Component<IHeaderProps, IHeaderState>{
    public state: IHeaderState = {
        isLeftAsideExpanded: false,
        isChatAvailable: false,
        isCreditorFileUploadAvailable: false,
        modulesSettings: {...defaultHederModuleSettings}
    };
    private moduleEvents: IModuleEventSubscription[] | undefined;
    private userIdentityService: UserIdentityService;

    constructor(props: any) {
        super(props);
        this.userIdentityService = new UserIdentityService();
    }

    public async componentDidMount() {
        await this.fetchModulesSettings();
        this.registerModuleEvents();
    }

    public componentWillUnmount() {
        if (this.moduleEvents) {
            ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
        }
    }

    public onClickHamburgerMenu = () => {
        const newExpandedValue = !this.state.isLeftAsideExpanded;
        this.setState({
            isLeftAsideExpanded: newExpandedValue
        }, () => this.props.onClickMobileTogglerMenu ? this.props.onClickMobileTogglerMenu(newExpandedValue) : '')
    };

    private fetchModulesSettings = async () => {
        const modulesSettings = await HeaderModuleApi.getModulesSettings();
        this.setState({modulesSettings});
    }

    public render() {
        return (
            <header className="main-header">
                <div className="logo-container">
                    {this.props.onClickMobileTogglerMenu &&
                        <button className="sidebar-toggle" type="button" onClick={this.onClickHamburgerMenu}>
                            <i className="fas fa-bars" />
                        </button>}
                    <NavLink className="w-100" to=''>
                        <div className="logo" />
                    </NavLink>
                </div>

                <div className="navbar">
                    {!this.props.isDcaProfile && this.userIdentityService.IsLoggedIn() && (
                        <div className="navbar-case-filter">
                            <NavbarSearchForm/>
                        </div>
                    )}

                    <nav className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                            {this.userIdentityService.IsLoggedIn() ? (
                                <>
                                    {!this.props.isDcaProfile && (
                                        <li><CreditorSwitcher /></li>
                                    )}

                                    <li className="nav-link">
                                        <Link id="myProfileBtn" to="/userprofile">
                                            <i className="fas fa-user" />
                                            <strong>{this.getLoggedUserDetails()}</strong>
                                        </Link>
                                    </li>
                                    {!this.props.isDcaProfile && this.state.isCreditorFileUploadAvailable &&
                                        <li className="nav-link" onClick={this.state.isCreditorFileUploadAvailable ? this.openCreditorFileUploadModal : undefined}>
                                            <i className={`fas fa-upload ${!this.state.isCreditorFileUploadAvailable && 'disabled'}`} />
                                        </li>
                                    }
                                    {this.state.isChatAvailable && <li className="nav-link" onClick={this.openChat}>
                                        <i className="far fa-comment" />
                                        <div className="chat-dot chat-dot--available" />
                                    </li>}
                                    <li>
                                        <LanguageSwitcher displayAsDropdown={true} />
                                    </li>
                                    <li className="nav-link">
                                        <i className="far fa-envelope" />
                                        <strong>
                                            <Link to={{ pathname: this.props.applicationSettings.ContactUrl }} target="_blank">
                                                {TranslationService.translate('ContactUs')}
                                            </Link>
                                        </strong>
                                    </li>
                                    <li className="nav-link" onClick={this.logout}>
                                        <i className="fas fa-power-off" />
                                    </li>
                                </>
                            ) : (
                                    <li><LanguageSwitcher displayAsDropdown={true} /></li>
                                )}
                        </ul>
                    </nav>
                </div>
            </header>
        )
    }

    public getLoggedUserDetails(): string {
        const user: IUserDetails = this.userIdentityService.GetUserDetails();
        return user.firstname + ' ' + user.lastname;
    }

    public openCreditorFileUploadModal = () => {
        if (this.state.creditorFileUploadModalId) {
            ModuleEventSubscriber.emitEvent({
                name: getModalOpenEvent(this.state.creditorFileUploadModalId),
                data: {
                    modalData: {
                        MODAL_HEADER_TEXT: TranslationService.translateModule('CreditorFileUploadTitle', ModuleNamesList.CreditorFileUpload),
                        MODAL_HEADER_ICON: 'fas fa-upload'
                    }
                }
            })
        }
    }

    public openChat = () => {
        ModuleEventSubscriber.emitEvent({
            name: EventsList.OPEN_CHAT
        })
    }

    public logout = async () => {
        await this.userIdentityService.Logout();
    }

    private registerModuleEvents() {
        this.moduleEvents = [
            { name: EventsList.CHAT_LOADED, callback: () => { this.toggleChatState(true) } },
            { name: EventsList.CHAT_DISABLED, callback: () => { this.toggleChatState(false) } },
            {
                name: EventsList.MODULE_LOADED, callback: (data: any) => {
                    if (data.moduleName === ModuleNamesList.CreditorFileUpload) {
                        this.setState({
                            isCreditorFileUploadAvailable: this.state.modulesSettings.isCreditorFileUploadEnabled,
                            creditorFileUploadModalId: data.moduleId as number
                        })
                    }
                }
            }
        ];

        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }

    private toggleChatState = (state: boolean) => {
        this.setState({
            isChatAvailable: state
        })
    }
}

const styleConfig = [
    {
        searchMethod: 'module',
        instructions: {
            backgroundColor: '.main-header .navbar',
            imgBackgroundColor: '.main-header .logo-container',
            imgUrl: '.main-header .logo-container .logo',
            imgDisabled: '.main-header .logo-container .logo',
        }
    }, {
        searchMethod: 'element',
        instructions: {
            togglemenu: '.main-header .sidebar-toggle'
        }
    }, {
        searchMethod: 'module',
        include: 'navbar',
        instructions: {
            textColor: '.navbar-nav li, .navbar-nav li a',
            backgroundColor: '.navbar-nav li',
            backgroundColorHover: '.navbar-nav li:hover, .navbar-nav li:active',
        }
    }
]

const mapStateToProps = (state: IStore) => (
    {
        applicationSettings: state.applicationSettings
    }
);

export default connect(mapStateToProps)(StyleWrapper('module:header', styleConfig)(Header));

import React, {useState, useEffect} from 'react';
import {Button, BUTTON_VARIANTS, BUTTON_SIZE} from '../../../core/components/Button/Button';
import TranslationService from '../../../core/services/TranslationService';
import {ModuleNamesList} from '../../../core/lists/ModuleNamesList';
import {TransferType} from '../types/TransferType';
import TransferInvoicesTableConfigurator from '../helpers/TransferInvoicesTableConfigurator';
import {ITransferableInvoice} from '../types/ITransferableInvoice';
import {IComponentTable} from '../../../core/types/IComponentTable';
import {BootstrapTableContainer} from '../../../core/components/BootstrapTable/BootstrapTableContainer';

type InvoicesTablePropsType = {
    data: IComponentTable<ITransferableInvoice>,
    count: number,
    sizePerPage: number,
    sortBy: string,
    sortDirection: string,
    fetchCases: (currentPage: number, sortDirection: string, sortBy: string) => void,
    updateInvoicesStatus: (transferType: TransferType, selectedRows: ITransferableInvoice[]) => void
}

export const InvoicesTable = (props: InvoicesTablePropsType) => {
    const [selectedRows, setSelectedRows] = useState<ITransferableInvoice[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sizePerPage, setSizePerPage] = useState<number>(props.sizePerPage);
    const [sortBy, setSortBy] = useState<string>(props.sortBy || 'dueDate');
    const [sortDirection, setSortDirection] = useState<string>(props.sortDirection);

    const handleSelectRow = (element: ITransferableInvoice) => {
        let currentSelectedRows = [...selectedRows];
        let isExist = false;

        currentSelectedRows = currentSelectedRows.filter((row: ITransferableInvoice) => {
            if (row.id === element.id) {
                isExist = true;
                return false;
            }

            return row;
        });

        if (!isExist) {
            currentSelectedRows.push(element);
        }

        setSelectedRows(currentSelectedRows);
    };

    const onTableChange = (type: any, { page, sizePerPage, sortField, sortOrder}: any) => {
        setCurrentPage(page);
        setSizePerPage(sizePerPage)
        setSortBy(sortField ? sortField : props.sortBy);
        setSortDirection(sortOrder ? sortOrder : props.sortDirection);
    };

    const rowClasses = (row: ITransferableInvoice) => {
        return `invoiceNumber-${row.invoiceNo}`;
    };

    useEffect(() => {
        props.fetchCases(currentPage, sortDirection, sortBy);
        // eslint-disable-next-line
    }, [currentPage, sortDirection, sortBy, sizePerPage])

    return (
        <>
            <div className="row mb-2">
                <div className="col align-self-end">
                    <Button
                        id="MoveToCollectionBtn"
                        className="mr-3"
                        variant={BUTTON_VARIANTS.PRIMARY}
                        size={BUTTON_SIZE.SM}
                        disabled={selectedRows.length ? false : true}
                        label={TranslationService.translateModule(
                            'MoveToCollection',
                            ModuleNamesList.TransferToDebtCollection
                        )}
                        onClick={() => props.updateInvoicesStatus(TransferType.MoveToCollection, selectedRows)}
                    />

                    <Button
                        id="DontMoveToCollectionBtn"
                        variant={BUTTON_VARIANTS.PRIMARY}
                        size={BUTTON_SIZE.SM}
                        disabled={selectedRows.length ? false : true}
                        label={TranslationService.translateModule(
                            'DontMoveToCollection',
                            ModuleNamesList.TransferToDebtCollection
                        )}
                        onClick={() => props.updateInvoicesStatus(TransferType.DontMoveToCollection, selectedRows)}
                    />
                </div>
            </div>

            <BootstrapTableContainer
                remote={true} 
                keyField="id" 
                wrapperClasses="bt"
                classes="bt__table bt-table"
                paginationProps={{
                    custom: true, 
                    page: currentPage,
                    sizePerPage: sizePerPage,
                    totalSize: props.count
                }}
                rowClasses={rowClasses}
                data={props.data.values}
                onTableChange={onTableChange}
                columns={TransferInvoicesTableConfigurator.getTableColumns(props.data)}
                selectRow={{
                    mode: 'checkbox',
                    clickToSelect: true,
                    classes: 'bt bt-table bt-table__row bt-table__row--selected',
                    onSelect: handleSelectRow,
                    selected: selectedRows.map((row) => row.id)
                }}
            />
        </>
    )
};
import React, { useState, useEffect } from 'react';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import { FormFilterFieldsType } from './types/FormFilterFieldsType';
import { IModuleProps } from '../../core/types/IModuleProps';
import { ManagedPageModel } from './Models/ManagedPageModel';
import { ManagedProfileModel } from './Models/ManagedProfileModel';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import Loader from '../../core/components/Loading/Loader';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import PagesListApiClient from './PagesListApiClient';
import PagesListFilter from './components/PagesListFilter';
import TranslationService from '../../core/services/TranslationService';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';
import { compose } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';

type PagesListPropsType = IModuleProps & AbortRequestPropsType & RouteComponentProps;
type TableDataType = ManagedPageModel & {
    profileName: string
}

const PagesList = (props: PagesListPropsType) => {
    const [paginationProps, setPaginationProps] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(true);
    const [profiles, setProfiles] = useState<ManagedProfileModel[]>();
    const [tableData, setTableData] = useState<TableDataType[]>([]);
    const [tableDataInit, setTableDataInit] = useState<TableDataType[]>([]);
    const [defaultProfile, setDefaultProfile] = useState<string>('');

    const pagesListApiClient = new PagesListApiClient(props.cancelTokenSource.token);

    const tableColumns = [{
        dataField: 'id',
        text: '#',
        sort: false,
    }, {
        dataField: 'name',
        text: 'Name',
        sort: true,
    }, {
        dataField: 'profileName',
        text: 'Profile name',
        sort: true,
    }, {
        dataField: 'pageUrl',
        text: 'URL',
        sort: false,
    }, {
        dataField: 'isPublic',
        text: 'Public',
        sort: true,
    }, {
        dataField: 'actions',
        text: '',
        sort: false,
        formatter: (cell: any, row: any) => {
            return (
                <Button 
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTON_SIZE.MD}
                    type="button" 
                    label={TranslationService.translateModule('Manage', ModuleNamesList.PagesList)} 
                    onClick={() => handleManageClick(row.id)}
                />
            )
        }
    }];

    const handleManageClick = (id: number) => {
        props.history.push({
            pathname: '/manage-module-instances',
            search: `?id=${id}`
        })
    }

    const fetchData = async () => {
        try {
            setIsLoading(true);
            
            const profiles = await pagesListApiClient.getProfiles();

            setProfiles(profiles);
        } catch (error) {
            const hasAccess = ErrorResponseHandler.hasAccess(error);
            setHasAccess(hasAccess);
        }
        finally {
            setIsLoading(false);
        }
    }
    
    const handleOnSubmitFilter = (values?: FormFilterFieldsType) => {
        let tableDataFiltered = tableDataInit;

        if (values) {
            tableDataFiltered = tableDataFiltered.filter((data: TableDataType) => {
                return data.name.includes(values.name) && data.profileName.includes(values.profileName) ? true : false
            });
        }

        setPaginationProps({
            page: 1,
            totalSize: tableDataFiltered.length
        });

        setTableData(tableDataFiltered);
    }

    const prepareTableData = (profiles: ManagedProfileModel[], filterProfileName: string) => {
        const tableData = profiles.map((profile) => {
            const name = profile.name;

            return profile.pages.map((page: TableDataType) => {
                page.profileName = name;

                return page;
            });
        }).reduce(function(prev, cur) {
            return prev.concat(cur);
         });

        setTableDataInit(tableData);

        if (filterProfileName) {
            const tableDataFilterd = tableData.filter((data) => {
                return data.profileName === filterProfileName ? true : false;
            });
            
            setTableData(tableDataFilterd);
        } else {
            setTableData(tableData);
        }
    }

    const handlePageChange = (page: number) => {
        setPaginationProps({
            ...paginationProps,
            page
        });
    }

    useEffect(() => {
        if (defaultProfile && profiles) {
            prepareTableData(profiles, defaultProfile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultProfile]);

    useEffect(() => {
        if (profiles && profiles.length) {
            setDefaultProfile(profiles[0].name);
        }
    }, [profiles]);
    
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);
    
    return (
        !hasAccess ? (
            <NoPermissionsComponent/>
        ) : (
        <article className="l-module">
            <section className="l-module__section l-module__section--head">
                <h1>
                    {TranslationService.translateModule('PagesHeader', ModuleNamesList.PagesList)}
                </h1>
            </section>

            <section className="l-module__section l-module__section--filter mb-2">
                <PagesListFilter defaultProfile={defaultProfile} profiles={profiles} onSubmit={handleOnSubmitFilter} />
            </section>

            <section className="l-module__section mb-3">
                {(isLoading && !profiles) ? (
                    <Loader/>
                ) : (
                    <>
                        <BootstrapTableContainer
                            remote={false}
                            wrapperClasses="bt"
                            paginationProps={{
                                ...paginationProps,
                                onPageChange: handlePageChange
                            }}
                            classes="bt__table bt-table"
                            keyField='id'
                            data={tableData}
                            columns={tableColumns}
                        /> 
                    </>
                )}
            </section>
        </article>
        )
    );
}

export default compose(withRouter, withAbortRequest)(PagesList);
import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import UserIdentityService from '../core/services/UserIdentityService';
import { getProfile } from '../core/api/helpers/getProfileFromApi';
import { IPage } from '../core/api/types/IPage';
import PagesService from '../core/services/PagesService';

const userIdentityService = new UserIdentityService();

const fetchPagesSucceded = (pages: IPage[]) => ({
    type: ACTIONS.FETCH_PAGES_SUCCEEDED,
    pages
})

const fetchPagesFailed = () => ({
    type: ACTIONS.FETCH_PAGES_FAILED,
})

const fetchPagesInProgress = () => ({
    type: ACTIONS.FETCH_PAGES_IN_PROGRESS
})

export default () => (dispatch: Dispatch<any>) => {
    return new Promise<void>(async (resolve, reject) => {
        dispatch(fetchPagesInProgress());
        
        if (userIdentityService.IsLoggedIn()) {
            return getProfile()
                .then((pages: any) => {
                    dispatch(fetchPagesSucceded(pages));
                    resolve();
                })
                .catch(_ => {
                    dispatch(fetchPagesFailed());
                    reject();
                });
    
        } else {
            return PagesService.GetPublic()
                .then(pages => {
                    dispatch(fetchPagesSucceded(pages));
                    resolve();
                })
                .catch(_ => {
                    dispatch(fetchPagesFailed())
                    reject();
                });
        }
    })
}

import * as React from 'react';
import { IComponentTable, emptyComponentTable } from '../../core/types/IComponentTable';
import { IModuleProps } from '../../core/types/IModuleProps';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import InvoicesListTableConfigurator from './helpers/InvoicesListTableConfigurator';
import './InvoicesList.scss';
import InvoicesListService from './services/InvoicesListService';
import { IInvoiceListElement } from './types/IInvoiceListElement';
import { InvoicesColumns } from './types/InvoicesColumns';
import { configureRowExpander } from './helpers/InvoicesListTableRowExpander';
import Loader from '../../core/components/Loading/Loader';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';

interface IState {
    currentPage: number,
    sizePerPage: number,
    total: number,
    invoicesTable: IComponentTable<IInvoiceListElement>,
    sortBy: string,
    sortDirection: string,
    expandedRows: string[],
    isDataLoading: boolean
}

interface IProps extends IModuleProps {
    locale: string
}

export default class InvoicesList extends React.Component<IProps, IState> {
    public state: IState = {
        currentPage: 1,
        sizePerPage: 10,
        sortDirection: 'desc',
        sortBy: InvoicesColumns[InvoicesColumns.Id],
        total: 0,
        invoicesTable: {...emptyComponentTable},
        expandedRows: [],
        isDataLoading: false
    }

    public async componentDidMount() {
        await this.fetchInvoices();
    }

    public render() {
        const caseId = this.props.routeParameters.id as string;

        return (
            <div className="invoices-list position-relative mt-4">
                <section className={`l-module__section ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
                    {this.state.isDataLoading ? <Loader /> : null}

                    <BootstrapTableContainer
                        remote={true}
                        wrapperClasses="bt"
                        classes="bt__table bt-table"
                        keyField='id'
                        data={this.state.invoicesTable.values}
                        paginationProps={{
                            page: this.state.currentPage,
                            sizePerPage: this.state.sizePerPage,
                            totalSize: this.state.total
                        }}
                        onTableChange={this.onTableChange}
                        expandRow={configureRowExpander(this.handleRowExpand, this.onExpandAll, this.state.expandedRows, this.props.module.id, caseId)}
                        columns={InvoicesListTableConfigurator.InvoicesListTableConfigurator.getColumns(this.state.invoicesTable)} 
                    />
                </section>
            </div>
        )
    }

    private onExpandAll = (isExpandAll: boolean, resutls: any[], e: any) => {
        if (isExpandAll) {
            this.setState({
                expandedRows: [...this.state.invoicesTable.values.map(v => v.id.toString())]
            });
        } else {
            this.setState({
                expandedRows: []
            });
        }
    }

    private handleRowExpand = (row: any, isExpand: boolean, rowIndex: number, e: any) => {
        if (isExpand) {
            this.setState({
                expandedRows: [...this.state.expandedRows, row.id]
            });
        } else {
            this.setState({
                expandedRows: [...this.state.expandedRows.filter(x => x !== row.id)]
            });
        }
    }

    private onTableChange = (type: any, { page, sizePerPage, sortField, sortOrder }: any) => {
        this.setState({
            currentPage: page,
            sizePerPage,
            sortBy: sortField ? this.convertToSortByNumber(sortField) : this.state.sortBy,
            sortDirection: sortOrder ? sortOrder : this.state.sortDirection
        }, this.fetchInvoices)
    }

    private fetchInvoices = async () => {
        this.setState({
            isDataLoading: true
        })

        try {
            const { id } = this.props.routeParameters;
            const request = {
                caseId: id as string,
                pageNumber: this.state.currentPage,
                pageSize: this.state.sizePerPage,
                sortBy: InvoicesColumns[this.state.sortBy],
                orderingDirection: this.state.sortDirection === 'asc' ? 0 : 1,
                moduleInstanceId: this.props.module.id
            }

            const data = await InvoicesListService.GetInvoices(request);
            this.setState({
                invoicesTable: data.table,
                total: data.total,
                isDataLoading: false
            })
        } catch {
            InfoMessageService.error(TranslationService.translate('ErrorLoadingData'))
            this.setState({
                isDataLoading: false,
            })
        }
    }

    private convertToSortByNumber = (sortField: string) => {
        return InvoicesColumns[sortField.charAt(0).toUpperCase() + sortField.slice(1)];
    }
}
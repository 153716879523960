import RestApiClient from '../../../core/api/RestApiClient';
import { ICreditorUserVm } from '../types/ICreditorUserVm';
import { IUserModuleVm } from '../types/IUsersModuleVm';
import { IActionStatus } from '../../../core/api/types/IActionStatus';
import { IActionStatusTyped } from '../../../core/api/types/IActionStatusTyped';

class UsersApiClient
{
    public async getUsersModuleVm(moduleId: number): Promise<IUserModuleVm> {
        return await RestApiClient.get<IUserModuleVm>(`modules/UsersModule/${moduleId}`);
    }

    public async getUsers(moduleId: number): Promise<IActionStatusTyped<ICreditorUserVm[]>> {
        const result = await RestApiClient.get<IActionStatusTyped<ICreditorUserVm[]>>(`modules/UsersModule/${moduleId}/users`);
        return {...result, data: result.data.map((u: ICreditorUserVm, index: number) => ({...u, id: index}))};
    }
    
    public async inviteUser(moduleId: number, creditorId: string, userEmail : string, roleId : string): Promise<ICreditorUserVm> {
        return await RestApiClient.post<ICreditorUserVm>(`modules/UsersModule/${moduleId}/${creditorId}/invite/${userEmail}`, roleId);
    }

    public async setCreditorUserRoles(moduleId: number, creditorId: string, userId : string, roleIds : string[]): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>(`modules/UsersModule/${moduleId}/${creditorId}/user/${userId}/roles`, roleIds);
    }    

    public async resendInvitation(moduleId: number, creditorId: string, userEmail : string, roleId : string): Promise<ICreditorUserVm[]> {
        return await RestApiClient.post<ICreditorUserVm[]>(`modules/UsersModule/${moduleId}/${creditorId}/invite/${userEmail}`, roleId);
    }

    public async cancelInvitation(moduleId: number, creditorId: string, userEmail : string): Promise<void> {
        return await RestApiClient.delete(`modules/UsersModule/${moduleId}/${creditorId}/invite/${userEmail}`);
    }
}

export default new UsersApiClient();
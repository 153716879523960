import SessionStorageService from './../services/SessionStorageService';

class GlobalConfig {
    private globalConfig: any;

    public val = () => {
        if (window['globalConfig']) {
            return window['globalConfig'];
        }

        const globalConfigJson = SessionStorageService.get('globalConfig');
        this.globalConfig = JSON.parse(globalConfigJson ? globalConfigJson : "");
        return this.globalConfig;
    }
}

export default new GlobalConfig();

import * as React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { ICaseSummary } from '../types/ICaseSummary';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import CurrencyParser from '../../../core/helpers/CurrencyParser';

interface IProps {
    case: ICaseSummary
}

class CaseOverview extends React.Component<IProps> {
    public render() {
        return (
            <>
                <h6>{TranslationService.translateModule('CaseOverview', ModuleNamesList.PrintCase)}</h6>
                <table className='table table-bordered table-sm mb-0'>
                    <tbody>
                        <tr>
                            <td>{TranslationService.translateModule('CaseOverviewStatus', ModuleNamesList.PrintCase)}</td>
                            <td>{this.props.case.status}</td>
                        </tr>
                        <tr>
                            <td>{TranslationService.translateModule('CaseOverviewRegistrationDate', ModuleNamesList.PrintCase)}</td>
                            <td>{DateTimeParser.toLocaleDateString(this.props.case.registrationDate)}</td>
                        </tr>
                        <tr>
                            <td>{TranslationService.translateModule('CaseOverviewRefNumber', ModuleNamesList.PrintCase)}</td>
                            <td>{this.props.case.refNumber}</td>
                        </tr>
                        <tr>
                            <td>{TranslationService.translateModule('CaseOverviewCloseDate', ModuleNamesList.PrintCase)}</td>
                            <td>{DateTimeParser.toLocaleDateString(this.props.case.closeDate)}</td>
                        </tr>
                        <tr>
                            <td>{TranslationService.translateModule('CaseOverviewOriginalClaim', ModuleNamesList.PrintCase)}</td>
                            <td>{CurrencyParser.toLocaleString(this.props.case.originalClaim)}</td>
                        </tr>
                        <tr>
                            <td>{TranslationService.translateModule('CaseOverviewKid', ModuleNamesList.PrintCase)}</td>
                            <td>{this.props.case.kid}</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default CaseOverview;
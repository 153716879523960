import TimePicker from 'antd/lib/time-picker';
import { Field } from 'formik';
import React from 'react';
import ScheduleReportService from '../ScheduleReportService';
import DatePicker from './../../../core/components/Forms/DatePicker';
import TranslationService from './../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import moment from 'moment';

interface IProps {
    inputData: any,
    values: IValues,
    calendarDayNumbersMin: number,
    calendarDayNumbersMax: number,
    calendarDayFieldName: string,
    handleChange: (data: any) => void
}

interface IState {
    repeatAt: any,
    isLoaded: boolean,
    calendarDayValue: number
}

interface IValues {
    startTime: string,
    stopTime: string,
    startTimeHours: string,
    startTimeMinutes: string
    calendarDayNumbers: any[]
}

class Monthly extends React.Component<IProps, IState> {
    static defaultProps = {
        calendarDayNumbersMin: 1,
        calendarDayNumbersMax: 31,
        calendarDayFieldName: 'calendarDayNumbers'
    }

    state: IState = {
        repeatAt: {},
        isLoaded: false,
        calendarDayValue: this.props.calendarDayNumbersMin
    }

    componentDidMount = () => {
        this.setTimePicker();
    }

    render() {
        const { startTime, stopTime } = this.props.values;

        return (
            <>
                {this.state.isLoaded &&
                    <>
                        <div className="c-creator__box">
                            <div className="row">
                                <div className="col-9">
                                    <div className="c-control">
                                        <Field 
                                            name="startTime"
                                            validate={this.validationDate}>
                                            {({ field, form }: any) => (
                                                <div className="form-group row row--aligned">
                                                    <label className={"col-form-label col-12 col-sm-4 "} htmlFor={field.name}>
                                                        {TranslationService.translateModule('StartDate', ModuleNamesList.ScheduleReports)}
                                                    </label>

                                                    <div className="col-12 col-sm-8 icon-calendar default-form c-control">
                                                        <DatePicker 
                                                            onChange={(date) => {
                                                                this.onChangeDateHandler(date, field.name);
                                                            }}
                                                            inputId={field.name}
                                                            inputName={field.name}
                                                            value={startTime ? new Date(startTime) : ''}/>
                                                    </div>

                                                    {form.errors[field.name] && form.touched[field.name] &&
                                                        <div className="col-12 c-input-error">{form.errors[field.name]}</div>
                                                    }
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="c-control">
                                        <TimePicker format={'HH:mm'} onChange={this.handleTimeChange} defaultValue={this.state.repeatAt}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-9">
                                    <div className="c-control">
                                        <Field name="stopTime">
                                            {({ field, form }: any) => (
                                                <div className="form-group row row--aligned">
                                                    <label className={"col-form-label col-12 col-sm-4 "} htmlFor={field.name}>
                                                        {TranslationService.translateModule('EndDate', ModuleNamesList.ScheduleReports)}
                                                    </label>

                                                    <div className="col-12 col-sm-8 icon-calendar default-form c-control">
                                                        <DatePicker 
                                                            onChange={(date) => {
                                                                this.onChangeDateHandler(date, field.name);
                                                            }}
                                                            inputId={field.name}
                                                            inputName={field.name}
                                                            value={stopTime ? new Date(stopTime) : ''}/>
                                                    </div>
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="c-creator__box">
                            <div className="c-control">
                                <Field 
                                    name={this.props.calendarDayFieldName}
                                    validate={this.validationCalendarDay}>
                                        {({ field, form }: any) => (
                                            <div className="row row--aligned">
                                                <div className="col-3">
                                                    <label className="c-control__label" htmlFor={field.name}>
                                                        {TranslationService.translateModule('RepeatEvery', ModuleNamesList.ScheduleReports)}
                                                    </label>
                                                </div>

                                                <div className="col-9">
                                                    <input
                                                        type="number"
                                                        min={this.props.calendarDayNumbersMin}
                                                        max={this.props.calendarDayNumbersMax}
                                                        name={field.name}
                                                        id={field.name}
                                                        className="col-3"
                                                        defaultValue={this.props.values[field.name][0] || this.props.calendarDayNumbersMin}
                                                        onChange={(ev: any) => this.handleNumberChange(form, ev)}
                                                    />

                                                    <span className="c-control__suffix">{TranslationService.translate('DayOfTheMonth')}</span>
                                                
                                                    {form.errors[field.name] && form.touched[field.name] &&
                                                        <div className="col-12 text-left c-input-error">{form.errors[field.name]}</div>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                </Field>
                            </div>
                        </section>
                    </>
                }
            </>
        )
    }

    validationCalendarDay = (values: string[]) => {
        const value = values.length > 0 ? values[0] : null;
        let error;

        if (!value) {
            error = TranslationService.translate('ValidationMessageRequiredField');
        } else if (
            parseInt(value) < this.props.calendarDayNumbersMin ||
            parseInt(value) > this.props.calendarDayNumbersMax
        ) {
            error = TranslationService.translate('ValidationMessageIncorrectValue');
        }

        return error;
    }

    validationDate = (value: string) => {
        let error;

        if (!value) {
            error = TranslationService.translate('ValidationMessageRequiredField');
        }

        return error;
    }

    onChangeDateHandler = (date: any, name: string) => {
        this.props.handleChange({
            name: name,
            value: date
        });
    }

    handleNumberChange = (form: any, { target: { value }} : any) => {
        form.setFieldTouched(this.props.calendarDayFieldName, true);

        this.props.handleChange({
            name: this.props.calendarDayFieldName,
            value: [value]
        });
    }

    handleTimeChange = (time: {}) => {
        const timeArr = [{
            name: 'startTimeHours',
            value: moment(time).hour()
        }, {
            name: 'startTimeMinutes',
            value: moment(time).minute()
        }]

        for (let i = 0; i < timeArr.length; i++) {
            const time = timeArr[i];

            this.props.handleChange({
                name: time.name,
                value: time.value
            });
        }
    }

    setTimePicker = () => {
        const { startTimeHours, startTimeMinutes } = this.props.values;
        const computedTime = ScheduleReportService.setTimePicker(startTimeHours, startTimeMinutes);

        this.handleTimeChange(computedTime);
        
        this.setState({ 
            repeatAt: moment().hour(parseInt(startTimeHours)).minute(parseInt(startTimeMinutes)),
            isLoaded: true
        });
    }
}

export default Monthly;
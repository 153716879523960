import React from 'react';
import classNames from 'classnames';
import styles from '../Card.module.scss';

enum CardContentVariants {
    Secondary = 'secondary'
}

type CardContentPropsType = {
    variant?: string,
    children: React.ReactChild,
    class?: string,
}

const CardContent = (props: CardContentPropsType) => {
    return (
        <div className={classNames(
            styles.card__content,
            props.class,
            props.variant && styles[`card__content--${props.variant}`]
        )}>
            {props.children}
        </div>
    )
}

export { 
    CardContent,
    CardContentVariants
};
import { Field } from 'formik';
import React from 'react';
import { toTitleCaseWithoutWhitespaces } from '../../../core/helpers/toTitleCaseWithoutWhitespaces';
import TranslationService from './../../../core/services/TranslationService';

interface ISimpleInput {
    name: string,
    prompt: string,
    suffix?: string,
    inputDesc?: string,
    type: string,
    dataType?: string,
    defaultValues?: string | string[],
    value?: string,
    placeholder?: string
    required: boolean,
    onChange: any
}

const SimpleInput = (props: ISimpleInput) => {
    const isRequired = (value: string, isRequired: boolean) => {
        let error;

        if (!value && isRequired) {
            error = TranslationService.translate('ValidationMessageRequiredField');
        }

        return error;
    }

    const handleChange = ({ target: { value }}: any) => {
        props.onChange({
            name: props.name,
            type: 'String',
            value
        })
    }

    return (
        <Field 
            name={props.name}
            validate={(value: string) => isRequired(value, props.required)}>
            {({ field, form }: any) => (
                <div className="c-control">
                    <div className="row row--aligned">
                        {props.type === 'checkbox' ? (
                            <>
                                <label className={`col-12 c-control__label c-control__label--${props.type}`} htmlFor={props.name}>
                                    <span className="c-control__label-icon">
                                        {props.value || (props.defaultValues && props.defaultValues[0] === "True") ? (
                                            <i className="far fa-check-square"></i>
                                        ) : (
                                            <i className="far fa-square"></i>
                                        )}
                                    </span>
        
                                    {TranslationService.translate(toTitleCaseWithoutWhitespaces(props.prompt), props.prompt)}
        
                                    <input
                                        type={props.type}
                                        name={props.name}
                                        id={props.name}
                                        value={props.value}
                                        className="col-7 check-custom"
                                        onChange={handleChange}
                                        required={props.required}/>
                                </label>
                            </>
                        ) : (
                            <>
                                <label className={`col-5 c-control__label c-control__label--${props.type}`} htmlFor={props.name}>
                                    {TranslationService.translate(toTitleCaseWithoutWhitespaces(props.prompt), props.prompt)}
                                </label>
                                
                                <div className="col-7">
                                    <input
                                        type={props.type}
                                        name={props.name}
                                        id={props.name}
                                        defaultValue={props.value}
                                        onChange={handleChange}
                                        placeholder={props.placeholder}/>

                                        {props.inputDesc && props.inputDesc.length > 0 &&
                                            <span className="c-control__input-desc">
                                                {props.inputDesc}
                                            </span>
                                        }
                                </div>
        
                                {props.suffix && props.suffix.length > 0 &&
                                    <span className="c-control__suffix">
                                        {props.suffix}
                                    </span>
                                }

                                {form.errors[field.name] && form.touched[field.name] &&
                                    <div className="col-12 c-input-error">{form.errors[field.name]}</div>
                                }
                            </> 
                        )}
                    </div>
                </div>
            )}
        </Field>
    )
}

export default SimpleInput;
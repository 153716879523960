import React, { useState, useEffect } from 'react';
import { Card } from '../../core/components/Card/Card';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { connect } from 'react-redux';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IPaymentsHistory, PaymentHistoryDataInit } from './types/Payment';
import { IStore } from '../../reducers/IStore';
import Loader from '../../core/components/Loading/Loader';
import PaymentRow from './PaymentRow/PaymentRow';
import PaymentsService from './Services/PaymentsService';
import TranslationService from '../../core/services/TranslationService';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';
import { CardHeader } from '../../core/components/Card/components/CardHeader';

type PaymentsPropsType = IModuleProps & AbortRequestPropsType & {
    creditors: string[],
    currency: string
}

const Payments = (props: PaymentsPropsType) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentsHistory, setpaymentsHistory] = useState<IPaymentsHistory>(PaymentHistoryDataInit)

    const loadData = async () => {
        setIsLoading(true);

        const PaymentsServiceObj = new PaymentsService(props.cancelTokenSource.token);
        const payments = await PaymentsServiceObj.getPayments(props.module.id);

        setpaymentsHistory(payments);
        setIsLoading(false);
    }
    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [props.creditors]);

    return (
        <Card>
                <>
                    <CardHeader>
                        <h1 className="c-heading">
                            {TranslationService.translateModule('Title', props.module.name)}
                        </h1>
                    </CardHeader>

                    <CardContent>
                        <>
                            {isLoading && <Loader />}
                            
                            <PaymentRow
                                key="payment_1"
                                amount={paymentsHistory.zeroTo7}
                                currency={props.currency}
                                daysRange={TranslationService.translateModule('DateRange1', props.module.name)}
                                amountLabel={TranslationService.translateModule('Paid', props.module.name)}
                                showDaysLabel={true} 
                            />
                            
                            <PaymentRow
                                key="payment_2"
                                amount={paymentsHistory.sevenTo14}
                                currency={props.currency}
                                daysRange={TranslationService.translateModule('DateRange2', props.module.name)}
                                amountLabel={TranslationService.translateModule('Paid', props.module.name)}
                                showDaysLabel={true} 
                            />
                            
                            <PaymentRow
                                key="payment_3"
                                amount={paymentsHistory.fourteenTo30}
                                currency={props.currency}
                                daysRange={TranslationService.translateModule('DateRange3', props.module.name)}
                                amountLabel={TranslationService.translateModule('Paid', props.module.name)}
                                showDaysLabel={true} 
                            />
                            
                            <PaymentRow
                                key="payment_4"
                                amount={paymentsHistory.zeroTo7 + paymentsHistory.sevenTo14 + paymentsHistory.fourteenTo30}
                                currency={props.currency}
                                daysRange={TranslationService.translateModule('Sum', props.module.name)}
                                amountLabel={TranslationService.translateModule('Paid', props.module.name)}
                                showDaysLabel={false} 
                            />
                        </>
                    </CardContent>
                </>
        </Card>
    );
}

const mapStateToProps = (state: IStore) => ({
    creditors: state.currentCreditors,
    currency: state.currency
})

export default connect(mapStateToProps)(withAbortRequest(Payments));

import CasePaymentsApi from '../api/CasePaymentsApi';
import { IAmountsStructure } from '../types/IAmountsStructure';

class CasePaymentsService {
    public async getCasePayments(caseId: string): Promise<IAmountsStructure> {
        const result = await CasePaymentsApi.getCasePayments(caseId);
        return result.casePayments;
    }
}

export default new CasePaymentsService();
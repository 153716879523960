import RestApiClient from '../../core/api/RestApiClient';
import { IInitialData } from './types/IInitialData';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { IRoleVm } from './types/IRoleVm';

class RolesAssignmentApiClient {
    public async getInitialData(moduleId: number, userUId: string): Promise<IInitialData> {
        return await RestApiClient.get<IInitialData>(`modules/RolesAssignment/getInitialData/${moduleId}/${userUId}`);
    }

    public async assignRoles(userUId: string, roles: IRoleVm[]): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>(`modules/RolesAssignment/assignRoles`, {userUId, roleUIds: roles.map(itm => itm.uId)});
    }
}

export default new RolesAssignmentApiClient();

import React from 'react';
import { store } from 'index';
import InfoMessageService from '../../services/InfoMessageService';
import TranslationService from '../../services/TranslationService';
import { IDictionary } from '../../types/IDictionary';

const STATIC = {
    backgroundColor: 'background-color',
    backgroundColorHover: 'background-color',
    linkBackgroundColor: 'background-color',
    linkActiveBackgroundColor: 'background-color',
    linkHoverBackgroundColor: 'background-color',
    subnavBackgroundColor: 'background-color',
    separatorColor: 'background-color',
    subnavSeparatorColor: 'background-color',
    imgBackgroundColor: 'background-color',
    imgUrl: 'background-image',    
    imgDisabled: 'background-image',
    favicon: 'favicon',
    font: 'font-family',
    brand: 'color',
    textColor: 'color',
    textColorHover: 'color',
    linkColor: 'color',
    linkActiveColor: 'color',
    linkActiveIndicatorColor: 'border-right-color',
    linkHoverColor: 'color',
}

interface IStyles {
    [name: string]: {
        [name: string]: string
    }
}

interface IStyleWrapper {
    styles: IStyles
}

interface IStyleWrapperConfig {
    searchMethod: string,
    include?: string,
    instructions: IDictionary<any>
    
}

const styleWrapper = (moduleName: string, styleWrapperConfig: IStyleWrapperConfig[]) => <P extends object>(Component: React.ComponentType<P>) => {
    return class StyleWrapper extends React.Component<P, IStyleWrapper> {
        state: IStyleWrapper = {
            styles: {} as IStyles
        }

        componentDidMount = async() => {
            try {
                const definitionsStyles = await store.getState().stylingSettings;
                const cssRules = this.prepareStyles(moduleName, definitionsStyles);

                this.prepareStyleTag(moduleName, cssRules);
            } catch (e) {
                InfoMessageService.error(TranslationService.translate('GeneralErrorOccured'));
            }
        }

        render() {
          return <Component {...this.props as P} styles={this.state.styles} />;
        }

        prepareCssRules = (selector: string, properties: any) => {
            let cssRules = '';

            for (let property in properties) {
                const cssProperty = STATIC[property];

                if (property === 'favicon') {
                    this.makeFaviconTag(properties[property]);
                } else if (property === 'brand') {
                    const colorsArray = properties[property].split(',');

                    for (let i = 0; i < colorsArray.length; i++) {
                        if (colorsArray[i].length) {
                            document.documentElement.style.setProperty(`--brand-color-${i + 1}`, colorsArray[i]);
                        }
                    }
                } else {
                    if (cssProperty) {
                        let propertyValue = properties[property];

                        if (property === 'imgUrl') {
                            propertyValue = `url(${propertyValue})`
                        }

                        cssRules = `
                            ${cssRules} 
                            ${selector} {
                                ${cssProperty}: ${propertyValue};
                            }
                        `
                    }
                }
            }

            return cssRules;
        }

        prepareStyles = (moduleName: string, definitionsStyles: {}) => {
            let cssRules = '';

            for (let i = 0; i < styleWrapperConfig.length; i++) {
                const config: any = styleWrapperConfig[i];
                let isImgDisabled = false;

                if (config.include) {
                    moduleName = `${moduleName}:${config.include}`;
                }

                for (let instruction in config.instructions) {
                    const selector = config.instructions[instruction];

                    if (config.searchMethod === 'element') {
                        const selectorProperties = definitionsStyles[`${moduleName}:${instruction}`];

                        cssRules = `
                            ${cssRules} 
                            ${this.prepareCssRules(selector, selectorProperties)}
                        `;

                    } else if (config.searchMethod === 'module') {
                        const propertyName = STATIC[instruction];
                        if (propertyName && definitionsStyles[moduleName][instruction]) {
                            let propertyValue = definitionsStyles[moduleName][instruction];
                            let addRule = true;

                            if (instruction === 'imgUrl' && !isImgDisabled) {
                                propertyValue = `url(${definitionsStyles[moduleName][instruction]})`;  
                            } else if (instruction === 'imgDisabled' && propertyValue === 'true') {
                                isImgDisabled = true;
                                propertyValue = 'none';
                            } else if(instruction === 'imgDisabled'){
                                addRule = false;
                            }
                            if(addRule){
                                cssRules = `
                                ${cssRules} 
                                ${selector} {
                                    ${propertyName}: ${propertyValue};
                                }`
                            }
                        }
                    }
                }
            }

            return  cssRules;
        }

        prepareStyleTag = (moduleName: string, cssRules: string) => {
            let styleEl = document.getElementById(moduleName);

            if (styleEl && styleEl.parentNode) {
                styleEl.parentNode.removeChild(styleEl);
            }

            styleEl = document.createElement('style');

            document.head.appendChild(styleEl);
            styleEl.setAttribute('id', moduleName);
            styleEl.innerHTML = cssRules;
        }

        makeFaviconTag = (url: string) => {
            const nodeList = document.getElementsByTagName('link');

            for (var i = 0; i < nodeList.length; i++) {
                if (nodeList[i].getAttribute("rel") === "shortcut icon") {
                    nodeList[i].remove();
                }
            }

            let linkEl = document.createElement('link');
            
            linkEl.setAttribute('rel', 'shortcut icon');
            linkEl.setAttribute('href', url);

            document.head.appendChild(linkEl);
        }
    };
}

export default styleWrapper;
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IModuleSetting } from './types/IModuleSetting';
import { IModuleSettingsFilter } from './types/IModuleSettingsFilter';
import { IStore } from '../../reducers/IStore';
import { IUpdateModuleSettingRequest } from './types/IUpdateModuleSettingRequest';
import ComplexSettingsTableWrapper from './components/ComplexSettingsTableWrapper';
import Filter from './components/Filter';
import SimpleSettingsTableWrapper from './components/SimpleSettingsTableWrapper';

type TModuleSettings = {
    moduleSettings: IModuleSetting[],
    moduleComplexSettings: IModuleSetting[],
}

type ModuleInstancesPropsType = IExtendedModuleProps & {
    moduleSettings: TModuleSettings,
    isLoadingData: boolean,
    columnsSettings: any,
    onFilter: (moduleId: number) => void,
    onReset?: (instanceSettingId: number, moduleId: number) => void,
    onUpdate: (moduleId: number, moduleSettingsId: number, setting: IUpdateModuleSettingRequest, type?: string) => void
}

const ManageModuleSettings = (props: ModuleInstancesPropsType) => {
    const [moduleId, setModuleId] = useState<number>(0);

    const handleUpdateSetting = async (setting: IUpdateModuleSettingRequest, moduleSettingId: number) => {
        props.onUpdate(moduleId, moduleSettingId, setting);
    }

    const handleResetSettings = async (instanceSettingId: number) => {
        if(props.onReset) {
            props.onReset(instanceSettingId, moduleId);
        }
    };

    const handleFilterChange = async (filter: IModuleSettingsFilter) => {
        if (filter.moduleId) {
            setModuleId(filter.moduleId);

            props.onFilter(filter.moduleId);
        }
    };

    return (
        <>
            <section className="l-module__section l-module__section--filter">
                {props.modules && (
                    <Filter
                        modules={props.modules}
                        filterSettings={handleFilterChange}
                    />
                )}
            </section>

            <section className="l-module__section mt-4">
                {props.moduleSettings && (
                    <>
                        {props.moduleSettings.moduleSettings.length > 0 && 
                            <SimpleSettingsTableWrapper
                                columnsSettings={props.columnsSettings}
                                data={props.moduleSettings.moduleSettings} 
                                updateSetting={handleUpdateSetting}
                                resetSettings={handleResetSettings}
                            />
                        }

                        {props.moduleSettings.moduleComplexSettings.length > 0 && 
                            <ComplexSettingsTableWrapper 
                                moduleSettings={props.moduleSettings.moduleComplexSettings} 
                                updateModuleSetting={handleUpdateSetting}
                                resetSettings={handleResetSettings}
                            />
                        }
                    </>
                )}
            </section>
        </>
    )
}

const mapStateToProps = (state: IStore) => {
    return {
        currency: state.currency
    }
};

export default connect(mapStateToProps)(ManageModuleSettings)
import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IDocumentsListRequest } from '../types/IDocumentsListRequest';
import { IDocumentListElement } from '../types/IDocumentsListElement';

class DocumentsListApi {
    public async getDocuments(request: IDocumentsListRequest) : Promise<IComponentTable<IDocumentListElement>> {
        return await RestApiClient.post<IComponentTable>('modules/DocumentsListModule/getDocuments', request);
    }
    public async getDocument(documentId: number, caseNumber: string) : Promise<any> {
        return await RestApiClient.downloadFile(`modules/DocumentsListModule/getDocument/${documentId}/${caseNumber}`);
    }
}

export default new DocumentsListApi();
import RestApiClient from '../../../core/api/RestApiClient';
import { GetTranslationsRequest } from '../types/GetTranslationsRequest';
import { TranslationItem } from '../types/TranslationItem';
import { SaveTranslationsRequest } from '../types/SaveTranslationsRequest';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

export default {
    getTranslations: async(request: GetTranslationsRequest): Promise<TranslationItem[]> => {
        return await RestApiClient.post<TranslationItem[]>("modules/TranslationsManagementModule", request);
    },

    saveTranslation: async(request: SaveTranslationsRequest) => {
        return await RestApiClient.post<IActionStatus>("modules/TranslationsManagementModule/save", request);
    }
}
import RestApiClient from '../../core/api/RestApiClient';
import {IPage} from '../../core/api/types/IPage';

class PagesApiClient
{
    public async GetPublic(): Promise<IPage[]> {
        return await RestApiClient.get<IPage[]>('Pages/public');
    }
}

export default new PagesApiClient();
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IInvoiceDetailsMessageItemIndexed } from '../types/IInvoiceDetailsMessageItem';
import { InvoiceDetailsMessagesColumns } from './InvoiceDetailsMessagesColumns';

class InvoiceDetailsMessagesTableConfigurator {
    public getColumns = (invoicesListTable: IComponentTable<IInvoiceDetailsMessageItemIndexed>) => {
        let columns: IComponentTableColumn[] = [];
        if (invoicesListTable != null) {
            columns = [...invoicesListTable.columns];
            this.configureDateColumns(columns);
            this.configureDirectionColumn(columns);
            this.configureMessageColumn(columns);
        }

        return columns;
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = [InvoiceDetailsMessagesColumns.Date];
        const currencyColumnsNames = dateColumns.map(col => InvoiceDetailsMessagesColumns[col].charAt(0).toLowerCase() + InvoiceDetailsMessagesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return DateTimeParser.toLocaleDateString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureDirectionColumn = (columns: IComponentTableColumn[]) => {
        const directionCol = [InvoiceDetailsMessagesColumns.Direction];
        const currencyColumnsNames = directionCol.map(col => InvoiceDetailsMessagesColumns[col].charAt(0).toLowerCase() + InvoiceDetailsMessagesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: IInvoiceDetailsMessageItemIndexed) => {
            return row.direction === 1
                ? TranslationService.translateModule('FromCreditor', ModuleNamesList.InvoiceDetailsMessages)
                : row.direction === 2
                    ? TranslationService.translateModule('FromCaseHandler', ModuleNamesList.InvoiceDetailsMessages)
                    : null
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureMessageColumn = (columns: IComponentTableColumn[]) => {
        const messageCol = [InvoiceDetailsMessagesColumns.Message];
        const messageColName = messageCol.map(col => InvoiceDetailsMessagesColumns[col].charAt(0).toLowerCase() + InvoiceDetailsMessagesColumns[col].slice(1));
        const cols = columns.filter(col => messageColName.indexOf(col.dataField) >= 0);

        for (const col of cols) {
            col.headerClasses = () => { return "idm-table__col idm-table__col--break-text" }
            col.classes = () => { return "idm-table__col idm-table__col--break-text" }
        }
    }
}

export default new InvoiceDetailsMessagesTableConfigurator();
import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loader from '../../../core/components/Loading/Loader';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTable, emptyComponentTable } from '../../../core/types/IComponentTable';
import CreateNewCaseApiClient from '../api/CreateNewCaseApiClient';
import { IDebtorDetails } from '../types/IDebtorDetails';
import { emptyStandardSearch, IStandardSearch } from '../types/IStandardSearch';
import { StandardSearchForm } from './StandardSearchForm';
import SearchDebtorsList from './SearchDebtorsList';
import { ISearchDebtorListElement } from '../types/ISearchDebtorListElement';

interface IProps {
  show: boolean;
  moduleInstanceId: number;
  onClose: () => void
  onDebtorSelected: (debtorDetails: IDebtorDetails) => void;
}

interface IState {
  isOpen: boolean;
  isLoading: boolean;
  standardSeach: IStandardSearch;
  debtorsTable: IComponentTable<ISearchDebtorListElement>;
}

export class SearchDebtorModal extends React.Component<IProps, IState> {
  public state: IState = {
    isOpen: false,
    isLoading: false,
    standardSeach: {...emptyStandardSearch },
    debtorsTable: {...emptyComponentTable }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        isOpen: this.props.show
      })
    }
  }

  public render() {
    return (<div>
      <Modal isOpen={this.state.isOpen} backdrop={true} toggle={this.toggle} className="modal-lg">
        <ModalHeader toggle={this.toggle} charCode="X">
          {TranslationService.translateModule('DebtorInfoModalHeader', ModuleNamesList.CreateNewCase)}
        </ModalHeader>
        <ModalBody>
          <StandardSearchForm searchValues={this.state.standardSeach} updateStandardSearch={this.updateStandardSearch} />
          { this.state.isLoading ? <Loader /> : this.state.debtorsTable.columns.length > 0 ? 
            <SearchDebtorsList data={this.state.debtorsTable} onDebtorSelect={this.onDebtorSelect}/> 
          : '' }
        </ModalBody>
        <ModalFooter>
          <button onClick={this.searchDebtors} className="btn btn-sm btn-color-2 text-transform-none float-right">{TranslationService.translateModule('DebtorInfoModalSearchButton', ModuleNamesList.CreateNewCase)}</button>                    
        </ModalFooter>
      </Modal>
    </div>)
  }

  public toggle = () => {
    const isOpen = !this.state.isOpen;
    this.setState({
      isOpen,
      standardSeach: {...emptyStandardSearch },
      debtorsTable: {...emptyComponentTable }
    })

    if (!isOpen) {
      this.props.onClose();
    }
  }

  private updateStandardSearch = (standardSeach: IStandardSearch) => {
    this.setState({
      standardSeach
    })
  }

  private searchDebtors = async () => {
    this.setState({ isLoading: true })
    const debtorsTable = await CreateNewCaseApiClient.searchForDebtors(this.state.standardSeach, this.props.moduleInstanceId);
    this.setState({ 
      isLoading: false,
      debtorsTable
    })
  }

  private onDebtorSelect = async (domainId: string) => {
    this.setState({ isLoading: true })
    const debtorDetails: IDebtorDetails = await CreateNewCaseApiClient.getDebtorDetails(domainId);
    this.props.onDebtorSelected(debtorDetails);
    this.setState({ isLoading: false })
  }
}
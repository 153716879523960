import * as React from 'react';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { Redirect } from 'react-router';

interface IProps {
    caseNumber: string;
    startFromBeginning(): void;
}

interface IState {
    redirectToCase: boolean;
}

class Done extends React.Component<IProps, IState> {
    public state: IState = {
        redirectToCase: false
    }

    public render() {
        return (
            <>
                { this.state.redirectToCase ? 
                    <Redirect push={true} to={`case?id=${this.props.caseNumber}`} />
                : '' }
                <h6 className="create-case-header">{TranslationService.translateModule('DoneSectionHeader', ModuleNamesList.CreateNewCase)}</h6>
                <p className="mt-2" dangerouslySetInnerHTML={{__html:TranslationService.translateModule('DoneSectionMessage', ModuleNamesList.CreateNewCase)}}/>
                <hr className="mt-2 mb-3"/>
                <StepListWizardButtons 
                    leftButtonOnClick={this.props.startFromBeginning}
                    leftButtonClassName="float-left small text-transform-none"
                    leftButtonText={<>
                        <i className="fas fa-folder-plus left"/>
                        {' '+TranslationService.translateModule('DoneLeftButtonText', ModuleNamesList.CreateNewCase)}
                    </>}
                    rightButtonOnClick={() => this.setState({redirectToCase: true})}
                    rightButtonClassName="c-btn--icon-right"
                    rightButtonText={this.props.caseNumber!== null ? <>{TranslationService.translateModule('DoneRightButtonText', ModuleNamesList.CreateNewCase)}</> : undefined}
                />
            </>
        );
    }
}

export default Done;
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { InvoicesColumns } from '../types/InvoicesColumns';

class InvoicesTableConfigurator {
    public getInvoicesListTableColumns = (columns: IComponentTableColumn[], automaticDistribution: boolean) => {
        columns = columns.map(c => ({...c, editable: false}));

        this.configureDateColumns(columns);
        this.configureCurrencyColumns(columns);
        this.addOrderEditableColumn(columns, automaticDistribution);

        return columns;
    }

    private addOrderEditableColumn = (columns: IComponentTableColumn[], automaticDistribution: boolean) => {
        columns.push({
            dataField: 'order',
            text: TranslationService.translateModule('Order', ModuleNamesList.DirectPayment),
            editable: !automaticDistribution,
            sort: false,
            classes: (cell: any, row: any, rowIndex: number, colIndex: number) => !automaticDistribution ? 'invoices-table__cell--editable' : '',
        } as IComponentTableColumn);
    }

    private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
        const currencyColumns = [InvoicesColumns.OriginalAmount, InvoicesColumns.Movement, InvoicesColumns.RemainingAmount];
        const currencyColumnsNames = currencyColumns.map(col => InvoicesColumns[col].charAt(0).toLowerCase() + InvoicesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return CurrencyParser.toLocaleString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = [InvoicesColumns.DueDate, InvoicesColumns.RegDate];
        const dateColumnNames = dateColumns.map(col => InvoicesColumns[col].charAt(0).toLowerCase() + InvoicesColumns[col].slice(1));
        const cols = columns.filter(col => dateColumnNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return DateTimeParser.toLocaleDateString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }
}

export default new InvoicesTableConfigurator();
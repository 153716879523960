export enum ActivitiesColumns {
    Date = 0,
    Description = 1,
    Paid = 2,
    Capital = 3,
    Interest = 4,
    LegalFee = 5,
    Fee1 = 6,
    Fee2 = 7,
    NoteCode = 8,
    NoteText = 9
}
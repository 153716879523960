import * as React from 'react';
import ReactModal from 'react-modal';
import { Status } from '../../core/api/Enums/Status';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import Loader from '../../core/components/Loading/Loader';
import { IOrderByType } from '../../core/Enums/IOrderByType';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
// import { PermissionNames } from '../../core/helpers/PermissionNames';
import { addFromUserPrefix } from '../../core/helpers/utils';
import { EventsList } from '../../core/lists/EventsList';
// import CreditorAccessValidator from '../../core/services/CreditorAccessValidator';
import InfoMessageService from '../../core/services/InfoMessageService';
import UserIdentityService from '../../core/services/UserIdentityService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import ConversationsList from './components/ConversationsList';
import DebtorDetails from './components/DebtorDetails';
import MessagesList from './components/MessagesList';
import NewMessageForm from './components/NewMessageForm';
import { NewMessageModal } from './components/NewMessageModal';
import { IConversationsOrderByType } from './enums/IConversationsOrderByType';
import { IMessageFolderType } from './enums/IMessageFolderType';
import { IMessageType } from './enums/IMessageType';
import MessagesService from './MessagesService';
import { IConversationListElement } from './types/IConversationListElement';
import { IMessage } from './types/IMessage';
import { IMessagesState, emptyMessageState } from './types/IMessagesState';
import { INewConversationRequest } from './types/INewConversationRequest';
import { INewMessage } from './types/INewMessage';
import { INewMessageRequest } from './types/INewMessageRequest';
import { ISearchConversationsRequest } from './types/ISearchConversationsRequest';
import './_MessagesComponent.scss';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';

ReactModal.setAppElement('#root');

class Messages extends React.Component<IExtendedModuleProps, IMessagesState> {
  public state: IMessagesState = { ...emptyMessageState };
  public folderType: IMessageFolderType = IMessageFolderType.Inbox;
  public messagesContainer: any = React.createRef();
  public userIdentityService: UserIdentityService = new UserIdentityService();

  private module: any = flatModuleOptions<any>(this.props.module as any);
  private moduleEvents: IModuleEventSubscription[] = [];

  public updateCurrentConversation = async (
    elem: IConversationListElement,
    setContainerClass: boolean = true
  ) => {
    this.setState({
      isCurrentConversationLoading: true,
    });

    const conversation = await MessagesService.getConversation(
      elem.id,
      elem.caseDetails.creditor.number
    );

    this.setState({
      currentConversation: conversation,
      currentConversationIsMobileOpened: !!setContainerClass,
      isCurrentConversationLoading: false,
    });
  };

  public filterConversationsList = async (searchValue: string) => {
    this.setState(
      {
        currentPage: emptyMessageState.currentPage,
        conversations: {
          ...this.state.conversations!,
          values: [],
        },
        searchValue,
      },
      async () => {
        await this.fetchConversations();
      }
    );
  };

  public async componentDidMount() {
    const { folder } = this.props.routeParameters;
    let currentFolder: string = '';

    if (folder && folder.length) {
      currentFolder = folder.toString().toLowerCase();
    } else if (this.module.DefaultFolder) {
      currentFolder = this.module.DefaultFolder.toString().toLowerCase();
    }

    this.folderType = this.getCurrentFolder(currentFolder);
    this.setState({
      moduleSettings: {
        messagesFileSizeLimit: parseInt(this.module.MessagesFileSizeLimit, 10),
        messagesNumOfFilesLimit: parseInt(
          this.module.MessagesNumOfFilesLimit,
          10
        ),
        messagesMinLength: parseInt(this.module.MessagesMinLength, 10),
        messagesMaxLength: parseInt(this.module.MessagesMaxLength, 10),
        isMobileViewOnly: this.module.IsMobileViewOnly === 'true',
      },
    });

    try {
      await this.fetchConversations();
    } finally {
      this.registerModuleEvents();
    }
  }

  public createNewMessage = async (
    message: INewMessage,
    createNewConversation: boolean = false
  ) => {
    const newMessage: IMessage = this.prepareIMessage(message);

    if (createNewConversation) {
      this.setState({
        isAddingNewConversation: true,
      });

      await this.addNewConversation(newMessage);
    } else {
      await this.addMessageToCurrentConversation(newMessage);
      await this.sendMessage(newMessage);
    }
  };

  public changeSortType = (newOrderBy: IConversationsOrderByType) => {
    if (newOrderBy === this.state.orderedBy) {
      this.setState({
        orderDirection:
          this.state.orderDirection === IOrderByType.Ascendent
            ? IOrderByType.Descendent
            : IOrderByType.Ascendent,
      });
    } else {
      this.setState({
        orderDirection: IOrderByType.Descendent,
        orderedBy: newOrderBy,
      });
    }

    this.setState(
      {
        conversations: {
          ...this.state.conversations!,
          values: [],
        },
        currentConversation: undefined,
        currentPage: emptyMessageState.currentPage,
      },
      this.fetchConversations
    );
  };

  public sendMessage = async (
    message: IMessage,
    createNewConversation: boolean = false
  ) => {
    this.setMessageIsSending(message);
    let messageRequest: any;
    messageRequest = createNewConversation
      ? this.prepareNewConversationRequest(message)
      : this.prepareMessageRequest(message);
    const actionStatus = createNewConversation
      ? await MessagesService.createNewConversation(
          messageRequest,
          this.props.module.id
        )
      : await MessagesService.sendNewMessage(
          messageRequest,
          this.props.module.id
        );

    if (!createNewConversation) {
      if (actionStatus.status === Status.Success) {
        if (this.folderType === IMessageFolderType.Inbox) {
          const currentConversationId = this.state.currentConversation!.id || 0;

          this.setState({
            answeredConversationsIds: [
              ...this.state.answeredConversationsIds,
              currentConversationId,
            ],
          });
        }

        this.setMessageSend(
          message.attachments.length
            ? {
                ...message,
                content: `${
                  message.content
                } \r\n\r\n ${TranslationService.translateModule(
                  'FileWasAddedToMessage',
                  this.props.module.name
                )}`,
              }
            : message
        );
      } else if (actionStatus.status === Status.Warning) {
        InfoMessageService.displayActionStatus(actionStatus, true);
        this.setMessageSend(message);
      } else {
        this.setMessageNotSend(message);
      }
    } else {
      InfoMessageService.displayActionStatus(actionStatus, true);
    }
  };

  public sendMessageAgain = async (message: IMessage) => {
    this.removeMessageFromNotSend(message);
    await this.sendMessage(message);
  };

  public componentWillUnmount() {
    ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
  }

  public fetchConversations = async (setFirstActive: boolean = true) => {
    this.setState({
      isConversationsLoading: true,
    });

    let newState: any = {};
    let creditorData = null;
    let currentConversationsList = this.state.conversations;
    const request: ISearchConversationsRequest = {
      folderType: this.folderType,
      moduleInstanceId: this.props.module.id,
      orderedBy: this.state.orderedBy,
      orderDirection: this.state.orderDirection,
      page: this.state.currentPage,
      searchValue: this.state.searchValue,
      caseNumber: this.isInCaseContext()
        ? (this.props.routeParameters.id as string)
        : '',
    };
    const result = await MessagesService.getConversations(request);
    const conversations = result.table;

    if (conversations.values.length < this.state.conversationsPageSize) {
      this.setState({ isConversationsLimit: true });
    }

    if (this.isInCaseContext()) {
      creditorData = await MessagesService.getCreditorData(
        this.props.routeParameters.id as string
      );
    }

    if (currentConversationsList === undefined) {
      currentConversationsList = conversations;
    } else {
      currentConversationsList.values = currentConversationsList.values.concat(
        conversations.values
      );
    }

    newState.conversations = currentConversationsList;
    newState.isConversationsLoading = false;
    newState.conversationsPageSize = result.pageSize;

    if (creditorData !== null) {
      newState = { ...newState, creditor: creditorData };
    }

    this.setState(newState);

    if (conversations.values.length > 0 && setFirstActive) {
      await this.updateCurrentConversation(conversations.values[0], false);
    }
  };

  public fetchNextPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => this.fetchConversations(false)
    );
  };

  public render() {
    return this.getMessagesComponent(
      this.isInCreatingNewConversationOnlyMode()
    );
  }

  private getMessagesComponent = (createNewConversationOnly: boolean) => {
    if (createNewConversationOnly) {
      return (
        <NewMessageForm
          sendNewMessage={(message) => this.createNewMessage(message, true)}
          prefix="messages"
          isAddingNewConversation={this.state.isAddingNewConversation}
          messageModuleSettings={this.state.moduleSettings}
        />
      );
    } else {
      return (
        <>
          <article
            className={`l-module m-messages ${
              this.state.moduleSettings.isMobileViewOnly
                ? 'm-messages--mobile'
                : 'm-messages--standard'
            } ${this.state.currentConversationIsMobileOpened ? 'c-view' : ''}`}
          >
            {this.isMessageBoxFolderTitleVisible() ? (
              <section className="l-module__header">
                <button
                  type="button"
                  onClick={this.mobileBackToList}
                  className="btn btn-conversation-back animated fadeIn"
                >
                  <i className="fas fa-arrow-left" />
                </button>

                <h1 className="l-module__title">
                  <i className="far fa-envelope mr-2" />
                  {TranslationService.translateModule(
                    'MessagesHeader',
                    ModuleNamesList.Messages
                  )}
                  :
                  <strong className="l-module__title-highlighted">
                    {this.getCurrentFolderName()}
                  </strong>
                </h1>
              </section>
            ) : (
              <section className="l-module__header l-module__header--transparent">
                <button
                  type="button"
                  onClick={this.mobileBackToList}
                  className="btn btn-conversation-back animated fadeIn"
                >
                  <i className="fas fa-arrow-left" />
                </button>
              </section>
            )}

            <section className="l-module__section l-module__section--full-height">
              <div className="animated fadeIn">
                <div className="row ">
                  <div
                    className={`col-12 ${
                      this.state.moduleSettings.isMobileViewOnly
                        ? ''
                        : 'col-lg-7'
                    }  ${
                      this.state.currentConversationIsMobileOpened
                        ? 'c-view'
                        : ''
                    }`}
                  >
                    <ConversationsList
                      folderType={this.folderType}
                      staticHeight={this.props.staticHeight}
                      className={`${
                        this.props.module.DefaultFolder ===
                        IMessageFolderType[IMessageFolderType.Case]
                          ? 'm-conversations--in-tab'
                          : null
                      }`}
                      isHeaderVisible={this.isMessageBoxFolderTitleVisible()}
                      isConversationsLoading={this.state.isConversationsLoading}
                      filterConversationsList={this.filterConversationsList}
                      currentConversation={this.state.currentConversation}
                      conversationsTable={this.state.conversations}
                      fetchNextPage={this.fetchNextPage}
                      mobileBackToList={this.mobileBackToList}
                      currentConversationIsMobileOpened={
                        this.state.currentConversationIsMobileOpened
                      }
                      orderedBy={this.state.orderedBy}
                      changeSortType={this.changeSortType}
                      orderDirection={this.state.orderDirection}
                      onRowClick={this.updateCurrentConversation}
                      enableAddNewConversation={this.isAddNewMessageToCaseButtonVisible()}
                      onAddNewConversation={this.onAddNewConversation}
                      isConversationsLimit={this.state.isConversationsLimit}
                      answeredConversationsIds={
                        this.state.answeredConversationsIds
                      }
                    />
                  </div>

                  <div
                    className={`col-12 ${
                      this.state.moduleSettings.isMobileViewOnly
                        ? ''
                        : 'col-lg-5'
                    } pr-0`}
                  >
                    {this.state.isCurrentConversationLoading ? (
                      <Loader hideContent={true} />
                    ) : this.state.currentConversation ? (
                      <div
                        className={`m-conversation-messages 
                                                    ${
                                                      this.props.module
                                                        .DefaultFolder ===
                                                      IMessageFolderType[
                                                        IMessageFolderType.Case
                                                      ]
                                                        ? 'm-conversation-messages--in-tab'
                                                        : ''
                                                    }
                                                    ${
                                                      this.props.staticHeight
                                                        ? 'm-conversation-messages--full-height'
                                                        : ''
                                                    }
                                                    ${
                                                      this.state
                                                        .currentConversationIsMobileOpened
                                                        ? 'c-view animated fadeIn'
                                                        : ''
                                                    }
                                                    ${
                                                      !this.state
                                                        .currentConversationIsMobileOpened
                                                        ? 'non-active-hidden'
                                                        : ''
                                                    }`}
                      >
                        {this.isDebtorAndCreditorDetailsVisible() ? (
                          <DebtorDetails
                            currentConversation={this.state.currentConversation}
                          />
                        ) : null}
                        <MessagesList
                          staticHeight={this.props.staticHeight}
                          messages={this.state.currentConversation.messages}
                          currentMessagesInSendingProcess={
                            this.state.messagesInSendingProcess
                          }
                          messagesNotSend={this.state.messagesNotSend}
                          sendMessageAgain={this.sendMessageAgain}
                        />
                        {this.folderType !== IMessageFolderType.Archive && (
                          <NewMessageForm
                            isAddingNewConversation={
                              this.state.isAddingNewConversation
                            }
                            sendNewMessage={this.createNewMessage}
                            prefix="messages"
                            messageModuleSettings={this.state.moduleSettings}
                          />
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <NewMessageModal
                  show={this.state.isModalOpen}
                  messageModuleSettings={this.state.moduleSettings}
                  onClose={() => this.setState({ isModalOpen: false })}
                  isAddingNewConversation={this.state.isAddingNewConversation}
                  onSendNewMessage={async (message) =>
                    await this.createNewMessage(message, true)
                  }
                />
              </div>
            </section>
          </article>
        </>
      );
    }
  };

  private getCurrentFolderName = () => {
    switch (this.folderType) {
      case IMessageFolderType.Inbox:
        return TranslationService.translateModule(
          'Inbox',
          ModuleNamesList.Messages
        );
      case IMessageFolderType.Sent:
        return TranslationService.translateModule(
          'Sent',
          ModuleNamesList.Messages
        );
      case IMessageFolderType.Archive:
        return TranslationService.translateModule(
          'Archive',
          ModuleNamesList.Messages
        );
      case IMessageFolderType.Overdue:
        return TranslationService.translateModule(
          'Overdue',
          ModuleNamesList.Messages
        );
      case IMessageFolderType.Case:
        return TranslationService.translateModule(
          'Case',
          ModuleNamesList.Messages
        );
      default:
        return TranslationService.translateModule(
          'Inbox',
          ModuleNamesList.Messages
        );
    }
  };

  private isAddNewMessageToCaseButtonVisible = () =>
    this.module.IsAddNewMessageToCaseButtonVisible &&
    this.module.IsAddNewMessageToCaseButtonVisible === '1';
  private isDebtorAndCreditorDetailsVisible = () =>
    this.module.IsDebtorAndCreditorDetailsVisible &&
    this.module.IsDebtorAndCreditorDetailsVisible === '1';
  private isMessageBoxFolderTitleVisible = () =>
    this.module.IsMessageBoxFolderTitleVisible &&
    this.module.IsMessageBoxFolderTitleVisible === '1';

  private isInCaseContext = () =>
    this.module.DefaultFolder.toString().toLowerCase() ===
    IMessageFolderType[IMessageFolderType.Case].toString().toLowerCase();
  private isInCreatingNewConversationOnlyMode = () =>
    this.module.CreateNewConversationOnlyMode === 'true';

  private getEntityReferenceNumber = () => {
    if (this.isInCaseContext()) {
      return this.props.routeParameters.id as string;
    }

    if (this.isInCreatingNewConversationOnlyMode()) {
      return this.props.accountNo as string;
    }

    return '';
  };

  private getCreditorDetails = () => {
    if (this.isInCaseContext()) {
      return this.state.creditor;
    }

    return this.state.currentConversation!.caseDetails.creditor;
  };

  private onAddNewConversation = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  private mobileBackToList = () => {
    this.setState({
      currentConversationIsMobileOpened: false,
    });
  };

  private fetchConversationsForNewCreditors = () => {
    this.setState(
      {
        currentPage: emptyMessageState.currentPage,
        orderDirection: IOrderByType.Descendent,
        orderedBy: IConversationsOrderByType.DueDate,
        conversations: {
          ...this.state.conversations!,
          values: [],
        },
      },
      this.fetchConversations
    );
  };

  private registerModuleEvents() {
    this.moduleEvents = [
      ...this.moduleEvents,
      {
        name: EventsList.CHANGED_CURRENT_CREDITOR,
        callback: this.fetchConversationsForNewCreditors,
      },
    ];
    ModuleEventSubscriber.registerEvents(this.moduleEvents);
  }

  private prepareIMessage = (message: INewMessage): IMessage => ({
    createDate: new Date().toJSON(),
    content: addFromUserPrefix() + ' ' + message.content,
    senderDetails: this.getCreditorConversationName(),
    type: IMessageType.Send,
    sendCopyToEmail: message.sendCopyToEmail,
    attachments: message.attachedFiles,
  });

  private prepareNewConversationRequest = (
    message: IMessage
  ): INewConversationRequest => {
    const newMessage: INewMessage = {
      content: message.content,
      sendCopyToEmail: message.sendCopyToEmail
        ? message.sendCopyToEmail
        : false,
      attachedFiles: message.attachments,
    };

    return {
      isArm: this.isInCreatingNewConversationOnlyMode(),
      message: newMessage,
      creditorNumber: this.getCreditorDetails().number,
      caseNumber: this.getEntityReferenceNumber(),
    };
  };

  private prepareMessageRequest = (message: IMessage): INewMessageRequest => {
    const newMessage: INewMessage = {
      content: message.content,
      sendCopyToEmail: message.sendCopyToEmail
        ? message.sendCopyToEmail
        : false,
      attachedFiles: message.attachments,
    };

    return {
      message: newMessage,
      creditorNumber: this.getCreditorDetails().number,
      conversationId: this.state.currentConversation!.id,
    };
  };

  private removeMessageFromIsSendingProcess = (message: IMessage) => {
    const currentMessagesInSendingProcess = this.state.messagesInSendingProcess;
    const index = currentMessagesInSendingProcess.indexOf(message);
    currentMessagesInSendingProcess.splice(index, 1);

    this.setState({
      messagesInSendingProcess: currentMessagesInSendingProcess,
    });
  };

  private removeMessageFromNotSend = (message: IMessage) => {
    const currentMessagesNotSend = this.state.messagesNotSend;
    const index = currentMessagesNotSend.indexOf(message);
    currentMessagesNotSend.splice(index, 1);

    this.setState({
      messagesNotSend: currentMessagesNotSend,
    });
  };

  private removeMessageFromCurrentConversation = (message: IMessage) => {
    const currentConversationMessages =
      this.state.currentConversation!.messages;
    const index = currentConversationMessages.indexOf(message);
    currentConversationMessages.splice(index, 1);

    this.setState({
      currentConversation: {
        ...this.state.currentConversation!,
        messages: currentConversationMessages,
      },
    });
  };

  private addNewConversation = async (message: IMessage) => {
    await this.sendMessage(message, true);

    this.setState({
      conversations: undefined,
      isModalOpen: false,
      isAddingNewConversation: false,
    });

    await this.fetchConversations();
  };

  private addMessageToCurrentConversation = async (message: IMessage) => {
    const currentConversationMessages =
      this.state.currentConversation!.messages;
    currentConversationMessages.push(message);

    this.setState(
      {
        currentConversation: {
          ...this.state.currentConversation!,
          messages: currentConversationMessages,
        },
      },
      () => {
        ModuleEventSubscriber.emitEvent({
          name: EventsList.MESSAGES_SEND_NEW_MESSAGE,
        });
      }
    );
  };

  private setMessageIsSending = (message: IMessage) => {
    const currentMessagesInSendingProcess = this.state.messagesInSendingProcess;
    currentMessagesInSendingProcess.push(message);
    this.setState({
      messagesInSendingProcess: currentMessagesInSendingProcess,
    });
  };

  private setMessageSend = (message: IMessage) => {
    message.createDate = new Date().toJSON();
    this.removeMessageFromCurrentConversation(message);
    this.addMessageToCurrentConversation(message);

    const currentMessagesInSendingProcess = this.state.messagesInSendingProcess;
    const index = currentMessagesInSendingProcess.indexOf(message);
    currentMessagesInSendingProcess.splice(index, 1);

    this.setState({
      messagesInSendingProcess: currentMessagesInSendingProcess,
    });
  };

  private setMessageNotSend = (message: IMessage) => {
    this.removeMessageFromIsSendingProcess(message);
    const currentMessagesNotSend = this.state.messagesNotSend;
    currentMessagesNotSend.push(message);

    this.setState({
      messagesNotSend: currentMessagesNotSend,
    });
  };

  private getCreditorConversationName() {
    return this.getCreditorDetails().name;
  }

  // private hasWriteAccess = (creditorNumber: string): boolean => {
  //     return CreditorAccessValidator.hasPermissionTo(creditorNumber, PermissionNames.CanWriteMessages);
  // };

  private getCurrentFolder = (folderName: string): IMessageFolderType => {
    switch (folderName) {
      case 'inbox':
        return IMessageFolderType.Inbox;
      case 'sent':
        return IMessageFolderType.Sent;
      case 'archive':
        return IMessageFolderType.Archive;
      case 'overdue':
        return IMessageFolderType.Overdue;
      case 'case':
        return IMessageFolderType.Case;
      default:
        return IMessageFolderType.Inbox;
    }
  };
}

export default Messages;

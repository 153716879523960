import * as React from 'react';
import { ILayoutProps } from '../types/ILayoutProps';
import { SkinFactory } from '../factories/SkinFactory';
import StickyFooter from '../../modules/StickyFooter/StickyFooter';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import Topbar from '../../modules/Topbar/Topbar';
import StyleWrapper from '../../core/components/StyleWrapper/StyleWrapperHOC';

export interface IDCAFullWidthLayoutWithDynamicHeight {
    isMobileMenuExpanded: boolean
}

class DCAFullWidthLayoutWithDynamicHeight extends React.Component<ILayoutProps, IDCAFullWidthLayoutWithDynamicHeight>{
    public state = {
        isMobileMenuExpanded : false
    };

    public onClickHamburgerMenu = (newState: boolean) => {
        this.setState({
            isMobileMenuExpanded: newState
        })
    };
    
    public render() {
        return (
            <div className="layout">
                <Topbar />
                <Header isDcaProfile={true} onClickMobileTogglerMenu={this.onClickHamburgerMenu}/>

                <div className="layout__main">
                    <Sidebar {...this.props} isMobileMenuExpanded={this.state.isMobileMenuExpanded}/>
            
                    <div className="layout__skin">
                        <div className="layout__skin-body">
                            <SkinFactory skin={this.props.layout.skin} routeParameters={this.props.routeParameters} />
                        </div>
                        <div className="layout__skin-footer">
                            <StickyFooter />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styleConfig = [
  {
      searchMethod: 'module',
      instructions: {
          backgroundColor: '.layout'
      }
  }
]

export default StyleWrapper('module:skin', styleConfig)(DCAFullWidthLayoutWithDynamicHeight);
import React from 'react';

type SelectLabelPropsType = {
    children: React.ReactChild
}

const SelectLabel = (props: SelectLabelPropsType) => {
    return (
        <>{props.children}</>
    );
}

export default SelectLabel;
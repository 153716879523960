import * as React from 'react';
import { useEffect, useState } from 'react';
import { IDictionary, INumberDictionary } from '../../../core/types/IDictionary';

interface IProps {
  elements: INumberDictionary<string> | IDictionary<string | boolean | number>[];
  asArray?: boolean;
  name: string;
  value: string;
  disabled?: boolean;
  onChange?(value: string): void;
}

const RadioSelectBoxes = (props: IProps) => {
  const [elements, setElements] = useState<any[]>([]);

  useEffect(() => {
    let elementComputed = []; 

    if (props.asArray) {
      elementComputed = (
        props.elements as IDictionary<string | number>[]
      ).map((element) => {
        return {
          key: element.key.toString(),
          value: element.value,
          disabled: element.disabled
        };
      });
    } else {
      elementComputed = Object.getOwnPropertyNames(props.elements).map(
        (key: string, index: number) => {
          return {
            key,
            value: props.elements[key],
            disabled: props.disabled
          };
        }
      );
    }

    setElements(elementComputed);
  }, []);

  return (
    <>
      <ul className="radio-small-boxes">
        {elements.map((element, index) => {
          return (
            <li
              key={index}
              className={`${element.disabled ? 'disabled' : ''} ${
                element.key === props.value ? 'active' : ''
              }`}
            >
              <label>
                {element.value}
                <input
                  type="radio"
                  name={props.name}
                  value={element.key}
                  disabled={element.disabled}
                  checked={element.key === props.value}
                  onChange={(event: any) =>
                    props.onChange !== undefined
                      ? props.onChange(event.target.value)
                      : undefined
                  }
                  className="form-check-input"
                />
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default RadioSelectBoxes;

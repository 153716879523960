import React from 'react';
import { ChromePicker } from 'react-color';

interface IProps {
    name: string,
    id?: string,
    value: string,
    handleChange: (value: string) => void
}

interface IState {
    displayColorPicker: boolean,
    displayRemoveBtn: boolean,
    colorPicekr: string,
}

class ColorPickerInput extends React.Component<IProps, IState> {
    state: IState = {
        displayColorPicker: false,
        displayRemoveBtn: false,
        colorPicekr: ''
    }

    componentDidMount = () => {
        if (this.props.value) {
            this.setState({
                displayRemoveBtn: true,
                colorPicekr: this.props.value
            });
        }
    }

    render() {

        return (
            <div className="c-control">
                <div className="c-control__input-group">
                    <input 
                        type='text'
                        id={this.props.id || this.props.name}
                        className="form-control c-control__input--picker" 
                        value={this.state.colorPicekr} 
                        name={this.props.name}
                        onClick={this.handleClick}
                        onChange={() => {}}
                        readOnly
                    />

                    {this.state.displayRemoveBtn &&
                        <button type="button" className="c-control__input-icon" onClick={this.clear}>
                            <i className="fas fa-times"></i>
                        </button>
                    }

                    <div className="c-control__color-picker" style={{ backgroundColor: this.state.colorPicekr}}></div>

                    {this.state.displayColorPicker && 
                        <div className="c-color-picker">
                            <div className="c-color-picker__cover" onClick={() => this.toggleDisplayColorPicker(false)}>

                            </div>
                            <ChromePicker 
                                color={this.state.colorPicekr}
                                onChange={this.handleChangeColorPicker}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }

    clear = () => {
        this.setState({ 
            colorPicekr: '',
            displayRemoveBtn: false
        }, () => this.props.handleChange(''));
    }
    
    toggleDisplayColorPicker = (value: boolean) => {
        if (!value) {
            this.props.handleChange(this.state.colorPicekr);
        }

        this.setState({ displayColorPicker: value });
    }

    handleChangeColorPicker = ({ hex }: any) => {
        this.setState((prevState: IState) => {
            return {
                colorPicekr: hex, 
                displayRemoveBtn: true
            }
        });
    }

    handleClick = () => {
        this.setState({ displayColorPicker: true });
    }
}

export default ColorPickerInput;
import { IModuleEvent } from '../types/IModuleEvent';
import { IModuleEventSubscription } from '../types/IModuleEventSubscription';
import { eventEmmiter } from '../utils';

interface ISub {
    unsubscribe: () => void;
}

interface IModuleSub {
    moduleEventSubscription: IModuleEventSubscription,
    sub: ISub
}

class ModuleEventSubscriber {
    private moduleSubscriptions: IModuleSub[] = [];
    private eventEmitter = eventEmmiter;

    public emitEvent(moduleEvent: IModuleEvent) {
        this.eventEmitter.emit(moduleEvent.name, moduleEvent.data);
    }

    public registerEvents(moduleEvents: IModuleEventSubscription[]) {
        moduleEvents.forEach((eventSubscription: IModuleEventSubscription) => {
            const subscript = this.eventEmitter.on(eventSubscription.name, eventSubscription.callback);
            this.moduleSubscriptions.push({ moduleEventSubscription: eventSubscription, sub: subscript });
        });
    }

    public unsubscribeEvents(moduleEvents: IModuleEventSubscription[]) {
        this.moduleSubscriptions.filter(s => moduleEvents.indexOf(s.moduleEventSubscription) >= 0).forEach(s => s.sub.unsubscribe());
    }
}

export default new ModuleEventSubscriber();
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import fetchTilesCounters from "../../actionCreators/fetchTilesCounters";
import { ICaseCounters } from "../../core/api/types/ICaseCounters";
import Loader from "../../core/components/Loading/Loader";
import withAbortRequest, { AbortRequestPropsType } from "../../core/hoc/AbortRequest";
import TranslationService from "../../core/services/TranslationService";
import UserIdentityService from "../../core/services/UserIdentityService";
import { IModuleProps } from "../../core/types/IModuleProps";
import { IStore } from "../../reducers/IStore";
import CardWithIcon from "./CardWithIcon/CardWithIcon";
import Icons from "./common/Icons";
import TilesService from "./services/TilesService";
import { ITilesTableModuleSettings } from "./types";

const userIdentityService = new UserIdentityService();

type TilesPropsType = IModuleProps &
  AbortRequestPropsType & {
    fetchCasesCounters: (creditorsUuid?: string) => void;
    currentCreditors: string[];
    tilesCounters: any;
    fetchTilesCounters: any;
  };

const Tiles = (props: TilesPropsType) => {
  const [casesCounters, setCasesCounters] = useState<ICaseCounters[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [moduleSettings, setModuleSettings] = useState<ITilesTableModuleSettings>();

  const TilesServiceObj = new TilesService(props.cancelTokenSource.token);

  const fetchData  = async () => {
    setModuleSettings(await TilesServiceObj.getModuleSettings(props.module.id));
    props.fetchTilesCounters();
  }

  const loadTiles = async () => {
    setCasesCounters([]);

      const mappedCounters: ICaseCounters[] = [];

      moduleSettings!.tilesSettings.forEach(element => {
        mappedCounters.push({
          code: element.name,
          counter:
            props.tilesCounters[element.name] !== null ? props.tilesCounters[element.name] : null,
          isVisible: element ? element.isVisible : false,
          order: element ? element.order : 0,
          url: element ? element.url : null,
          permissions: element.permissions
        });
      });

      setCasesCounters(mappedCounters);
      setIsDataLoading(false);
  };

  useEffect(() => {
    setIsDataLoading(true);
    fetchData();
  }, [props.currentCreditors]);

  useEffect(() => {
    if(props.tilesCounters && Object.keys(props.tilesCounters).length !== 0 && moduleSettings) {
      loadTiles();
    }
  }, [props.tilesCounters]);

  return (
    <article
      className={`l-module mt-3 ${isDataLoading ? "l-module--loading" : ""}`}
    >
      {isDataLoading ? (
        <Loader />
      ) : (
        <section className="l-module__section">
          <div className="tiles">
            <div className="info-cards">
              {casesCounters.length &&
                casesCounters
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((item, index) => {
                    const icon = Icons.find(x => x.code === item.code);

                    if (!icon || !item.isVisible) {
                      return null;
                    }

                    if(item.permissions && item.permissions.length > 0)
                    {
                      let hasAtLeastOnePermission = false;
                      item.permissions.forEach(element => {
                        hasAtLeastOnePermission = userIdentityService.hasPermission(element);
                        if(hasAtLeastOnePermission){ return; }
                      });
                      if(!hasAtLeastOnePermission)
                      {
                        return null;
                      }
                    }

                    return (
                      <CardWithIcon
                        icon={icon.icon}
                        color={icon.color}
                        title={TranslationService.translateModule(
                          icon.code,
                          props.module.name
                        )}
                        counter={{ primary: item.counter }}
                        redirectUrl={item.url || undefined}
                        key={index}
                      />
                    );
                  })}
            </div>
          </div>
        </section>
      )}
    </article>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    currentCreditors: state.currentCreditors,
    tilesCounters: state.tilesCounters
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, AnyAction>) => ({
  fetchTilesCounters: () => dispatch(fetchTilesCounters())
})

export default connect(mapStateToProps, mapDispatchToProps)(withAbortRequest(Tiles));

import * as React from 'react';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import TranslationService from '../../core/services/TranslationService';
import { IModuleProps } from '../../core/types/IModuleProps';
import './Welcome.scss';

const WELCOME_IMAGE_URL_OPTION_KEY = 'WelcomeImageUrl';
const WELCOME_MESSAGE_TITLE_TRANSLATION_KEY = 'WelcomeMessageTitle';
const WELCOME_MESSAGE_TRANSLATION_KEY = 'WelcomeMessage';

interface IState {
    imageUrl: string;
}

export default class Welcome extends React.Component<IModuleProps, IState> {
    private module: any = flatModuleOptions<any>(this.props.module as any);

    constructor(props: IModuleProps) {
        super(props);

        if (this.module) {
            const welcomeImageUrlOption = this.module[WELCOME_IMAGE_URL_OPTION_KEY]
            if (welcomeImageUrlOption) {
                this.state = {
                    imageUrl: welcomeImageUrlOption || ''
                }
            }
        }
    }

    public render() {
        return (
            <div className="module">
                <div className="module-body">
                    <h5 className="module-title">{TranslationService.translateModule(WELCOME_MESSAGE_TITLE_TRANSLATION_KEY, this.props.module.name)}</h5>
                    <p>{TranslationService.translateModule(WELCOME_MESSAGE_TRANSLATION_KEY, this.props.module.name)}</p>
                    <figure className="welcome-image-container">
                        <img src={this.state.imageUrl}
                            alt={TranslationService.translateModule(WELCOME_MESSAGE_TITLE_TRANSLATION_KEY, this.props.module.name)}
                            className="welcome-image" />
                    </figure>
                </div>
            </div>
        )
    }
}
import * as React from 'react';
import * as yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { Button, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { InputContainer } from '../../../core/components/Forms/InputContainer';
import { SettingsType } from '../../../core/Enums/SettingsType';
import { IUserPropertyDefinition } from '../types/IUserPropertyDefinition';
import { IUserProperty } from '../types/IUserProperty';
// import { FormFieldset } from '../../../core/components/Forms/FormFieldset';
// import { FORM_GROUP_VARIANTS, FormGroup } from '../../../core/components/Forms/FormGroup';

interface IProps {
    propertyDefinitions: IUserPropertyDefinition[],
    userPropertiesCount: number,
    addProperty: (propDefition: IUserProperty) => void
}

export default class AddPropertyForm extends React.Component<IProps> {
    private validationMessages = {
        required: TranslationService.translate("ValidationMessageRequiredField"),
    }

    private validationSchema = yup.object().shape({
        value: yup.string().required(this.validationMessages.required),
        propertyDefinitionId: yup.number()
            .typeError(this.validationMessages.required)
            .required(this.validationMessages.required)
            .min(1, this.validationMessages.required),
    });

    public render() {
        return (
            <div>
                <Formik
                    initialValues={{value: '', propertyDefinitionId: 0} as IUserProperty}
                    validationSchema={this.validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        const propertyDesc = this.props.propertyDefinitions.find(x => x.id === values.propertyDefinitionId);
                        values.propertyDefinitionDescription = propertyDesc!.description;
                        values.index = this.props.userPropertiesCount + 1;
                        this.props.addProperty(values);
                        resetForm();
                    }}
                    >
                    {({ values,
                        handleChange,
                    }) => {
                        return (
                            <Form>
                                <label className="c-form-group__label">{TranslationService.translateModule('ManageProperties', ModuleNamesList.GroupUsersList)}</label>
                                <div className="row mb-5">
                                    <div className="col-5">
                                        <InputContainer
                                            name="propertyDefinitionId"                                        
                                            type={SettingsType.Select}
                                            value={values.propertyDefinitionId} 
                                            options={this.props.propertyDefinitions.map(item => ({key: item.id, value: item.description}))}
                                            id="choose-property-select"
                                            onChange={(value)=>handleChange({target:{value, name: 'propertyDefinitionId', id: "propertyDefinitionId"}})}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <InputContainer
                                            name={"value"}
                                            type={SettingsType.String}
                                            value={values.value}
                                            id={"value"}
                                            onChange={(value)=>handleChange({target:{value, name: "value", id: "value"}})}
                                        />
                                        <ErrorMessage name={"value"} component="div" className="error error-message" />
                                    </div>
                                    <div className="col-auto">
                                        <Button 
                                            variant={BUTTON_VARIANTS.PRIMARY}
                                            type="submit"
                                            label={TranslationService.translateModule('AddPropertyButton', ModuleNamesList.GroupUsersList)}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        )
    }
}
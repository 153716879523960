import React from 'react';
import { connect } from 'react-redux';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IStore } from '../../reducers/IStore';
import TransferToDebtCollectionApiClient from './api/TransferToDebtCollectionApiClient';
import { IComponentTable, emptyComponentTable } from '../../core/types/IComponentTable';
import { ITransferableInvoice } from './types/ITransferableInvoice';
import Loader from '../../core/components/Loading/Loader';
import { ITransferInvoicesRequest } from './types/ITransferInvoicesRequest';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import TransferInvoicesFilter from './components/TransferInvoicesFilter';
import { ITransferInvoiceFilterRequest, emptyTransferInvoiceFilterRequest } from './types/ITransferInvoiceFilterRequest';
import { ISetInvoicesStatusRequest } from './types/ISetInvoicesStatusRequest';
import InfoMessageService from 'core/services/InfoMessageService';
import { Status } from 'core/api/Enums/Status';
import {InvoicesTable} from "./components/InvoicesTable";
import {TransferType} from "./types/TransferType";
import { INumberDictionary } from 'core/types/IDictionary';

interface IProps extends IModuleProps {
    fetchCasesCounters: (creditorsUuid?: string) => void,
    currentCreditors: string[]
}

interface IState {
    invoicesTable: IComponentTable<ITransferableInvoice>,
    isDataLoading: boolean,
    filterByOptions: INumberDictionary<string>,
    currentPage: number,
    count: number,
    filterRequest: ITransferInvoiceFilterRequest,
    sizePerPage: number,
    sortBy: string,
    sortDirection: string,
    selectedRows: ITransferableInvoice[]
}

class TransferToDebtCollection extends React.Component<IProps, IState> {
    state: IState = {
        invoicesTable: {...emptyComponentTable},
        isDataLoading: true,
        currentPage: 1,
        count: 0,
        filterRequest: {...emptyTransferInvoiceFilterRequest},
        filterByOptions: {},
        sizePerPage: 10,
        sortBy: 'dueDate',
        sortDirection: 'desc',
        selectedRows: []
    };

    componentDidMount = async () => {
        await this.fetchCases()
    };

    render() {
        const { isDataLoading } = this.state;

        return (
            <article className="l-module">
                <section className="l-module__section l-module__section--head">
                    <h1>
                        <i className="fas fa-file-invoice" />
                        {TranslationService.translateModule('TransferToDebtCollectionLeftPartHeader', ModuleNamesList.TransferToDebtCollection)}: 
                        
                        <strong className="l-module__title-highlighted">
                            { TranslationService.translateModule('TransferToDebtCollectionHeader', ModuleNamesList.TransferToDebtCollection )}
                        </strong>
                    </h1>
                </section>

                <section className="l-module__section l-module__section--filter mb-3">
                    <TransferInvoicesFilter
                        filterByOptions={this.state.filterByOptions}
                        filterCases={this.filterCases}
                        baseRequest={this.state.filterRequest}
                    />
                </section>

                <section className={`l-module__section ${isDataLoading ? 'l-module__section--loading' : ''}`}>
                    {isDataLoading &&
                        <Loader opacity={0.5} />
                    }
                    <InvoicesTable
                        count={this.state.count}
                        data={this.state.invoicesTable}
                        sizePerPage={this.state.sizePerPage}
                        sortBy={this.state.sortBy}
                        sortDirection={this.state.sortDirection}
                        fetchCases={this.fetchCases}
                        updateInvoicesStatus={this.updateInvoicesStatus} 
                    />
                </section>
            </article>
        )
    }

    fetchCases = async (currentPage?: number, sortDirection?: string, sortBy?: string) => {
        this.setState({isDataLoading: true})

        const request: ITransferInvoicesRequest = {
            sortDirection: sortDirection || this.state.sortDirection,
            moduleInstanceId: this.props.module.id,
            page: currentPage || this.state.currentPage,
            resultsPerPage: this.state.sizePerPage,
            sortBy: sortBy || this.state.sortBy,
            filter: this.state.filterRequest
        }

        const {
            count,
            table: {
                columns,
                values
            },
            filterByOptions
        } = await TransferToDebtCollectionApiClient.getTransferableInvoicesTable(request);

        this.setState({
            count,
            isDataLoading: false,
            invoicesTable: {
                columns,
                values
            },
            filterByOptions
        })
    }

    filterCases = (filter: ITransferInvoiceFilterRequest) => {
        this.setState({
            filterRequest: filter,
            currentPage: 1
        }, this.fetchCases)
    }

    updateInvoicesStatus = async (transferType: TransferType, selectedRows: ITransferableInvoice[]) => {
        this.setState({
            isDataLoading: true
        })

        const request: ISetInvoicesStatusRequest = {
            moduleInstanceId: this.props.module.id,
            invoices: selectedRows.map((v) => ({...v, transferType}))
        }
        const response = await TransferToDebtCollectionApiClient.updateInvoicesStatus(request);

        if (response.status === Status.Success) {
            this.setState({
                isDataLoading: false,
                currentPage: 1,
                selectedRows: []
            })

            InfoMessageService.success(
                TranslationService.translateModule(
                    'SelectedInvoicesWasChanged',
                    ModuleNamesList.TransferToDebtCollection
                ),
                TranslationService.translate('InfoMessageSuccessTitle')
            )

            await this.fetchCases()
        } else {
            InfoMessageService.displayActionStatus(response)

            this.setState({
                isDataLoading: false
            })
        }
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        currentCreditors: state.currentCreditors
    }
}

export default connect(mapStateToProps)(TransferToDebtCollection);
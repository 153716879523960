import React, { useEffect, useRef } from 'react';
import { useSelectFieldContext } from '../context/SelectFieldContext';
import styles from '../SelectField.module.scss';
// @ts-ignore
import classNames from 'classnames';

type SelectFieldOptionPropsType = {
  id: string;
  value: string | number;
  children: React.ReactNode;
  className?: string;
  selected?: boolean;
  onClick?: (value: any, event: MouseEvent) => void;
};

const SelectFieldOption = (props: SelectFieldOptionPropsType) => {
  const { value, onClick } = useSelectFieldContext();

  const listItemRef = useRef<HTMLLIElement>(null);

  const handleClick = (e: any) => {
    if (props.onClick) {
      props.onClick(props.value, e);
    }

    if (onClick && listItemRef.current) {
      onClick(
        {
          value: props.value,
          name: listItemRef.current.innerText,
        },
        e,
      );
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    if (props.selected) {
      if (onClick && listItemRef.current) {
        onClick(
          {
            value: props.value,
            name: listItemRef.current.textContent,
          },
          listItemRef.current,
        );
      }
    }
    // eslint-disable-next-line
  }, [props.selected]);

  return (
    <li
      id={props.id}
      ref={listItemRef}
      className={classNames(
        styles.listItem,
        props.className,
        value && value === props.value && styles.listItemSelected,
      )}
      onClick={handleClick}
    >
      {props.children}
    </li>
  );
};

export default React.memo(SelectFieldOption);

import * as Oidc from 'oidc-client'  
import { UserManagerSettings, User } from 'oidc-client';
import { IUserDetails } from '../types/IUserDetails';
import ConfigurationService from './ConfigurationService';
import SessionStorageService from './SessionStorageService';

const REDIRECT_URL_SESSION_STORAGE_KEY = 'auth:redirect';

export default class UserIdentityService {

    public manager: Oidc.UserManager | null = null;
    public user: User | null = null;
    public configurationService: ConfigurationService;

    constructor() {
        this.configurationService = new ConfigurationService();
    }

    public IsLoggedIn() : boolean {
        const token = localStorage.getItem('user.access_token');
        if(token === '' || token === null)
        {
            return false;
        }

        const expiredAtString = localStorage.getItem('user.expires_at');
        if(expiredAtString !== null && expiredAtString !== '')
        {
            const expiredAt = parseInt((expiredAtString !== '') ? expiredAtString : '0', 10);
            const now = Math.floor(Date.now() / 1000);
            if(now > expiredAt)
            {
                return false;
            }
        }
        return true;
    }

    public isInRole(roleName: string): boolean {
        return this.GetUserRoles().includes(roleName);
    }

    public hasPermission(permissionName: string, creditorNo?: string[]): boolean {
        const creditorPermissions = this.GetUserPermissions();
        if(creditorPermissions['GLOBAL'] && creditorPermissions['GLOBAL'].includes(permissionName))
        {
            return true;   
        }

        if (creditorPermissions) {
            if (creditorNo && creditorNo.length) {
                for (let cred in creditorPermissions) {
                    if (creditorNo.indexOf(cred) > -1) {
                        if (creditorPermissions[cred].includes(permissionName)) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    public GetAuthorizationHeaderValue(): string {
        const token = localStorage.getItem('user.access_token');
        const tokentype = localStorage.getItem('user.token_type');
        if(token === '' || tokentype === '')
        {
            return '';
        }
        return `${tokentype} ${token}`;
    }

    public updateUserDetails(userDetails: IUserDetails) {
        localStorage.setItem('user.firstname', userDetails.firstname)
        localStorage.setItem('user.lastname', userDetails.lastname)
        localStorage.setItem('user.phone', userDetails.phone)
        localStorage.setItem('user.email', userDetails.email)
        localStorage.setItem('user.locale',  userDetails.locale);
    }

    public async CompleteAuthentication(): Promise<string|null> {
        const redirectUrl = SessionStorageService.get(REDIRECT_URL_SESSION_STORAGE_KEY);
        const manager = await this.getManager();

        this.user = await manager.signinRedirectCallback();

        localStorage.setItem('user.access_token', this.user.access_token)
        localStorage.setItem('user.expires_at', this.user.expires_at.toString())
        localStorage.setItem('user.firstname', this.user.profile.given_name || '')
        localStorage.setItem('user.lastname', this.user.profile.family_name || '')
        localStorage.setItem('user.phone', this.user.profile.phone_number || '')
        localStorage.setItem('user.email', this.user.profile.email || '')
        localStorage.setItem('user.token_type', this.user.token_type)
        localStorage.setItem('user.id', this.user.profile.id)
        localStorage.setItem('user.roles',  JSON.stringify(this.user.profile.roles));
        localStorage.setItem('user.locale',  this.user.profile.locale || '');

        SessionStorageService.remove(REDIRECT_URL_SESSION_STORAGE_KEY);

        return redirectUrl;
    }

    public async CompleteSilentAuthentication(): Promise<any> {
        const manager = await this.getManager();
        await manager.signinSilentCallback();
    }

    public GetUserId(): string {
        return localStorage.getItem('user.id') || '';
    }

    public GetUserDetails() : IUserDetails {
        return {
            firstname: localStorage.getItem('user.firstname') || '',
            lastname: localStorage.getItem('user.lastname') || '',
            phone: localStorage.getItem('user.phone') || '',
            email: localStorage.getItem('user.email') || '',
            locale: localStorage.getItem('user.locale') || '',
        }
    }

    public GetUserPermissions() : { [id: number] : string[]; } {
        const storedData = localStorage.getItem('user.permissions');
        if (storedData) {
            return JSON.parse(storedData);
        } 
        return [];
    }

    public GetUserRoles() : any[] {
        const storedData = localStorage.getItem('user.roles');
        if (storedData && storedData !== 'undefined') {
            return JSON.parse(storedData);
        } 
        return [];
    }

    public async StartAuthentication(redirectUrl: string): Promise<void> {
        SessionStorageService.set(REDIRECT_URL_SESSION_STORAGE_KEY, redirectUrl);

        const manager = await this.getManager();
        await manager.signinRedirect();
    }

    public async Logout(): Promise<void>
    {
        const manager = await this.getManager();
        await manager.signoutRedirect();
        localStorage.setItem('user.access_token', '')
        localStorage.setItem('user.expires_at', '')
        localStorage.setItem('user.firstname', '')
        localStorage.setItem('user.lastname', '')
        localStorage.setItem('user.email', '')
        localStorage.setItem('user.phone', '')
        localStorage.setItem('user.token_type', '')
        localStorage.setItem('user.id', '')
        localStorage.setItem('user.locale',  '');
        localStorage.setItem('user.permissions',  '');
        localStorage.setItem('user.roles',  '');

        SessionStorageService.clear();
    }

    public async getManager(): Promise<Oidc.UserManager> {
        if(this.manager === null)
        {
            this.manager = new Oidc.UserManager(await this.GetClientSettings());
            this.manager!.getUser().then(user => {
                this.user = user;
            });
        }
        return this.manager;
    }

    private async GetClientSettings(): Promise<UserManagerSettings> {
        let websiteUrl = await this.configurationService.getWebsiteUrl();
        if(!websiteUrl.endsWith('/'))
        {
            websiteUrl += '/';
        }
        return {
            authority: await this.configurationService.getIdentityServerUrl(),
            client_id: "waypoint_spa",
            filterProtocolClaims: true,
            loadUserInfo: true,
            post_logout_redirect_uri : websiteUrl,
            redirect_uri: websiteUrl + "auth/callback",
            response_type: "code",
            scope:"openid profile waypoint_api IdentityServerApi",
            automaticSilentRenew: false,
            silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent-renew`,
            monitorSession: true,
        };
    }
}

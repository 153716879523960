import { store } from '../../index';
import moment from 'moment';

import 'moment/locale/nb';
import 'moment/locale/da';
import 'moment/locale/sv';

class DateTimeParser
{
    public toLocaleDateString(date: string | Date | null): string {
        if(date === null)
        {
            return '';
        }
        const dateObj = typeof(date) === 'string' ? new Date(date) : date;
        
        return moment(dateObj).locale(DateTimeParser.getCurrentCultureCode()).format('L');
    }

    public toLocaleTimeString(jsonFormatDateString: string): string {
        const date = new Date(jsonFormatDateString);
        return moment(date).locale(DateTimeParser.getCurrentCultureCode()).format('LT');
    }

    public toLocaleDateTimeString(jsonFormatDateString: string): string {
        return this.toLocaleDateString(jsonFormatDateString) + ' ' + this.toLocaleTimeString(jsonFormatDateString);
    }

    public toDateFormat(date: string, format: string): string {
        const localeFormat = moment.localeData(DateTimeParser.getCurrentCultureCode()).longDateFormat('L');
        return moment(date, localeFormat).format(format);
    }

    public isValidFormat(date: string, format: string): boolean {
        if (moment(date, format).format(format) === date) {
            return true;
        } 
        return false;
    }

    public static getCurrentCultureCode() : string {
        const state = store.getState();
        return state.currentCultureCode;
    }
}

export default new DateTimeParser();
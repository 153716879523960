import CurrencyParser from '../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { InvoicesColumns } from '../types/InvoicesColumns';
import { PaymentMethod } from '../types/PaymentMethod';

class InvoicesTableConfigurator {
    public getInvoicesListTableColumns = (paymentMethod: PaymentMethod, automaticDistribution: boolean) => {
        const columns: IComponentTableColumn[] = Object.keys(InvoicesColumns).filter(c => c !== InvoicesColumns[InvoicesColumns.CaseNo]).filter(k => typeof InvoicesColumns[k as any] === "number").map(k => ({
            dataField: k.charAt(0).toLowerCase() + k.slice(1),
            sort: true,
            editable: false,
            text: TranslationService.translateModule(k, ModuleNamesList.DirectPayment)
        } as IComponentTableColumn));
        this.configureDateColumns(columns);
        this.configureCurrencyColumns(columns);
        if (paymentMethod === PaymentMethod.CreditNote) {
            this.addAmountEditableColumn(columns);
        } else {
            this.addOrderEditableColumn(columns, automaticDistribution);
        }

        return columns;
    }

    private addOrderEditableColumn = (columns: IComponentTableColumn[], automaticDistribution: boolean) => {
        columns.push({
            dataField: 'order',
            text: TranslationService.translateModule('Order', ModuleNamesList.DirectPayment),
            editable: !automaticDistribution,
            sort: false,
            classes: (cell: any, row: any, rowIndex: number, colIndex: number) => !automaticDistribution ? 'invoices-table__cell--editable' : '',
        } as IComponentTableColumn);
    }

    private addAmountEditableColumn = (columns: IComponentTableColumn[]) => {
        columns.push({
            dataField: 'payAmount',
            text: TranslationService.translateModule('Amount', ModuleNamesList.DirectPayment),
            editable: true,
            classes: (cell: any, row: any, rowIndex: number, colIndex: number) => 'invoices-table__cell--editable',
            sort: false
        } as IComponentTableColumn);
    }

    private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
        const currencyColumns = [InvoicesColumns.OriginalAmount, InvoicesColumns.Movement, InvoicesColumns.InterestBalance, InvoicesColumns.Balance];
        const currencyColumnsNames = currencyColumns.map(col => InvoicesColumns[col].charAt(0).toLowerCase() + InvoicesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return CurrencyParser.toLocaleString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = [InvoicesColumns.DueDate, InvoicesColumns.InvoiceDate];
        const dateColumnNames = dateColumns.map(col => InvoicesColumns[col].charAt(0).toLowerCase() + InvoicesColumns[col].slice(1));
        const cols = columns.filter(col => dateColumnNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return DateTimeParser.toLocaleDateString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }
}

export default new InvoicesTableConfigurator();
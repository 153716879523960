import React from 'react';
import './_LeftNavMenu.scss';
import {connect} from 'react-redux';
import {ILeftMenuElement} from '../../types/ILeftMenuElement';
import {INavLink} from './types/INavLink';
import {IStore} from '../../../reducers/IStore';
import {IUserCreditorRoles} from '../../types/IUserCreditorRoles';
import {LeftMenuModel} from '../../types/LeftMenuModel';
import LeftMenuNavLink from './LeftMenuNavLink';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import StyleWrapper from './../../../core/components/StyleWrapper/StyleWrapperHOC';
import TranslationService from '../../../core/services/TranslationService';

export interface ILeftMenuProps {
  userCreditorRoles: IUserCreditorRoles,
  leftMenuModel: LeftMenuModel
}

const LeftNavMenu = (props: ILeftMenuProps) => {
  const mapToNavLinkItem = (item: ILeftMenuElement): INavLink => {
    const name = item.translationModuleName
      ? TranslationService.translateModule(item.translationKey, item.translationModuleName)
      : TranslationService.translate(item.translationKey);

    return {
      to: item.pageUrl,
      name: name,
      subLinks: mapToNavLinkItems(item.items),
      icon: item.iconClass,
      id: item.idAttribute,
      order: item.order
    }
  };

  const mapToNavLinkItems = (items: ILeftMenuElement[]): INavLink[] => {
    const links: INavLink[] = [];

    items.forEach(element => {
      links.push(mapToNavLinkItem(element));
    });

    return links;
  }

  const getMenuElements = (): any[] => {
    let navs: {
      order: number;
      element: any;
      isGroup: boolean;
    }[] = [];

    for (const group of props.leftMenuModel.groups) {
      const name = group.translationModuleName
        ? TranslationService.translateModule(group.translationKey, group.translationModuleName)
        : TranslationService.translate(group.translationKey);
      navs.push({
        order: group.order,
        isGroup: true,
        element: (
          <NavItem
            className={`c-left-menu__item c-left-menu__item--group`}
            key={group.id}
          >
            {name}
          </NavItem>
        )
      });
    }

    for (const nav of props.leftMenuModel.items) {
      const item = mapToNavLinkItem(nav);
      const el = <LeftMenuNavLink key={item.id} link={item}/>;

      navs.push({order: nav.order, isGroup: false, element: el});
    }

    navs.sort((a, b) => a.order - b.order);

    for (let i = 0; i < navs.length - 1; i++) {
      if ((navs[i].isGroup && navs[i + 1].isGroup)) {
        navs.splice(i, 1);
      }
    }

    const lastIdx = navs.length - 1;
    if (navs[lastIdx].isGroup) {
      navs.splice(lastIdx, 1);
    }

    return navs.map(nav => nav.element);
  }

  return (
    <Nav className="c-left-menu" vertical={true}>
      {getMenuElements()}
    </Nav>
  );
}

const styleConfig = [{
  searchMethod: 'module',
  instructions: {
    linkActiveColor: '.c-left-menu .c-left-menu__link.active',
    linkActiveIndicatorColor: '.c-left-menu .c-left-menu__link.active',
    linkActiveBackgroundColor: '.c-left-menu .c-left-menu__link.active',
    linkColor: '.c-left-menu .c-left-menu__link',
    linkBackgroundColor: '.c-left-menu .c-left-menu__link',
    linkHoverColor: '.c-left-menu .c-left-menu__link:hover',
    linkHoverBackgroundColor: '.c-left-menu .c-left-menu__link:hover',
    backgroundColor: '.c-left-menu',
    subnavBackgroundColor: '.c-left-menu--sub',
    separatorColor: '.c-left-menu .c-left-menu__item::before',
    subnavSeparatorColor: '.c-left-menu--sub .c-left-menu__item::before'
  }
}, {
  searchMethod: 'module',
  include: 'groupTitle',
  instructions: {
    textColor: '.c-left-menu .c-left-menu__item--group.nav-item',
  }
}]

const mapStateToProps = (state: IStore) => {
  return {
    userCreditorRoles: state.userCreditorRoles,
    leftMenuModel: state.leftMenuModel
  }
};

export default connect(mapStateToProps)(StyleWrapper('module:nav', styleConfig)(LeftNavMenu));
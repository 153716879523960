import * as React from 'react';
import * as ACTIONS from '../../../../../actions';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ModuleEventSubscriber from '../../../../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../../../lists/EventsList';
import { ModuleNamesList } from '../../../../lists/ModuleNamesList';
import TranslationService from '../../../../../core/services/TranslationService';
import { ICreditor } from '../../../../types/ICreditor';
import { IStore } from '../../../../../reducers/IStore';
import { ICreditorSwitcher } from './ICreditorSwitcher';
import SessionStorageService from './../../../../services/SessionStorageService';
import CreditorSwitcherApi from './api/CreditorSwitcherApi';
import { ModuleSettins } from './types/ModuleSettins';
import { Tooltip as BootstrapTooltip, OverlayTrigger } from 'react-bootstrap';
import { CreditorNumberType } from './types/CreditorNumberType';

interface IProps {
    creditors: ICreditor[],
    currentCreditors: string[],
    changeSelectedCreditor: (selectedCreditors: string[]) => void;
}

class CreditorSwitcher extends React.Component<IProps, ICreditorSwitcher> {
    public state: ICreditorSwitcher = {
        dropdownOpen: false,
        filteredCreditor: ''
    };

    public toggle = () => {
        this.setState((prevState: any) => ({
            dropdownOpen: !prevState.dropdownOpen,
            filteredCreditor: ''
        }));
    };

    public componentDidMount = async () => {
        const settings = await CreditorSwitcherApi.getModuleSettings();
        const moduleSettings: ModuleSettins = {
            showDoesCreditorHaveFactoring: (settings['ShowDoesCreditorHaveFactoring'] === 'true'),
            showDoesCreditorHaveInsurance: (settings['ShowDoesCreditorHaveInsurance'] === 'true'),
            displayedCreditorNumberType: parseInt(settings['DisplayedCreditorNumberType'])
        }
        this.setState({moduleSettings});
    };

    public switchCreditor = (creditorId: string) => () => {
        const updatedCreditors = this.props.creditors.map(creditor => ({
            ...creditor,
            isSelected: creditor.domainId === creditorId
        }));

        SessionStorageService.set('selectedCreditor', creditorId);
        this.props.changeSelectedCreditor(CreditorSwitcher.getSelectedCreditorsList(updatedCreditors));
        ModuleEventSubscriber.emitEvent({ name: EventsList.CHANGED_CURRENT_CREDITOR })
    };

    handleCreditorChange = (e: any) => {
        this.setState({
            filteredCreditor: e.target.value
        });
    }

    public getDisplayName = (creditor: ICreditor) => {
        let value = creditor.organizationName;

        if(this.state.moduleSettings && this.state.moduleSettings.displayedCreditorNumberType !== CreditorNumberType.NoDisplay)
        {
            switch (this.state.moduleSettings.displayedCreditorNumberType)
            {
                case CreditorNumberType.DomainId:
                    value += creditor.domainId && creditor.domainId !== ''  ? ` (${creditor.domainId})` : '';
                    break;
                case CreditorNumberType.ExternalRef:
                    value += creditor.externalNo && creditor.externalNo !== ''  ? ` (${creditor.externalNo})` : '';
                    break;
                default:
                    break;
            }
        }

        return value;
    }

    public render() {
        const filterValue = this.state.filteredCreditor.toLocaleLowerCase();
        const selectedCreditor = this.props.currentCreditors.length === 1 ? this.props.creditors.find(creditor => this.props.currentCreditors.indexOf(creditor.domainId) >= 0) : undefined;
        const selectedCreditorName = selectedCreditor ? selectedCreditor.organizationName : TranslationService.translateModule('AllCreditors', ModuleNamesList.CreditorSwitcher);

        return (
            <>
            
            <Dropdown className="c-creditor-switcher c-dropdown" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle id="creditorSwitcher" tag="a" className="nav-link" tabIndex={0} caret={true}>
                    <>
                        {this.state.moduleSettings && this.state.moduleSettings.showDoesCreditorHaveInsurance 
                            && selectedCreditor && selectedCreditor.hasInsurance && 
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(tprops: any) => <BootstrapTooltip {...tprops}>{TranslationService.translateModule('Insurance', ModuleNamesList.CreditorSwitcher)}</BootstrapTooltip>}
                            >
                                <div className="c-circle-letter">{TranslationService.translateModule('InsuranceChar', ModuleNamesList.CreditorSwitcher)}</div>
                            </OverlayTrigger>
                        }
                        {this.state.moduleSettings && this.state.moduleSettings.showDoesCreditorHaveFactoring 
                            && selectedCreditor && selectedCreditor.hasFactoring && 
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(tprops: any) => <BootstrapTooltip {...tprops}>{TranslationService.translateModule('Factoring', ModuleNamesList.CreditorSwitcher)}</BootstrapTooltip>}
                            >
                                <div className="c-circle-letter">{TranslationService.translateModule('FactoringChar', ModuleNamesList.CreditorSwitcher)}</div>
                            </OverlayTrigger>
                        }
                        {selectedCreditorName}
                    </>
                </DropdownToggle>
                <DropdownMenu className="c-creditor-switcher__menu" right={true}>
                    <div className="c-creditor-switcher__menu-item">
                        <div className="c-creditor-switcher__search">
                            <input type="text" placeholder={TranslationService.translateModule('TypeCreditorName', ModuleNamesList.CreditorSwitcher)} onChange={this.handleCreditorChange}/>
                        </div>
                        
                        <div className="c-creditor-switcher__list">
                            {this.props.creditors.map(creditor => {
                                const creditorName = this.getDisplayName(creditor);

                                return (creditorName.toLocaleLowerCase().includes(filterValue) || this.state.filteredCreditor.length === 0) && (
                                    <DropdownItem 
                                        key={creditor.domainId}
                                        className="c-creditor-switcher__list-item"
                                        onClick={this.switchCreditor(creditor.domainId)} 
                                    >
                                        {creditorName}
                                    </DropdownItem>
                                );
                            })}
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
            </>
        );
    }

    private static getSelectedCreditorsList(updatedCreditors: ICreditor[]) {
        const selectedCreditor = updatedCreditors.find(x => x.isSelected);
        let selectedNumbers: string[] = [];

        if (selectedCreditor!.domainId === "all") {
            selectedNumbers = updatedCreditors.filter(x => x.domainId !== 'all').map(x => x.domainId);
        }
        else {
            selectedNumbers.push(selectedCreditor!.domainId);
        }
        return selectedNumbers;
    }
}

const mapStateToProps = (state: IStore) => {
    const allCreditorsOption = {
        isSelected: state.currentCreditors.length === 0,
        isAuthorized: false, 
        organizationName: TranslationService.translateModule('AllCreditors', ModuleNamesList.CreditorSwitcher),
        domainId: "all"
    };

    return {
        currentCreditors: state.currentCreditors,
        creditors: [allCreditorsOption, ...state.creditors.filter(c => c.isAuthorized)]
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, AnyAction>) => {
    return {
        changeSelectedCreditor: (selectedCreditors: string[]) => dispatch({ type: ACTIONS.CHANGE_CURRENT_CREDITOR, crediors: selectedCreditors })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditorSwitcher);
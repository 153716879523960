import * as React from 'react';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IGroupUser } from '../types/IGroupUser';
import DateTimeParser from '../../../core/helpers/DateTimeParser';

class GroupUsersTableConfigurator {
    public getTableColumns = (baseColumns: IComponentTableColumn[]) => {
        const columns = [...baseColumns];
        this.configureSquareCheckbox(columns);
        this.configureDateColumns(columns);
        return columns;
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = ['createDate'];
        const cols = columns.filter(col => dateColumns.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            if(value !== null && value !== '')
            {
                return DateTimeParser.toLocaleDateTimeString(value);
            }
            return '';
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureSquareCheckbox = (columns: IComponentTableColumn[]) => {
        columns.push({
            dataField: 'selected',
            formatter: (value: any, item: IGroupUser) => this.actionsRowData(),
            sort: false,
            text: ''
        } as IComponentTableColumn);
    }

    private actionsRowData = () => {
        return <i className="far fa-square"/>;
    }
}

export default new GroupUsersTableConfigurator();
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LocalBootstrapTable } from '../../core/components/BootstrapTable/RemoteBootstrapTable';
import Loader from '../../core/components/Loading/Loader';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import RouteParamsService from '../../core/services/RouteParamsService';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import InvoiceDetailsActivitiesTableConfigurator from './helpers/InvoiceDetailsActivitiesTableConfigurator';
import InvoiceDetailsActivitiesService from './services/InvoiceDetailsActivitiesService';
import { IInvoiceDetailsActivityItem } from './types/InvoiceDetailsActivityItem';

export const InvoiceDetailsActivities = (props: IExtendedModuleProps) => {
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState<IComponentTable<IInvoiceDetailsActivityItem>>({ columns: [], values: [] });
    const [hasAccessToModule, setHasAccessToModule] = useState(true);

    const fetchInvoiceDetailsActivities = async () => {
        setIsLoading(true);
        try {
            const result = await InvoiceDetailsActivitiesService.getInvoiceDetailsActivities({
                moduleInstanceId: props.module.id,
                accountNo: props.accountNo,
                creditorNo: props.creditorNo,
                invoiceNo: props.invoiceNo
            })
            
            setDataTable(result);
            setIsInitialDataLoaded(true);
            setHasAccessToModule(true);
        } catch {
            setHasAccessToModule(false);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await fetchInvoiceDetailsActivities()
        };

        load();
        // eslint-disable-next-line
    }, [props.routeParameters]);

    if (!hasAccessToModule) return <NoPermissionsComponent />

    return (<div>
        <Loader opacity={1} isLoaded={!isLoading} />
        {isInitialDataLoaded && dataTable.columns.length > 0 && <LocalBootstrapTable
            keyField="id"
            wrapperClasses="bt"
            classes="bt__table bt-table"
            data={dataTable.values}
            columns={InvoiceDetailsActivitiesTableConfigurator.getColumns(dataTable)} />}
    </div>)
};

const mapRouteParamsToProps = () => {
    const routeParams = RouteParamsService.getQueryParameters();
    return {
        accountNo: routeParams.accountNo as string,
        creditorNo: routeParams.creditorNo as string,
        invoiceNo: routeParams.invoiceNo as string
    }
}

export default connect(mapRouteParamsToProps)(InvoiceDetailsActivities);
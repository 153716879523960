import * as React from 'react';
import { RouteComponentProps } from 'react-router'
import UserIdentityService from './core/services/UserIdentityService';
import RestApiClient from './core/api/RestApiClient';
import RouteParamsService from './core/services/RouteParamsService';
import Header from './core/components/Header/Header';
import StickyFooter from './modules/StickyFooter/StickyFooter';
import TranslationService from './core/services/TranslationService';
import { ModuleNamesList } from './core/lists/ModuleNamesList';
import Topbar from './modules/Topbar/Topbar';

interface IInvitationCallbackState {
    verificationStatus?: string,
    invitationId: string,
    isMobileMenuExpanded: boolean,
    statusMsg: string
}

const STATIC = {
    VERIFICATION_STATUS_EXPIRED: 'expired',
    VERIFICATION_STATUS_NOTFOUND: 'notfound',
    VERIFICATION_STATUS_VALID: 'valid',
    PATH_REGISTER: '/register',
    HOME_PAGE: '/'
}

const verifyInvitationService = async (invitationId: string): Promise<string> => (
    RestApiClient.get<string>(`invitation/${invitationId}`)
);

export class InvitationCallback extends React.Component<RouteComponentProps, IInvitationCallbackState> {
    private queryString = RouteParamsService.getQueryParameters();
    private userIdentityService = new UserIdentityService();

    state: IInvitationCallbackState = {
        isMobileMenuExpanded: false,
        invitationId: this.queryString.id as string,
        statusMsg: TranslationService.translateModule('LabelDuringVerification', ModuleNamesList.Invitation)
    }

    componentDidMount() {
        if (!this.state.invitationId || this.userIdentityService.IsLoggedIn()) {
            this.goToPage(STATIC.HOME_PAGE);
        } else if (this.state.verificationStatus === undefined) {
            this.verifyInvitation(this.state.invitationId);
        }
    }

    render() {
        return (
            <div className="layout">
                <Topbar />
                <Header onClickMobileTogglerMenu={this.onClickHamburgerMenu} />

                <div className="layout__main">

                    <div className="layout__skin layout__skin--centered-content">
                        <div className="layout__skin-body">
                            <h2>{this.state.statusMsg}</h2>
                        </div>

                        <div className="layout__skin-footer">
                            <StickyFooter />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private getStatusMsg = (status: string) => {
        if (status === STATIC.VERIFICATION_STATUS_EXPIRED) {
            return TranslationService.translateModule('LabelInvitationExpired', ModuleNamesList.Invitation)
        } else if (status === STATIC.VERIFICATION_STATUS_NOTFOUND) {
            return TranslationService.translateModule('LabelInvitationGeneralError', ModuleNamesList.Invitation)
        }

        return TranslationService.translateModule('LabelDuringVerification', ModuleNamesList.Invitation);
    }

    private onClickHamburgerMenu = (newState: boolean) => {
        this.setState({
            isMobileMenuExpanded: newState
        })
    };

    private verifyInvitation(invitationId: string) {
        if (this.canBeValidated(invitationId)) {
            localStorage.setItem('invitation.id', invitationId);

            verifyInvitationService(invitationId)
                .then(verificationStatus => {
                    const status = verificationStatus.toLocaleLowerCase();
                    const newState = {
                        invitationId: this.state.invitationId,
                        verificationStatus: status,
                        statusMsg: this.getStatusMsg(status)
                    }

                    this.setState(newState, () => this.continueValidation(newState.verificationStatus));
                }, () => {
                    this.goToPage(STATIC.HOME_PAGE);
                });
        }
        else {
            this.goToPage(STATIC.HOME_PAGE);
        }
    }

    private canBeValidated(invitationId: string): boolean {
        return invitationId.length !== 0;
    }

    private continueValidation(status: string) {
        if (!this.userIdentityService.IsLoggedIn()) {
            if (status === STATIC.VERIFICATION_STATUS_VALID) {
                this.goToPage(STATIC.PATH_REGISTER);
            }

            return;
        }

        this.goToPage(STATIC.HOME_PAGE);
    }

    private goToPage = (url: string) => window.location.href = url;
}
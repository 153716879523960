import * as React from 'react';
import SimpleStep from './SimpleStep';
import { INewRegistrationProps } from '../types/INewRegistrationProps';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';

const NewRegistration = (props: INewRegistrationProps) => 
    <>
        <SimpleStep 
            title={TranslationService.translateModule('NewRegistrationHeader', ModuleNamesList.RegisterUserModuleName)} 
            body={TranslationService.translateModule('NewRegistrationContent', ModuleNamesList.RegisterUserModuleName)}
        />
        
        <StepListWizardButtons
            rightButtonOnClick={props.goToNextStep}
            rightButtonText={<>
                {TranslationService.translate('Next')} <i className="fas fa-angle-double-right next-button-arrow right"/>
            </>}
        />
    </>;

export default NewRegistration;

import * as React from 'react';
import GlobalConfig from '../../../core/api/GlobalConfig';
import { Button, BUTTON_COLORS, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { AutoOnboardingTokenStatus, IAutoOnboardingTokenData } from '../../../core/types/IAutoOnboardingTokenData';
import StepListWizardButtons from '../../StepListWizard/StepListWizardButtons';

interface IBankVerificationStepProps {
    goToNextStep: () => any;
    backToPrevStep: () => any;
    token: IAutoOnboardingTokenData | null
}

class BankIdVerification extends React.Component<IBankVerificationStepProps> {

    private getMessage() {
        if (this.props.token) {
            if (this.props.token.status === AutoOnboardingTokenStatus.PendingUserRegistration) {
                return TranslationService.translateModule('BankIdVerificationMsg', ModuleNamesList.RegisterUserModuleName);
            } else if (this.props.token.status === AutoOnboardingTokenStatus.PassedBankIdVerification) {
                return TranslationService.translateModule('BankIdVerificationPassedMsg', ModuleNamesList.RegisterUserModuleName);
            } else if (this.props.token.status === AutoOnboardingTokenStatus.FailedBankIdVerification) {
                return TranslationService.translateModule('BankIdVerificationFailedMsg', ModuleNamesList.RegisterUserModuleName);
            }
        }

        return TranslationService.translateModule('BankIdVerificationErrorMsg', ModuleNamesList.RegisterUserModuleName);
    }

    private getStepButtons() {
        if (this.props.token) {
            if (this.props.token.status === AutoOnboardingTokenStatus.PassedBankIdVerification) {

                return <StepListWizardButtons
                    rightButtonOnClick={this.props.goToNextStep}
                    rightButtonClassName="float-right"
                    rightButtonText={<>
                        {TranslationService.translate('Next') + " "}
                        <i className="fas fa-angle-double-right next-button-arrow right" />
                    </>} />
            } else if (this.props.token.status === AutoOnboardingTokenStatus.FailedBankIdVerification
                || this.props.token.status === AutoOnboardingTokenStatus.PendingUserRegistration) {

                return <StepListWizardButtons
                    leftButtonOnClick={this.props.backToPrevStep}
                    leftButtonClassName="float-left"
                    leftButtonText={<>
                        <i className="fas fa-angle-double-left back-button-arrow left" />
                        {" " + TranslationService.translate('Back')}
                    </>} />
            }
        }
        
        return null;
    }

    public render() {
        return (
            <div className="step-content">
                <header className="step-header">
                    <h2 className="step-title">
                        {TranslationService.translateModule('BankIdVerification', ModuleNamesList.RegisterUserModuleName)}
                    </h2>

                    <p>
                        {this.getMessage()}
                    </p>
                </header>

                <div className="bankid-content">
                    {(this.props.token
                        && (this.props.token.status === AutoOnboardingTokenStatus.PendingUserRegistration
                            || this.props.token.status === AutoOnboardingTokenStatus.FailedBankIdVerification))
                        && <Button label={TranslationService.translateModule('BankIdVerificationBtn', ModuleNamesList.RegisterUserModuleName)}
                            size={BUTTON_SIZE.MD}
                            variant={BUTTON_VARIANTS.PRIMARY}
                            color={BUTTON_COLORS.PRIMARY}
                            className="mb-5"
                            onClick={() => {
                                const baseUrl = GlobalConfig.val().GatewayApiUrl
                                window.location.href = `${baseUrl}/modules/AutoOnboardingModule/BankId/Verification/${this.props.token ? this.props.token.token : ''}`;
                            }} />}
                    {this.getStepButtons()}
                </div>
            </div>
        )
    }
}

export default BankIdVerification;

import * as React from 'react';
import { ContainerFactory } from '../factories/ContainerFactory';
import { ISkinProps } from '../../core/types/ISkinProps';

export class WrapperMainSidebarSkin extends React.Component<ISkinProps> {
    public render() {
        const containers = this.props.skin.containers;
        const routeParameters = this.props.routeParameters;
        return (
            <div className="wrapper-container">
                <ContainerFactory containers={containers} moduleIndex={0} routeParameters={routeParameters}/>
                <div className="row">
                    <div className="main-container col-lg-8 col-xl-6 offset-xl-3">
                        <ContainerFactory containers={containers} moduleIndex={1} routeParameters={routeParameters}/>
                    </div>
                    <div className="sidebar-container col">
                        <ContainerFactory containers={containers} moduleIndex={2} routeParameters={routeParameters}/>
                    </div>
                </div>
            </div>
        );
    }
}

import React from 'react';

type Props = {
    text: string,
    btnSubmitLabel: string,
    btnCloseLabel: string,
    onSubmit: () => void,
    onClose: () => void
}

class ModalConfirmation extends React.PureComponent<Props> {
    render() {
        return (
            <div className="c-modal-body">
                <p>{this.props.text}</p>

                <div className="c-modal-body__actions">
                    <button type="button" className="c-btn c-btn--md" onClick={this.props.onClose}>
                        {this.props.btnCloseLabel}
                    </button>
                    <button type="button" className="c-btn c-btn--main c-btn--md" onClick={this.props.onSubmit}>
                        {this.props.btnSubmitLabel}
                    </button>
                </div>
            </div>
        )
    }
}

export default ModalConfirmation;
import React from 'react';
import TranslationService from './../../../core/services/TranslationService';
import { Field } from 'formik';
import IReportParameter from './../types/IReportParameter';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import DatePicker from './../../../core/components/Forms/DatePicker';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import { toTitleCaseWithoutWhitespaces } from '../../../core/helpers/toTitleCaseWithoutWhitespaces';

interface IProps {
    value: any,
    data: IReportParameter,
    dateRange: string[],
    handleChange: (date: any) => void
}

interface ISelectedRangeDate {
    code: string,
    name: string
}

interface IComplexTypeValues {
    id: string,
    key: string,
    value: string
}

interface IState {
    complexTypeValues: Array<IComplexTypeValues>,
    selectedRangeDate: ISelectedRangeDate
}

export class DateRange extends React.PureComponent<IProps, IState> {
    state: IState = {
        complexTypeValues: [],
        selectedRangeDate: {
            code: '',
            name: ''
        }
    }
    
    componentDidMount() {
        this.prepareOptions();
    }
    
    componentDidUpdate(prevProps: IProps) {
        if (this.props.dateRange !== prevProps.dateRange) {
            for (let i = 0; i < this.props.data.itemParameters.length; i++) {
                this.handleDateChange(this.props.dateRange[i], this.props.data.itemParameters[i].name);
            }
        }
    }
    
    render() {
        return (
            <section className="c-creator__box c-creator__box--decor">
                {this.props.data.itemParameters.map((param: any, index: number) => (
                    <Field 
                        key={index}
                        name={param.name}
                        validate={this.validationDate}>
                        {({ field, form }: any) => (
                            <div className="c-control row row--aligned">
                                <label className="c-control__label col-12 col-sm-4" htmlFor={field.name}>
                                    {TranslationService.translate(toTitleCaseWithoutWhitespaces(param.prompt || '').replace(/ /g,''), param.prompt)}
                                </label>

                                <div className="col-12 col-sm-8 icon-calendar default-form">
                                    <DatePicker 
                                        onChange={(date) => {
                                            this.handleDateChange(date, field.name);
                                        }}
                                        inputId={field.name}
                                        inputName={field.name}
                                        value={this.props.dateRange.length ? DateTimeParser.toLocaleDateString(this.props.dateRange[index]) : this.props.value[field.name] ? DateTimeParser.toLocaleDateString(this.props.value[field.name]) : ''}/>
                                </div>

                                {form.errors[field.name] && form.touched[field.name] &&
                                    <div className="col-12 c-input-error">{form.errors[field.name]}</div>
                                }
                            </div>
                        )}
                    </Field>
                ))}

                <div className="c-control row row--aligned">
                    <label className="c-control__label col-12 col-sm-4">
                        {TranslationService.translate('DateRange')}
                    </label>

                    <div className="col-12 col-sm-8 icon-calendar default-form">
                        <SimpleSelect
                            id="selected-range-date"
                            value={this.state.selectedRangeDate.code}
                            name={`${this.props.data.name}_DateRange_`}
                            onChangeHandle={(data: ISelectedValue) => this.handleDateRangeChange(data, this.props.data.name)}
                            options={this.state.complexTypeValues}/>
                    </div>
                </div>
            </section>
        )
    }

    validationDate = (value: any) => {
        let error;

        if (!value) {
            error = TranslationService.translate('ValidationMessageRequiredField');
        }

        return error;
    }

    handleDateChange = (date: any, name: string) => {
        this.props.handleChange({
            name: name,
            type: 'DateTime',
            value: DateTimeParser.toLocaleDateString(date),
        });
    }

    handleDateRangeChange = (data: ISelectedValue, name: string) => {
        this.props.handleChange({
            name: `${this.props.data.name}_DateRange_`,
            type: '_DateRange_',
            value: data.key,
        });

        this.setState({
            selectedRangeDate: {
                name: data.value,
                code: data.key
            }
        }); 
    }

    prepareOptions() {
        const selectOptions = this.props.data.complexTypeValues.map((value: any) => {
            return {
                id: value.id,
                key: value.code,
                value: TranslationService.translate(toTitleCaseWithoutWhitespaces(value.name), value.name)
            }
        });

        this.setState({
            complexTypeValues: selectOptions,
            selectedRangeDate: {
                ...this.state.selectedRangeDate,
                code: this.props.value[`${this.props.data.name}_DateRange_Complex`] || ''
            }
        });
    }
}

export default DateRange
import * as React from 'react';
import { IModuleProps } from '../../core/types/IModuleProps';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import { LocalBootstrapTable } from '../../core/components/BootstrapTable/RemoteBootstrapTable';
import RolesAssignmentTableConfigurator from './helpers/RolesAssignmentTableConfigurator';
import Loader from '../../core/components/Loading/Loader';
import RolesAssignmentApiClient from './RolesAssignmentApiClient';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { IRoleVm } from './types/IRoleVm';
import { Status } from '../../core/api/Enums/Status';
import InfoMessageService from '../../core/services/InfoMessageService';

interface IState {
  isLoading: boolean;
  rolesTable: IComponentTable<IRoleVm>;
  selectedRoles: IRoleVm[];
}

interface IRolesAssignment extends IModuleProps {
  userUId: string;
}

class RolesAssignment extends React.Component<IRolesAssignment> {
  public state: IState = {
    isLoading: true,
    rolesTable: {...emptyComponentTable},
    selectedRoles: [],
  }

  public componentDidMount = async () => {
    this.setState({isLoading: true});
    
    const initialData = await RolesAssignmentApiClient.getInitialData(this.props.module.id, this.props.userUId);
    this.setState({
      rolesTable: initialData.table,
      selectedRoles: initialData.assignedRoles
    })

    this.setState({isLoading: false});

  }

  public render() {
    return (
      <div className="modal-content--min-height">
          {this.state.isLoading ? <Loader opacity={1}/> : null}
          { this.state.rolesTable.columns.length > 0 && <LocalBootstrapTable
              wrapperClasses="bt"
              classes="bt__table bt-table bt--small bt--selectable" 
              keyField='uId'
              selectRow={ { 
                      hideSelectColumn: true,
                      mode: 'checkbox', 
                      classes: 'bt bt-table bt-table__row bt-table__row--selected', 
                      clickToSelect: true,
                      onSelect: this.onRoleSelect,
                      selected: this.state.selectedRoles.map((item) => item.uId)
                  } 
              }
              data={this.state.rolesTable.values}
              columns={RolesAssignmentTableConfigurator.getTableColumns(this.state.rolesTable.columns)} />
          }
          <button className='btn btn--normal btn-color-2 btn-sm mt-1 float-right' onClick={this.assignRoles}>
              {TranslationService.translateModule('AssignRolesButton', ModuleNamesList.RolesAssignment)}
          </button>
      </div>
    )
  }

  private assignRoles = async () => {
    try {
        this.setState({isLoading:true})
        const result = await RolesAssignmentApiClient.assignRoles(this.props.userUId, this.state.selectedRoles);
        if (result.status === Status.Success) {
          InfoMessageService.success(TranslationService.translateModule('SuccessRolesAssignment', ModuleNamesList.RolesAssignment));
        } else {
          InfoMessageService.displayActionStatus(result);
        }
    } catch {
        InfoMessageService.error(TranslationService.translateModule('ErrorRolesAssignment', ModuleNamesList.RolesAssignment));
    } finally {
        this.setState({
            isLoading: false
        })
    }
  }

  private onRoleSelect = (element: IRoleVm) => {
    let selectedRoles = this.state.selectedRoles;
    const index = selectedRoles.findIndex(item => item.uId === element.uId);
    if (index > -1) {
      selectedRoles.splice(index, 1);
    } else {
      selectedRoles.push(element);
    }
    this.setState({selectedRoles});
}
}

export default RolesAssignment;
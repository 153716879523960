import React, { useState } from 'react';
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
// @ts-ignore
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { IComponentTableColumn } from '../../types/IComponentTableColumn';
import TranslationService from '../../services/TranslationService';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../Button/Button';

interface IPaginationFactoryProps {
    custom?: boolean,
    page?: number,
    sizePerPage?: number,
    totalSize?: number,
    hideSizePerPage?: boolean,
    alwaysShowAllBtns?: boolean,
    hidePageListOnlyOnePage?: boolean,
    onPageChange?: (value: any) => void
}

interface IBaseBootstrapTableProps {
    id?: string,
    cellEdit?: any
    classes: string,
    columns: IComponentTableColumn[],
    data: any[],
    defaultSorted?: any,
    expandRow?: any,
    keyField: string,
    nonEditableRows?: number[],
    onTableChange?: any,
    remote: boolean,
    search?: boolean,
    selectRow?: any,
    variant?: string,
    wrapperClasses?: string,
    isDataLoading?: boolean,
    onExport?: () => Promise<void>
    rowClasses?: (row: any) => string,
    onFilter?: (value: string) => void,
}

interface IRemoteBootstrapTable extends IBaseBootstrapTableProps {
    paginationProps?: IPaginationFactoryProps;
}

const NoDataIndication = () => (
    <div className="alert alert-primary" role="alert">
        {TranslationService.translate('NoDataToDisplay')}
    </div>
);

const CustomSearchBar = (props: any) => {
    let input: any;
    const handleClick = () => {
      props.onSearch(input.value);

      if (props.onFilter) {
        props.onFilter(input.value);
      }
    };

    return (
      <div className="c-data-table__searchbar">
          <div className="row">
              <div className="col">
                <input
                    ref={ n => input = n }
                    type="text"
                    className="c-form-control"
                />
              </div>

              <div className="col-auto">
                <Button 
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTON_SIZE.SM}
                    type="button" 
                    label={TranslationService.translate('Search')} 
                    onClick={handleClick}
                />
              </div>
          </div>
      </div>
    );
  };

export const BootstrapTableContainer = (props: IRemoteBootstrapTable) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isExporting, setIsExporting] = useState(false);

    const pagination = {
        page: props.paginationProps ? (props.paginationProps.page || currentPage) : currentPage,
        sizePerPage: props.paginationProps ? (props.paginationProps.sizePerPage || 10) : 10,
        totalSize: props.paginationProps ? (props.paginationProps.totalSize || props.data.length) : props.data.length,
        hideSizePerPage: props.paginationProps ? props.paginationProps.hideSizePerPage : undefined,
        alwaysShowAllBtns: props.paginationProps ? (props.paginationProps.alwaysShowAllBtns || undefined) : undefined,
        hidePageListOnlyOnePage: props.paginationProps ? props.paginationProps.hidePageListOnlyOnePage : undefined,
    }

    const rowsTableLoader = props.paginationProps ? props.paginationProps.sizePerPage : 5;
    const handlePageChange = (value: number) => {
        if (props.paginationProps && props.paginationProps.onPageChange) {
            props.paginationProps.onPageChange(value);
        }

        setCurrentPage(value);
    }

    return (
        <>
            {!props.columns || props.columns.length === 0 || props.isDataLoading ? (
                <div className="table-loader">
                    <div className="table-loader__head"></div>

                    <div className="table-loader__body">
                        {[...Array(rowsTableLoader)].map((x, i) =>
                            <div className="table-loader__row" key={i}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <PaginationProvider
                    pagination={paginationFactory({
                        custom: true,
                        page: pagination.page,
                        sizePerPage: pagination.sizePerPage,
                        totalSize: pagination.totalSize,
                        hideSizePerPage: pagination.hideSizePerPage,
                        alwaysShowAllBtns: pagination.alwaysShowAllBtns,
                        hidePageListOnlyOnePage: pagination.hidePageListOnlyOnePage,
                        onPageChange: handlePageChange
                    })}
                >
                    {({
                        paginationProps,
                        paginationTableProps
                    }: any) => (
                        <div className={`c-data-table ${pagination.hideSizePerPage ? 'c-data-table--hideSizePerPage' : ''} ${props.variant ? `c-data-table--${props.variant}` : ''}`}>
                            {props.onExport && 
                                <Button
                                    type="button"
                                    variant={BUTTON_VARIANTS.PRIMARY}
                                    size={BUTTON_SIZE.MD}
                                    showLoader={isExporting}
                                    disabled={isExporting}
                                    onClick={() => {
                                        setIsExporting(true);
                                        props.onExport!().then(() =>
                                            setIsExporting(false))
                                            .catch(() =>
                                                setIsExporting(false))
                                    }}
                                    icon={{ className: 'fas fa-file-csv', position: 'left' }}
                                    label={TranslationService.translate('Export')}
                                    className="c-data-table__export" 
                                />
                            }

                            {paginationProps && !paginationProps.hideSizePerPage ? 
                                <div className="c-data-table__sizeperpage">
                                    <label className="c-data-table__sizeperpage-label">
                                        {TranslationService.translate('Rows')}:
                                    </label>

                                    <SizePerPageDropdownStandalone
                                        className="c-data-table__sizeperpage-dropdown"
                                        {...paginationProps}
                                    />
                                </div> : null
                            }

                            {props.remote ? (
                                <BootstrapTable
                                    id={props.id}
                                    wrapperClasses={props.wrapperClasses}
                                    remote={{ pagination: true }}
                                    classes={props.classes}
                                    keyField={props.keyField}
                                    alwaysShowAllBtns={true}
                                    data={props.data}
                                    rowClasses={props.rowClasses}
                                    columns={props.columns}
                                    onTableChange={props.onTableChange}
                                    expandRow={props.expandRow}
                                    noDataIndication={() => <NoDataIndication />}
                                    defaultSorted={props.defaultSorted}
                                    selectRow={props.selectRow}
                                    {...paginationTableProps}
                                />
                            ) : (
                                <ToolkitProvider
                                    keyField={props.keyField}
                                    data={props.data}
                                    columns={props.columns}
                                    search
                                >
                                    {
                                        (toolkitProps: any) => (
                                            <>
                                                {props.search && (
                                                    <CustomSearchBar { ...toolkitProps.searchProps } onFilter={props.onFilter} />
                                                )}

                                                <BootstrapTable
                                                    wrapperClasses={props.wrapperClasses}
                                                    classes={props.classes}
                                                    rowClasses={props.rowClasses}
                                                    selectRow={props.selectRow}
                                                    noDataIndication={() => <NoDataIndication />}
                                                    onTableChange={props.onTableChange}
                                                    defaultSorted={props.defaultSorted}
                                                    cellEdit={cellEditFactory(props.cellEdit)}
                                                    {...paginationTableProps}
                                                    {...toolkitProps.baseProps}
                                                />
                                            </>
                                        )
                                    }
                                </ToolkitProvider>
                            )}

                            <div className="c-data-table__pagination">
                                <PaginationListStandalone
                                    className="c-data-table__pagination"
                                    {...paginationProps}
                                />
                            </div>
                        </div>
                    )}
                </PaginationProvider>
            )}
        </>
    )
};
import * as React from 'react';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { ModalType } from '../types/ModalType';

class LoginProvidersTableConfigurator {
    public getColumns = (columns: IComponentTableColumn[], 
                        setEditModal: (modalData: ModalType) => void, 
                        setDeleteModal: (modalData: ModalType) => void) =>
    {
        const customColumns = [
        {
            dataField: 'actions',
            sort: false,
            text: 'Actions',
            formatter: (_: any, el: any) => {
            return (
                    <>
                        <Button
                            type="button"
                            id="externalLoginProvidersEdit"
                            className="c-btn--icon-only mr-2"
                            variant={BUTTON_VARIANTS.PRIMARY}
                            size={BUTTON_SIZE.SM}
                            icon={{
                            className: 'far fa-edit',
                            position: ''
                            }}
                            onClick={() => setEditModal({ isOpen: true, data: el})} 
                        />
            
                        <Button
                            type="button"
                            id="externalLoginProvidersDelete"
                            className="c-btn--icon-only"
                            variant={BUTTON_VARIANTS.DELETE}
                            size={BUTTON_SIZE.SM}
                            icon={{
                            className: 'far fa-trash-alt',
                            position: ''
                            }}
                            onClick={() => setDeleteModal({ isOpen: true, data: el})} 
                        />
                    </>
                )
            }
        }];
    
        return [
            ...columns,
            ...customColumns
        ];
    }

}

export default new LoginProvidersTableConfigurator();
import React, { useEffect } from 'react';
import RestApiClient from '../api/RestApiClient';
import { CancelTokenSource } from 'axios';

export type AbortRequestPropsType = {
    cancelTokenSource: CancelTokenSource,
  }

const withAbortRequest = <P extends object>(Component: React.ComponentType<P>) => ((props: P & AbortRequestPropsType) => {
    const cancelTokenSource = RestApiClient.getCancelToken();

    useEffect(() => {
        return function willUnmount() {
            cancelTokenSource.cancel()
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Component {...props} cancelTokenSource={cancelTokenSource} />
        </>
    )
})

export default withAbortRequest;
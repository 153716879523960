import * as React from 'react';
import InvoicePaymentsTable from '../components/InvoicePayments';

const expanderRenderer = (moduleInstanceId: number, caseId: string) => (row: any) => <InvoicePaymentsTable invoiceLineNumber={row.id} moduleInstanceId={moduleInstanceId} caseId={caseId} />
const expandHeaderColumnRenderer = ({ isAnyExpands }: any) => {
    return isAnyExpands ? <i className="fas fa-sort-up" /> : <i className="fas fa-sort-down" />;
};
const expandColumnRenderer = ({ expanded }: any) => {
    return expanded ? <i className="fas fa-sort-up" /> : <i className="fas fa-sort-down" />;
}

export const configureRowExpander = (
    handleOnExpand: (row: any, isExpand: boolean, rowIndex: number, e: any) => void,
    onExpandAll: (isExpandAll: boolean, resutls: any[], e: any) => void,
    expanded: string[],
    moduleInstanceId: number,
    caseId: string) => {
    return {
        renderer: expanderRenderer(moduleInstanceId, caseId),
        expanded,
        onExpand: handleOnExpand,
        showExpandColumn: true,
        expandColumnPosition: 'right',
        expandByColumnOnly: true,
        expandHeaderColumnRenderer,
        expandColumnRenderer,
        onExpandAll
    }
} 
import * as React from 'react';
import { INewMessageFormState } from '../types/INewMessageFormState';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { INewMessageFormProps } from '../types/INewMessageFormProps';
import { emptyMessage } from '../types/INewMessage';
import InfoMessageService from 'core/services/InfoMessageService';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

class NewMessageForm extends React.Component<INewMessageFormProps, INewMessageFormState> {

    public state: INewMessageFormState = {
        message: emptyMessage
    } 

    private lastId: number = 0;
    private sendEmailCopyId: string;
    private attachmentsId: string;
    private fileInput: any;

    constructor(props: INewMessageFormProps) {
        super(props);
        this.fileInput = React.createRef();
        this.sendEmailCopyId = this.generateUniqueId(props.prefix);
        this.attachmentsId = this.generateUniqueId(props.prefix);
    }

    componentDidUpdate(prevProps: INewMessageFormProps) {
        if (this.props.isAddingNewConversation !== prevProps.isAddingNewConversation) {
            if (!this.props.isAddingNewConversation) {
                this.setState({
                    message: emptyMessage
                });
            }
        }
    }

    public sendMessage = async () => {
        if (this.state.message.content.length < this.props.messageModuleSettings.messagesMinLength) {
            const message = TranslationService.translateModule('ErrorMessageMinMessageLength', ModuleNamesList.Messages);
            const mergedMessage = message.replace('{0}',this.props.messageModuleSettings.messagesMinLength.toString());
            InfoMessageService.warning(mergedMessage);
        } else if (this.props.messageModuleSettings.messagesMaxLength > 0 && this.state.message.content.length > this.props.messageModuleSettings.messagesMaxLength) {
            const message = TranslationService.translateModule('ErrorMessageMaxMessageLength', ModuleNamesList.Messages);
            const mergedMessage = message.replace('{0}',this.props.messageModuleSettings.messagesMaxLength.toString());
            InfoMessageService.warning(mergedMessage);
        } else {
            await this.props.sendNewMessage(this.state.message);
            this.setState({message: {
                ...this.state.message,
                content: ''
            }});
        }
    }

    public handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            this.sendMessage();
        }
    }

    public onMessageChange = (event: React.ChangeEvent<any>) => {
        this.setState({
            ...this.state,
            message:{
                ...this.state.message,
                content: event.target.value
            }
        })
    }

    public onSendCopyToEmailChange = (event: React.ChangeEvent<any>) => {
        this.setState({
            ...this.state,
            message:{
                ...this.state.message,
                sendCopyToEmail: !this.state.message.sendCopyToEmail
            }
        })
    }

    public attachFiles = (files: FileList) => {
        const selectedFiles = Array.from(files);
        let attachedFiles = this.state.message.attachedFiles.concat(selectedFiles);

        const fileSizeLimit = this.props.messageModuleSettings.messagesFileSizeLimit * 1000000;
        const filesAboveLimitSize = attachedFiles.filter((file) => file.size > fileSizeLimit);
        if(filesAboveLimitSize.length !== 0)
        {
            InfoMessageService.warning(TranslationService.translateModule('ErrorMessageFileSizeReached', ModuleNamesList.Messages));
            attachedFiles = attachedFiles.filter((file) => file.size <= fileSizeLimit);
        }

        if(attachedFiles.length > this.props.messageModuleSettings.messagesNumOfFilesLimit)
        {
            InfoMessageService.warning(TranslationService.translateModule('ErrorMessageFilesLimitReached', ModuleNamesList.Messages));
            attachedFiles = attachedFiles.splice(0, this.props.messageModuleSettings.messagesNumOfFilesLimit);
        }

        this.setState({
            message:{
                ...this.state.message,
                attachedFiles
            }
        })
    }

    public removeFile = (index: number) =>{
        const attachedFiles = this.state.message.attachedFiles;
        attachedFiles.splice(index,1);
        this.setState({
            message:{
                ...this.state.message,
                attachedFiles
            }
        })
    }

    public getFileSize = (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) { return '0 Byte'; }
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    public render() {
        const textareaId = `contentMessage${this.props.prefix?.charAt(0).toUpperCase()}${this.props.prefix?.slice(1)}`;

        return (
            <form className={`new-message-form-container ${this.props.mode ? `new-message-form-container--${this.props.mode}` : ''}`}>
                <div className="new-message-text-editor mb-2">
                    <textarea 
                        id={textareaId}
                        className="form-control dark-border" 
                        value={this.state.message.content} 
                        onChange={this.onMessageChange} 
                        placeholder={TranslationService.translateModule('WriteYourMessagePlaceholder', ModuleNamesList.Messages)}
                    />
                </div>
                
                {this.state.message.attachedFiles.length > 0 ?
                    <ul className="attached-files">
                        {this.state.message.attachedFiles.map((file: File, index: number) => {
                            return (
                                <li key={index}>
                                    <div className="attachment-container">
                                        <span className="filename">{ file.name }</span>
                                        <span className="size">{ this.getFileSize(file.size) }</span>
                                        <button type="button" onClick={() => this.removeFile(index)} className="trash-button"><i className="far fa-trash-alt"/></button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                : '' }
                
                <footer className="new-message-footer">
                    <div className="row justify-content-end">
                        <div className="col-auto">
                            <input 
                                type="checkbox" 
                                id={this.sendEmailCopyId} 
                                checked={this.state.message.sendCopyToEmail} 
                                onChange={this.onSendCopyToEmailChange} 
                                className="form-check-input"
                            />
                            
                            <label 
                                className="form-check-label" 
                                htmlFor={this.sendEmailCopyId}
                            >
                                {TranslationService.translateModule('SendCopyToEmail', ModuleNamesList.Messages)}
                            </label>
                        </div>

                        <div className="col-auto">
                            <input 
                                type="file" 
                                id={this.attachmentsId} 
                                multiple={true} 
                                ref={(input) => { this.fileInput = input; }} 
                                className="d-none"
                                onChange={(e: any) => { this.attachFiles(e.target.files); this.fileInput.value = "" } }
                            />
                            
                            <label 
                                htmlFor={this.attachmentsId} 
                                className="btn-transparent attachment-button" 
                            >
                                <i className="fas fa-paperclip"/>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            {this.props.prefix === 'modal' && (
                                <Button
                                    type="button"
                                    id="sendMessage"
                                    variant={BUTTON_VARIANTS.LINK}
                                    size={BUTTON_SIZE.MD}
                                    label={`${TranslationService.translateModule('Cancel', ModuleNamesList.Messages)}`}
                                    onClick={this.props.onClose}
                                />
                            )}
                        </div>

                        <div className="col-auto ml-auto">
                            <Button
                                type="button"
                                variant={BUTTON_VARIANTS.PRIMARY}
                                size={BUTTON_SIZE.MD}
                                showLoader={this.props.isAddingNewConversation}
                                label={`${TranslationService.translateModule('Send', ModuleNamesList.Messages)}`}
                                onClick={this.sendMessage}
                            />
                        </div>
                    </div>
                </footer>
            </form>
        );
    }

    private generateUniqueId = (prefix: string = 'default') => {
        this.lastId++;
        return `${prefix}_${this.lastId}`;
    }
}

export default NewMessageForm;
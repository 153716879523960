import RestApiClient from '../../../core/api/RestApiClient';
import { CaseTimelineModel } from '../types/CaseTimelineModel';
import { IActionStatusTyped } from '../../../core/api/types/IActionStatusTyped';

class CaseTimelineApiClient
{
    public async getCaseTimeline(moduleInstanceId: number, caseId: string): Promise<IActionStatusTyped<CaseTimelineModel>> {
        return await RestApiClient.get<IActionStatusTyped<CaseTimelineModel>>(`modules/CaseTimeline/${moduleInstanceId}/${caseId}`);
    }
}

export default new CaseTimelineApiClient();
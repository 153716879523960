import { IConversation } from './IConversation';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IMessage } from './IMessage';
import { IConversationsOrderByType } from '../enums/IConversationsOrderByType';
import { IOrderByType } from '../../../core/Enums/IOrderByType';
import { ICredtitor } from './ICredtitor';
import { IMessagesModuleSettings, emptyMessagesModuleSettings } from './IMessagesModuleSettings';

export interface IMessagesState {
    conversations?: IComponentTable,
    creditor: ICredtitor,
    currentConversation?: IConversation,
    currentConversationIsMobileOpened: boolean,
    currentPage: number,
    isConversationsLoading: boolean,
    isCurrentConversationLoading: boolean,
    conversationsPageSize: number,
    isModalOpen: boolean,
    messagesInSendingProcess: IMessage[],
    messagesNotSend: IMessage[],
    moduleSettings: IMessagesModuleSettings,
    orderDirection: IOrderByType,
    orderedBy: IConversationsOrderByType,
    searchValue: string,
    isConversationsLimit: boolean,
    isMobileViewOnly: boolean,
    answeredConversationsIds: number[],
    isAddingNewConversation: boolean
}

export const emptyMessageState : IMessagesState = {
    creditor: {
        name: '',
        number: ''
    },
    currentConversationIsMobileOpened: false,
    currentPage: 1,
    conversationsPageSize: 0,
    isConversationsLoading: false,
    isCurrentConversationLoading: false,
    isModalOpen: false,
    isMobileViewOnly: false,
    messagesInSendingProcess: [],
    moduleSettings: { ...emptyMessagesModuleSettings },
    messagesNotSend: [],
    orderDirection: IOrderByType.Descendent,
    orderedBy: IConversationsOrderByType.DueDate,
    searchValue: '',
    isConversationsLimit: false,
    answeredConversationsIds: [],
    isAddingNewConversation: false,
}
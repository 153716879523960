import React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import {ModuleNamesList} from '../../../core/lists/ModuleNamesList';
import {SettingsType} from '../../../core/Enums/SettingsType';
import {IComponentTableColumn} from '../../../core/types/IComponentTableColumn';
// import SettingsTypeCellEditor from './SettingsTypeCellEditor';
import EditCell from "../components/EditCell";

export const getColumns = (moduleInstanceId: number) => {
  return [
    {
      dataField: 'name',
      sort: true,
      text: TranslationService.translateModule('Name', ModuleNamesList.TranslationsManagement),
      editable: false,
      headerStyle: (col, colIndex) => ({width: '20%'})
    },
    {
      dataField: 'type',
      sort: true,
      text: TranslationService.translateModule('Type', ModuleNamesList.TranslationsManagement),
      editable: false,
      formatter: (value: any, row: any) => {
        return <>{SettingsType[value].toString()}</>
      },
      headerStyle: (col, colIndex) => ({width: '10%'})
    },
    {
      dataField: 'value',
      sort: true,
      text: TranslationService.translateModule('Value', ModuleNamesList.TranslationsManagement),
      editable: false,
      headerStyle: (col, colIndex) => ({width: '40%'}),
      formatter: (value: any, row: any) => {
        if (row.type === SettingsType.HtmlString) {
          return <div dangerouslySetInnerHTML={{__html: value}}/>
        }

        return value;
      }
    },
    {
      dataField: 'custom',
      sort: true,
      text: TranslationService.translateModule('Custom', ModuleNamesList.TranslationsManagement),
      editable: true,
      headerStyle: (col, colIndex) => ({width: '30%'}),
      formatter: (value: string, row: any) => {
        return (
          <EditCell moduleInstanceId={moduleInstanceId} row={row} value={value} custom={row.custom} />
        )
      }
    }
  ] as IComponentTableColumn[];
}
import React from 'react';
import styles from '../SelectField.module.scss';
// @ts-ignore
import classNames from 'classnames';
import { useSelectFieldContext } from '../context/SelectFieldContext';

type PropsType = {
  children: React.ReactNode;
  className?: string;
};

const SelectFieldList = (props: PropsType) => {
  const { isExpanded } = useSelectFieldContext();

  return (
    <ul className={classNames(styles.list, props.className, isExpanded && styles.listIsExpanded)}>
      {props.children}
    </ul>
  );
};

export default SelectFieldList;

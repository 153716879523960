import React, {useEffect, useState} from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { Form, Formik } from 'formik';
import {IDBConnectionDetails, emptyConnectionDetails} from '../types/IDBConnectionDetails';
import * as yup from 'yup';
import FormGroupField from '../../../core/components/Forms/FormGroupField';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from "../../../core/components/Button/Button";

type DatabaseFormType = {
    formData: IDBConnectionDetails,
    isDBConnected: boolean,
    isDataLoading: boolean,
    onCloseConnection: () => void,
    onSubmit: (connectionDetals: IDBConnectionDetails) => void
}

const DatabaseForm = (props: DatabaseFormType) => {
    const [DBConnectionDetails, setDBConnectionDetails] = useState({...emptyConnectionDetails});
    const [isConnecting, setIsConnecting] = useState(false);

    const validationMessages = {
        required: TranslationService.translate("ValidationMessageRequiredField"),
    };

    const validationSchema = yup.object().shape({
        hostName: yup.string().required(validationMessages.required),
        databaseName: yup.string().required(validationMessages.required),
        username: yup.string().required(validationMessages.required),
        password: yup.string().required(validationMessages.required),
    });

    const handleClick = () => {
        props.onCloseConnection();
        setDBConnectionDetails({...emptyConnectionDetails});
    };

    useEffect(() => {
        setIsConnecting(props.isDataLoading);
    }, [props.isDataLoading])

    return (
        <>
            {props.isDBConnected ? (
                <>
                    <div className="row">
                        <div className="col">
                            <ul className="c-list c-list--ddn-details row">
                                {Object.keys(DBConnectionDetails).map((key, index) => {
                                    let value = DBConnectionDetails[key];

                                    if (key === 'password') {
                                        value = value.replace(/./g, '*');
                                    }

                                    return (
                                        <li key={index} className="col-12 col-lg-6">{key}: <span>{value}</span></li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Button
                                variant={BUTTON_VARIANTS.PRIMARY}
                                size={BUTTON_SIZE.MD}
                                label={`Edit`}
                                icon={{
                                    className: 'far fa-edit',
                                    position: 'left'
                                }}
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <Formik
                    enableReinitialize={true}
                    initialValues={DBConnectionDetails}
                    onSubmit={(value: IDBConnectionDetails) => {
                        setDBConnectionDetails(value);
                        props.onSubmit(value);
                        setIsConnecting(true);
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur
                      }) => {
                        return (
                            <Form className="c-form">
                                <div className="c-form__group">
                                    <div className="row">
                                        <div className="col-3">
                                            <FormGroupField
                                                errors={errors}
                                                fieldName="hostName"
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                inputContainerClass="col-12"
                                                label={TranslationService.translateModule('DatabaseFormHostName', ModuleNamesList.DnnMigration)}
                                                labelClass="col-12"
                                                touched={touched}
                                                required={true}
                                                disabled={isConnecting}
                                                value={values.hostName}
                                            />
                                        </div>

                                        <div className="col-3">
                                            <FormGroupField
                                                errors={errors}
                                                fieldName="databaseName"
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                inputContainerClass="col-12"
                                                label={TranslationService.translateModule('DatabaseFormDatabaseName', ModuleNamesList.DnnMigration)}
                                                labelClass="col-12"
                                                touched={touched}
                                                required={true}
                                                disabled={isConnecting}
                                                value={values.databaseName}
                                            />
                                        </div>

                                        <div className="col-3">
                                            <FormGroupField
                                                errors={errors}
                                                fieldName="username"
                                                handleChange={handleChange}
                                                label={TranslationService.translateModule('DatabaseFormUsername', ModuleNamesList.DnnMigration)}
                                                handleBlur={handleBlur}
                                                touched={touched}
                                                labelClass="col-12"
                                                inputContainerClass="col-12"
                                                required={true}
                                                disabled={isConnecting}
                                                value={values.username}
                                            />
                                        </div>

                                        <div className="col-3">
                                            <FormGroupField
                                                errors={errors}
                                                fieldName="password"
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                inputContainerClass="col-12"
                                                label={TranslationService.translateModule('DatabaseFormPassword', ModuleNamesList.DnnMigration)}
                                                labelClass="col-12"
                                                touched={touched}
                                                required={true}
                                                type="password"
                                                disabled={isConnecting}
                                                value={values.password}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="c-form__group">
                                    <Button
                                        variant={BUTTON_VARIANTS.PRIMARY}
                                        size={BUTTON_SIZE.MD}
                                        label={TranslationService.translateModule('ConnectToDatabase', ModuleNamesList.DnnMigration)}
                                        showLoader={isConnecting}
                                    />
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            )}
        </>
    );

};

DatabaseForm.defaultProps = {
    formData:  {...emptyConnectionDetails}
};

export default DatabaseForm;

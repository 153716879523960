import React, { useEffect } from 'react';
import { Modal } from 'reactstrap';
import ModalBody from 'reactstrap/lib/ModalBody';
import { useState } from 'react';
import TranslationService from '../../../core/services/TranslationService';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';

export const NewMessageModal = (props: {
    maxMessageLength: number,
    addNewMessage: (message: string) => void,
    onClose: () => void,
    isOpen: boolean
}) => {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [isValid, setIsValid] = useState(true);
    const [message, setMessage] = useState('');

    const toggle = () => {
        setIsOpen(!isOpen);
        setMessage('');
        props.onClose();
    }
    const onChangeMessage = (event: any) => {
        setMessage(event.target.value);
        setIsValid(event.target.value.length > 0 && event.target.value.length <= props.maxMessageLength);
    }

    const submitMessage = () => {
        if (validate()) {
            setIsValid(true)
            props.addNewMessage(message);
            setMessage('');
            toggle();
        } else {
            setIsValid(false)
        }
    }

    const validate = () => {
        return message.length > 0 && message.length <= props.maxMessageLength;
    }

    useEffect(() => {
        setIsOpen(props.isOpen)
    }, [props.isOpen])

    return (
        <Modal
            isOpen={isOpen}
            backdrop={true}
            toggle={toggle}
        >
            <ModalHeader>{TranslationService.translateModule('NewMessageHeader', ModuleNamesList.InvoiceDetailsMessages)}</ModalHeader>
            <ModalBody className="row flex-column">
                <textarea onChange={onChangeMessage} className="mt-1 w-100" rows={5}/>
                {!isValid && message.length <=0 && <span className="font--color-4">* {TranslationService.translate('ValidationMessageRequiredField')}</span>}
                {!isValid && message.length > props.maxMessageLength && <span className="font--color-4">* {TranslationService.translateModule('ErrorMessageMaxMessageLength', ModuleNamesList.InvoiceDetailsMessages).replace('{0}', props.maxMessageLength.toString())}</span>}
                <section className="d-flex justify-content-end mt-2">
                    <button className="btn btn-sm btn-success mr-3" onClick={submitMessage}>{TranslationService.translate('Save')}</button>
                    <button className="btn btn-sm btn-cancel btn-color-3" onClick={toggle}>{TranslationService.translate('Cancel')}</button>
                </section>
            </ModalBody>
        </Modal>
    )
}
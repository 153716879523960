import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IModuleSetting } from './types/IModuleSetting';
import { ISelectedValue } from 'core/components/Forms/types/ISelectedValue';
import { IStore } from '../../reducers/IStore';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import { Status } from '../../core/api/Enums/Status';
import ModuleSettingsApiClient from './api/ModuleSettingsApiClient';
import TranslationService from '../../core/services/TranslationService';
import useEffectAsync from '../../core/hooks/useEffectAsync';
import InfoMessageService from '../../core/services/InfoMessageService';
import { IUpdateModuleSettingRequest } from './types/IUpdateModuleSettingRequest';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import ManageModuleSettings from '../ManageModuleSettings/ManageModuleSettings';
import Loader from '../../core/components/Loading/Loader';

const ModuleSettings = (props: IExtendedModuleProps) => {
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const [modules, setModules] = useState<ISelectedValue[]>([]);
    const [hasAccess, setHasAccess] = useState<boolean>(true);
    const [moduleSettings, setModuleSettings] = useState<IModuleSetting[]>([]);
    const [moduleComplexSettings, setModuleComplexSettings] = useState<IModuleSetting[]>([]);

    const handleSimpleModuleUpdate = async (setting: IUpdateModuleSettingRequest) => {
        setIsLoadingData(true)

        try {
            const result = await ModuleSettingsApiClient.updateSetting(setting);

            if (result.status === Status.Success) {
                InfoMessageService.success(TranslationService.translateModule('SettingSucessfullyChanges', ModuleNamesList.ModuleSettings));
            } else {
                InfoMessageService.error(TranslationService.translateModule('SettingErrorChanges', ModuleNamesList.ModuleSettings));
            }
        } finally {
            const updatetedModuleSettings  = moduleSettings.map((module) => {
                if (module.id === setting.id) {
                    module.value = `${setting.value}`;
                }

                return module;
            });

            setModuleSettings(updatetedModuleSettings);
            setIsLoadingData(false);
        }
    }

    const handleComplexModuleUpdate = async (setting: IModuleSetting, moduleSettingId: number) => {
        setIsLoadingData(true);
        try {
            let result;

            if (setting.complexFields) {
                result = await ModuleSettingsApiClient.updateComplexSetting(setting);
            } else {
                result = await ModuleSettingsApiClient.updateSetting(setting);
            }

            if (result.status === Status.Success) {
                InfoMessageService.success(TranslationService.translateModule('SettingSucessfullyChanges', ModuleNamesList.ModuleSettings));

                const updatetedModuleSettings = moduleComplexSettings.map((moduleSetting) => {
                  if (moduleSetting.id === moduleSettingId) {
                    if (setting.complexFields) {
                      moduleSetting.complexFields = setting.complexFields;
                    } else {
                      moduleSetting.complexFields.map((field) => {
                        for (const fieldSetting in field.complexFieldsSettings) {
                          if (field.complexFieldsSettings[fieldSetting].complexFieldsSettingId === setting.id) {
                            field.complexFieldsSettings[fieldSetting].value = setting.value
                          }
                        }

                        return field;
                      });
                    }
                  }

                  return moduleSetting;
                });

                setModuleComplexSettings(updatetedModuleSettings);
            } else {
                InfoMessageService.error(TranslationService.translateModule('SettingErrorChanges', ModuleNamesList.ModuleSettings));
            }
        } finally {
            setIsLoadingData(false);
        }
    };

    const handleFilter = async (moduleId: number) => {
        const moduleSettings = await ModuleSettingsApiClient.loadModuleSettings(moduleId);

        setModuleSettings(moduleSettings[0] || []);
        setModuleComplexSettings(moduleSettings[1] || []);
    };

    const handleUpdate = async (moduleId: number, moduleSettingId: number, setting: any) => {
        if (setting.isComplex) {
            await handleComplexModuleUpdate(setting, moduleSettingId)
        } else {
            await handleSimpleModuleUpdate(setting)
        }
    }

    useEffectAsync(async () => {
        try {
            const modulesPlain = await ModuleSettingsApiClient.getTransferableInvoicesTable(props.module.id);
            const modules = modulesPlain.map(item => (
                {
                    key: item.id.toString(), 
                    value: item.name
                } as ISelectedValue
            ));

            setModules(modules);
        } catch (error) {
            const hasAccess = ErrorResponseHandler.hasAccess(error);
            setHasAccess(hasAccess);
        }
        // eslint-disable-next-line
    }, []);

    return (
        (!hasAccess) ? (
            <NoPermissionsComponent/>
        ) : (
            <article className="l-module">
                <section className="l-module__section l-module__section--head">
                    <h1 className="l-module__title">
                        <i className="fas fa-cogs" />
                        {TranslationService.translateModule('LeftMenuItemApplicationSettings', ModuleNamesList.ApplicationSettings)}: 
                        <strong className="l-module__title-highlighted">
                            {TranslationService.translateModule('Header', ModuleNamesList.ModuleSettings)}
                        </strong>
                    </h1>
                </section>
                {isLoadingData && <Loader main={false} opacity={0.5} /> }

                <ManageModuleSettings
                    columnsSettings={{
                        isResetAvailable: false
                    }}
                    modules={modules}
                    moduleSettings={{moduleSettings, moduleComplexSettings}}
                    onUpdate={handleUpdate} 
                    onFilter={handleFilter}
                />
            </article>
        )
    )
}

const mapStateToProps = (state: IStore) => {
    return {
        currency: state.currency
    }
};

export default connect(mapStateToProps)(ModuleSettings)
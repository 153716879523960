import React from 'react';
import { ContainerFactory } from '../factories/ContainerFactory';
import { ISkinProps } from '../../core/types/ISkinProps';

export function FullWidthSkin(props: ISkinProps) {
    const containers = props.skin.containers;
    /** @type {{search: React.CSSProperties}} */

    return (
        <>
            <div className={`skin ${props.staticHeight ? 'skin--fixed-height' : ''}`}>
                <div className="skin__row skin__row--full">
                    <div className="skin__content">
                        <ContainerFactory containers={containers} staticHeight={props.staticHeight} routeParameters={props.routeParameters} moduleIndex={0}/>
                    </div>
                </div>
            </div>
        </>
    );
}

import { CancelToken } from 'axios';
import { ICashflowDataCollection } from '../types/ICashflowDataCollection';
import RestApiClient from '../../../core/api/RestApiClient';

class CashflowApiClient {
    private cancelToken: CancelToken;

    constructor(cancelToken: CancelToken) {
        this.cancelToken = cancelToken;
    }

    public async getCashflowData(moduleInstanceId: number): Promise<ICashflowDataCollection[]> {
        return await RestApiClient.get<ICashflowDataCollection[]>(`modules/Cashflow/getCashflowData/${moduleInstanceId}`, {}, this.cancelToken);
    }
}

export default CashflowApiClient;

/** @jsx jsx */
import React from 'react';
import { css, jsx } from "@emotion/core";
import './Loader.scss';
import { ILoaderProps } from './types/ILoaderProps';

class Loader extends React.Component<ILoaderProps> {
    public state = {
        isLoaded: false
    }

    componentDidMount() {
        this.toggleLoaded();
    }

    componentDidUpdate(prevProps: ILoaderProps) {
        if (this.props.isLoaded !== prevProps.isLoaded) {
            this.toggleLoaded();
        }
    }

    render() {
        const { main, opacity, isLoaded, cssClass, hideContent } = this.props;
        const customClasses = this.prepareClassNames({
            cssClass,
            'loader--loaded': isLoaded,
            'loader--overlay': opacity,
            'hide-background': hideContent
        });

        const opacityCss = opacity ? css`opacity: ${opacity}` : '';

        return (
            !this.state.isLoaded && (
                <div className={`loader loader--small ${customClasses}`} css={opacityCss}>
                    <div className="lds-css ng-scope">
                        {/* tslint:disable-next-line jsx-self-close  */}
                        <div className="lds-wedges">
                            <div>
                                <div>
                                    <div></div>
                                </div>
                                
                                <div>
                                    <div></div>
                                </div>
                                
                                <div>
                                    <div></div>
                                </div>
                                
                                <div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        
                        {main && <p className="loader__msg">Loading</p>}
                    </div>
                </div>
            )
        );
    }

    toggleLoaded() {
        setTimeout(() => {
            this.setState({
                isLoaded: this.props.isLoaded
            })
        }, 300);
    }

    prepareClassNames(classes: {}) {
        return Object.entries(classes)
            .filter(([key, value]) => value)
            .map(([key]) => key)
            .join(' ');
    }
}

export default Loader;

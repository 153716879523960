import React from 'react';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import { Card } from '../../../core/components/Card/Card';
import { CardContent } from '../../../core/components/Card/components/CardContent';
import { CardHeader } from '../../../core/components/Card/components/CardHeader';
import { getSimpleColumns } from '../helpers/SimpleSettingsTableConfigurator';
import { IModuleSetting } from '../types/IModuleSetting';
import { IUpdateModuleSettingRequest } from '../types/IUpdateModuleSettingRequest';

interface IProps {
    data: IModuleSetting[],
    columnsSettings: any,
    resetSettings: (instanceSettingId: number) => void,
    updateSetting: (setting: IUpdateModuleSettingRequest, moduleSettingId: number) => void
}

const SimpleSettingsTableWrapper = (props: IProps) => {
    const handleChange = (value: any, id: number) => {
        const changedSetting: IUpdateModuleSettingRequest = {
            id,
            value: value,
            isComplex: false
        };

        props.updateSetting(changedSetting, id);
    };

    const handleResetClick = (instanceSettingId: number) => {
        props.resetSettings(instanceSettingId);
    };

    const prepareDataTabe = () => {
        return props.data
    };

    return (
        <Card class="mb-4">
            <>
                <CardHeader>
                    <div className="row align-items-center justify-content-between">
                        <div className="col">
                            <h1 className="c-heading">
                                Basic settings    
                            </h1>
                        </div>
                    </div>
                </CardHeader>

                <CardContent>
                    <section>
                        <BootstrapTableContainer
                            classes="bt__table bt-table"
                            columns={getSimpleColumns(props.columnsSettings, handleChange, handleResetClick)}
                            data={prepareDataTabe()}
                            keyField="id"
                            paginationProps={{}}
                            remote={false}
                            variant="secondary"
                            wrapperClasses="bt bt--secondary"
                        /> 
                    </section>
                </CardContent>
            </>
        </Card>
    )
}

export default SimpleSettingsTableWrapper;
import UsersApiClient from '../api/UsersApiClient';
import { ICreditorUserVm } from '../types/ICreditorUserVm';

export interface IIUserInvited {
    creditorId: string
}

export const InviteUserService = {
    inviteUser: async (moduleId: number, creditorId: string, userId : string, roleId : string): Promise<ICreditorUserVm> => {
        return await UsersApiClient.inviteUser(moduleId, creditorId, userId, roleId)
    }
};
import React from 'react';
import classNames from 'classnames';

type Props = {
    field: Field,
    form: Form,
    id: any,
    label: any,
    className?: any
}

type Field = {
    name: string,
    value: string,
    checked: boolean,
    onChange: () => void,
    onBlur: () => void
}

type Form = {
    errors: any, 
    touched: any, 
}

const Checkbox = ({
    field: { name, value, checked, onChange, onBlur },
    form: { errors, touched },
    id,
    label,
    ...props
  }: Props) => {
    return (
        <div className="c-control c-control--checkbox"> 
            <input
                name={name}
                id={id}
                type="checkbox"
                value={value}
                checked={checked}
                onChange={onChange}
                onBlur={onBlur}
                className={classNames('radio-button')}
            />
            <label className="c-control__label" htmlFor={id}>{label}</label>

            {touched[name] && 
                <div>{errors[name]}</div>
            }
        </div>
    );
  };

  export default Checkbox;
import React, { useState, useEffect } from 'react';
import { FormFieldset } from "../../../core/components/Forms/FormFieldset";
import TranslationService from "../../../core/services/TranslationService";
import { ModuleNamesList } from "../../../core/lists/ModuleNamesList";
import { FORM_GROUP_VARIANTS, FormGroup } from "../../../core/components/Forms/FormGroup";
import SelectField from "../../../core/components/SelectField/SelectField";
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from "../../../core/components/Button/Button";
import { IDictionary } from "../../../core/types/IDictionary";
import { DnnRoleType, OoRoleType } from "../types/IDnnMigration";
import { BootstrapTableContainer } from "../../../core/components/BootstrapTable/BootstrapTableContainer";
import { IComponentTableColumn } from "../../../core/types/IComponentTableColumn";

type RoleGroupPropsType = {
  dnnRoleGroup: DnnRoleType[];
  ooRoleGroup: OoRoleType[];
  migrationResult: any,
  onChange: (rolesSelected: any) => void;
};

type TableDataType = {
  dnnRole: string;
  dnnRoleId: number;
  ooRole: string;
  ooRoleId: string;
}

const RoleGroup = (props: RoleGroupPropsType) => {
  const [roleSelected, setRoleSelected] = useState<IDictionary<string>>({
    dnnRoleGroup: '',
    ooRoleGroup: ''
  });
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const TABLE_COLUMNS: IComponentTableColumn[] = [{
    dataField: "dnnRole",
    sort: true,
    text: "dnnRole"
  }, {
    dataField: "dnnRoleId",
    sort: true,
    hidden: true,
    text: "dnnRoleId"
  }, {
    dataField: "ooRole",
    sort: true,
    text: "ooRole"
  }, {
    dataField: "ooRoleId",
    sort: true,
    hidden: true,
    text: "ooRoleId"
  }, {
    dataField: "action",
    sort: true,
    text: "",
    formatter: (value: undefined , row: TableDataType, rowIndex: number, formatExtraData: TableDataType[]) => {
      return (
        <>
          <Button
            type="button"
            className="c-btn--icon-only"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZE.SM}
            icon={{
              className: 'fas fa-trash',
              position: ''
            }}
            onClick={() => {
              setTableData(
                formatExtraData.filter((t) => !(t.dnnRole === row.dnnRole))
              );
            }}
          />
        </>
      )
    },
    formatExtraData: tableData
  }];

  const handleOnRoleChange = (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
    setRoleSelected({
      ...roleSelected,
      [e.target.name]: value
    });
  };

  const handleOnMigrate = () => {
    const dnnRole = props.dnnRoleGroup.find(dnn => dnn.id === parseInt(roleSelected.dnnRoleGroup)) as DnnRoleType;
    const ooRole = props.ooRoleGroup.find(oo => oo.id === roleSelected.ooRoleGroup) as OoRoleType;
    const roles = [
      ...tableData,
      {
        dnnRole: dnnRole.name,
        dnnRoleId: dnnRole.id,
        ooRole: ooRole.name,
        ooRoleId: ooRole.id
      }
    ];

    setTableData(roles);

    setRoleSelected({
      dnnRoleGroup: '',
      ooRoleGroup: ''
    });
  };

  useEffect(() => {
    const result = {};

    for (let i = 0; i < tableData.length; i++) {
      result[tableData[i].dnnRoleId] = [tableData[i].ooRoleId];
    }

    props.onChange(result);
  }, [tableData]);

  useEffect(() => {
    if (props.migrationResult) {
      setTableData([]);
    }
  }, [props.migrationResult])

  return (
    <>
      <h6 className="font-weight-bold mb-3">
        {TranslationService.translateModule("MappingOfRoleGroup", ModuleNamesList.DnnMigration)}
      </h6>

      <div className="mb-4">
        <div className="row">
          <div className="col-3">
            <FormFieldset
              className="br"
              label={TranslationService.translateModule(
                'DNNRoleGroup',
                ModuleNamesList.DnnMigration
              )}
              render={() => (
                <FormGroup
                  variant={FORM_GROUP_VARIANTS.INLINE}
                  name="dnnRoleGroup"
                  label={TranslationService.translateModule(
                    'From',
                    ModuleNamesList.MessageTemplatesManagement
                  )}
                  render={(name) => (
                    <SelectField
                      id="dnnRoleGroup"
                      name="dnnRoleGroup"
                      options={props.dnnRoleGroup
                        .filter(dnn => !tableData.some((d) => d.dnnRole === dnn.name))
                        .map(r => ({name: r.name, value: r.id.toString()}))}
                      value={roleSelected.dnnRoleGroup}
                      onChange={handleOnRoleChange}
                    />
                  )}
                />
              )}
            />
          </div>

          <div className="col-3">
            <FormFieldset
              className="br"
              label={TranslationService.translateModule(
                'OnlineOfficeRoleGroup',
                ModuleNamesList.DnnMigration
              )}
              render={() => (
                <FormGroup
                  variant={FORM_GROUP_VARIANTS.INLINE}
                  name="ooRoleGroup"
                  label={TranslationService.translateModule("To", ModuleNamesList.MessageTemplatesManagement)}
                  render={name => (
                    <SelectField
                      id="ooRoleGroup"
                      name="ooRoleGroup"
                      options={props.ooRoleGroup
                        .map(oo => ({name: oo.name, value: oo.id.toString()}))}
                      value={roleSelected.ooRoleGroup}
                      onChange={handleOnRoleChange}
                    />
                  )}
                />
              )}
            />
          </div>

          <div className="col-auto align-self-end">
            <div className="c-form-group">
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTON_SIZE.MD}
              disabled={!roleSelected.dnnRoleGroup || !roleSelected.ooRoleGroup}
              label={TranslationService.translateModule("MappRoleBtn", ModuleNamesList.DnnMigration)}
              onClick={handleOnMigrate}
            />
            </div>
          </div>
        </div>

        <BootstrapTableContainer
          remote={false}
          wrapperClasses="bt"
          classes="bt__table bt-table bt--small bt-table--stripped bt--selectable"
          keyField="dnnRole"
          data={tableData}
          columns={TABLE_COLUMNS}
        />
      </div>
    </>
  )
}

export default RoleGroup;
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { Formik, Form, ErrorMessage } from 'formik';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import { InputContainer } from '../../../core/components/Forms/InputContainer';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import TranslationService from '../../../core/services/TranslationService';

type AddNewSettingPropsType = {
    emptyTableDataRow: any,
    onAddClick: (values: any) => void,
    onCancelClick: () => void
}

const AddNewSetting = (props: AddNewSettingPropsType) => {
    const [formData, setFormData] = useState<any>();
    const [initialFormValues, setInitialFormValues] = useState<any>();
    const [validateSchema, setValidateSchema] = useState<any>();

    const handleSaveClick = (values: typeof initialFormValues) => {
        props.onAddClick(values);
    }

    const handleCancelClick = () => {
        props.onCancelClick();
    }

    const createYupSchema = (schema: any, config: any) => {
        const { id, validationType, validations = [] } = config;
        
        if (!yup[validationType]) {
          return schema;
        }

        let validator = yup[validationType]();
        
        validations.forEach((validation: any) => {
          const { params, type } = validation;
          
          if (!validator[type]) {
            return;
          }

          validator = validator[type](...params);
        });

        schema[id] = validator;
        
        return schema;
    }

    useEffect(() => {
        if (!formData) {
            return;
        }

        const initialValues = {};

        formData.forEach((item: any) => {
            initialValues[item.id] = item.value || '';
        });

        setInitialFormValues(initialValues);

        const yepSchema = formData.reduce(createYupSchema, {});
        const validateSchema = yup.object().shape(yepSchema);
        
        setValidateSchema(validateSchema);
    }, [formData]);
    
    useEffect(() => {
        const formData = Object.keys(props.emptyTableDataRow.props).map((props) => {
            return {
                id: props,
                label: props,
                placeholder: props,
                type: 'text',
                validationType: "string",
                value: "",
                validations: [
                    {
                        type: 'required',
                        params: [TranslationService.translate('ValidationMessageRequiredField')]
                    }
                ]
            }
        })

        formData.push({
            id: 'name',
            label: 'name',
            placeholder: 'name',
            type: 'text',
            validationType: "string",
            value: "",
            validations: [
                {
                    type: 'required',
                    params: [TranslationService.translate('ValidationMessageRequiredField')]
                }
            ]
        });

        setFormData(formData);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                validationSchema={validateSchema}
                onSubmit={(values: typeof initialFormValues) => {
                    handleSaveClick(values);
                }}
            >
                {({
                    values,
                    handleChange,
                  }) => {
                    return (
                        <Form className="c-form">
                            <div className="row">
                                <div className="col">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.BLOCK}
                                        name="name"
                                        label="Name"
                                        render={(name) => (
                                            <>
                                                <SimpleInput 
                                                    id={name} 
                                                    name={name} 
                                                    onChange={(value) => {
                                                        const handleChangeEvent = {
                                                            target: {
                                                                value,
                                                                id: name,
                                                                name: name,
                                                            }
                                                        } as React.ChangeEvent<any>;
                                                
                                                        handleChange(handleChangeEvent);
                                                     }} 
                                                    value={values[name]} 
                                                />

                                                <ErrorMessage 
                                                    name={name} 
                                                    component="div" 
                                                    className="error error-message" 
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                {Object.keys(props.emptyTableDataRow.props).map((prop: any, index: number) => {
                                    const fieldValue = props.emptyTableDataRow.props[prop];

                                    return (
                                        <div className="col" key={index}>
                                            <FormGroup
                                                variant={FORM_GROUP_VARIANTS.BLOCK}
                                                name={prop}
                                                label={prop}
                                                render={(name) => (
                                                    <>
                                                        <InputContainer 
                                                            name={name}
                                                            type={fieldValue.type} 
                                                            id={fieldValue.id}
                                                            value={values[name]} 
                                                            onChange={(value) => {
                                                                const handleChangeEvent = {
                                                                    target: {
                                                                        value,
                                                                        id: name,
                                                                        name: name,
                                                                    }
                                                                } as React.ChangeEvent<any>;
                                                        
                                                                handleChange(handleChangeEvent);
                                                            }} 
                                                        />

                                                        <ErrorMessage 
                                                            name={name} 
                                                            component="div" 
                                                            className="error error-message" 
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Button 
                                        type="submit"
                                        id="complexSettingsTableSaveRow"
                                        variant={BUTTON_VARIANTS.PRIMARY}
                                        size={BUTTON_SIZE.SM}
                                        label="Add setting"
                                    />

                                    <Button 
                                        type="button"
                                        id="complexSettingsTableCancelAdd"
                                        className="ml-3"
                                        variant={BUTTON_VARIANTS.LINK}
                                        size={BUTTON_SIZE.SM}
                                        label="Cancel"
                                        onClick={handleCancelClick} 
                                    />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default AddNewSetting;
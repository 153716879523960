import TranslationsManagementApi from '../api/TranslationsManagementApi';
import { GetTranslationsRequest } from '../types/GetTranslationsRequest';
import { TranslationItem } from '../types/TranslationItem';
import { SaveTranslationsRequest } from '../types/SaveTranslationsRequest';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

export default {
    getTranslations: async(request: GetTranslationsRequest): Promise<TranslationItem[]> => {
        return await TranslationsManagementApi.getTranslations(request);
    },

    saveTranslations: async(request: SaveTranslationsRequest): Promise<IActionStatus> => {
        return await TranslationsManagementApi.saveTranslation(request);
    }
}
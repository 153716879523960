import * as React from 'react';
import { IModuleProps } from '../../core/types/IModuleProps';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import './_CaseStatisticsSumOfAllCases.scss';
import CaseStatisticsSumOfAllCasesApiClient from './CaseStatisticsSumOfAllCasesApiClient';
import { ISumOfAllCasesData } from './types/ISumOfAllCasesData';
import Loader from '../../core/components/Loading/Loader';
import { connect } from 'react-redux';
import { IStore } from '../../reducers/IStore';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { EventsList } from '../../core/lists/EventsList';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import CurrencyParser from '../../core/helpers/CurrencyParser';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';

interface IProps extends IModuleProps, AbortRequestPropsType {
    locale: string,
    currency: string
}

interface IState {
    isLoading: boolean;
    data: ISumOfAllCasesData[];
}

class CaseStatisticsSumOfAllCases extends React.Component<IProps, IState> {
    public state: IState = {
        isLoading: false,
        data: []
    };
    public elements = Array.apply(null, {length: 10} as any[]).map(Number.call, Number);
    private moduleEvents: IModuleEventSubscription[];

    public componentDidMount = async () => {
        this.registerModuleEvents();
        await this.loadData();
    }

    public loadData = async () => {
        this.setState({
            isLoading: true
        });
        const data = await CaseStatisticsSumOfAllCasesApiClient.getData(this.props.cancelTokenSource.token);
        this.setState({            
            isLoading: false,
            data
        });
    }

    public render() {
        const { isLoading } = this.state;

        return (
            <article className="l-module">
                <section className="l-module__section l-module__section--head mb-3">
                    <h1>
                        {TranslationService.translateModule('Header', ModuleNamesList.CaseStatisticsSumOfAllCases)}: 
                        
                        <strong className='l-module__title-highlighted'>
                            {TranslationService.translateModule('HeaderAdditional', ModuleNamesList.CaseStatisticsSumOfAllCases)}
                        </strong>
                    </h1>
                </section>

                <section className={`l-module__section ${isLoading ? 'l-module__section--loading' : ''}`}>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="case-statictics">
                            <div className="row">
                                {this.state.data.map((element: ISumOfAllCasesData, index: number) => {
                                    return <div className="col-lg-6 col-xl" key={index}>
                                        <div className="case-summary-box">
                                            <div className="icon">
                                                <i className={`${element.icon} color-${index+1}`}/>
                                            </div>
                                            <div className="box">
                                                <div className="case-sumary-amounts">
                                                    <h3>{CurrencyParser.toLocaleString(element.amount)}</h3>
                                                    <h5>{this.props.currency}</h5>
                                                </div>
                                                <h4>{element.category}</h4>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    )}
                </section>
            </article>
        )
    }

    private registerModuleEvents() {
        this.moduleEvents = [
            { name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.loadData }
        ];
        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }
}
const mapStateToProps = (state: IStore) => {
    return {
        currency: state.currency
    }
}

export default connect(mapStateToProps)(withAbortRequest(CaseStatisticsSumOfAllCases));
import RestApiClient from '../../core/api/RestApiClient';
import { IComponentTable } from '../../core/types/IComponentTable';
import { ICreditorDetails } from './types/ICreditorDetails';
import { InitialSettings } from './types/InitialSettings';
import { CancelToken } from 'axios';
import { IActionStatus } from '../../core/api/types/IActionStatus';

type TypeAlias = {
    id: number,
    name: string,
    value: string
};

const GetUserCreditorsList = async (moduleInstanceId: number, cancelToken: CancelToken): Promise<IComponentTable<ICreditorDetails>> => {
    return await RestApiClient.get<IComponentTable<ICreditorDetails>>(`modules/CreditorsManagement/getUserCreditorsList/${moduleInstanceId}`, {}, cancelToken);
};

const GetCreditorAliases = async (domainId: string, cancelToken: CancelToken): Promise<TypeAlias[]> => {
    return await RestApiClient.get<TypeAlias[]>(`modules/CreditorsManagement/getCreditorAliases/${domainId}`, {}, cancelToken);
};

const GetInitialSettings = async (moduleInstanceId: number, cancelToken: CancelToken): Promise<InitialSettings> => {
    return await RestApiClient.get<InitialSettings>(`modules/CreditorsManagement/getInitialSettings/${moduleInstanceId}`, {}, cancelToken);
};

const AddOrUpdateCreditorAlias = async (aliasData: any): Promise<IActionStatus> => {
    return await RestApiClient.post<IActionStatus>(`modules/CreditorsManagement/addOrUpdateCreditorAlias`, aliasData);
};

const RemoveCreditorAlias = async (aliasId: number, cancelToken: CancelToken): Promise<IActionStatus> => {
    return await RestApiClient.get<IActionStatus>(`modules/CreditorsManagement/RemoveCreditorAlias/${aliasId}`, {}, cancelToken);
};

export {
    AddOrUpdateCreditorAlias,
    GetInitialSettings,
    GetUserCreditorsList,
    GetCreditorAliases,
    RemoveCreditorAlias
}

import React from 'react';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

type TimelineActionsType = {
    timelineZoomIn: () => void,
    timelineZoomOut: () => void,
}

const TimelineActions = (props: TimelineActionsType) => {
    return (
        <>
            <div className="timeline-actions">
                <Button
                    variant={BUTTON_VARIANTS.ICON}
                    className="mr-2"
                    size={BUTTON_SIZE.MD}
                    icon={
                        {className: "fas fa-plus", position: ''}
                    }
                    onClick={props.timelineZoomIn}
                />

                <Button
                    variant={BUTTON_VARIANTS.ICON}
                    size={BUTTON_SIZE.MD}
                    icon={
                        {className: "fas fa-minus", position: ''}
                    }
                    onClick={props.timelineZoomOut}
                />
            </div>
        </>
    )
}

export default TimelineActions;
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../core/components/Button/Button';
import FormGroupField from '../../core/components/Forms/FormGroupField';
import FormGroupRadioButton from '../../core/components/Forms/FormGroupRadioButton';
import { phoneRegex } from '../../core/helpers/utils';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IUser } from '../RegisterUser/types/IUser';
import DcaRegisterUserApiClient from './api/DcaRegisterUserApiClient';

const DcaRegisterUser = (props: IExtendedModuleProps) => {
  const [emailFieldFocused, setEmailFieldFocused] = useState(false);
  const [emailSpinner, setEmailSpinner] = useState(false);
  const [prevEmailValue, setPrevEmailValue] = useState('');
  const [emailExistingState, setEmailExistingState] = useState(false);

  const checkIfEmailExists = async (email: string) => {
    setEmailSpinner(true);

    const result = await DcaRegisterUserApiClient.validateIsEmailExists(email);

    setEmailSpinner(false);

    return result;
  }

  const validationMessages = {
    atLeastOneLowercase: TranslationService.translate("ValidationMessagePasswordAtLeastOneLowercaseLetter"),
    atLeastOneNumber: TranslationService.translate("ValidationMessagePasswordAtLeastOneNumber"),
    atLeastOneSpecial: TranslationService.translate("ValidationMessagePasswordAtLeastOneSpecial"),
    atLeastOneUppercase: TranslationService.translate("ValidationMessagePasswordAtLeastOneUpercaseLetter"),
    email: TranslationService.translate("ValidationMessageIncorrentEmailFormat"),
    emailExists: TranslationService.translateModule("ValidationMessageEmailExists", ModuleNamesList.DcaRegisterUser),
    incorrectPhoneNoFormat: TranslationService.translate("ValidationMessageIncorrectPhoneNoFormat"),
    passwordMinimumLength: TranslationService.translate("ValidationMessagePasswordMinimumLength"),
    repeat: TranslationService.translate("ValidationMessageRepeatedIncorrectly"),
    required: TranslationService.translate("ValidationMessageRequiredField"),
  }

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(validationMessages.required),
    lastName: yup.string().required(validationMessages.required),
    phone: yup.string().required(validationMessages.required)
      .matches(phoneRegex, validationMessages.incorrectPhoneNoFormat),
    email: yup.string().email(validationMessages.email)
      .required(validationMessages.required)
      .test('email-exists', validationMessages.emailExists, async (value) => {
        let emailExists = emailExistingState;
        if (value && emailFieldFocused === false && prevEmailValue !== value) {
          emailExists = await checkIfEmailExists(value);

          setPrevEmailValue(value);
          setEmailExistingState(!emailExists);

          emailExists = !emailExists;
        }

        return emailExists;
      }),
    emailRepeat: yup.string().oneOf([yup.ref('email'), null], validationMessages.repeat)
    .email(validationMessages.email).required(validationMessages.required),
    password: yup.string().required(validationMessages.required)
      .min(6, validationMessages.passwordMinimumLength)
      .matches(/[a-z]+/, validationMessages.atLeastOneLowercase)
      .matches(/[A-Z]+/, validationMessages.atLeastOneUppercase)
      .matches(/[0-9]+/, validationMessages.atLeastOneNumber)
      .matches(/[!@#$%&]+/, validationMessages.atLeastOneSpecial),
    passwordRepeat: yup.string().oneOf([yup.ref('password'), null], validationMessages.repeat).required(validationMessages.required),
  });

  const callExitHandler = (actionStatus?: boolean) => {
    if (props.exit) {
      props.exit(actionStatus);
    }
  };

  return (<div>
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        emailRepeat: '',
        defaultLanguage: '',
        userType: '',
        password: '',
        passwordRepeat: '',
        userAuthorizationType: '0'
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await DcaRegisterUserApiClient.createUser({
          firstName: values.firstName,
          email: values.email,
          emailRepeat: values.emailRepeat,
          lastName: values.lastName,
          password: values.password,
          passwordRepeat: values.passwordRepeat,
          phone: values.phone,
          userAuthorizationType: parseInt(values.userAuthorizationType, 10)
        } as IUser, props.module.id)
        setSubmitting(false);
        callExitHandler(true);
      }}
    >
      {({ values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        validateField
      }) => {
        return (
          <Form className="C-form" autoComplete="off">
            <div className="contacts-form">
              <FormGroupField
                errors={errors}
                fieldName="firstName"
                handleChange={handleChange}
                label={TranslationService.translateModule('ContactsFirstName', ModuleNamesList.DcaRegisterUser)}
                handleBlur={handleBlur}
                touched={touched}
                required={true}
                value={values.firstName}
              />

              <FormGroupField
                errors={errors}
                fieldName="lastName"
                handleChange={handleChange}
                label={TranslationService.translateModule('ContactsLastName', ModuleNamesList.DcaRegisterUser)}
                handleBlur={handleBlur}
                touched={touched}
                required={true}
                value={values.lastName}
              />

              <FormGroupField
                errors={errors}
                fieldName="phone"
                handleChange={handleChange}
                label={TranslationService.translateModule('ContactsPhone', ModuleNamesList.DcaRegisterUser)}
                handleBlur={handleBlur}
                placeholder={TranslationService.translate('PhoneNumberFieldPlaceholder')}
                touched={touched}
                required={true}
                value={values.phone}
              />

              <FormGroupField
                errors={errors}
                fieldName="email"
                handleChange={(e: any) => {
                  setEmailFieldFocused(true); 
                  handleChange(e); }}
                label={TranslationService.translateModule('ContactsEmail', ModuleNamesList.DcaRegisterUser)}
                handleBlur={(e) => { 
                  setEmailFieldFocused(false);
                  handleBlur(e);
                }}
                touched={touched}
                loadingSpinner={emailSpinner}
                required={true}
                autoComplete='new-password'
                value={values.email}
              />

              <FormGroupField
                errors={errors}
                fieldName="emailRepeat"
                handleChange={handleChange}
                label={TranslationService.translateModule('ContactsEmailRepeat', ModuleNamesList.DcaRegisterUser)}
                handleBlur={handleBlur}
                touched={touched}
                type="email"
                required={true}
                value={values.emailRepeat}
              />

              <FormGroupField
                errors={errors}
                fieldName="password"
                handleChange={handleChange}
                label={TranslationService.translateModule('ContactsPassword', ModuleNamesList.DcaRegisterUser)}
                handleBlur={handleBlur}
                touched={touched}
                type="password"
                required={true}
                value={values.password}
              />

              <FormGroupField
                errors={errors}
                fieldName="passwordRepeat"
                handleChange={handleChange}
                label={TranslationService.translateModule('ContactsPasswordRepeat', ModuleNamesList.DcaRegisterUser)}
                handleBlur={handleBlur}
                touched={touched}
                type="password"
                required={true}
                value={values.passwordRepeat}
              />

              <FormGroupRadioButton
                errors={errors}
                fieldName="userAuthorizationType"
                handleChange={handleChange}
                options={
                  [
                    { label: TranslationService.translateModule('UserAuthorizationType_Creditor', ModuleNamesList.DcaRegisterUser), value: "0" },
                    { label: TranslationService.translateModule('UserAuthorizationType_Dca', ModuleNamesList.DcaRegisterUser), value: "1" }
                  ]
                }
                label={TranslationService.translateModule('UserAuthorizationType', ModuleNamesList.DcaRegisterUser)}
                touched={touched}
                labelClass="col-sm-8"
                handleBlur={handleBlur}
                inputContainerClass="col-sm-4"
                value={values.userAuthorizationType}
              />
              <Button
                type="submit"
                showLoader={isSubmitting}
                disabled={isSubmitting}
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTON_SIZE.MD}
                label={TranslationService.translate('Save')}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  </div>)
}

export default DcaRegisterUser

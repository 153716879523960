import RestApiClient from '../../../core/api/RestApiClient';
import { IPaymentsHistory } from '../types/Payment';
import { CancelToken } from 'axios';

class PaymentsApiClient {
    private cancelToken: CancelToken;

    constructor(cancelToken: CancelToken) {
        this.cancelToken = cancelToken;
    }

    public async getPayments(moduleInstanceId: number) {
        return await RestApiClient.get<IPaymentsHistory>(`modules/PaymentsModule/${moduleInstanceId}`, {}, this.cancelToken);
    }
}

export default PaymentsApiClient;
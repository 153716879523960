import { CancelToken } from 'axios';
import { IActionStatus } from 'core/api/types/IActionStatus';
import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { CreditorOnboardingAgreement } from '../types/CreditorOnboardingAgreement';


const CreditorOnboardingAgreementsApiClient = {
    async getCreditorAgreementsList(moduleInstanceId: number, cancelToken: CancelToken): Promise<IComponentTable<CreditorOnboardingAgreement>> {
        return await RestApiClient.get<IComponentTable<CreditorOnboardingAgreement>>(`modules/CreditorOnboardingAgreements/getCreditorAgreementsList/${moduleInstanceId}`, {}, cancelToken);
    },
    async addOrEdit(item: CreditorOnboardingAgreement, cancelToken: CancelToken): Promise<IActionStatus<CreditorOnboardingAgreement>> {
        return await RestApiClient.post<IActionStatus<CreditorOnboardingAgreement>>(`modules/CreditorOnboardingAgreements/addOrEdit`, item);
    },
    async remove(itemId: number): Promise<IActionStatus> {
        return await RestApiClient.delete<IActionStatus>(`modules/CreditorOnboardingAgreements/remove/${itemId}`);
    }
}

export default CreditorOnboardingAgreementsApiClient;

import React from 'react';
import { Link } from 'react-router-dom';
import { CaseProgressType } from '../../../../core/Enums/CaseProgressType';
import CurrencyParser from '../../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../../core/helpers/DateTimeParser';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';
import TranslationService from '../../../../core/services/TranslationService';
import { IComponentTable } from '../../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../../core/types/IComponentTableColumn';
import { CaseProductType } from '../../../CustomerTransactionsList/types/CaseProductType';
import { UnitCase } from '../types/UnitCase';
import { UnitCasesColumns } from '../types/UnitCasesColumns';

const configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
  const currencyColumns = [UnitCasesColumns.OriginalClaim, UnitCasesColumns.PaidBalance, UnitCasesColumns.TotalBalance, UnitCasesColumns.RemainBalance];
  const currencyColumnsNames = currencyColumns.map(col => UnitCasesColumns[col].charAt(0).toLowerCase() + UnitCasesColumns[col].slice(1));
  const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
  const formatterFunc = (value: any) => CurrencyParser.toLocaleString(value);

  for (const col of cols) {
    col.formatter = formatterFunc;
    col.classes = () => 'bt-table__cell bt-table__cell--text-right';
    col.headerClasses = () => 'bt-table__header bt-table__header--text-right';
  }
};

const configureDateColumns = (columns: IComponentTableColumn[]) => {
  const dateColumns = [UnitCasesColumns.RegDate, UnitCasesColumns.CloseDate];
  const currencyColumnsNames = dateColumns.map(col => UnitCasesColumns[col].charAt(0).toLowerCase() + UnitCasesColumns[col].slice(1));
  const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
  const formatterFunc = (value: any) => DateTimeParser.toLocaleDateString(value);

  for (const col of cols) {
    col.formatter = formatterFunc;
  }
};

const statusIconRowData = (value: any, element: UnitCase) => {
  const mapping = {
    [CaseProgressType.PreLegal]: "case-progress-icon case-progress-icon--pre-legal",
    [CaseProgressType.Reminder]: "case-progress-icon case-progress-icon--reminder",
    [CaseProgressType.Legal]: "case-progress-icon case-progress-icon--legal",
    [CaseProgressType.Surveillance]: "case-progress-icon case-progress-icon--surveillance",
    [CaseProgressType.Invoice]: "case-progress-icon case-progress-icon--invoice"
  };

  return <i className={mapping[element.caseProgress]} />;
};

const configureCaseProgressIconColumn = (columns: IComponentTableColumn[]) => {
  const productCol = [UnitCasesColumns.CaseProgress];
  const productColName = productCol.map(col => UnitCasesColumns[col].charAt(0).toLowerCase() + UnitCasesColumns[col].slice(1));
  const col = columns.filter(col => productColName.indexOf(col.dataField) >= 0)[0];

  if (col) {
    col.formatter = (value: any, element: UnitCase) => TranslationService.translateModule(`ProductType_${CaseProductType[value]}`, ModuleNamesList.UnitCases);

    let statusIconColumn = {
      dataField: 'statusIcon',
      text: '',
      sort: false,
    } as IComponentTableColumn;

    statusIconColumn.formatter = statusIconRowData;
    statusIconColumn.headerClasses = () => 'bt-table__header bt-table__header--single-icon';
    statusIconColumn.classes = () => {
      return 'bt-table__cell bt-table__cell--text-center';
    };

    columns.splice(0, 0, statusIconColumn);
  }
};

const caseNumberRowData = (value: any, row: any) => {
  const link = `/case?id=${row.caseNo}`;
  return (
      <Link to={link} id="caseListItemCaseNoLink" className="font-color-1 font-weight-bold">{row.caseNo}</Link>
  )
}

const configureCaseNoLinks = (columns: IComponentTableColumn[]) => {
  const dateColumns = [UnitCasesColumns.CaseNo];
  const currencyColumnsNames = dateColumns.map(col => UnitCasesColumns[col].charAt(0).toLowerCase() + UnitCasesColumns[col].slice(1));
  const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);

  for (const col of cols) {
    col.formatter = caseNumberRowData;
  }
}


const TableConfigurator = {
  getTableColumns: (table: IComponentTable<UnitCase>) => {
    let columns: IComponentTableColumn[] = [];
    if (table != null) {
      columns = [...table.columns];
      configureCurrencyColumns(columns);
      configureDateColumns(columns);
      configureCaseProgressIconColumn(columns);
      configureCaseNoLinks(columns);

      return columns;
    }
    return columns;
  }
}

export default TableConfigurator
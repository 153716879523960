import RestApiClient from '../../core/api/RestApiClient';
import { IOnlineReportsVm } from './../Reports/types/IOnlineReportsVm';
import IReportParameter from './../Reports/types/IReportParameter';
import { IDictionary } from '../../core/types/IDictionary';

class OnlineReportsApiClient {
    public async getReports(moduleId: number): Promise<IOnlineReportsVm> {
        return await RestApiClient.get<IOnlineReportsVm>(`modules/OnlineReportsModule/${moduleId}`);
    }
    public async getReportParameters(moduleId: number, reportId: string): Promise<IReportParameter[]> {
        return await RestApiClient.get<IReportParameter[]>(`modules/OnlineReportsModule/${moduleId}/report/${reportId}`);
    }
    public async getParameterDateValue(moduleId: number, dateValueName: string): Promise<string> {
        return await RestApiClient.get<string>(`modules/OnlineReportsModule/${moduleId}/parameterDateValue/${dateValueName}`);
    }
    public async getParameterDateRange(moduleId: number, dateRangeName: string): Promise<IDictionary<string>> {
        return await RestApiClient.get<IDictionary<string>>(`modules/OnlineReportsModule/${moduleId}/parameterDateRange/${dateRangeName}`);
    }
}

export default new OnlineReportsApiClient();

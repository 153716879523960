export interface IInvoiceDetails {
    invoiceNo: string,
    status: string,
    regDate: string,
    dueDate: string,
    nextReminder: string,
    accountNo: string,
    kid: string,
    creditorNo: string,
    creditorFirstName: string,
    creditorLastName: string,
    reference: string,
    caseHandlerName: string,
    originalAmount: number,
    remainingAmount: number,
    remainingFee: number,
    caseNo: string
}

export const emptyInvoiceDetails: IInvoiceDetails = {
    invoiceNo: '',
    status: '',
    regDate: '',
    dueDate: '',
    nextReminder: '',
    accountNo: '',
    kid: '',
    creditorNo: '',
    creditorFirstName: '',
    creditorLastName: '',
    reference: '',
    caseHandlerName: '',
    originalAmount: 0,
    remainingAmount: 0,
    remainingFee: 0,
    caseNo: ''
}
import * as React from 'react';
import SimpleStep from './SimpleStep';
import { IWelcomeProps } from '../types/IWelcomeProps';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import { Redirect } from 'react-router';
import { IWelcomeState } from '../types/IWelcomeState';
import RouteParamsService from '../../../core/services/RouteParamsService';

class Welcome extends React.Component<IWelcomeProps> {

    public state: IWelcomeState = {
        toHomePage: false,
    };

    public goToHomePage = () =>{
        this.setState({
            toHomePage: true
        });
    }

    public render() {
        if (this.state.toHomePage === true) {
            const queryString = RouteParamsService.getQueryParameters();
            let redirectUrl = '/';
            if (queryString['token']) {
                redirectUrl += '?token=' + queryString['token']
            }

            return <Redirect to={redirectUrl} />
        }
        const bodyContent = TranslationService.translateModule("WelcomeContent", ModuleNamesList.RegisterUserModuleName).replace("{companyName}",this.props.companyName);    
        return <>
                <SimpleStep title={TranslationService.translateModule("WelcomeHeader", ModuleNamesList.RegisterUserModuleName)} body={bodyContent}/> 
                <StepListWizardButtons
                    rightButtonOnClick={this.goToHomePage}
                    rightButtonText={<>
                        <i className="fas fa-home left"/> {TranslationService.translateModule('WelcomeButtonText', ModuleNamesList.RegisterUserModuleName)}
                    </>}
                />
        </>
    }
}

export default Welcome;

export enum InvoicesColumns {
  CaseNo = 0,
  InvoiceNumber = 1,
  Description = 2,
  InvoiceDate = 3,
  DueDate = 4,
  OriginalAmount = 5,
  Movement = 6,
  Balance = 7,
  InterestBalance = 8,
  Currency = 9,
}

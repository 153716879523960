import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { Status } from '../../core/api/Enums/Status';
import { ISelectedValue } from '../../core/components/Forms/types/ISelectedValue';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import ManageModuleSettings from '../ManageModuleSettings/ManageModuleSettings';
import { IModuleSetting } from '../ManageModuleSettings/types/IModuleSetting';
import { ManagedPageModel } from '../PagesList/Models/ManagedPageModel';
import ManageModuleInstancesApiClient from './api/ManageModuleInstancesApiClient';
import { IUpdateModuleSettingRequest } from './types/IUpdateModuleSettingRequest';
import TModuleSetting from './types/TModuleSetting';
import ManagePagePermissions from './components/ManagePagePermissions';

type ManageModulesInstancesPropsType = RouteComponentProps & AbortRequestPropsType & {
    routeParameters: {
        id: string,
        profileId: string
    }
}

const ManageModulesInstances = (props: ManageModulesInstancesPropsType) => {
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
    const [moduleSettings, setModuleSettings] = useState<IModuleSetting[]>([]);
    const [moduleComplexSettings, setModuleComplexSettings] = useState<IModuleSetting[]>([]);
    const [modules, setModules] = useState<ISelectedValue[]>();
    const [pageModule, setPageModule] = useState<ManagedPageModel>();
    const [hasAccess, setHasAccess] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState(1);

    const manageModuleInstancesApiClient = new ManageModuleInstancesApiClient(props.cancelTokenSource.token);

    const fetchData = async () => {
        setIsLoadingData(true);

        try {
            const pageModule = await manageModuleInstancesApiClient.getPageModuleInsatances(props.routeParameters.id);
            const pageModuleInstances = pageModule.moduleInstances.map(item => (
                {
                    key: item.id.toString(),
                    value: item.name
                } as ISelectedValue
            ));

            setPageModule(pageModule);
            setModules(pageModuleInstances);
        } catch (error) {
            const hasAccess = ErrorResponseHandler.hasAccess(error);

            setHasAccess(hasAccess);
        } finally {
            setIsLoadingData(false);
        }
    };

    const getModuleInstanceSettings = async (moduleId: number) => {
        setIsLoadingData(true);

        try {
            const moduleInstanceSettings = await manageModuleInstancesApiClient.getModuleInstanceSettings(moduleId);

            setModuleSettings(moduleInstanceSettings[0] || []);
            setModuleComplexSettings(moduleInstanceSettings[1] || []);
        } finally {
            setIsLoadingData(false);
        }
    };

    const handleUpdate = async (moduleInstanceId: number, moduleSettingsId: number, updatedModuleSetting: IUpdateModuleSettingRequest) => {
        setIsLoadingData(true);

        try {
            const isComplexForSelectType = (updatedModuleSetting: IUpdateModuleSettingRequest) => {
                return updatedModuleSetting.isComplex ? (moduleSettingsId !== updatedModuleSetting.id) : false;
            }

            const { data, status } = await manageModuleInstancesApiClient.updateSetting({
                ...updatedModuleSetting,
                moduleInstanceId,
                isComplex: isComplexForSelectType(updatedModuleSetting)
            });

            if (data.complexFields.length) {
                const settings = moduleComplexSettings.map((setting: TModuleSetting) => {
                    if (moduleSettingsId === setting.id) {
                        setting.id = data.id;
                        setting.isDefault = data.isDefault;
                        setting.value = data.value;
                        setting.complexFields = data.complexFields
                    }

                    return setting;
                });

                setModuleComplexSettings(settings);
            } else {
                const settings = [...moduleSettings].map((setting: TModuleSetting) => {
                    if (moduleSettingsId === setting.id) {
                        setting.id = data.id;
                        setting.isDefault = data.isDefault;
                        setting.value = data.value;
                    }

                    return setting;
                });

                setModuleSettings(settings);
            }

            if (status === Status.Success) {
                InfoMessageService.success(TranslationService.translateModule('SettingSucessfullyChanges', ModuleNamesList.ModuleSettings));
            } else {
                InfoMessageService.error(TranslationService.translateModule('SettingErrorChanges', ModuleNamesList.ModuleSettings));
            }
        } finally {
            setIsLoadingData(false);
        }
    };

    const handleFilter = (moduleId: number) => {
        getModuleInstanceSettings(moduleId);
    };

    const handleReset = async (instanceSettingId: number, moduleId: number) => {
        try {
            const { status } = await manageModuleInstancesApiClient.deleteModuleInstanceSetting(instanceSettingId);

            if (status === Status.Success) {
                InfoMessageService.success(TranslationService.translateModule('SettingSucessfullyChanges', ModuleNamesList.ModuleSettings));
            } else {
                InfoMessageService.error(TranslationService.translateModule('SettingErrorChanges', ModuleNamesList.ModuleSettings));
            }
        } finally {
            getModuleInstanceSettings(moduleId);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        (!hasAccess) ? (
            <NoPermissionsComponent />
        ) : (
                <article className="l-module">
                    {pageModule && (
                        <section className="l-module__section l-module__section--head">
                            <h1 className="l-module__title">
                                <i className="fas fa-cogs" />
                                {TranslationService.translateModule('ModuleInstancesSettings', ModuleNamesList.ApplicationSettings)}:

                            <strong className="l-module__title-highlighted">
                                    {pageModule.name}
                                </strong>
                            </h1>
                        </section>
                    )}
                    <div className="tab">
                        <ul className="tab__nav nav-tabs">
                            <li className={`tab__nav-item ${activeTab === 1 ? 'tab__nav-item--active' : ''}`} key={`tab-item_1`}>
                                <p className="tab__nav-link" onClick={() => setActiveTab(1)}>
                                    {TranslationService.translateModule('ModulesSettings', ModuleNamesList.ModuleSettings)}
                                </p>
                            </li>
                            {pageModule && !pageModule.isPublic && <li className={`tab__nav-item ${activeTab === 2 ? 'tab__nav-item--active' : ''}`} key={`tab-item_2`}>
                                <p className="tab__nav-link" onClick={() => setActiveTab(2)}>
                                    {TranslationService.translateModule('PagePermissions', ModuleNamesList.ManagePagePermissions)}
                                </p>
                            </li>}
                        </ul>
                    </div>
                    <div className="tab__body">
                        {activeTab === 1 ?
                            <ManageModuleSettings
                                columnsSettings={{
                                    isResetAvailable: true
                                }}
                                modules={modules}
                                moduleSettings={{ moduleSettings, moduleComplexSettings }}
                                onUpdate={handleUpdate}
                                onFilter={handleFilter}
                                onReset={handleReset}
                                isLoadingData={isLoadingData}
                            />
                            : <ManagePagePermissions
                            pageId={parseInt(props.routeParameters.id)} />}
                    </div>

                </article>
            )
    )
}

export default compose(withRouter, withAbortRequest)(ManageModulesInstances);
import RestApiClient from '../../../core/api/RestApiClient';
import { IInvoicesAgeAnalysisRequest } from '../types/IInvoicesAgeAnalysisRequest';
import { IInvoicesAgeAnalysisSummary } from '../types/IInvoicesAgeAnalysisSummary';
import { IInvoiceAgeAnalysisInitialSetings } from '../types/IInvoiceAgeAnalysisInitialSetings';
import { CancelToken } from 'axios';

class InvoicesAgeAnalysisApi {
    public async getInvoicesAgeAnalysisData(request: IInvoicesAgeAnalysisRequest, cancelToken: CancelToken): Promise<IInvoicesAgeAnalysisSummary> {
        return await RestApiClient.post<IInvoicesAgeAnalysisSummary>("modules/InvoicesAgeAnalysisModule", request, undefined, undefined, undefined, undefined, cancelToken)
    }

    public async getInitialSettings(moduleInstanceId: number, cancelToken: CancelToken): Promise<IInvoiceAgeAnalysisInitialSetings> {
        return await RestApiClient.get<IInvoiceAgeAnalysisInitialSetings>(`modules/InvoicesAgeAnalysisModule/${moduleInstanceId}`, {} , cancelToken)
    }
}

export default new InvoicesAgeAnalysisApi();
import StringComponentInput from '../components/StringComponentInput';

class ComponentInputTypeMapper {
    public getSettingInput(settingType: string)
    {
        switch(settingType)
        {
            default:
                return StringComponentInput;
        }
    }
}


export default new ComponentInputTypeMapper();
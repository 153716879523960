import * as React from 'react';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import { ModuleFactory } from '../../core/factories/ModuleFactory';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../core/lists/EventsList';
import TranslationService from '../../core/services/TranslationService';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { IModuleProps } from '../../core/types/IModuleProps';
import CaseTabsService from './services/CaseTabsService';
import { ICaseTabsSettings } from './types/CaseTabsSettings';
import Loader from 'core/components/Loading/Loader';

interface IState {
    isDataLoading: boolean,
    settings: ICaseTabsSettings,
    selectedTabIndex: number,
    hasAccessToModule: boolean
}

export default class CaseTabs extends React.Component<IModuleProps, IState> {
    public state: IState = {
        isDataLoading: false,
        hasAccessToModule: false,
        settings: {
            tabsSettings: []
        },
        selectedTabIndex: 0
    }

    private moduleEvents: IModuleEventSubscription[] = [];

    public componentDidMount() {
        this.loadData();
        this.registerModuleEvents();
    }

    public componentWillUnmount() {
        ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
    }

    public render() {
        const selectedTabSetting = this.state.settings.tabsSettings[this.state.selectedTabIndex];
        if (!this.state.hasAccessToModule && !this.state.isDataLoading) {
            return <NoPermissionsComponent />
        }

        return (
            <div className="tab">
                { this.state.isDataLoading && <Loader />}
                <ul className="tab__nav nav-tabs">
                    {this.state.settings.tabsSettings.map((s, idx) =>
                        <li className={`tab__nav-item ${this.state.selectedTabIndex === idx ? 'tab__nav-item--active' : ''}`} key={`tab-item_${idx}`}>
                            <p className="tab__nav-link" onClick={() => this.selectTab(idx)}>
                                {TranslationService.translateModule(s.name, this.props.module.name)}
                            </p>    
                        </li>
                    )}
                </ul>
                <div className="tab__body">
                    {selectedTabSetting &&
                        <ModuleFactory routeParameters={this.props.routeParameters} moduleIndex={0} modules={[selectedTabSetting.module]} />
                    }
                </div>
            </div>
        )
    }

    private selectTab = (index: number) => {
        this.setState({
            selectedTabIndex: index
        })
    }

    private loadData = async () => {
        this.setState({
            isDataLoading: true
        });

        try {
            const caseId = this.props.routeParameters.id as string;
            const settings = await CaseTabsService.getModuleSettings(caseId, this.props.module.id);

            this.setState({
                isDataLoading: false,
                hasAccessToModule: true,
                settings,
            });
        }
        catch (err) {
            this.setState({
                hasAccessToModule: err.response && err.response.status ? !(err.response.status === 403) : false,
                isDataLoading: false
            })
        }
    }

    private registerModuleEvents() {
        this.moduleEvents = [...this.moduleEvents, { name: EventsList.CASE_DATA_UPDATED, callback: this.loadData }]
        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }
}

import { Status } from "core/api/Enums/Status";
import * as React from "react";
import Loader from "../../../core/components/Loading/Loader";
import { ModuleNamesList } from "../../../core/lists/ModuleNamesList";
import TranslationService from "../../../core/services/TranslationService";
import { IDictionary } from "../../../core/types/IDictionary";
import StepListWizardButtons from "../../../modules/StepListWizard/StepListWizardButtons";
import CreateNewCaseApiClient from "../api/CreateNewCaseApiClient";
import { CaseType } from "../types/CaseType";
import { CollectionNotice } from "../types/CollectionNotice";
import { IDebtorDetails } from "../types/IDebtorDetails";
import { IInitialSettings } from "../types/IInitialSettings";
import { IInvoiceDetails } from "../types/IInvoiceDetails";
import { IInvoicesSetup } from "../types/IInvoicesSetup";
import { IClaimInfo } from "./IClaimInfo";
import InvoicesList from "./InvoicesList";
import NewInvoiceForm from "./NewInvoiceForm";
import RadioSelectBoxes from "./RadioSelectBoxes";

interface IState {
  isLoading: boolean;
  showEmptyInvoicesError: boolean;
  doesCreditorAccessToDebtAdmin: boolean;
  casesType: IDictionary<string | boolean | number>[];
  caseTypeSelectedId: string;
}

interface IProps {
  caseType: CaseType;
  claimInfo: IClaimInfo;
  invoicesSetup: IInvoicesSetup;
  invoicesList: IInvoiceDetails[];
  debtorDetails: IDebtorDetails;
  instanceSettings: IInitialSettings;
  backToPrevStep(): void;
  goToNextStep(): void;
  setCaseType(caseType: CaseType): void;
  addNewInvoice(invoice: IInvoiceDetails): void;
  updateInvoice(index: number, invoice: IInvoiceDetails): void;
  attachFiles(files: FileList, index: number): void;
  removeInvoice(index: number): void;
}

class CaseInfo extends React.Component<IProps, IState> {
  public state: IState = {
    isLoading: true,
    showEmptyInvoicesError: false,
    doesCreditorAccessToDebtAdmin: true,
    casesType: [],
    caseTypeSelectedId: this.props.caseType.toString()
  };


  private getCaseTypeOptions = (
    doesCreditorAccessToDebtAdmin: boolean
  ): IDictionary<string | boolean | number>[] => {
    let availableCaseProductTypes = this.props.instanceSettings.availableCaseProductTypes;

    if (doesCreditorAccessToDebtAdmin) {
      availableCaseProductTypes = this.props.instanceSettings.availableCaseProductTypes.filter((x) =>
        !(x.caseType === CaseType.CMSDebtAdmin && !doesCreditorAccessToDebtAdmin)
      );
    }

    if(this.props.instanceSettings.showExtendedWorkflow && this.props.claimInfo.collectionNoticeType === CollectionNotice.No){
      availableCaseProductTypes = this.props.instanceSettings.availableCaseProductTypes.filter((x) =>
        !(x.caseType === CaseType.CollectionCase)
      );
    }
    if(this.props.instanceSettings.showExtendedWorkflow && this.props.claimInfo.collectionNoticeType === CollectionNotice.YesWithReminderFee){
      availableCaseProductTypes = this.props.instanceSettings.availableCaseProductTypes.filter((x) =>
        !(x.caseType === CaseType.CollectionNotice)
      );
    }

    return availableCaseProductTypes
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map((item) => {
        return {
          key: item.caseType,
          value: TranslationService.translateModule(
            item.translationKey,
            ModuleNamesList.CreateNewCase
          ),
          disabled: this.props.instanceSettings.showExtendedWorkflow && this.props.claimInfo.collectionNoticeType === 1 && item.caseType === CaseType.CollectionNotice
        };
      });
  };

  checkCreditorAccessToDebtAdmin = async () => {
    let doesCreditorAccessToDebtAdmin;

    this.setState({
      isLoading: true,
    });

    if (
      this.props.instanceSettings.availableCaseProductTypes.some(
        (x) => x.caseType === CaseType.CMSDebtAdmin
      )
    ) {
      const doesCreditorAccessToDebtAdminResponse =
        await CreateNewCaseApiClient.doesCreditorAccessToDebtAdmin(
          this.props.debtorDetails.domainId
        );

      doesCreditorAccessToDebtAdmin =
        doesCreditorAccessToDebtAdminResponse.status === Status.Success;
    } else {
      doesCreditorAccessToDebtAdmin = false;
    }

    const casesType = this.getCaseTypeOptions(doesCreditorAccessToDebtAdmin);

    if (this.props.instanceSettings.showExtendedWorkflow) {
      let caseTypeSelected = casesType.find((x) => {
        if (this.props.claimInfo.collectionNoticeType === 1) {
          return x.key !== CaseType.CollectionNotice
        }

        return false;
      });

      if (caseTypeSelected) {
        this.props.setCaseType(caseTypeSelected.key as number);

        this.setState({
          caseTypeSelectedId: caseTypeSelected.key.toString()
        })
      }
    }

    this.setState({
      isLoading: false,
      casesType,
      doesCreditorAccessToDebtAdmin,
    });
  };

  componentDidMount = async () => {
    await this.checkCreditorAccessToDebtAdmin();
  };

  componentDidUpdate(prevProps: IProps) {}
  public render() {
    return (
      <>
        <h6 className="create-case-header">
          {TranslationService.translateModule(
            'CaseInfoCaseTypeSelectCaseType',
            ModuleNamesList.CreateNewCase
          )}
        </h6>

        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="form-group row mb-1 mb-sm-3">
              <div className="col-12">
                <RadioSelectBoxes
                  elements={this.state.casesType}
                  value={this.state.caseTypeSelectedId}
                  onChange={(caseType: string) => {
                    this.setState({
                      ...this.state,
                      caseTypeSelectedId: caseType
                    })
                    this.props.setCaseType(parseInt(caseType, 0));
                  }}
                  name="caseType"
                  asArray
                />
              </div>
            </div>

            <NewInvoiceForm
              addNewInvoice={this.props.addNewInvoice}
              invoicesSetup={this.props.invoicesSetup}
              instanceSettings={this.props.instanceSettings}
              claimInfo={this.props.claimInfo}
              debtorDetails={this.props.debtorDetails}
            />

            <InvoicesList
              invoicesList={this.props.invoicesList}
              removeInvoice={this.props.removeInvoice}
              attachFiles={this.props.attachFiles}
              updateInvoice={this.props.updateInvoice}
              instanceSettings={this.props.instanceSettings}
            />

            {this.props.invoicesList.length === 0 &&
              this.state.showEmptyInvoicesError && (
                <div className="error error-message">
                  {TranslationService.translateModule(
                    'CaseInfoInvoicesListEmptyInvoicesListWarning',
                    ModuleNamesList.CreateNewCase
                  )}
                </div>
              )}
            <hr className="mt-2 mb-3" />

            <StepListWizardButtons
              leftButtonOnClick={this.props.backToPrevStep}
              leftButtonText={
                <>
                  <i className="fas fa-chevron-left left" />
                  {' ' +
                    TranslationService.translateModule(
                      'CaseInfoBackButton',
                      ModuleNamesList.CreateNewCase
                    )}
                </>
              }
              rightButtonOnClick={() =>
                this.props.invoicesList.length === 0
                  ? this.setState({ showEmptyInvoicesError: true })
                  : this.props.goToNextStep()
              }
              rightButtonClassName="c-btn--icon-right"
              rightButtonText={
                <>
                  {TranslationService.translateModule(
                    'CaseInfoNextButton',
                    ModuleNamesList.CreateNewCase
                  ) + ' '}
                  <i className="fas fa-chevron-right right" />
                </>
              }
            />
          </>
        )}
      </>
    );
  }
}

export default CaseInfo;

export enum InvoicesColumns {
        CaseNo = 0,
        Id = 1,
        InvoiceNumber = 2,
        Description = 3,
        InvoiceDate = 4,
        DueDate = 5,
        OriginalAmount = 6,
        Movement = 7,
        Balance = 8,
        InterestBalance = 9,
        Currency = 10,
}

export enum InvoicesPaymentsColumns {
        PaidDate = 0,
        Description = 1,
        Capital = 2,
        Interest = 3,
        InvoiceFee = 4,
}
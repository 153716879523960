import * as React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../reducers/IStore';
import { ICreditor } from '../types/ICreditor';
import { ISkinProps } from '../types/ISkinProps';
import TranslationService from './../../core/services/TranslationService';
import './CreditorAwaitingApprovalSkin.scss';
import ListGroup from 'reactstrap/lib/ListGroup';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';

interface IProps {
    creditors: ICreditor[],
    staticHeight: boolean,
}

class CreditorAwaitingApprovalSkin extends React.Component<ISkinProps & IProps> {
    public render() {
        const creditorsList = this.getPendingCreditorsList();
        return (

            <div className={`skin ${this.props.staticHeight ? 'skin--fixed-height' : ''}`}>
                <div className="skin__row">
                    <div className="skin__content">
                        <div className="creditor-approval-waiting-container">
                            <div className="creditor-approval-waiting-window">
                                <div className="creditor-approval-waiting-title">{TranslationService.translate('Welcome')}!</div>
                                <div className="creditor-approval-waiting-content">
                                    <span>{TranslationService.translate('CreditorPendingApproval')}</span>
                                    {creditorsList}
                                </div>
                                {/* TODO: this mail should be configurable from global application settings level */}
                                <a href="mailto:contact@concent.no" className="btn btn-home">
                                    <span className="fas fa-home" /> {TranslationService.translate('ContactUs')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        )
    }

    private getPendingCreditorsList() {
        if (!this.props.creditors || this.props.creditors.length === 0) {
            return null;
        }

        const listItems = this.props.creditors.map((c, index) => (<ListGroupItem disabled={true} key={index}>{c.organizationName}</ListGroupItem>))

        return (
            <ListGroup flush={true} className="creditors-pending-list">
                {listItems}
            </ListGroup>
        )
    }
}

const mapStateToProps = (state: IStore) => ({
    creditors: state.creditors
})

export default connect(mapStateToProps)(CreditorAwaitingApprovalSkin)
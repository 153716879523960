import InvoiceDetailsNotesApi from '../api/InvoiceDetailsNotesApi';
import { GetInvoiceDetailsNotesRequest } from '../types/GetInvoiceDetailsNotesRequest';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { InvoiceDetailsNoteItem } from '../types/InvoiceDetailsNoteItem';

class InvoiceDetailsNotesService {
    public async getInvoiceDetailsNotes(request: GetInvoiceDetailsNotesRequest): Promise<IComponentTable<InvoiceDetailsNoteItem>> {
        return await InvoiceDetailsNotesApi.getInvoiceDetailsNotes(request);
    }
}

export default new InvoiceDetailsNotesService();
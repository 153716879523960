import React from 'react';
import TranslationService from '../../core/services/TranslationService';
import CurrencyParser from '../../core/helpers/CurrencyParser';
import { IInvoiceDetails, emptyInvoiceDetails } from './types/IInvoiceDetails';
import DateTimeParser from '../../core/helpers/DateTimeParser';
import InvoiceDetailsService from './services/InvoiceDetailsService';
import { Redirect } from 'react-router';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import RouteParamsService from '../../core/services/RouteParamsService';
import { IStore } from '../../reducers/IStore';
import { connect } from 'react-redux';
import Loader from '../../core/components/Loading/Loader';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { IModuleProps } from '../../core/types/IModuleProps';

interface IState {
    isInitialDataLoaded: boolean,
    isLoading: boolean,
    invoiceDetails: IInvoiceDetails,
    hasAccessToModule: boolean,
}

interface IProps extends IModuleProps {
    invoiceNo: string,
    accountNo: string,
    creditorNo: string,
    currency: string,
}

class InvoiceDetails extends React.Component<IProps, IState> {
    public module: any = flatModuleOptions<any>(this.props.module as any);
    public state: IState = {
        isInitialDataLoaded: false,
        hasAccessToModule: true,
        isLoading: false,
        invoiceDetails: emptyInvoiceDetails
    }

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        if (!this.hasRequiredUrlParams()) {
            return <Redirect to="/error" />
        }

        if (!this.state.hasAccessToModule) {
            return <NoPermissionsComponent />
        }

        return (
            <div className="l-module mt-3">
                <section className="l-module__section">
                    <Loader opacity={1} isLoaded={!this.state.isLoading} />
                    <article className="invoice-details">
                        <section className="invoice-details__info">
                            <div className="c-table c-table--col-2">
                                <div className="c-table__row invoice-details__row invoice-details__row--font-1">
                                    <div className="c-table__cell font--color-1 font--700">
                                        {TranslationService.translateModule('InvoiceNo', this.props.module.name)}
                                    </div>
                                    <div className="c-table__cell color-1 font--color-2 font--700">
                                        {this.state.invoiceDetails.invoiceNo}
                                    </div>
                                </div>

                                <div className="c-table__row invoice-details__row">
                                    <div className="c-table__cell font--color-1 font--700">
                                        {TranslationService.translateModule('Status', this.props.module.name)}
                                    </div>
                                    <div className="c-table__cell font--color-3 font--700">
                                        {this.state.invoiceDetails.status}
                                    </div>
                                </div>

                                <div className="c-table__row invoice-details__row">
                                    <div className="c-table__cell">
                                        {TranslationService.translateModule('RegDate', this.props.module.name)}
                                    </div>
                                    <div className="c-table__cell">
                                        {this.state.invoiceDetails.regDate && DateTimeParser.toLocaleDateString(this.state.invoiceDetails.regDate)}
                                    </div>
                                </div>

                                <div className="c-table__row invoice-details__row">
                                    <div className="c-table__cell">
                                        {TranslationService.translateModule('DueDate', this.props.module.name)}
                                    </div>
                                    <div className="c-table__cell">
                                        {this.state.invoiceDetails.dueDate && DateTimeParser.toLocaleDateString(this.state.invoiceDetails.dueDate)}
                                    </div>
                                </div>

                                <div className="c-table__row invoice-details__row">
                                    <div className="c-table__cell font--color-1 font--700">{TranslationService.translateModule('AccountNo', this.props.module.name)}</div>
                                    <div className="c-table__cell font--color-3 font--700">{this.state.invoiceDetails.accountNo}</div>
                                </div>

                                <div className="c-table__row invoice-details__row">
                                    <div className="c-table__cell font--color-1 font--700">{TranslationService.translateModule('Kid', this.props.module.name)}</div>
                                    <div className="c-table__cell font--color-3 font--700">{this.state.invoiceDetails.kid}</div>
                                </div>

                                <div className="c-table__row invoice-details__row">
                                    <div className="c-table__cell">{TranslationService.translateModule('Creditor', this.props.module.name)}</div>
                                    <div className="c-table__cell font--color-1 font--700">
                                        {`${this.state.invoiceDetails.creditorFirstName} ${this.state.invoiceDetails.creditorLastName}`}
                                    </div>
                                </div>

                                <div className="c-table__row invoice-details__row">
                                    <div className="c-table__cell">{TranslationService.translateModule('CreditorRef', this.props.module.name)}</div>
                                    <div className="c-table__cell">{this.state.invoiceDetails.reference}</div>
                                </div>

                                <div className="c-table__row invoice-details__row">
                                    <div className="c-table__cell">{TranslationService.translateModule('CaseHandler', this.props.module.name)}</div>
                                    <div className="c-table__cell">{this.state.invoiceDetails.caseHandlerName}</div>
                                </div>
                            </div>
                        </section>

                        <section className="invoice-details__payments">
                            <div className="invoice-details__payments-box c-box c-box--1">
                                <div className="c-box__amount">
                                    <span className="c-box__amount-value invoice-details__payments-box-amount">
                                        {CurrencyParser.toLocaleString(this.state.invoiceDetails.originalAmount)}
                                    </span>
                                    <span className="c-box__currency">{this.props.currency}</span>
                                </div>
                                <div className="c-box__desc">{TranslationService.translateModule('OriginalAmount', this.props.module.name)}</div>
                            </div>

                            <div className="invoice-details__payments-box c-box c-box--2">
                                <div className="c-box__amount">
                                    <span className="c-box__amount-value invoice-details__payments-box-amount">
                                        {CurrencyParser.toLocaleString(this.state.invoiceDetails.remainingAmount)}
                                    </span>
                                    <span className="c-box__currency">{this.props.currency}</span>
                                </div>
                                <div className="c-box__desc">{TranslationService.translateModule('RemainingAmount', this.props.module.name)}</div>
                            </div>

                            <div className="invoice-details__payments-box c-box c-box--3">
                                <div className="c-box__amount">
                                    <span className="c-box__amount-value invoice-details__payments-box-amount">
                                        {CurrencyParser.toLocaleString(this.state.invoiceDetails.remainingFee)}
                                    </span>
                                    <span className="c-box__currency">{this.props.currency}</span>
                                </div>
                                <div className="c-box__desc">{TranslationService.translateModule('RemainingFee', this.props.module.name)}</div>
                            </div>
                        </section>
                        {!this.state.invoiceDetails.caseNo && this.module.ShowDirectPaymentButton === "true" ?
                            <section className="invoice-details__actions">
                                <div className="invoice-details__action-btn">
                                    <button className={"c-btn c-btn--1 c-btn--xl"}
                                        id="directPayment"
                                        onClick={this.openDirectPaymentModal}>
                                        <i className="fas fa-credit-card" />
                                        {TranslationService.translateModule('DirectPayment', this.props.module.name)}
                                    </button>

                                </div>
                            </section>
                            : null}
                    </article>
                </section>
            </div>
        )
    }

    openDirectPaymentModal = () => {
        ModuleEventSubscriber.emitEvent({
            name: getModalOpenEvent(this.module.InvoiceDetailsDirectPaymentModalModuleInstanceId), data: {
                modalData: {
                    MODAL_HEADER_TEXT: TranslationService.translateModule('DirectPayment', this.props.module.name),
                    MODAL_HEADER_ICON: 'fas fa-credit-card',
                    onClose: (actionSuccess?: boolean) => {
                        const load = async () => await this.loadData();
                        if (actionSuccess) { load(); }
                    }
                },
                otherData: {
                    accountNo: this.props.accountNo,
                    invoiceNo: this.props.invoiceNo,
                    creditorNo: this.props.creditorNo
                }
            }
        });
    };

    public loadData = async () => {
        this.setState({
            isLoading: true
        })

        try {
            const invoiceDetails = await InvoiceDetailsService.getInvoiceDetails({
                accountNo: this.props.accountNo,
                creditorNo: this.props.creditorNo,
                invoiceNo: this.props.invoiceNo,
                moduleInstanceId: this.props.module.id
            });

            this.setState({
                invoiceDetails,
                isLoading: false,
                hasAccessToModule: true,
                isInitialDataLoaded: true
            })
        } catch (err) {
            const newState = {
                isLoading: false,
                hasAccessToModule: false
            }

            this.setState(newState)
        }
    }

    private hasRequiredUrlParams = () => this.props.invoiceNo && this.props.accountNo && this.props.creditorNo;
}

const mapStateToProps = (state: IStore) => {
    const queryparams = RouteParamsService.getQueryParameters();
    return {
        invoiceNo: queryparams.invoiceNo,
        accountNo: queryparams.accountNo,
        creditorNo: queryparams.creditorNo,
        currency: state.currency
    }
};

export default connect(mapStateToProps)(InvoiceDetails);
import React from 'react';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IReportViewerParams } from './IReportViewerParams';

interface IProps extends IExtendedModuleProps {
  reportViewerParams: IReportViewerParams[],
  reportViewerUrl: string
}

class ReportViewer extends React.Component<IProps> {

  public componentDidMount() {
    const reportParamsName = "reportParams";

    const reportForm = document.createElement("form");
    reportForm.target = "report_viewer";
    reportForm.method = "post";
    reportForm.action = this.props.reportViewerUrl;

    const reportInput = document.createElement('input');

    reportInput.type = 'hidden';
    reportInput.name = reportParamsName;
    reportInput.value = encodeURIComponent(JSON.stringify(this.props.reportViewerParams));

    reportForm.appendChild(reportInput);

    document.body.appendChild(reportForm);
    
    reportForm.submit();
  }

  public render() {
    return (
      <iframe width="100%" height="100%" title="Report viewer" name="report_viewer" />
    );
  }
}

export default ReportViewer

import RestApiClient from '../../../core/api/RestApiClient';
import { IDBConnectionDetails } from '../types/IDBConnectionDetails';
import { IMigrationResult } from '../types/IMigrationResult';
import { IDnnMigration } from '../types/IDnnMigration';

class DnnMigrationApi {
    public async getDnnUsers(moduleInstanceId: number, connection: IDBConnectionDetails) : Promise<IDnnMigration> {
        return await RestApiClient.post<IDnnMigration>(`modules/DnnMigration/gatherDnnUsers/${moduleInstanceId}`, connection);
    }
    public async migrateUsers(moduleInstanceId: number, connectionDetails: IDBConnectionDetails, userUIds: string[]) : Promise<IMigrationResult> {
        const request = {
            moduleInstanceId,
            connectionDetails,
            userUIds
        }
        return await RestApiClient.post<IMigrationResult>(`modules/DnnMigration/migrateUsers`, request);
    }
}

export default new DnnMigrationApi();
import React from 'react';

type ContextProps = {
  value: string;
  options: any[];
  isExpanded: boolean;
  updateSelected: (value: any) => void;
  updateOptions: (option: string) => void;
  onClick: (option: any, event: any) => void;
  onToggle: (e: any) => void;
};
const SelectFieldContext = React.createContext<Partial<ContextProps>>({});

const useSelectFieldContext = () => {
  const context = React.useContext(SelectFieldContext);

  if (!context) {
    throw new Error(
      `Dropdown compound components cannot be rendered outside the Dropdown component`
    );
  }

  return context;
};

export { SelectFieldContext, useSelectFieldContext };

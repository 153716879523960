import RestApiClient from '../../core/api/RestApiClient';
import { IConversationsTableResult } from './types/IConversationsTableResult';
import { IConversation } from './types/IConversation';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { ISearchConversationsRequest } from './types/ISearchConversationsRequest';
import { ICredtitor } from './types/ICredtitor';

class MessagesApiClient
{
    public async getConversationsList(request: ISearchConversationsRequest): Promise<IConversationsTableResult> {
        return await RestApiClient.post<IConversationsTableResult>(`modules/MessagesModule/getConversations`, request);
    }

    public async getConversation(conversationId: number, creditorNumber: string): Promise<IConversation> {
        return await RestApiClient.get<IConversation>(`modules/MessagesModule/getConversation/${conversationId}/${creditorNumber}` );
    }

    public async sendNewMessage(formData: FormData): Promise<IActionStatus> {
        const headers = {
            'Content-Type': 'multipart/form-data;'
        };
        return await RestApiClient.post<IActionStatus>(`modules/MessagesModule/sendNewMessage`, formData, headers, false);
    }

    public async createNewConversation(formData: FormData): Promise<IActionStatus> {
        const headers = {
            'Content-Type': 'multipart/form-data;'
        };
        return await RestApiClient.post<IActionStatus>(`modules/MessagesModule/createNewConversation`, formData, headers, false);
    }

    public async getCreditorData(caseId: string): Promise<ICredtitor> {
        return await RestApiClient.get<ICredtitor>(`modules/MessagesModule/getCreditorData`, { caseId });
    }
}

export default new MessagesApiClient();
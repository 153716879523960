import RestApiClient from '../../../core/api/RestApiClient';
import { IUserDetails } from '../../../core/types/IUserDetails';
import { IUserProfileModuleSettings } from '../types/IUserProfileModuleSettings';
import { IActionStatus } from 'core/api/types/IActionStatus';

class UserProfileApiClient
{
    public async updateUserDetails(userDetails: IUserDetails, moduleInstanceId: number): Promise<any> {
        return await RestApiClient.put<any>(`modules/UserProfile/${moduleInstanceId}`, userDetails);
    }

    public async getModuleSettings(moduleInstanceId: number): Promise<IUserProfileModuleSettings> {
        return await RestApiClient.get<IUserProfileModuleSettings>(`modules/UserProfile/getInstanceSettings/${moduleInstanceId}`);
    }

    public async requestVerificationCodes(phone: string, email: string, moduleInstanceId: number) : Promise<IActionStatus> {
        const result = await RestApiClient.post<IActionStatus>(`modules/UserProfile/sendConfirmationCodes/${moduleInstanceId}`, { phone, email });
        return result;
    }

    public async checkEmailExists(email: string, moduleInstanceId: number) : Promise<boolean> {
        return await RestApiClient.post<any>(`modules/UserProfile/verifyEmailExists/${moduleInstanceId}`, { email });
    }
}

export default new UserProfileApiClient();
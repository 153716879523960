import React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import Dropdown from 'react-bootstrap/Dropdown';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IBaseCreditorDetails } from '../../CreditorsAssignment/types/IBaseCreditorDetails';
import { UserStatus } from '../types/UserStatus';

export default {
    getColumns: (creditors: IBaseCreditorDetails[], openModal: (userUId: string, creditors: IBaseCreditorDetails[]) => void) => creditors ? [
        {
            dataField: 'userName',
            text: TranslationService.translateModule('TableColumnName', ModuleNamesList.Users),
            sort: true
        } as IComponentTableColumn,
        {
            dataField: 'firstname',
            text: TranslationService.translateModule('TableColumnFirstName', ModuleNamesList.Users),
            sort: true
        } as IComponentTableColumn,
        {
            dataField: 'lastname',
            text: TranslationService.translateModule('TableColumnLastName', ModuleNamesList.Users),
            sort: true
        } as IComponentTableColumn,
        {
            dataField: 'phoneNumber',
            text: TranslationService.translateModule('TableColumnPhonenumber', ModuleNamesList.Users),
            sort: true
        } as IComponentTableColumn,
        {
            dataField: 'status',
            text: TranslationService.translateModule('Status', ModuleNamesList.Users),
            sort: true,
            formatter: (value: number, row: any) => {
                switch (value) {
                    case UserStatus.Active:
                        return <span>{TranslationService.translateModule('StatusActive', ModuleNamesList.Users)}</span>
                    case UserStatus.Invited:
                        return <span>{TranslationService.translateModule('StatusInvited', ModuleNamesList.Users)}</span>
                    case UserStatus.InvitationExpired:
                        return <span>{TranslationService.translateModule('InvitationExpired', ModuleNamesList.Users)}</span>
                }

                return null;
            }
        } as IComponentTableColumn,
        {
            dataField: 'actions',
            text: "",
            sort: false,
            formatter: (value: any, row: any) => {
                return row.status === UserStatus.Active ? 
                    <Dropdown alignRight className="button-table">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {TranslationService.translateModule('Actions', ModuleNamesList.Users)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { openModal(row.userId, creditors) }}>
                                <i className="fas fa-user-tie" /> {TranslationService.translateModule('ActionCreditorsAssignment', ModuleNamesList.Users)}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    : null
            }
        } as IComponentTableColumn
    ] as IComponentTableColumn[] : []
}
import React from 'react';
import SelectOption from './SelectOption';
import SelectLabel from './SelectLabel';
import SelectOptions from './SelectOptions';
import styles from './Select.module.scss';
import classNames from 'classnames';

type SelectPropsType = {
}

class Select extends React.PureComponent<SelectPropsType> {
    static Options = SelectOptions;
    static Option = SelectOption;
    static Label = SelectLabel;

    render() {
        return (
            <>
                <div className={classNames(
                    styles.select
                )}>
                    {this.props.children}
                </div>
            </>
        )
    }
}

export default Select;
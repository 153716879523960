import React, { useState, useEffect, useRef } from 'react';
import SelectFieldOption from './components/SelectFieldOption';
import { SelectFieldContext } from './context/SelectFieldContext';
// @ts-ignore
import classNames from 'classnames';
import styles from './SelectField.module.scss';
import SelectFieldSelected from './components/SelectFieldSelected';
import SelectFieldList from './components/SelectFieldList';

enum DirectionEnum {
  Top = 'top',
}

export type SelectOptionType = {
  name: string;
  value: string;
};

type SelectFieldPropsType = {
  id?: string;
  name: string;
  className?: string;
  selectedFieldClassname?: string;
  fieldListClassname?: string;
  options: SelectOptionType[];
  value?: string;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  direction?: DirectionEnum;
  isExpanded?: boolean;
  validate?: (value: string) => string;
  onChange: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SelectField = (props: SelectFieldPropsType) => {
  const [isExpanded, _setIsExpanded] = useState<boolean>(props.isExpanded || false);

  const [selectedOptionName, setSelectedOptionName] = useState<string>(() => {
    if (props.value) {
      const selectedOption = props.options.find((o) => o.value === props.value);

      return selectedOption ? selectedOption.name : '';
    }

    return '';
  });

  const isExpandedRef = useRef(isExpanded);

  const setIsExpanded = (data: boolean) => {
    isExpandedRef.current = data;
    _setIsExpanded(data);
  };

  const node = useRef<HTMLInputElement>(null);

  const handleOnClick = () => {
    setIsExpanded(false);
  };

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  const mouseupListenerCallback = (event: any) => {
    if (node.current) {
      if (isExpandedRef.current && !node.current.contains(event.target as Node)) {
        setIsExpanded(!isExpandedRef.current);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', mouseupListenerCallback);

    return () => {
      document.removeEventListener('mouseup', mouseupListenerCallback);
    };
  }, []);

  useEffect(() => {
    if (props.value) {
      const v = props.options.find((option) => option.value === props.value);
      if (v) {
        setSelectedOptionName(v.name);
      }
    } else if (props.value?.length === 0) {
      setSelectedOptionName('');
    }
  }, [props.value]);

  return (
    <div
      id={props.id || props.name}
      ref={node}
      className={classNames(
        styles.wrapper,
        props.className,
        props.direction === DirectionEnum.Top ? styles.directionTop : styles.directionBottom
      )}
    >
      <SelectFieldContext.Provider
        value={{
          value: props.value,
          isExpanded,
          onClick: handleOnClick,
          onToggle: toggle,
        }}
      >
        <SelectFieldSelected
          className={classNames(props.selectedFieldClassname, props.disabled && styles.disabled)}
        >
          {selectedOptionName}
        </SelectFieldSelected>

        <SelectFieldList className={classNames(props.fieldListClassname)}>
          {props.options &&
            props.options.map((option) => (
              <SelectFieldOption
                key={option.value}
                id={option.value}
                value={option.value}
                onClick={(value) => {
                  const handleChangeEvent = {
                    target: {
                      id: props.name,
                      name: props.name,
                      value: value,
                    },
                  } as React.ChangeEvent<HTMLInputElement>;

                  props.onChange(value, handleChangeEvent);
                }}
              >
                <>{option.name}</>
              </SelectFieldOption>
            ))}
        </SelectFieldList>
      </SelectFieldContext.Provider>

      {props.touched && props.error && (
        <div className={styles.errorField}>{props.error && <div>{props.error}</div>}</div>
      )}
    </div>
  );
};

SelectField.Option = SelectFieldOption;
SelectField.List = SelectFieldList;
SelectField.Selected = SelectFieldSelected;
SelectField.DirectionEnum = DirectionEnum;

export default SelectField;

import * as React from 'react';
import ColorPickerInputComponent from './ColorPickerInput';

const ORDER_NAME = {
    0: 'ColorBrandFirst',
    1: 'ColorBrandSecond',
    2: 'ColorBrandThird'
}

interface IProps {
    name: string,
    id?: string,
    value: string,
    initNumberColors: number,
    handleChange: (value: string | string[]) => void
}

type ColorsType = {
    name: string,
    value: string,
    order: number
}

type ColorType = {
    name: string,
    value: string,
}

interface IState {
    colorValues: ColorType[],
    colors: ColorsType[]
}

class ColorPickerArray extends React.Component<IProps, IState> {
    static defaultProps = {
        initNumberColors: 3
    }

    state: IState = {
        colorValues: [],
        colors: []
    }

    componentDidMount() {
        const colorsValue = this.props.value.split(',');

        this.setState({
            colors: [...Array(this.props.initNumberColors)].map((_, index: number) => {
                return {
                    name: ORDER_NAME[index],
                    value: colorsValue[index] || '',
                    order: index
                }
            })
        });
    }

    render() {
        const colorsValue = this.props.value.split(',');
        
        return (
            [...Array(this.props.initNumberColors)].map((_, index: number) => {
                const name = ORDER_NAME[index];
                const value = colorsValue[index] || '';
                const order = index;

                return (
                    <div key={index} className="mb-3">
                        <ColorPickerInputComponent 
                            name={name} 
                            id={this.props.id}
                            value={value} 
                            handleChange={(value) => this.handleChange(value, name, order)}
                        />
                    </div>
                )
            })
        );
    }

    handleChange = (value: string, name: string, order: number) => {
        this.setState((prevState: IState) => {
            const filteredColorValues = prevState.colors.filter((color: ColorType) => {
                return color.name !== name;
            });

            filteredColorValues.push({ name, value, order });

            const valuesForSubmit = filteredColorValues
                .sort((a, b) => a.order - b.order)
                .map((color: ColorType) => {
                    return color.value;
                })
                .join(',');

            this.props.handleChange(valuesForSubmit);

            return {
                colors: filteredColorValues,
            }
        })
    }
}

export default ColorPickerArray;
import RestApiClient from '../../../core/api/RestApiClient';
import { CancelToken } from 'axios';

class LicenseExpirationApi {
    private cancelToken: CancelToken;

    constructor(cancelToken: CancelToken)  {
        this.cancelToken = cancelToken;
    }

    public async getLicenseExpirationDate() {
        return await RestApiClient.get<Date>('modules/LicenseExpirationModule', {}, this.cancelToken);
    }
}

export default LicenseExpirationApi;
import RestApiClient from '../../core/api/RestApiClient';
import { IRoundedBlockData } from './types/IRoundedBlockData';
import { CancelToken } from 'axios';

class CaseStatisticsPortfolioAmountsApiClient {
    public async GetData(moduleInstanceId: number, cancelToken: CancelToken): Promise<IRoundedBlockData[]>{
        return await RestApiClient.get<IRoundedBlockData[]>(`modules/CaseStatisticsPortfolioAmounts/getData/${moduleInstanceId}`, {}, cancelToken);
    }
}

export default new CaseStatisticsPortfolioAmountsApiClient();

import CurrencyParser from '../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { ActivitiesColumns } from '../types/ActivitiesColumns';
import { IActivityListElement } from '../types/IActivityListElement';

class ActivitiesListTableConfigurator {
    public getActivitiesListTableColumns = (activitiesListTable: IComponentTable<IActivityListElement>) => {
        let columns: IComponentTableColumn[] = [];
        if (activitiesListTable != null) {
            columns = [...activitiesListTable.columns.map(c => ({ ...c, sort: true }))];
            this.configureCurrencyColumns(columns);
            this.configureDateColumns(columns);

            return columns;
        }
        return columns;
    }

    private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
        const currencyColumns = [ActivitiesColumns.Fee1, ActivitiesColumns.Fee2, ActivitiesColumns.Interest, ActivitiesColumns.LegalFee, ActivitiesColumns.Capital, ActivitiesColumns.Paid];
        const currencyColumnsNames = currencyColumns.map(col => ActivitiesColumns[col].charAt(0).toLowerCase() + ActivitiesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return CurrencyParser.toLocaleString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }

    private configureDateColumns = (columns: IComponentTableColumn[]) => {
        const dateColumns = [ActivitiesColumns.Date];
        const currencyColumnsNames = dateColumns.map(col => ActivitiesColumns[col].charAt(0).toLowerCase() + ActivitiesColumns[col].slice(1));
        const cols = columns.filter(col => currencyColumnsNames.indexOf(col.dataField) >= 0);
        const formatterFunc = (value: any, row: any) => {
            return DateTimeParser.toLocaleDateString(value);
        }

        for (const col of cols) {
            col.formatter = formatterFunc;
        }
    }
}

export default new ActivitiesListTableConfigurator();
import React, {ReactNode} from "react";
import classNames from "classnames";

type FormGroupType = {
    id?: string,
    label?: string,
    bolded?: boolean,
    colLabel?: number,
    name: string,
    variant?: string,
    className?: string,
    render: (name: string) => ReactNode
}

export const FORM_GROUP_VARIANTS = {
    BLOCK: 'c-form-group--block',
    INLINE: 'c-form-group--inline'
};

export const FormGroup = ({
    ...props
}: FormGroupType) => {
    const {id, label, name, variant, className, colLabel, bolded} = props;
    const formGroupClass = classNames(
        'c-form-group',
        bolded && 'c-form-group--bolded',
        variant,
        className,
    );

    return (
        <div
            id={id}
            className={formGroupClass}
        >
            {label &&
                <label
                    htmlFor={name}
                    className={`c-form-group__label ${colLabel ? `col-${colLabel}` : ''}`}
                >
                    {label}
                </label>
            }

            <div className="c-form-group__input">
                {props.render(name)}
            </div>
        </div>
    )
};


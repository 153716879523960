import { CreditorContactInfoType } from "../types/CreditorContactInfo";
import { OrderProductType } from "../types/OrderProduct";
import OrderPaperApi from "../api/OrderPaperApi";

class OrderPaperService {
  public async getCreditorContact(
    creditorId: string
  ): Promise<CreditorContactInfoType> {
    return await OrderPaperApi.GetCreditorContact(creditorId);
  }

  public async orderPaper(orderProduct: OrderProductType, moduleId: number) {
    return await OrderPaperApi.OrderPaper(orderProduct, moduleId);
  }

  public async getProducts(moduleId: number) {
    return await OrderPaperApi.GetProducts(moduleId);
  }
}

export default new OrderPaperService();

import React from 'react';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { UnitIdSearchResultItem } from '../types/UnitIdSearchResultItem';
import { Link } from 'react-router-dom';
import CurrencyParser from '../../../core/helpers/CurrencyParser';

const buildUnitIdLinkColumn = (columns: IComponentTableColumn[]) => {
  const column = columns.find((x: IComponentTableColumn) => x.dataField === 'clientNo');
  if (column) {
    column.formatter = (value: any, row: UnitIdSearchResultItem) => (
      <Link
        to={`/creditor-account-summary?unitId=${row.unitNo}&creditorNo=${row.clientNo}&accountNo=${row.accountNo}&debtorNo=${row.debtorNumber}`}
        className="font-color-1 font-weight-bold">
        {value}
      </Link>
    )
  }
}

const buildCurrencyColumns = (columns: IComponentTableColumn[]) => {
  const columnNames = ['balanceUnitNoAR', 'balanceUnitNoDebtCollection', 'balanceUnitNoSum']
  const cols = columns.filter((x: IComponentTableColumn) => columnNames.indexOf(x.dataField) > -1);
  const formatterFunc = (value: any) => CurrencyParser.toLocaleString(value);
  if (cols) {
    for (const col of cols) {
        col.formatter = formatterFunc;
        col.classes = () => 'bt-table__cell bt-table__cell--text-right';
        col.headerClasses = () => 'bt-table__header bt-table__header--text-right';
    }
  }
}

export default {
  getTableColumns: (table: IComponentTable<UnitIdSearchResultItem>) => {
    let columns: IComponentTableColumn[] = [];
    if (table != null) {
      columns = [...table.columns];
      buildUnitIdLinkColumn(columns);
      buildCurrencyColumns(columns);
    }

    return columns;
  }
}
import React from 'react';
import ExternalLoginProvidersForm from './ExternalLoginProvidersForm';
import { ExternalLoginProviderType } from '../types/ExternalLoginProviderType';
import { LoginProviderTypeId } from '../types/LoginProviderTypeId';
import NETSLoginProviderForm from './NETSLoginProviderForm';
import OidcLoginProviderForm from './OidcLoginProviderForm';

type EditProviderModalType = {
    data: ExternalLoginProviderType | null,
    onClose: () => void,
    onSubmit: (data: ExternalLoginProviderType) => void,
}

const EditProviderModal = ({
    data, onClose, onSubmit
}: EditProviderModalType) => {

    const getProviderForm = () => {
        if (data){
            switch (data.loginProviderTypeId)
            {
                case LoginProviderTypeId.AzureAd:
                    return <ExternalLoginProvidersForm data={data} onClose={onClose} onSubmit={onSubmit} />
                case LoginProviderTypeId.NETS:
                    return <NETSLoginProviderForm data={data} onClose={onClose} onSubmit={onSubmit}/>;
                case LoginProviderTypeId.Oidc:
                    return <OidcLoginProviderForm data={data} onClose={onClose} onSubmit={onSubmit}/>;
                default:
                    return <></>
            }
        } else {
            return <></>
        }
        
    }

    return (
        <>
            {data && getProviderForm()}
        </>
    )
}

export default EditProviderModal;
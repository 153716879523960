import Loader from 'core/components/Loading/Loader';
import * as React from 'react';
import { Status } from '../../../core/api/Enums/Status';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import ListInputs from '../../../core/components/Forms/ListInputs';
import { IListInputElement } from '../../../core/components/Forms/types/IListInputElement';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import InfoMessageService from '../../../core/services/InfoMessageService';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import CreditorsAssignmentApiClient from '../CreditorsAssignmentApiClient';
import AssignCreditorsTableConfigurator from '../helpers/AssignCreditorsTableConfigurator';
import { IBaseCreditorDetails } from '../types/IBaseCreditorDetails';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

interface IProps {
    availableCreditorsRoles: IListInputElement[];
    creditorsFilteredValues: IBaseCreditorDetails[];
    creditorsTableColumns: IComponentTableColumn[];
    userUId: string;
    updateCreditorsToAssignList(assignedCreditorDomainIds: IBaseCreditorDetails[]): Promise<void>;
}

interface IState {
    isLoading: boolean;
    selectedCreditorsToAssign: IBaseCreditorDetails[];
    selectedRolesToAssign: string[];
    filter: string;
}

class AssignCreditors extends React.PureComponent<IProps> {
    public state: IState = {
        isLoading: false,
        selectedCreditorsToAssign: [],
        selectedRolesToAssign: [],
        filter: ''
    }

    public render() {
        return (
            <>
                {this.state.isLoading && <Loader opacity={0.6} />}

                <div className="c-assignment-box">
                    <div className="c-assignment-box__header">
                        <h6 className="mt-2 mb-1 font-weight-bold">
                            {TranslationService.translateModule('AssignCreditors', ModuleNamesList.CreditorsAssignment)}
                        </h6>
                    </div>

                    <div className="c-assignment-box__body">
                        <div className="c-assignment-box__body-item c-assignment-box__body-item--table">
                            {this.props.creditorsTableColumns.length > 0 && 
                                <BootstrapTableContainer
                                    id="creditorToAssingTable"
                                    remote={false}
                                    paginationProps={{
                                        hideSizePerPage: true
                                    }}
                                    wrapperClasses="bt c-assignment-box__table-wrapper"
                                    classes="bt__table bt__table--decor-1 bt-table bt--small bt--selectable" 
                                    keyField="domainId"
                                    selectRow={{ 
                                        hideSelectColumn: true,
                                        mode: "checkbox", 
                                        classes: "bt bt-table bt-table__row bt-table__row--selected", 
                                        clickToSelect: true,
                                        onSelect: this.onCreditorSelect,
                                        selected: this.state.selectedCreditorsToAssign.map((item) => item.domainId)
                                    }}
                                    data={this.props.creditorsFilteredValues.filter(c => {
                                      if (this.state.filter) {
                                        return (c.organizationName.toLocaleLowerCase()).includes(this.state.filter.toLocaleLowerCase()) || c.domainId.indexOf(this.state.filter) > -1;
                                      }

                                      return true;
                                    })}
                                    onFilter={(value: string) => {
                                        this.setState({filter: value})
                                    }}
                                    columns={AssignCreditorsTableConfigurator.getTableColumns(this.props.creditorsTableColumns)} 
                                    search={true}
                                />
                            }
                        </div>

                        <div className="c-assignment-box__body-item">
                            <ListInputs
                                col={2}
                                listInputs={this.props.availableCreditorsRoles}
                                allInputsSelected={false}
                                selectedInputs={this.state.selectedRolesToAssign}
                                markWithActiveClass={true}
                                onSelect={this.onSelectHandler}
                            />
                        </div>
                    </div>

                    <div className="c-assignment-box__footer">
                        <div className="row">
                                <div className="col-auto mr-auto">
                                    <Button
                                        id="clearToAssignSelectionBtn"
                                        label={TranslationService.translateModule('ClearSelection', ModuleNamesList.CreditorsAssignment)}
                                        variant={BUTTON_VARIANTS.SECONDARY}
                                        size={BUTTON_SIZE.MD}
                                        onClick={this.clearSelection}
                                    />
                                </div>

                                <div className="col-auto">
                                    <Button
                                        id="assignCreditorsBtn"
                                        label={TranslationService.translateModule('AssignCreditorsButton', ModuleNamesList.CreditorsAssignment)}
                                        variant={BUTTON_VARIANTS.PRIMARY}
                                        size={BUTTON_SIZE.MD}
                                        disabled={!this.state.selectedCreditorsToAssign.length || !this.state.selectedRolesToAssign.length}
                                        onClick={this.assignCreditors}
                                    />
                                </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    onSelectHandler = (selectedRolesToAssign: IListInputElement) => {
        let stateToUpdate: any = [];
        const defaultValues: string[] = [...this.state.selectedRolesToAssign];

        if (defaultValues.indexOf(selectedRolesToAssign.key as any) !== -1) {
            const defaultValuesFiltered = defaultValues.filter((item) => item !== selectedRolesToAssign.key);

            stateToUpdate = defaultValuesFiltered;
        } else {
            stateToUpdate = [...this.state.selectedRolesToAssign, selectedRolesToAssign.key];
        }

        this.setState({
            selectedRolesToAssign: stateToUpdate
        });
    }

    assignCreditors = async () => {
        if (this.state.selectedCreditorsToAssign.length === 0 || this.state.selectedRolesToAssign.length === 0) {
            InfoMessageService.warning(TranslationService.translateModule('ValidationAtLeastOneRoleOrCreditorToAssign', ModuleNamesList.CreditorsAssignment));
        } else {
            try {
                this.setState({isLoading:true})
                const result = await CreditorsAssignmentApiClient.assignCreditors(this.props.userUId, this.state.selectedCreditorsToAssign, this.state.selectedRolesToAssign);
                if (result.status === Status.Success) {
                    await this.props.updateCreditorsToAssignList(this.state.selectedCreditorsToAssign);
                    this.setState({
                        selectedCreditorsToAssign: [],
                        selectedRolesToAssign: [],
                    })
                } else {
                    InfoMessageService.displayActionStatus(result);
                }
            } catch {
                InfoMessageService.error(TranslationService.translateModule('ErrorAddCreditorAssignment', ModuleNamesList.CreditorsAssignment));
            } finally {
                this.setState({
                    isLoading: false
                })
            }
        }
    }

    clearSelection = () => {
        this.setState({
            selectedCreditorsToAssign: [],
            selectedRolesToAssign: [],
        })
    }

    private onCreditorSelect = (element: IBaseCreditorDetails) => {
        let selectedCreditorsToAssign = [...this.state.selectedCreditorsToAssign];
        
        if (selectedCreditorsToAssign.includes(element)) {
            selectedCreditorsToAssign.splice(selectedCreditorsToAssign.indexOf(element), 1);
        } else {
            selectedCreditorsToAssign.push(element);
        }

        this.setState({selectedCreditorsToAssign});
    }
}


export default AssignCreditors;
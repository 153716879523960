import { Formik, Form } from 'formik';
import { ISecurityAndTermsProps } from '../types/ISecurityAndTermsProps';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import * as React from 'react';
import * as yup from 'yup';
import TranslationService from '../../../core/services/TranslationService';
import FormGroupCheckbox from '../../../core/components/Forms/FormGroupCheckbox';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';

class SecurityAndTerms extends React.Component<ISecurityAndTermsProps> {
    private validationSchema = yup.object().shape({
        isAgreementConfirmed: yup.boolean().oneOf([true], TranslationService.translate("ValidationMessageCheckboxRequired")),
    });

    public render() {
        return (
            <div>
                <div className="step-content">
                    <header className="step-header">
                        <h2 className="step-title">{TranslationService.translateModule('SecurityAndTermsHeader1', ModuleNamesList.RegisterUserModuleName)}</h2>
                        <div dangerouslySetInnerHTML={{ __html: TranslationService.translateModule('SecurityAndTermsContent1', ModuleNamesList.RegisterUserModuleName) }}/>
                    </header>
                    <dl className="row">
                        <dt className="col-4 col-sm-2">{TranslationService.translateModule('SecurityAndTermsName', ModuleNamesList.RegisterUserModuleName)}</dt>
                        <dd className="col-8 col-sm-10">{this.props.user.firstName + ' ' + this.props.user.lastName}</dd>
                        <dt className="col-4 col-sm-2">{TranslationService.translateModule('SecurityAndTermsPhone', ModuleNamesList.RegisterUserModuleName)}</dt>
                        <dd className="col-8 col-sm-10">{this.props.user.phone}</dd>
                        <dt className="col-4 col-sm-2">{TranslationService.translateModule('SecurityAndTermsEmail', ModuleNamesList.RegisterUserModuleName)}</dt>
                        <dd className="col-8 col-sm-10">{this.props.user.email}</dd>
                    </dl>
                    <section className="step-section">
                        <header className="step-header">
                            <h2 className="step-title">{TranslationService.translateModule('SecurityAndTermsHeader2', ModuleNamesList.RegisterUserModuleName)}</h2>
                        </header>
                        <div className="step-body agreement-content" dangerouslySetInnerHTML={{ __html: TranslationService.translateModule('SecurityAndTermsAgreement', ModuleNamesList.RegisterUserModuleName) }}/>
                    </section>
                </div>
                <div className="form-check form-check--wrapper">
                    <Formik
                        initialValues={{ isAgreementConfirmed: this.props.isAgreementConfirmed }}
                        onSubmit={() => {
                            this.props.goToNextStep();
                        }}
                        validationSchema={this.validationSchema}
                        >
                        {({ values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur
                        }) => {
                            return (
                                <Form className="confirmation-form">
                                    <FormGroupCheckbox 
                                        label={TranslationService.translateModule('SecurityAndTermsConfirmationCheckBoxLabel', ModuleNamesList.RegisterUserModuleName)}
                                        type="checkbox"
                                        fieldName="isAgreementConfirmed"
                                        value={values.isAgreementConfirmed}
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        inputContainerClass={"mb-3"}
                                        touched={touched}
                                        required={true}
                                        handleChange={async(e) => { this.props.onAgreementChange(!values.isAgreementConfirmed); handleChange(e); } }
                                    />
                                    <StepListWizardButtons
                                        leftButtonOnClick={this.props.backToPrevStep}
                                        leftButtonClassName="float-left"
                                        leftButtonText={<>
                                            {TranslationService.translateModule('SecurityAndTermsButton1Text', ModuleNamesList.RegisterUserModuleName)}
                                        </>}
                                        rightButtonClassName="float-right"
                                        rightButtonText={<>
                                            {TranslationService.translateModule('SecurityAndTermsButton2Text', ModuleNamesList.RegisterUserModuleName)}
                                        </>}
                                    />
                                </Form>)
                            }
                        }
                    </Formik>
                </div>
            </div>
        )
    }
}

export default SecurityAndTerms;

import * as React from 'react';
import { IRoundedBlockData } from '../types/IRoundedBlockData';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IProps {
    data: IRoundedBlockData[];
}

class RoundedBlockChart extends React.Component<IProps> {
    private elements = Array.apply(null, {length:10} as any[]).map(Number.call, Number);

    render() {
        const maxValue = Math.max(...this.props.data.map((item) => item.value));
        return (
            <div className="c-chart chart-portfolio-amounts">
                {this.props.data.map((element: IRoundedBlockData, index: number) => (
                    <article className="c-chart__item" key={index}>
                        <header className="c-chart__header">
                            {element.headerValue}
                        </header>

                        <div className="c-chart__body">
                            <svg className="chart" width="60%" height="200" xmlns="http://www.w3.org/2000/svg">
                                {
                                    this.elements.map((indexY: number) => {
                                        return ( 
                                            <rect 
                                                className={`column-${index + 1} ${(maxValue - Math.floor(element.value)) < ((indexY + 1 )* (maxValue/10)) ? 'colored' : ''}`} 
                                                key={indexY} 
                                                width="100%"
                                                height="7%"
                                                x="0"
                                                y={`${(indexY * 10)}%`}
                                                rx="6" 
                                                ry="6"
                                            />
                                        )
                                    }) 
                                }
                            </svg>
                        </div>

                        <footer className={`c-chart__footer c-chart__cat-icons c-chart__cat-icons--${index + 1}`}>
                            <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-${index}`}><label className="mb-0 pb-0">{element.headerTitle}</label></Tooltip>}>
                                <i className={element.icon}/>
                            </OverlayTrigger>
                        </footer>
                    </article>
                ))}
            </div>
        )
    }
}
export default RoundedBlockChart;
import RestApiClient from '../../core/api/RestApiClient';
import { IDictionary } from '../types/IDictionary';
import { ITranslationCollection } from '../types/ITranslationCollection';
import { ILanguage } from '../types/ILanguage';

class TranslationsApiClient
{
    public async GetAllTranslations(): Promise<IDictionary<ITranslationCollection>> {
        return await RestApiClient.get<IDictionary<ITranslationCollection>>('Translation/getTranslations');
    }

    public async GetTraslations(languageCode: string): Promise<IDictionary<ITranslationCollection>> {
        return await RestApiClient.get<IDictionary<ITranslationCollection>>(`Translation/getTranslations/${languageCode}`);
    }

    public async GetLanguages(): Promise<ILanguage[]> {
        return await RestApiClient.get<ILanguage[]>('Translation/languages');
    }
}

export default new TranslationsApiClient();
import RestApiClient from './RestApiClient';
import { IDictionary } from '../types/IDictionary';

class PermissionsApiClient {
    public async getUserPermissions() {
        return await RestApiClient.get<IDictionary<string[]>>('UserPermissions');
    }

    public async hasAccessToCase(caseNumber: string) {
        try{
            return await RestApiClient.get<boolean>(`Case/hasAccessToCase/${caseNumber}`);
        } catch {
            return false;
        }
    }
}

export default new PermissionsApiClient();
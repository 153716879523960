import { IPermissionDetails } from './IPermissionDetails';

export interface IRoleDetails {
    uId: string;
    permissions: IPermissionDetails[];
    isCoreRole: boolean;
    displayName: string;
    key: string;
    authorizationTypeCode: string;
    authorizationTypeName: string;
}

export const emptyRole: IRoleDetails = {
    uId: '',
    permissions: [],
    isCoreRole: false,
    displayName: '',
    key: '',
    authorizationTypeCode: '',
    authorizationTypeName: '',
}
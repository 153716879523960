import * as React from 'react';
import StickyFooter from '../../modules/StickyFooter/StickyFooter';
import Header from '../components/Header/Header';
import { ContainerFactory } from '../factories/ContainerFactory';
import { SkinFactory } from '../factories/SkinFactory';
import { ILayoutProps } from '../types/ILayoutProps';
import Sidebar from '../components/Sidebar/Sidebar';
import Topbar from '../../modules/Topbar/Topbar';
import StyleWrapper from '../../core/components/StyleWrapper/StyleWrapperHOC';

export interface IFullWidthLayoutWithStaticHeightLayoutState {
    isMobileMenuExpanded: boolean
}

class FullWidthLayoutWithStaticHeight extends React.PureComponent<ILayoutProps, IFullWidthLayoutWithStaticHeightLayoutState>{
    public state = {
        isMobileMenuExpanded : false
    }

    public onClickHamburgerMenu = (newState: boolean) => {
        this.setState({
            isMobileMenuExpanded: newState
        })
    };
    
    public render() {
        const containers = this.props.layout.containers;
        return (
            <>
                <div className="layout layout--fixed-height">
                    <Topbar />
                    <Header onClickMobileTogglerMenu={this.onClickHamburgerMenu}/>

                    <div className="layout__main">
                        <Sidebar {...this.props} isMobileMenuExpanded={this.state.isMobileMenuExpanded}/>

                        <div className="layout__skin">
                            <div className="layout__skin-body">
                                <SkinFactory skin={this.props.layout.skin} staticHeight={true} routeParameters={this.props.routeParameters} />
                                <ContainerFactory containers={containers} routeParameters={this.props.routeParameters} moduleIndex={0} />
                            </div>

                            <div className="layout__skin-footer">
                                <StickyFooter />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const styleConfig = [
  {
      searchMethod: 'module',
      instructions: {
          backgroundColor: '.layout'
      }
  }
]

export default StyleWrapper('module:skin', styleConfig)(FullWidthLayoutWithStaticHeight);

import * as React from 'react';
import { IModuleProps } from '../../core/types/IModuleProps';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import RolesManagementApiClient from './RolesManagementApiClient';
import { IComponentTable, emptyComponentTable } from 'core/types/IComponentTable';
import Loader from '../../core/components/Loading/Loader';
import RolesTableConfigurator from './helpers/RolesTableConfigurator';
import ManageRole from './components/ManageRole';
import { IRoleDetails, emptyRole } from './types/IRoleDetails';
import { Status } from '../../core/api/Enums/Status';
import ConfirmationModal from '../../core/components/ConfirmationModal/ConfirmationModal';
import InfoMessageService from '../../core/services/InfoMessageService';
import ComponentTableHelper from '../../core/helpers/ComponentTableHelper';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import './RolesManagement.scss';

interface IState {
    isDataLoading: boolean,
    rolesTable: IComponentTable<IRoleDetails>,
    folders: string[],
    isManageRoleModalVisible: boolean,
    isRemoveRoleModalVisible: boolean,
    isRemoveRoleModalIsLoading: boolean,
    isManageModalLoading: boolean,
    selectedRole?: IRoleDetails,
    hasAccess: boolean
}

class RolesManagement extends React.Component<IModuleProps, IState> {
    public state: IState = {
        isDataLoading: true,
        rolesTable: {...emptyComponentTable},
        folders: [],
        isManageRoleModalVisible: false,
        isRemoveRoleModalVisible: false,
        isRemoveRoleModalIsLoading: false,
        isManageModalLoading: false,
        hasAccess: true
    }

    public componentDidMount = async () => {
        this.setState({ isDataLoading: true });
        try {
            const rolesTable = await RolesManagementApiClient.getRolesTable(this.props.module.id);
            this.setState({
                rolesTable,
                isDataLoading: false
            });
        } catch (error) {
            const hasAccess = ErrorResponseHandler.hasAccess(error);
            this.setState({
                isDataLoading: false,
                hasAccess
            });
        }
    }

    public render() {
        if (!this.state.isDataLoading && !this.state.hasAccess) {
            return <NoPermissionsComponent/>
        }
        
        return (
            <>
                <article className="l-module">
                    <section className="l-module__section l-module__section--head">
                        <h1>
                            <i className="fas fa-shield-alt mr-2"/>
                            {TranslationService.translateModule('RolesManagmentHeader', ModuleNamesList.RolesManagement)}
                        </h1>
                    </section>

                    <section className="l-module__section l-module__section--filter mb-3">
                        <Button
                            type="button"
                            id="caseListFilterBtn"
                            className="mr-2"
                            variant={BUTTON_VARIANTS.PRIMARY}
                            size={BUTTON_SIZE.SM}
                            label={TranslationService.translateModule('CreateNewRole', ModuleNamesList.RolesManagement)}
                            onClick={() => this.openManageRoleModal({...emptyRole})} 
                        />
                    </section>

                    <section className={`l-module__section ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
                        {this.state.isDataLoading && <Loader opacity={0.5} />}
                        <BootstrapTableContainer
                            remote={false}
                            wrapperClasses="bt mr"
                            classes="bt__table bt-table" 
                            keyField='uId'
                            data={this.state.rolesTable.values}
                            columns={RolesTableConfigurator.getTableColumns(this.state.rolesTable, this.openManageRoleModal, this.openConfirmRemoveRoleModal)} 
                        />
                    </section>

                    {this.state.selectedRole ? 
                        <ManageRole 
                            roleUId={this.state.selectedRole.uId}
                            toogleVisibility={ () => this.setState({isManageRoleModalVisible: !this.state.isManageRoleModalVisible})}
                            isVisible={this.state.isManageRoleModalVisible}
                            isLoading={this.state.isManageModalLoading}
                            closeModal={() => this.setState({isManageRoleModalVisible: false, selectedRole: undefined})}
                            updateRole={this.addOrUpdateRole} 
                        /> : ''  
                    }
                </article>

                <ConfirmationModal 
                    isOpen={this.state.isRemoveRoleModalVisible}
                    onCancel={() => this.setState({isRemoveRoleModalVisible: false})}
                    message={TranslationService.translateModule(
                        'RemoveModalConfirmationText', 
                        ModuleNamesList.RolesManagement).replace('{0}', this.state.selectedRole ? this.state.selectedRole!.displayName : '')
                    }
                    isModalContentLoading={this.state.isRemoveRoleModalIsLoading}
                    onOk={this.removeRole}
                />
            </>
        )
    }

    private openManageRoleModal = (role: IRoleDetails) => {
        this.setState({
            isManageRoleModalVisible: true,
            selectedRole: role
        });
    }

    private addOrUpdateRole = async (selectedRolesIds: string[], value: IRoleDetails) => {
        if(!this.state.selectedRole) return;

        this.setState({isManageModalLoading:true});
        try {
            const result = await RolesManagementApiClient.addOrUpdateRole(value, selectedRolesIds);
            if(result.status === Status.Success) {
                value.uId = value.uId === '' ? result.data : value.uId;
                this.setState( { rolesTable: ComponentTableHelper.addOrUpdateRow(this.state.rolesTable, value, 'uId') } );
                InfoMessageService.success(TranslationService.translateModule('ManageRoleSuccessMessage', ModuleNamesList.RolesManagement));
            } else {
                InfoMessageService.error(TranslationService.translateModule('ManageRoleErrorMessage', ModuleNamesList.RolesManagement));
            }
        } finally {
            this.setState( { isManageModalLoading:false, isManageRoleModalVisible: false } );
        }
    }

    private openConfirmRemoveRoleModal = async (role: IRoleDetails) => {
        this.setState({
            isRemoveRoleModalVisible: true,
            selectedRole: role
        })
    }

    private removeRole = async () => {
        if(!this.state.selectedRole) return;

        this.setState({isRemoveRoleModalIsLoading:true});
        try {
            const result = await RolesManagementApiClient.removeRole(this.state.selectedRole.uId);
            if(result.status === Status.Success) {
                InfoMessageService.success(TranslationService.translateModule('RemoveRoleSuccessMessage', ModuleNamesList.RolesManagement));
                this.setState({ rolesTable: ComponentTableHelper.removeElement(this.state.rolesTable, this.state.selectedRole, 'uId') });
            } else {
                InfoMessageService.error(TranslationService.translateModule('RemoveRoleErrorMessage', ModuleNamesList.RolesManagement));
            }
        } finally {
            this.setState( { isRemoveRoleModalIsLoading:false, isRemoveRoleModalVisible: false } );
        }
    }
}

export default RolesManagement;
import { CaseListSelectFilter } from '../enums/CaseListSelectFilter';
import { CaseActivity } from '../enums/CaseActivity';
import { CaseProgressType } from '../../../core/Enums/CaseProgressType';

export interface ICaseListFilterRequest {
    filteredBy?: CaseListSelectFilter;
    filteredByValue?: string;
    dateFrom?: Date;
    dateTo?: Date;
    zipCode?: string;
    caseProgress: CaseProgressType[];
    caseActivity: CaseActivity[];
    amountLargerThan?: string;
}

export const emptyCaseListFilterRequest: ICaseListFilterRequest = {
    caseProgress: [],
    caseActivity: []
}
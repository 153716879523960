import { ICaseListElemet } from '../types/ICaseListElement';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { NavLink } from 'react-router-dom';
import * as React from 'react';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { CaseProgressType } from '../../../core/Enums/CaseProgressType';
import TranslationService from '../../../core/services/TranslationService';
import Dropdown from 'react-bootstrap/Dropdown';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { ICaseInitialSettings } from '../types/ICaseInitialSettings';

class CasesListTableConfigurator {
    public getTableColumns = (
        casesListTable: IComponentTable<ICaseListElemet>,
        isClosedDateVisible: boolean,
        openDirectPaymentModal: (caseNumber: string) => void,
        openWithdrawCaseModal: (caseNumber: string) => void,
        moduleSettings: ICaseInitialSettings) => {
        let columns: IComponentTableColumn[] = [];
        if (casesListTable != null) {
            columns = [...casesListTable.columns];
            this.configureDebtorColumn(columns);
            this.configureCaseNumberColumn(columns);
            this.configureRegisterDateRowDataColumn(columns);
            this.configureCloseDateRowDataColumn(columns, isClosedDateVisible);
            this.configureDecimalColumns(columns);
            this.configureCreditorColumn(columns, moduleSettings.displayedCreditorNumberType);
            this.configureStatusIconColumn(columns);
            this.configureCaseProgress(columns);
            this.configureActions(columns, openDirectPaymentModal, openWithdrawCaseModal, moduleSettings);

            return columns;
        }
        return columns;
    };

    private configureActions = (columns: IComponentTableColumn[], openDirectPaymentModal: (caseNumber: string) => void, openWithdrawCaseModal: (caseNumber: string) => void,
        moduleSettings: ICaseInitialSettings) => {
        const column = columns.find((x: IComponentTableColumn) => x.dataField === 'actions');
        if (column) {
            column.sort = false;
            column.formatter = (value: any, caseListElement: ICaseListElemet) => this.actionsRowData(value,
                caseListElement, openDirectPaymentModal, openWithdrawCaseModal, moduleSettings);
        }
    };

    private configureCaseNumberColumn = (columns: IComponentTableColumn[]) => {
        const column = columns.find((x: IComponentTableColumn) => x.dataField === 'caseNumber');
        if (column) {
            column.formatter = this.caseNumberRowData;
        }
    };

    private configureCaseProgress = (columns: IComponentTableColumn[]) => {
        const column = columns.find((x: IComponentTableColumn) => x.dataField === 'caseProgress');
        if (column) {
            column.formatter = this.caseProgressRowData;
        }
    };

    private configureCreditorColumn = (columns: IComponentTableColumn[], displayMode: number) => {
        const column = columns.find((x: IComponentTableColumn) => x.dataField === 'creditorName');
        if (column) {
            column.formatter = (value, element) => this.creditorRowData(value, element, displayMode);
        }
    };

    private configureDecimalColumns = (columns: IComponentTableColumn[]) => {
        const dataFields = ['originalClaim', 'paidBalance', 'remainBalance', 'totalBalance'];
        const cols: IComponentTableColumn[] = columns.filter((x: IComponentTableColumn) => dataFields.indexOf(x.dataField) >= 0);

        for (const col of cols) {
            col.formatter = this.decimalAmountRowData;
            col.classes = () => 'bt-table__cell bt-table__cell--text-right';
            col.headerClasses = () => 'bt-table__header bt-table__header--text-right';
        }
    };

    private configureDebtorColumn = (columns: IComponentTableColumn[]) => {
        const column = columns.find((x: IComponentTableColumn) => x.dataField === 'debtorName');
        if (column) {

            column.formatter = (value: any, caseListElement: ICaseListElemet) => this.debtorRowData(value, caseListElement);
        }
    };

    private configureRegisterDateRowDataColumn = (columns: IComponentTableColumn[]) => {
        const column = columns.find((x: IComponentTableColumn) => x.dataField === 'registerDate');
        if (column) {
            column.formatter = this.registerDateRowData;
        }
    };

    private configureCloseDateRowDataColumn = (columns: IComponentTableColumn[], isClosedDateVisible: boolean) => {
        const column = columns.find((x: IComponentTableColumn) => x.dataField === 'closeDate');
        if (column) {
            const className = isClosedDateVisible ? '' : 'd-none';
            column.formatter = this.closeDateRowData;
            column.headerClasses = () => {
                return className;
            };
            column.classes = () => {
                return className;
            };
        }
    };

    private configureStatusIconColumn = (columns: IComponentTableColumn[]) => {
        const column = columns.find((x: IComponentTableColumn) => x.dataField === 'statusIcon');
        if (column) {
            column.formatter = this.statusIconRowData;
            column.headerClasses = () => 'bt-table__header bt-table__header--single-icon';
            column.classes = () => {
                return 'bt-table__cell bt-table__cell--text-center';
            };
        }
    };

    private actionsRowData = (
        value: any,
        caseListElement: ICaseListElemet,
        openDirectPaymentModal: (caseNumber: string) => void,
        openWithdrawCaseModal: (caseNumber: string) => void,
        moduleSettings: ICaseInitialSettings
    ) => {
        return (
            <Dropdown alignRight className="button-table">
                <Dropdown.Toggle bsPrefix="c-btn c-btn--color-primary c-btn--toggle" id={`caseActionsBtn-${caseListElement.caseNumber}`}>
                    {TranslationService.translateModule('Actions', ModuleNamesList.CaseList)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {moduleSettings.enableDirectPayment &&
                        <Dropdown.Item onClick={() => { openDirectPaymentModal(caseListElement.caseNumber) }} id={`caseListItemDPCNAction-${caseListElement.caseNumber}`}>
                            <i className="far fa-credit-card" /> {TranslationService.translateModule('ActionsDirectPayment', ModuleNamesList.CaseList)}
                        </Dropdown.Item>}
                    {moduleSettings.enableWithdrawCase &&
                        <Dropdown.Item onClick={() => { openWithdrawCaseModal(caseListElement.caseNumber) }} id={`caseListItemWithdrawCaseAction-${caseListElement.caseNumber}`}>
                            <i className="fas fa-ban" /> {TranslationService.translateModule('ActionsWithdrawCase', ModuleNamesList.CaseList)}
                        </Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    private registerDateRowData = (value: any) => DateTimeParser.toLocaleDateString(value);
    private closeDateRowData = (value: any) => value === null ? '' : DateTimeParser.toLocaleDateString(value);

    private caseProgressRowData = (value: CaseProgressType) => {
        const mapping: INumberDictionary<string> = {
          [CaseProgressType.Reminder]: TranslationService.translate('CaseProgressReminder'),
            [CaseProgressType.PreLegal]: TranslationService.translate('CaseProgressPreLegal'),
            [CaseProgressType.Legal]: TranslationService.translate('CaseProgressLegal'),
            [CaseProgressType.Surveillance]: TranslationService.translate('CaseProgressSurveillance'),
        };
        return (
            mapping[value]
        );
    };

    private decimalAmountRowData = (value: number) => CurrencyParser.toLocaleString(value);

    private debtorRowData = (value: any, caseListElement: ICaseListElemet) => {

        return (
            <NavLink to={`/case-list?debtorNumber=${caseListElement.debtor.domainId}`} id="caseListItemCaseNoLink" className="font-color-1 font-weight-bold">{caseListElement.debtor.debtorName} ({caseListElement.debtor.domainId})</NavLink>
        );
    }

    private statusIconRowData = (value: any, caseListElement: ICaseListElemet) => {
        const mapping: INumberDictionary<string> = {
            [CaseProgressType.PreLegal]: "case-icon case-icon--pre-legal",
            [CaseProgressType.Reminder]: "case-icon case-icon--reminder",
            [CaseProgressType.Legal]: "case-icon case-icon--legal",
            [CaseProgressType.Surveillance]: "case-icon case-icon--surveillance"
        };
        return <i className={mapping[caseListElement.caseProgress]} />;
    };

    private creditorRowData = (value: any, caseListElement: ICaseListElemet, displayMode: number) => (
        <>
            {caseListElement.creditor.creditorName} {displayMode === 1
                ? caseListElement.creditor.domainId
                    ? `(${caseListElement.creditor.domainId})`
                    : ''
                : caseListElement.creditor.externalNo
                    ? `(${caseListElement.creditor.externalNo})`
                    : ''
            }
        </>
    )

    private caseNumberRowData = (value: any, caseListElement: ICaseListElemet) => {
        const link = `case?id=${caseListElement.caseNumber}`;
        return (
            <NavLink to={link} id="caseListItemCaseNoLink" className="font-color-1 font-weight-bold">{caseListElement.caseNumber}</NavLink>
        )
    }
}

export default new CasesListTableConfigurator();
import RestApiClient from '../../../core/api/RestApiClient';
import { IManageGroupInitialData } from '../types/IManageGroupInitialData';
import { ISaveUserPropsRequest } from '../types/ISaveUserPropsRequest';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class ManageGroupUserApiClient
{
    public async getInitialData(userUId: string | undefined): Promise<IManageGroupInitialData> {
        if(userUId) {
            return await RestApiClient.get<IManageGroupInitialData>(`modules/ManageUserProperties/getInitialData/${userUId}`);
        } else {
            return await RestApiClient.get<IManageGroupInitialData>(`modules/ManageUserProperties/getInitialData`);
        }
    }

    public async saveUserDetails(request: ISaveUserPropsRequest): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>(`modules/ManageUserProperties/saveUserDetails`, request);
    }
}

export default new ManageGroupUserApiClient();
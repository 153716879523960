import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Input.module.scss';
import classNames from 'classnames';

enum RadioVariants {
    Secondary = 'Secondary'
}

type RadioPropsType = {
    children?: ReactNode,
    id: string,
    checkedValue?: string,
    label: string
    name: string,
    value: string,
    variant: RadioVariants,
    onChange: (value: string) => void
}

const Radio = (props: RadioPropsType) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const handleCheckboxChange = (e: any) => {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    useEffect(() => {
        if (props.checkedValue) {
            setIsChecked(props.checkedValue === props.value);
        }
    }, [props.checkedValue, props.value])

    return (
        <div 
            className={classNames(
                styles.radioInput,
                props.variant && styles[`radioInput${props.variant}`],
                isChecked && styles.radioInputChecked
            )}
        >

            {props.children ? (
                props.children
            ) : (
                <>
                    <label htmlFor={props.id}>
                        <i className="fas fa-check" />
                        {props.label}
                    </label>

                    <input 
                        type="radio" 
                        id={props.id} 
                        name={props.name}
                        value={props.value}
                        onChange={handleCheckboxChange}
                    />
                </>
            )}
        </div>
    )
}

export {
    Radio,
    RadioVariants
}
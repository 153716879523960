import React from 'react';
import TranslationService from '../../../../core/services/TranslationService';
import { CreditorAccountSummary } from '../types/CreditorAccountSummary';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';
import RouteParamsService from '../../../../core/services/RouteParamsService';

type Props = {
  summary: CreditorAccountSummary
}

const CreditorAndDebtorInfoTable = (props: Props) => {
  const { summary } = props;
  const queryParams = RouteParamsService.getQueryParameters();
 
  const isNameIdParamPassed = () => {
    return queryParams.nameId as string;
  }

  const isExternalVoucherNoParamPassed = () => {
    return queryParams.externalVoucherNo as string;
  }

  const isUnitIdParamPassed = () => {
    return queryParams.unitId as string;
  }

  const getStringBasedOnPassedParam = (translationKeyIfNameIdPassed: string, translationKeyIfExternalVoucherPassed: string, translationKeyIfUnitIdPassed: string) => {
    return isNameIdParamPassed()
      ? TranslationService.translateModule(translationKeyIfNameIdPassed, ModuleNamesList.CreditorAccountSummary)
      : isExternalVoucherNoParamPassed()
        ? TranslationService.translateModule(translationKeyIfExternalVoucherPassed, ModuleNamesList.CreditorAccountSummary)
        : isUnitIdParamPassed()
          ? TranslationService.translateModule(translationKeyIfUnitIdPassed, ModuleNamesList.CreditorAccountSummary)
          : '';
  }
  
  const contentHeader = getStringBasedOnPassedParam('NameIdHeader', 'ExternalVoucherNoHeader', 'UnitIdHeader');
  const getParamValue = () => isNameIdParamPassed() || isExternalVoucherNoParamPassed() || isUnitIdParamPassed() || '';

  return (
    <article className="l-section l-section--decor">
      <header className="l-section__header">
        <h1>{TranslationService.translateModule('CreditorAndDebtorData', ModuleNamesList.CreditorAccountSummary)}</h1>
      </header>

      <div className="l-section__body c-widget c-widget--light row">
        <div className="c-widget__item col-12 col-sm-4">
          <div className="c-widget__item-title c-widget__item-title--no-margin">
            {contentHeader}
          </div>

          <div className="c-widget__item-value c-widget__item-value--small">
            {getParamValue()}
          </div>
        </div>

        <div className="c-widget__item col-12 col-sm-4">
          <div className="c-widget__item-title c-widget__item-title--no-margin">
            {TranslationService.translateModule('CreditorNo', ModuleNamesList.CreditorAccountSummary)}
          </div>

          <div className="c-widget__item-value c-widget__item-value--small">
            {[`${summary.creditorName} (${summary.creditorExternalNo})`]}
          </div>
        </div>

        <div className="c-widget__item col-12 col-sm-4">
          <div className="c-widget__item-title c-widget__item-title--no-margin">
            {TranslationService.translateModule('DebtorName', ModuleNamesList.CreditorAccountSummary)}
          </div>

          <div className="c-widget__item-value c-widget__item-value--small">
            {[summary.debtorName]}
          </div>
        </div>
      </div>
    </article>
  )
}
  
export default CreditorAndDebtorInfoTable;
import { CancelToken } from 'axios';
import RestApiClient from '../../core/api/RestApiClient';
import { ManagedProfileModel } from './Models/ManagedProfileModel';

class PagesListApiClient {
    private cancelToken: CancelToken;

    constructor(cancelToken: CancelToken) {
        this.cancelToken = cancelToken;
    }

    public async getProfiles(): Promise<ManagedProfileModel[]> {
        return await RestApiClient.get<ManagedProfileModel[]>('modules/pageslist/profiles', null, this.cancelToken, true);
    }
}

export default PagesListApiClient;

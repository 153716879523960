import RestApiClient from '../../core/api/RestApiClient';
import { IActionStatus } from "../../core/api/types/IActionStatus";
import { IConfirmationCodes } from './types/IConfirmationCodes';
import { IRegisterModuleSettings } from './types/IRegisterModuleSettings';
import { IUser } from "./types/IUser";

class RegisterUserApiClient {
    public async CreateUser(user: IUser): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>('modules/RegisterUserModule/createUser', user);
    }
    public async GetModuleSettings(moduleInstanceId: number): Promise<IRegisterModuleSettings>{
        return await RestApiClient.get<IRegisterModuleSettings>(`modules/RegisterUserModule/getInstanceSettings/${moduleInstanceId}`);
    }
    public async SendConfirmationCodes(user: IUser, moduleInstanceId: number): Promise<IActionStatus>{
        return await RestApiClient.post<IActionStatus>(`modules/RegisterUserModule/sendConfirmationCodes/${moduleInstanceId}`, user);
    }
    public async ValidateConfirmationCodes(confirmationCodes: IConfirmationCodes, moduleInstanceId: number): Promise<IActionStatus>{
        return await RestApiClient.post<IActionStatus>(`modules/RegisterUserModule/validateConfirmationCodes/${moduleInstanceId}`, confirmationCodes);
    }
    public async ValidateIsEmailExists(email: string): Promise<boolean>{
        return await RestApiClient.get<boolean>(`modules/RegisterUserModule/validateIsEmailExists/${email}`);
    }
    public async CallBisnodeVerification(token: string): Promise<boolean> {
        return await RestApiClient.get<boolean>(`modules/AutoOnboardingModule/Bisnode/Verification/${token}`);
    }
}

export default new RegisterUserApiClient();

import { IActionStatus } from '../../../core/api/types/IActionStatus';
import PostponeInvoiceApi from '../api/PostponeInvoiceApi';
import { IPostponeInvoiceRequest } from '../types/IPostponeInvoiceRequest';

class PostponeInvoiceService {
    public async postponeInvoice(request: IPostponeInvoiceRequest): Promise<IActionStatus> {
        return await PostponeInvoiceApi.postponeInvoice(request);
    }
}

export default new PostponeInvoiceService();
import { Dispatch } from 'redux';
import * as ACTIONS from '../actions';
import TilesService from '../modules/Tiles/services/TilesService';
import RestApiClient from '../core/api/RestApiClient';

const fetchTilesCountersFailed = () => ({
    type: ACTIONS.FETCH_TILES_COUNTERS_FAILED
})

const fetchTilesCountersSucceded = (tilesCounters: any) => ({
    type: ACTIONS.FETCH_TILES_COUNTERS_SUCCEEDED,
    tilesCounters
})

const fetchTilesCounters = () => ({
    type: ACTIONS.FETCH_TILES_COUNTERS_IN_PROGRESS
})

export default () => (dispatch: Dispatch<any>) => {
    return new Promise<void>(async (resolve, reject) => {
        dispatch(fetchTilesCounters());
        try {

            const cancelToken = RestApiClient.getCancelToken();
            const TilesServiceObj = new TilesService(cancelToken.token);

            dispatch(fetchTilesCountersSucceded(await TilesServiceObj.getCasesCounters()));
            resolve();
        } catch (e) {
            dispatch(fetchTilesCountersFailed())
            reject();
        }
    });
}
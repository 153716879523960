import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import { Formik, Form, ErrorMessage } from 'formik';
import { InputContainer } from '../../../core/components/Forms/InputContainer';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { SettingsType } from '../../../core/Enums/SettingsType';
import * as yup from "yup";
import React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { ExternalLoginProviderType } from '../types/ExternalLoginProviderType';

type Props = {
    data: ExternalLoginProviderType,
    onClose: () => void,
    onSubmit: (dataProvider: any) => void,
}

const OidcLoginProviderForm = (props: Props) => {
    const validationSchema = yup.object().shape({
        loginProvider: yup.string().required(TranslationService.translate("ValidationMessageRequiredField")),
        authority: yup.string()
            .required(TranslationService.translate("ValidationMessageRequiredField"))
            .test(
                'is-https',
                TranslationService.translate('ValidationMessageHttpsRequired'),
                value => /^(https):/.test(value)
            )
            .test(
                'is-url',
                TranslationService.translate('ValidationMessageHttpsRequired'),
                value => /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/.test(value)
            ),
        clientId: yup.string().required(TranslationService.translate("ValidationMessageRequiredField")),
        clientSecret: yup.string().required(TranslationService.translate("ValidationMessageRequiredField")),
        providerDisplayName: yup.string().required(TranslationService.translate("ValidationMessageRequiredField")),
        disabled: yup.boolean().required(TranslationService.translate("ValidationMessageRequiredField")),
        showOnLoginPage: yup.boolean().required(TranslationService.translate("ValidationMessageRequiredField")),
        usePkce: yup.boolean().required(TranslationService.translate("ValidationMessageRequiredField"))
    });

    const handleSubmitForm = (dataProvider: any) => {
        props.onSubmit(dataProvider);
    }

    return (
        <>
            <Formik
                onSubmit={handleSubmitForm}
                validationSchema={validationSchema}
                initialValues={props.data}>
                    {({ values, handleChange }) => (
                        <Form>
                            <div className="row">
                                <div className="col-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="loginProvider"
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    {TranslationService.translateModule('TableHeaderLoginProvider', ModuleNamesList.ExternalLoginProviders)}
                                                </div>

                                                <div className="col-7">
                                                    <InputContainer
                                                        id={name}
                                                        name={name}
                                                        type={SettingsType.String}
                                                        value={values[name]}
                                                        onChange={(value)=> {
                                                            const handleChangeEvent = {
                                                                target: {
                                                                    value,
                                                                    id: name,
                                                                    name: name,
                                                                }
                                                            } as React.ChangeEvent<any>;
                                                    
                                                            handleChange(handleChangeEvent);
                                                        }}
                                                    />
                                                    
                                                    <ErrorMessage name={name} component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="col-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="providerDisplayName"
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    {TranslationService.translateModule('TableHeaderProviderDisplayName', ModuleNamesList.ExternalLoginProviders)}
                                                </div>

                                                <div className="col-7">
                                                    <InputContainer
                                                        id={name}
                                                        name={name}
                                                        type={SettingsType.String}
                                                        value={values[name]}
                                                        onChange={(value)=> {
                                                            const handleChangeEvent = {
                                                                target: {
                                                                    value,
                                                                    id: name,
                                                                    name: name,
                                                                }
                                                            } as React.ChangeEvent<any>;
                                                    
                                                            handleChange(handleChangeEvent);
                                                        }}
                                                    />
                                                    
                                                    <ErrorMessage name={name} component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="col-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="clientId"
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    {TranslationService.translateModule('TableHeaderClientId', ModuleNamesList.ExternalLoginProviders)}
                                                </div>

                                                <div className="col-7">
                                                    <InputContainer
                                                        id={name}
                                                        name={name}
                                                        type={SettingsType.String}
                                                        value={values[name]}
                                                        onChange={(value)=> {
                                                            const handleChangeEvent = {
                                                                target: {
                                                                    value,
                                                                    id: name,
                                                                    name: name,
                                                                }
                                                            } as React.ChangeEvent<any>;
                                                    
                                                            handleChange(handleChangeEvent);
                                                        }}
                                                    />
                                                    
                                                    <ErrorMessage name={name} component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="col-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="clientSecret"
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    {TranslationService.translateModule('TableHeaderClientSecret', ModuleNamesList.ExternalLoginProviders)}
                                                </div>

                                                <div className="col-7">
                                                    <InputContainer
                                                        id={name}
                                                        name={name}
                                                        type={SettingsType.String}
                                                        value={values[name]}
                                                        onChange={(value)=> {
                                                            const handleChangeEvent = {
                                                                target: {
                                                                    value,
                                                                    id: name,
                                                                    name: name,
                                                                }
                                                            } as React.ChangeEvent<any>;
                                                    
                                                            handleChange(handleChangeEvent);
                                                        }}
                                                    />
                                                    
                                                    <ErrorMessage name={name} component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="col-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="authority"
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    {TranslationService.translateModule('TableHeaderAuthority', ModuleNamesList.ExternalLoginProviders)}
                                                </div>

                                                <div className="col-7">
                                                    <InputContainer
                                                        id={name}
                                                        name={name}
                                                        type={SettingsType.String}
                                                        value={values[name]}
                                                        onChange={(value)=> {
                                                            const handleChangeEvent = {
                                                                target: {
                                                                    value,
                                                                    id: name,
                                                                    name: name,
                                                                }
                                                            } as React.ChangeEvent<any>;
                                                    
                                                            handleChange(handleChangeEvent);
                                                        }}
                                                    />
                                                    
                                                    <ErrorMessage name={name} component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="col-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="disabled"
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    {TranslationService.translateModule('TableHeaderDisabled', ModuleNamesList.ExternalLoginProviders)}
                                                </div>

                                                <div className="col-7">
                                                    <InputContainer
                                                        id={name}
                                                        name={name}
                                                        type={SettingsType.Bool}
                                                        value={values[name]}
                                                        onChange={(value)=> {
                                                            const handleChangeEvent = {
                                                                target: {
                                                                    value,
                                                                    id: name,
                                                                    name: name,
                                                                }
                                                            } as React.ChangeEvent<any>;
                                                    
                                                            handleChange(handleChangeEvent);
                                                        }}
                                                    />
                                                    
                                                    <ErrorMessage name={name} component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="col-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="showOnLoginPage"
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    {TranslationService.translateModule('ProviderFormShowOnLoginPage', ModuleNamesList.ExternalLoginProviders)}
                                                </div>

                                                <div className="col-7">
                                                    <InputContainer
                                                        id={name}
                                                        name={name}
                                                        type={SettingsType.Bool}
                                                        value={values[name]}
                                                        onChange={(value)=> {
                                                            const handleChangeEvent = {
                                                                target: {
                                                                    value,
                                                                    id: name,
                                                                    name: name,
                                                                }
                                                            } as React.ChangeEvent<any>;
                                                    
                                                            handleChange(handleChangeEvent);
                                                        }}
                                                    />
                                                    
                                                    <ErrorMessage name={name} component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="col-12">
                                    <FormGroup
                                        variant={FORM_GROUP_VARIANTS.INLINE}
                                        name="usePkce"
                                        render={(name) => (
                                            <div className="row">
                                                <div className="col-5 d-flex align-items-center justify-content-end">
                                                    {TranslationService.translateModule('ProviderFormUsePkce', ModuleNamesList.ExternalLoginProviders)}
                                                </div>

                                                <div className="col-7">
                                                    <InputContainer
                                                        id={name}
                                                        name={name}
                                                        type={SettingsType.Bool}
                                                        value={values[name]}
                                                        onChange={(value)=> {
                                                            const handleChangeEvent = {
                                                                target: {
                                                                    value,
                                                                    id: name,
                                                                    name: name,
                                                                }
                                                            } as React.ChangeEvent<any>;
                                                    
                                                            handleChange(handleChangeEvent);
                                                        }}
                                                    />
                                                    
                                                    <ErrorMessage name={name} component="div" className="error error-message" />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div> 

                            <div className="row justify-content-end">
                                <div className="col-auto">
                                    <Button
                                        type="button" 
                                        id="editProviderCancelBtn" 
                                        variant={BUTTON_VARIANTS.SECONDARY}
                                        size={BUTTON_SIZE.SM}
                                        label={TranslationService.translate('Cancel')}
                                        onClick={props.onClose}
                                    />
                                </div>

                                <div className="col-auto">
                                    <Button
                                        type="submit"
                                        id="editProviderSubmitBtn" 
                                        variant={BUTTON_VARIANTS.PRIMARY}
                                        size={BUTTON_SIZE.SM}
                                        label={TranslationService.translateModule('Submit', ModuleNamesList.ExternalLoginProviders)}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
            </Formik>
        </>
    )
}

export default OidcLoginProviderForm;
import { ISearchConversationsRequest } from '../Messages/types/ISearchConversationsRequest';
import { IConversationsTableResult } from '../Messages/types/IConversationsTableResult';
import { getConversationsList, getConversationDetails, sendNewMessageApi } from './api/HomeMessageApi';
import { IMessageFolderType } from './enums/IMessageFolderType';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import { INewMessageRequest } from './types/INewMessageRequest';

const getConversations = async (request: ISearchConversationsRequest): Promise<IConversationsTableResult> => {
    return await getConversationsList(request);
};

const getConversation = async (conversationId: number, creditorNumber: string) => {
    return await getConversationDetails(conversationId, creditorNumber);
};

const sendNewMessage = async (newMessageRequest: INewMessageRequest, moduleInstanceId: number): Promise<IActionStatus> => {
    const formData = new FormData();
    
    newMessageRequest.message.attachedFiles.forEach((element, index) => {
        formData.append('file', element, element.name);
    });

    if (newMessageRequest.conversationId) {
        formData.append('conversationId', newMessageRequest.conversationId.toString());
    }

    formData.append('moduleInstanceId', moduleInstanceId.toString());
    formData.append('creditorNumber', newMessageRequest.creditorNumber);
    
    const message = newMessageRequest.message;
    message.attachedFiles = [];
    
    formData.append('message', JSON.stringify(message));
   
    return await sendNewMessageApi(formData);
}

const getCurrentFolder = (folderName: string): IMessageFolderType => {
    switch (folderName) {
        case 'inbox':
            return IMessageFolderType.Inbox;
        case 'sent':
            return IMessageFolderType.Sent;
        case 'archive':
            return IMessageFolderType.Archive;
        case 'overdue':
            return IMessageFolderType.Overdue;
        case 'case':
            return IMessageFolderType.Case;
        default:
            return IMessageFolderType.Inbox;
    }
}

export {
    getConversations,
    getConversation,
    getCurrentFolder,
    sendNewMessage
};
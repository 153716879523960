export enum NavBarFilterOption {
    CaseNumber = 0,
    InvoiceNumber = 1, 
    DebtorNumber = 2, 
    DebtorName = 3,
    UnitId = 4,
    NameId = 5,
    ExternalVoucherNo = 6,
    DebtorSsn = 7,
    CaseReference = 8,
    Ref2Number = 9
}
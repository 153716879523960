import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import Collapse from 'reactstrap/lib/Collapse';
import { ILeftMenuNavLinkState } from "./ILeftMenuNavLinkState";
import * as React from 'react';
import { LeftMenuSubLinks } from './LeftMenuSubLinks';
import { INavLink } from './types/INavLink';
import NavItem from 'reactstrap/lib/NavItem';
import Nav from 'reactstrap/lib/Nav';

interface ILeftMenuNavLinkProps {
    link: INavLink
}

const anySubLinkActive = (subLinks: INavLink[], pathName: string) => {
    for (const subItem of subLinks) {
        const isActive = pathName ? (pathName === subItem.to) : false;

        if (isActive) {
            return true;
        }
    }

    return false;
}

const isCollapsed = (props: ILeftMenuNavLinkProps & RouteComponentProps) => {
    const pathname = props.location.pathname.replace("/", "")

    if (anySubLinkActive(props.link.subLinks, pathname)){
        return false;
    }

    return pathname !== props.link.to;
}

class LeftMenuNavLink extends React.Component<ILeftMenuNavLinkProps & RouteComponentProps, ILeftMenuNavLinkState> {
    public state = {
        isCollapsed: isCollapsed(this.props)
    };

    public render() {
        const { link } = this.props;
        const pathName = this.props.location.pathname.replace("/", "");
        const fullPathname = pathName + this.props.location.search;
        const isActive = (pathName === this.props.link.to) || (this.props.location.pathname === this.props.link.to);

        return (
            <NavItem className={`c-left-menu__item ${this.state.isCollapsed ? '' : 'c-left-menu__item--opened'}`}>
                {link.subLinks.length ? (
                    <>
                        <NavLink className={`c-left-menu__link c-left-menu__link--root ${this.state.isCollapsed ? 'closed' : 'opened'}`} to={link.to}
                            isActive={() => isActive || anySubLinkActive(link.subLinks, fullPathname)}
                            id={link.id}
                            onClick={(event) => {
                                if (!link.navigate) {
                                    event.preventDefault();
                                }
                                this.setState({ isCollapsed: !this.state.isCollapsed });                               
                            }}>
                            {link.icon ? <i className={link.icon} /> : ''} {link.name}
                        </NavLink>
                        <Collapse isOpen={!this.state.isCollapsed}>
                            <Nav vertical={true} className="c-left-menu c-left-menu--sub">
                                <LeftMenuSubLinks navItems={link.subLinks} pathName={fullPathname} />
                            </Nav>
                        </Collapse>
                    </>
                ) : (
                    <NavLink className="c-left-menu__link" isActive={() => isActive} id={link.id} to={link.to}>
                        {link.icon ? <i className={link.icon} /> : ''} {link.name}
                    </NavLink>
                )}
            </NavItem>
        )
    }
}

export default withRouter(LeftMenuNavLink)

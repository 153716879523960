import React from 'react';
import { CreditorAccountSummary } from '../types/CreditorAccountSummary';
import TranslationService from '../../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../../core/components/Button/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CurrencyParser from '../../../../core/helpers/CurrencyParser';
import RouteParamsService from '../../../../core/services/RouteParamsService';

type Props = {
  summary: CreditorAccountSummary
}

const BalanceOfTotalReceivables = (props: Props & RouteComponentProps) => {
  const { summary } = props;
  const queryParams = RouteParamsService.getQueryParameters();

  const getUnitIdParamIfExists = (): string => {
    const unitParam = (queryParams.unitId ? queryParams.unitId : '') as string;
    return unitParam !== '' ? `&unitId=${unitParam}` : '';
  }
  
  return (
    <article className="l-section">
      <div className="l-section__body c-widget row">
        <div className="c-widget__item c-widget__item--br c-widget__item--center col-3">
          <div className="c-widget__item-title">
            {TranslationService.translateModule('TotalReceivable', ModuleNamesList.CreditorAccountSummary)}
          </div>

          <div className="c-widget__item-value">
            {CurrencyParser.toLocaleString(summary.balanceTotalReceivablesData.balanceTotalReceivables)}
          </div>
          
          <div className="c-widget__item-link c-widget__item-link--flex-end">
            <Button
              id="creditorAccountSummaryTotalBalanceDetailsLink"
              className="c-widget__item--center"
              variant={BUTTON_VARIANTS.LINK}
              size={BUTTON_SIZE.SM}
              type="button"
              label={TranslationService.translateModule('ViewDetails', ModuleNamesList.CreditorAccountSummary)}
              onClick={() => props.history.push(`/unitid-invoices?creditorNo=${summary.creditorNo}&accountNo=${summary.accountNo}${getUnitIdParamIfExists()}`)}
            />
          </div>
        </div>

        <div className="c-widget__item c-widget__item--br c-widget__item--center col-3">
          <div className="c-widget__item-title">
            {TranslationService.translateModule('OfWhichOverdueCapital', ModuleNamesList.CreditorAccountSummary)}
          </div>

          <div className="c-widget__item-value">
            {CurrencyParser.toLocaleString(summary.balanceTotalReceivablesData.ofWhichOverdueCapital)}
          </div>
        </div>

        <div className="c-widget__item c-widget__item--br c-widget__item--center col-3">
          <div className="c-widget__item-title">
            {TranslationService.translateModule('OfWhichDebtCollectionCapital', ModuleNamesList.CreditorAccountSummary)}
          </div>

          <div className="c-widget__item-value">
            {CurrencyParser.toLocaleString(summary.balanceTotalReceivablesData.ofWhichDebtCollectionCapital)}
          </div>

          <div className="c-widget__item-link c-widget__item-link--flex-end">
            <Button
              id="creditorAccountSummaryTotalBalanceDetailsLink"
              variant={BUTTON_VARIANTS.LINK}
              size={BUTTON_SIZE.SM}
              type="button"
              label={TranslationService.translateModule('ViewDetails', ModuleNamesList.CreditorAccountSummary)}
              onClick={() => props.history.push(`/unitid-cases?creditorNo=${summary.creditorNo}&accountNo=${summary.accountNo}${getUnitIdParamIfExists()}`)}
            />
          </div>
        </div>

        <div className="c-widget__item c-widget__item--center col-3">
          <div className="c-widget__item-title">
            {TranslationService.translateModule('OfWhichDebtCollectionCost', ModuleNamesList.CreditorAccountSummary)}
          </div>

          <div className="c-widget__item-value">
            {CurrencyParser.toLocaleString(summary.balanceTotalReceivablesData.ofWhichDebtCollectionCost)}
          </div>
        </div>
      </div> 
    </article>
  )
}

export default withRouter(BalanceOfTotalReceivables);
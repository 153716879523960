import { IComponentTable } from '../../../core/types/IComponentTable';
import DocumentsListApi from '../api/DocumentsListApi';
import { IDocumentsListRequest } from '../types/IDocumentsListRequest';
import { IDocumentListElement } from '../types/IDocumentsListElement';

class DocumentsListService {
    public async getDocuments(request: IDocumentsListRequest) : Promise<IComponentTable<IDocumentListElement>> {
        return await DocumentsListApi.getDocuments(request);
    }
    public async getDocument(documentId: number, caseNumber: string) : Promise<void> {
        return await DocumentsListApi.getDocument(documentId, caseNumber);
    }
}

export default new DocumentsListService();
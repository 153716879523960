import * as React from 'react';
import { LocalBootstrapTable } from '../../../core/components/BootstrapTable/RemoteBootstrapTable';
import Loader from '../../../core/components/Loading/Loader';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import InfoMessageService from '../../../core/services/InfoMessageService';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTable, emptyComponentTable } from '../../../core/types/IComponentTable';
import InvoicesListTableConfigurator from '../helpers/InvoicesListTableConfigurator';
import InvoicesListService from '../services/InvoicesListService';

interface IProps {
    invoiceLineNumber: string,
    caseId: string,
    moduleInstanceId: number
}

interface IState {
    isDataLoading: boolean,
    payments: IComponentTable
}

export default class InvoicePaymentsTable extends React.Component<IProps, IState> {
    public state: IState = {
        isDataLoading: true,
        payments: emptyComponentTable
    }

    public async componentDidMount() {
        await this.fetchPayments();
    }

    public render() {
        return (
            <div className="invoice-payments">
                <h5>{TranslationService.translateModule('InvoicePayments', ModuleNamesList.InvoicesList)}</h5>
                {this.state.isDataLoading ? <Loader /> : <LocalBootstrapTable
                    wrapperClasses="bt"
                    classes="bt__table bt-table"
                    keyField='uniqueId'
                    paginationProps={{
                        hideSizePerPage: true
                    }}
                    data={this.state.payments.values}
                    columns={InvoicesListTableConfigurator.InvoicesListPaymentsTableConfigurator.getColumns(this.state.payments)} />}
            </div>)
    }

    private fetchPayments = async () => {
        this.setState({
            isDataLoading: true
        })

        try {
            const results = await InvoicesListService.GetInvoicePayments(this.props.invoiceLineNumber, this.props.moduleInstanceId, this.props.caseId);
            this.setState({
                payments: results,
                isDataLoading: false
            })
        } catch {
            InfoMessageService.error(TranslationService.translate('ErrorLoadingData'))
            this.setState({
                isDataLoading: false
            })
        }
    }
}
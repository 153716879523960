import * as React from 'react';
import { Field, ErrorMessage } from 'formik';
import { IFormGroupFieldProps } from '../../types/IFormGroupFieldProps';

class FormGroupField extends React.Component<IFormGroupFieldProps> {
    public getErrorClass() {
        const properties: string[] = this.props.fieldName.split(".");
        if (properties.length === 1) {
            return (this.props.errors[this.props.fieldName] && this.props.touched[this.props.fieldName]) ? "error" : "";
        }

        let errorProperty = this.props.errors[properties[0]];
        let touchProperty = this.props.touched[properties[0]];
        if (!errorProperty || !touchProperty) {
            return "";
        }

        let iter = 1;
        while (iter < properties.length) {
            errorProperty = (errorProperty as any)![properties[iter]];
            touchProperty = (touchProperty as any)![properties[iter]];
            if (!errorProperty || !touchProperty) {
                return "";
            }
            iter++;
        }

        if (errorProperty && touchProperty) {
            return "error"
        }
        return "";
    }

    public getInputClassNames = () => {
        return this.props.inputClassNames
            ? ` ${this.props.inputClassNames} `
            : '';

    };

    public getFormFieldObject = () => {
        return (
            <div className={`${this.props.inputContainerClass || 'col-sm-9'} c-control__input ${this.props.loadingSpinner ? 'spinner-inside' : ''}`}>
                <Field 
                    type={this.props.type ? this.props.type : "text"}
                    component={this.props.component}
                    name={this.props.fieldName}
                    id={this.props.id ? this.props.id : this.props.fieldName}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    className={"form-control " + this.getErrorClass() + this.getInputClassNames()}
                    onBlur={this.props.handleBlur}
                    placeholder={this.props.placeholder}
                    onChange={this.props.handleChange}
                    autoComplete={this.props.autoComplete || "off"}
                >
                    {this.props.children} 
                </Field>

                {this.props.loadingSpinner &&
                    <div className="spinner-border spinner-border-sm text-success" role="status" />
                }

                <ErrorMessage name={this.props.fieldName} component="div" className="error error-message" />
            </div>
        );
    };

    public getFormFieldLabel = () => {
        return (
            <>
                {!this.props.hideLabel &&
                    <label className={`${this.props.labelClass || 'col-sm-3'} c-control__label`} htmlFor={this.props.fieldName}>
                        {this.props.label}

                        {this.props.required && <span className="c-control__required-sign" />}
                    </label>
                }
            </>
        );
    };

    public render() {
        return (
            <>
                {this.props.hideFormGroupDiv ?
                    <>
                        {this.getFormFieldLabel()}
                        {this.getFormFieldObject()}
                    </>
                    :
                    <div className={`c-control row ${this.props.formGroupClass || ''}`}>
                        {this.getFormFieldLabel()}
                        {this.getFormFieldObject()}
                    </div>
                }
            </>
        );
    }
}

export default FormGroupField;
import React, { useState, useEffect } from 'react';
import Loader from '../../core/components/Loading/Loader';
import { LocalBootstrapTable } from '../../core/components/BootstrapTable/RemoteBootstrapTable';
import { IComponentTable } from '../../core/types/IComponentTable';
import { InvoiceDetailsPaymentItem } from './types/InvoiceDetialsPaymentItem';
import InvoiceDetailsPaymentsTableConfigurator from './helpers/InvoiceDetailsPaymentsTableConfigurator';
import InvoiceDetailsPaymentsService from './services/InvoiceDetailsPaymentsService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import RouteParamsService from '../../core/services/RouteParamsService';
import { connect } from 'react-redux';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';

export const InvoiceDetailsPayments = (props: IExtendedModuleProps) => {
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState<IComponentTable<InvoiceDetailsPaymentItem>>({ columns: [], values: [] });
    const [hasAccessToModule, setHasAccessToModule] = useState(true);

    const fetchInvoiceDetailsPayments = async () => {
        setIsLoading(true);
        try {
            const result = await InvoiceDetailsPaymentsService.getInvoiceDetailsPayments({
                moduleInstanceId: props.module.id,
                accountNo: props.accountNo,
                creditorNo: props.creditorNo,
                invoiceNo: props.invoiceNo
            })
            
            setDataTable(result);
            setIsInitialDataLoaded(true);
            setHasAccessToModule(true);
        } catch {
            setHasAccessToModule(false);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await fetchInvoiceDetailsPayments()
        };

        load();
        // eslint-disable-next-line
    }, [props.routeParameters]);

    if (!hasAccessToModule) return <NoPermissionsComponent />

    return (<div>
        <Loader opacity={1} isLoaded={!isLoading} />
        {isInitialDataLoaded && dataTable.columns.length > 0 && <LocalBootstrapTable
            keyField="id"
            id="invoiceDetailsPaymentsTable"
            wrapperClasses="bt"
            classes="bt__table bt-table"
            data={dataTable.values}
            columns={InvoiceDetailsPaymentsTableConfigurator.getColumns(dataTable)} />}
    </div>)
};

const mapRouteParamsToProps = () => {
    const routeParams = RouteParamsService.getQueryParameters();
    return {
        accountNo: routeParams.accountNo as string,
        creditorNo: routeParams.creditorNo as string,
        invoiceNo: routeParams.invoiceNo as string
    }
}

export default connect(mapRouteParamsToProps)(InvoiceDetailsPayments);
import CreditorAwaitingApprovalSkin from '../skins/CreditorAwaitingApprovalSkin';
import { DefaultSkin } from '../skins/DefaultSkin';
import ErrorSkin from '../skins/ErrorSkin/ErrorSkin';
import { FourContainersSkin } from '../skins/FourContainersSkin';
import { FullWidthSkin } from '../skins/FullWidthSkin';
import { OneColumnSkin } from '../skins/OneColumnSkin';
import { TopMainSidebarSkin } from '../skins/TopMainSidebarSkin';
import { TwoColumnsSkin } from '../skins/TwoColumnsSkin';
import { WrapperMainSidebarSkin } from '../skins/WrapperMainSidebarSkin';
import { FullWidthTopAnd3CenterContainersSkin } from '../skins/FullWidthTopAnd3CenterContainersSkin';
import AutoOnboardingErrorSkin from '../skins/AutoOnboardingErrorSkin/AutoOnboardingErrorSkin';
import AutoOnboardingUserMessageSkin from '../skins/AutoOnboardingUserMessageSkin/AutoOnboardingUserMessageSkin';

export default {
    CreditorAwaitingApprovalSkin,
    DefaultSkin,
    ErrorSkin,
    AutoOnboardingErrorSkin,
    AutoOnboardingUserMessageSkin,
    FourContainersSkin,
    FullWidthSkin,
    OneColumnSkin,
    TopMainSidebarSkin,
    TwoColumnsSkin,
    WrapperMainSidebarSkin,
    FullWidthTopAnd3CenterContainersSkin,
}

import React, { useState, useEffect } from 'react';
import { IModuleProps } from '../../core/types/IModuleProps';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import Loader from '../../core/components/Loading/Loader';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import { IComponentTable, emptyComponentTable } from '../../core/types/IComponentTable';
import withAbortRequest, { AbortRequestPropsType } from 'core/hoc/AbortRequest';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import Modal from '../../core/components/Modal/Modal';
import { GetUserCreditorsList } from './CreditorsManagmentApiClient';
import { ICreditorDetails } from './types/ICreditorDetails';
import ManageAliases from './components/ManageAliases';
import Dropdown from 'react-bootstrap/Dropdown';

type CreditorType = {
    uId: string,
    domainId: string
};

const CreditorsManagment = (props: IModuleProps & AbortRequestPropsType) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);    
    const [table, setTable] = useState<IComponentTable>({...emptyComponentTable});    
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedCreditor, setSelectedCreditor] = useState<CreditorType>();

    const loadCreditorsTable = async () => {
        const table = await GetUserCreditorsList(props.module.id, props.cancelTokenSource.token);

        table.columns.push({
            dataField: 'actions',
            text: 'Actions',
            sort: false,
            formatter: (value: any, element: ICreditorDetails) => {
                return (
                    <>
                        <Dropdown alignRight className="button-table">
                            <Dropdown.Toggle bsPrefix="c-btn c-btn--color-primary c-btn--toggle" id="actionsDropdown">
                                {TranslationService.translateModule('Actions', ModuleNamesList.CaseList)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item 
                                    id="aliasesDropdownItem"
                                    onClick={() => handleAliasManageClick(element.uId, element.domainId)}
                                >
                                    {TranslationService.translateModule('Aliases', ModuleNamesList.CreditorsManagment)}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                )
            }
        });

        setTable(table);
        setIsLoading(false);
    };

    const handleAliasManageClick = async (creditorUId: string, domainId: string) => {
        setSelectedCreditor({
            uId: creditorUId, domainId
        });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    useEffect(() => {
        loadCreditorsTable();
        // eslint-disable-next-line
    }, []);

    return (
        <article className="l-module">
            <section className="l-module__section l-module__section--head mb-3">
                <h1>
                    <i className="fas fa-user-friends mr-2"/>                            
                    {TranslationService.translateModule('PageHeaderCreditorsManagment', ModuleNamesList.CreditorsManagment)}
                </h1>
            </section>

            <section className={`l-module__section ${isLoading ? 'l-module__section--loading' : ''}`}>
                {isLoading && <Loader opacity={0.5} /> }

                <BootstrapTableContainer
                    remote={false}
                    wrapperClasses="bt"
                    classes="bt__table bt-table bt--small bt--selectable" 
                    keyField='uId'
                    data={table.values}
                    columns={table.columns} 
                />

                <Modal
                    isOpen={isModalOpen}
                    modalData={{
                        className: 'c-modal--lg',
                        content: 
                            <>
                                {selectedCreditor && (
                                    // @ts-ignore
                                    <ManageAliases 
                                        creditor={selectedCreditor} 
                                        moduleInstanceId={props.module.id}
                                        onClose={handleCloseModal}
                                    />
                                )}

                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <Button
                                            id="closeModalAliases"
                                            variant={BUTTON_VARIANTS.SECONDARY}
                                            size={BUTTON_SIZE.SM}
                                            label={TranslationService.translate('Close')}
                                            onClick={handleCloseModal}
                                        />
                                    </div>
                                </div>
                            </> 
                    }}
                />
            </section>
        </article>
    );
}
    


export default withAbortRequest(CreditorsManagment);
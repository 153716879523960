import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { IStore } from '../../reducers/IStore';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import * as ACTIONS from '../../actions'
import StylingSettingsApiClient from './StylingSettingsApiClient';
import TranslationService from './../../core/services/TranslationService';

import { IModuleProps } from '../../core/types/IModuleProps';
import { IStylingInitData } from './types/IStylingInitData';
import { IAttributesStyling } from './types/IAttributesStyling';
import { ISettingsStyling } from './types/ISettingsStyling';

import InfoMessageService from './../../core/services/InfoMessageService';
import InputTypeManager from './../../core/components/Forms/InputTypeManager';
import StyleSettingsService from '../../core/services/StyleSettingsService';
import Loader from '../../core/components/Loading/Loader';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import { Status } from '../../core/api/Enums/Status';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { Card } from '../../core/components/Card/Card';
import { CardHeader } from '../../core/components/Card/components/CardHeader';
import CardBs from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

interface ISubmitData {
    id: number,
    value: string
}

interface IState {
    isDataLoading: boolean;
    attributesMap: {},
    groupSettings: any,
    submitData: ISubmitData[],
    cardOpened: boolean[][]
}

interface IProps extends IModuleProps {
    fetchStylingSettings: any
}

class StylingSettings extends React.Component<IProps & RouteComponentProps, IState> {
    state : IState = {
        isDataLoading: true,
        attributesMap: {},
        groupSettings: {},
        submitData: [],
        cardOpened: []
    }

    async componentDidMount() {
        try {
            let { attributes, settings }: IStylingInitData = await this.fetchDataSettings();
            const attributesMap = this.prepareAttrMap(attributes);
            const groupSettings = this.prepareGroupSettings(settings);

            this.setState({
                attributesMap,
                groupSettings,
            });
        } finally {
            this.setState({
                isDataLoading: false
            });
        }
    }

    render() {
        const { groupSettings } = this.state;

        return (
            <article className="l-module">
                <section className="l-module__section l-module__section--head mb-3">
                    <h1>
                        <i className="fas fa-sliders-h mr-2"></i>
                        {TranslationService.translateModule('TitleStylingSettings', 'StylingSettings')} 
                    </h1>
                </section>

                <section className={`l-module__section ${this.state.isDataLoading ? 'l-module__section--loading' : ''}`}>
                    {this.state.isDataLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {Object.keys(groupSettings).map((groupSetting: any, groupIndex: number) => (
                                <Card key={groupIndex} class="mb-3">
                                    <>
                                        <CardHeader>
                                            <h1 className="c-heading">
                                                {TranslationService.translateModule(`StyleSettingsTitleGroup${groupSetting}`, 'StylingSettings')}
                                            </h1>
                                        </CardHeader>

                                        <CardContent>
                                            <>
                                                {Object.keys(groupSettings[groupSetting]).map((groupType: any, index: number) => (
                                                    <Accordion key={index}>
                                                        <CardBs className="accordion__wrapper" key={index}>
                                                            <Accordion.Toggle as={CardBs.Header} className="accordion__head" variant="link" eventKey={index.toString()} onClick={() => {
                                                                const cardOpened = this.state.cardOpened;
                                                                if(!cardOpened[groupIndex]) cardOpened[groupIndex] = [];
                                                                cardOpened[groupIndex][index] = !cardOpened[groupIndex][index]; 
                                                                this.setState({cardOpened})} 
                                                            }>
                                                                <strong>{groupType}</strong>
                            
                                                                <div className="card-header__icon">
                                                                    {this.state.cardOpened[groupIndex] && this.state.cardOpened[groupIndex][index] ? (
                                                                        <i className="fas fa-chevron-up"></i>
                                                                    ) : (
                                                                        <i className="fas fa-chevron-down"></i>
                                                                    )}
                                                                </div>
                                                            </Accordion.Toggle>
                
                                                            <Accordion.Collapse key={index} eventKey={index.toString()}>
                                                                <CardBs.Body>
                                                                    {Object.keys(groupSettings[groupSetting][groupType]).map((setting: any, index: number) => (
                                                                        <fieldset key={index} className="c-form-group c-form-group--fieldset">
                                                                            <legend>{setting}</legend>

                                                                            {groupSettings[groupSetting][groupType][setting].map((settingItem: any, index: number) => {
                                                                                const attributesMap = this.state.attributesMap[settingItem.styleAttributeId];
                                                                                const name = attributesMap.name.charAt(0).toUpperCase() + attributesMap.name.slice(1);

                                                                                return (
                                                                                    <div className="row mb-3" key={index}>
                                                                                        <div className="col-6">
                                                                                            {TranslationService.translateModule(`Label${name}`, 'StylingSettings')}
                                                                                        </div>

                                                                                        <div className="col-6">
                                                                                            <InputTypeManager 
                                                                                                key={index} 
                                                                                                attributes={attributesMap}
                                                                                                value={settingItem.value}
                                                                                                id={settingItem.id}
                                                                                                handleChange={value => {
                                                                                                    this.handleChange(settingItem.id, value)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </fieldset>
                                                                    ))}
                                                                </CardBs.Body>
                                                            </Accordion.Collapse>
                                                        </CardBs>
                                                    </Accordion>
                                                ))}
                                            </>
                                        </CardContent>
                                    </>
                                </Card>
                            ))}
                        </>
                    )}
                </section>

                <section className="l-module__section l-module__section--footer mb-3">
                    <Button
                        type="button"
                        id="caseListFilterBtn"
                        className="mr-2"
                        variant={BUTTON_VARIANTS.PRIMARY}
                        size={BUTTON_SIZE.MD}
                        label={TranslationService.translate('Save')}
                        onClick={this.handleSubmit}
                        showLoader={this.state.isDataLoading}
                    />
                </section>
            </article>
        );
    }

    handleSubmit = async() => {
        this.setState({
            isDataLoading: true
        });

        try {
            const status = await StylingSettingsApiClient.updateSettings(this.state.submitData);

            if (status.status === Status.Error){
                InfoMessageService.error(TranslationService.translateModule('ErrorMsgOnSubmit', 'StylingSettings'));
            }

            await this.updateStylingSettings();
        } finally {
            this.setState({
                isDataLoading: false
            });

            window.location.reload(true);
        }
    }

    handleChange = (settingId: number, value: string | string[]) => {
        let setting: any = [];

        setting = this.state.submitData.filter((setting: any) => {
            return setting.id !== settingId
        });

         this.setState({
             submitData: [
                 ...setting,
                 {
                    id: settingId,
                    value
                }
            ]
        });
    }

    prepareGroupSettings = (settings: ISettingsStyling) => {
        let groupSettings = {};
        
        for (const setting in settings) {
            let settingSplitted = setting.split(':').map((settingName) => {
                return settingName.charAt(0).toUpperCase() + settingName.slice(1);
            });

            settingSplitted.forEach((splittedSettingName: string, index: number, arr: []) => {
                let firstLevel = groupSettings[settingSplitted[0]];

                switch (index) {
                    case 0:
                        if (!groupSettings[splittedSettingName]) {
                            const data = (arr.length > 1) ? {} : {General: settings[setting]};

                            groupSettings[splittedSettingName] = data;
                        }

                        break;
                    case 1:

                        if (!firstLevel[splittedSettingName]) {
                            const data = (arr.length > 2) ? {} : {General: settings[setting]};

                            groupSettings[settingSplitted[0]] = {
                                ...firstLevel,
                                [splittedSettingName]: data
                            };
                        }

                        break;
                    case 2:
                        if (!firstLevel[settingSplitted[1]][splittedSettingName]) {
                            groupSettings[settingSplitted[0]][settingSplitted[1]][splittedSettingName] = settings[setting];
                        }

                        break;
                    case 3:
                        groupSettings[settingSplitted[0]][settingSplitted[1]][settingSplitted[2]] = [
                            ...groupSettings[settingSplitted[0]][settingSplitted[1]][settingSplitted[2]],
                            ...settings[setting],
                        ];

                        break;
                }
            });
        }

        return groupSettings;
    }

    prepareAttrMap = (attributes: IAttributesStyling[]) => {
        let attributesMap = {};

        for (let i = 0; i < attributes.length; i++) {
            const attribute = attributes[i];

            attributesMap[attribute.id] = attribute;
        }

        return attributesMap;
    }

    updateStylingSettings = () => {
        return StyleSettingsService.getStyleSettings().then((data) => {
            return this.props.fetchStylingSettings(data);
        });
    }

    private fetchDataSettings = () => {
        return StylingSettingsApiClient.getSettings();
    }
}


const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, AnyAction>) => ({
    fetchStylingSettings: (data: any) => dispatch({
        type: ACTIONS.FETCH_STYLING_SETTINGS_SUCCEEDED,
        stylingSettings: data
    })
  })
  
export default connect(null, mapDispatchToProps)(withRouter(StylingSettings));
import * as React from 'react';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';

export const CreditNoteNumberInput = (props: { onChange: (value: string) => void }) => {
    return (
        <div className="c-control">
            <div className="row row--aligned">
                <label className="c-control__label col-6" htmlFor="paymentAmount">
                    {TranslationService.translateModule('CreditNoteNo', ModuleNamesList.DirectPayment)}
                </label>

                <div className="col-6">
                    <input id="paymentAmount" type="text" className="form-control" onChange={(e: any) => props.onChange(e.target.value)} />
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import TranslationService from '../../../core/services/TranslationService';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { FormFieldset } from '../../../core/components/Forms/FormFieldset';
import { formFilterValuesInit, FormFilterFieldsType } from '../types/FormFilterFieldsType';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { ManagedProfileModel } from '../Models/ManagedProfileModel';

type PagesListFilterPropsType = {
    profiles: any,
    defaultProfile: string,
    onSubmit: (values?: FormFilterFieldsType) => void
}

const PagesListFilter = (props: PagesListFilterPropsType) => {
    const [profileNames, setProfileNames] = useState<FormFilterFieldsType>(formFilterValuesInit)
    const handleSubmit = (values: FormFilterFieldsType) => {
        props.onSubmit(values);
    }

    useEffect(() => {
        if (props.profiles && props.profiles.length) {
            const profileNames = props.profiles.map((profile: ManagedProfileModel) => {
                return {
                    key: profile.name,
                    value: profile.name
                };
            });

            setProfileNames(profileNames);
        }
    }, [props.profiles]);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    ...formFilterValuesInit,
                    profileName: props.defaultProfile
                }}
                onReset={(values, { resetForm, submitForm }) => {
                    resetForm();
                    submitForm();
                }}
                onSubmit={(value: FormFilterFieldsType) => {
                    handleSubmit(value);
                }}
            >
                {({
                    values,
                    handleChange,
                    handleReset
                }) => {
                    return (
                        <Form className="c-form">
                            <FormFieldset
                                className="br"
                                label={TranslationService.translateModule('FilterLabel', ModuleNamesList.PagesList)}
                                render={() => (
                                    <div className="row">
                                        <div className="col-auto">
                                            <FormGroup
                                                variant={FORM_GROUP_VARIANTS.BLOCK}
                                                name="name"
                                                label={TranslationService.translateModule('FilterFieldName', ModuleNamesList.PagesList)}
                                                render={(name) => (
                                                    <>
                                                        <SimpleInput
                                                            id="filterFieldName"
                                                            name={name}
                                                            value={values[name]}
                                                            onChange={(value) => {
                                                                const handleChangeEvent = {
                                                                    target: {
                                                                      id: name,
                                                                      name: name,
                                                                      value: value
                                                                    }
                                                                  } as React.ChangeEvent<any>;

                                                                handleChange(handleChangeEvent);
                                                            }}
                                                        />
                                                            
                                                        <ErrorMessage name="filterByValue" component="div" className="error error-message" />
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <div className="col-auto">
                                            <FormGroup
                                                variant={FORM_GROUP_VARIANTS.BLOCK}
                                                name="profileName"
                                                label={TranslationService.translateModule('FilterFieldProfileName', ModuleNamesList.PagesList)}
                                                render={(name) => (
                                                    <>
                                                        <SimpleSelect
                                                            id="filterFieldName"
                                                            name={name}
                                                            emptyFirstRow={false}
                                                            value={values.profileName}
                                                            options={profileNames}
                                                            onChangeHandle={(value) => {
                                                                const handleChangeEvent = {
                                                                    target: {
                                                                      id: name,
                                                                      name: name,
                                                                      value: value.key
                                                                    }
                                                                  } as React.ChangeEvent<any>;

                                                                handleChange(handleChangeEvent);
                                                            }}
                                                        />
                                                            
                                                        <ErrorMessage name="filterByValue" component="div" className="error error-message" />
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <div className="col-auto align-self-end">
                                            <Button
                                                id="pagesListFilterBtn"
                                                variant={BUTTON_VARIANTS.PRIMARY}
                                                size={BUTTON_SIZE.SM}
                                                label={TranslationService.translateModule('BtnLabelFilter', ModuleNamesList.PagesList)}
                                            />

                                            <Button
                                                type="button"
                                                id="pagesListFilterClearBtn"
                                                className="ml-3"
                                                variant={BUTTON_VARIANTS.LINK}
                                                size={BUTTON_SIZE.SM}
                                                label={TranslationService.translateModule('BtnLabelClear', ModuleNamesList.PagesList)}
                                                onClick={handleReset}
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default PagesListFilter;
import React, {ReactNode} from "react";
import classNames from "classnames";
import styles from './FormFieldset.module.scss';

type FormFieldsetType = {
    className?: string,
    label?: string,
    render: () => ReactNode
}

export const FormFieldset = ({
    className = '',
    ...props
 }: FormFieldsetType) => {
    const formFieldsetClass = classNames(
        styles.fieldset,
        className
    );

    return (
        <fieldset className={formFieldsetClass}>
            {props.label && (
                <legend className={styles.fieldsetLegend}>
                {props.label}
            </legend>
            )}

            {props.render()}
        </fieldset>
    )
}
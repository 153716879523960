import * as React from 'react';
import { ISkin } from '../../core/api/types/ISkin';
import SkinList from '../lists/SkinList';
import { ParsedQuery } from 'query-string';

const MissingSkin = () => <div> [missing skin] </div>
const SkinNotFound = () => <div> [skin not found] </div>

interface IProps {
    skin: ISkin;
    staticHeight?: boolean;
    routeParameters: ParsedQuery;
}

export const SkinFactory = (props: IProps) => {
    if (!props.skin) {
        return (<MissingSkin />);
    }
    
    const Skin = SkinList[props.skin.name];

    return Skin ? <Skin skin={props.skin} staticHeight={props.staticHeight} routeParameters={props.routeParameters}/> : <SkinNotFound />;
};

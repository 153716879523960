import React from 'react';
import TopbarApi from './api/TopbarApi';
import { useQuery } from 'react-query';

const Topbar = () => {
  const settings = useQuery(
    'settings',
    () => {
      return TopbarApi.getModuleSettings();
    },
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );

  const setHtml = (html: any, el: any) => {
    if (el === null) return;
    const range = document.createRange();

    range.selectNodeContents(el);
    range.deleteContents();

    el.appendChild(range.createContextualFragment(html));
  };

  return (
    <>
      {settings.isFetched && settings.data?.isEnabled && (
        <>
          <div ref={setHtml.bind(null, settings.data?.html)} />
        </>
      )}
    </>
  );
};

export default React.memo(Topbar);

import RestApiClient from '../../../core/api/RestApiClient';
import { InitialSettings } from '../types/InitialSettings';

class NavbarSearchFormApiClient
{
    public async getInitialSettings(): Promise<InitialSettings> {
        return await RestApiClient.get<InitialSettings>(`modules/NavbarSearchForm/getInitialSettings`);
    }
}

export default new NavbarSearchFormApiClient()

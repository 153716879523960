import React from 'react';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import { ICasePrintDetails } from './types/ICasePrintDetails';
import PrintCaseApiClient from './api/PrintCaseApiClient';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import Loader from '../../core/components/Loading/Loader';
import DebtorDetails from './components/DebtorDetails';
import CaseOverview from './components/CaseOverview';
import PaymentOverview from './components/PaymentOverview';
import PaymentDetails from './components/PaymentDetails';
import ActivitiesDetails from './components/ActivitiesDetails';
import InvoicesDetails from './components/InvoicesDetails';
import NotesDetails from './components/NotesDetails';
import MessagesDetails from './components/MessagesDetails';
import ReactToPrint from 'react-to-print';

interface IState {
    printData: ICasePrintDetails,
    isLoading: boolean,
}

class PrintCase extends React.Component<IExtendedModuleProps, IState> {
    public state: IState = {
        printData: {} as ICasePrintDetails,
        isLoading: true
    }

    public componentRef = React.createRef() as any;

    public componentDidMount = async () => {
        const printData = await PrintCaseApiClient.getPrintCaseData(this.props.caseId);

        this.setState({
            printData,
            isLoading: false
        });
    }

    public render() {
        return (
            <div>
                {
                    this.state.isLoading ?
                        <Loader opacity={0.7} /> :
                        <>
                            <ReactToPrint
                                trigger={() => <button className="btn btn--normal btn-color-2 btn-sm mt-1 float-right">{TranslationService.translate('Print')}</button>}
                                content={() => this.componentRef.current}
                            />
                            <div id='print-section' className='print-content font font--size-13' ref={this.componentRef}>
                                <dt>{TranslationService.translateModule('CreditorName', ModuleNamesList.PrintCase)}: {this.state.printData.case.creditorName}</dt>
                                <dt>{TranslationService.translateModule('CaseNumber', ModuleNamesList.PrintCase)}: {this.state.printData.case.caseNumber}</dt>
                                <hr />
                                <div className="row">
                                    <div className="col-6">
                                        <DebtorDetails debtor={this.state.printData.debtor} />
                                    </div>
                                </div>
                                <hr />
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <CaseOverview case={this.state.printData.case} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <PaymentOverview payments={this.state.printData.payments} />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <PaymentDetails payments={this.state.printData.payments} />
                                    </div>
                                </div>
                                <hr />
                                <div className="row mb-3">
                                    <div className="col-12 table-responsive">
                                        <ActivitiesDetails activities={this.state.printData.activities} />
                                    </div>
                                </div>
                                <hr />
                                <div className="row mb-3">
                                    <div className="col-12 table-responsive">
                                        <InvoicesDetails invoices={this.state.printData.invoices} />
                                    </div>
                                </div>
                                {!this.props.hideMessagesOnPrint && <>
                                    <hr />
                                    <div className="row mb-3">
                                        <div className="col-12 table-responsive">
                                            <MessagesDetails conversations={this.state.printData.messages} />
                                        </div>
                                    </div>
                                </>
                                }
                                {!this.props.hideNotesOnPrint && <>
                                    <hr />
                                    <div className="row mb-3">
                                        <div className="col-12 table-responsive">
                                            <NotesDetails notes={this.state.printData.notes} />
                                        </div>
                                    </div>
                                </>
                                }
                            </div>
                        </>
                }
            </div>
        );
    }
}

export default PrintCase;
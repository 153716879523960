import RestApiClient from '../../core/api/RestApiClient';
import { ISubscriptionDataVM } from './types/ISubscriptionDataVM';
import { IScheduleData } from '../Reports/types/IScheduleData';
import { IScheduleReportVm } from './types/IScheduleReportVm';

class ScheduleReportApiClient {
    public async getReportSchedules(moduleId: number, reportId: string): Promise<ISubscriptionDataVM> {
        return await RestApiClient.get<ISubscriptionDataVM>(`modules/ScheduleReportModule/${moduleId}/report/${reportId}`);
    }

    public async getSchedule(moduleId: number, scheduleId: string): Promise<IScheduleReportVm> {
        return await RestApiClient.get<IScheduleReportVm>(`modules/ScheduleReportModule/${moduleId}/schedule/${scheduleId}`);
    }

    public async saveSchedule(moduleId: number, scheduleData: IScheduleData): Promise<any> {
        return await RestApiClient.post<any>(`modules/ScheduleReportModule/${moduleId}/schedule`, scheduleData);
    }
}

export default new ScheduleReportApiClient();

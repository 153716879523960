import * as React from 'react';
import './_sidebar.scss';
import StyleWrapper from './../../../core/components/StyleWrapper/StyleWrapperHOC';
import LeftNavMenu from './../LeftNavMenu/LeftNavMenu';

export interface IProps {
    isMobileMenuExpanded: boolean
}

class Sidebar extends React.Component<IProps, {}> {
    public render() {
        return (
            <>
                <aside className={`main-sidebar ${this.props.isMobileMenuExpanded ? 'opened' : ''}`}>
                    <section className="sidebar">
                        <LeftNavMenu />
                    </section>
                </aside>
            </>
        );
    }
}

const styleConfig = [
    {
        searchMethod: 'module',
        instructions: {
            backgroundColor: '.main-sidebar'
        }
    }
]

export default StyleWrapper('module:sidebar', styleConfig)(Sidebar);
import * as React from 'react';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IUserDetails } from '../types/IUserDetails';
import TranslationService from '../../../core/services/TranslationService';

class UsersTableConfigurator {
    public getTableColumns = (items: IComponentTable<IUserDetails>) => {
        let columns: IComponentTableColumn[] = [];
        if (items != null) {
            columns = [...items.columns];

            this.configureErrorsMsgColumn(columns);
            this.configureSquareCheckbox(columns);
            this.configureIsMigrated(columns);

            return columns;
        }

        return columns;
    };

    private configureErrorsMsgColumn = (columns: IComponentTableColumn[]) => {
        columns.push({
            dataField: 'message',
            sort: false,
            text: ''
        } as IComponentTableColumn);

        const column = columns.find(col => col.dataField === 'message');

        if (column) {
            column.formatter = (value: any, row: IUserDetails) => {
                return (
                    row.error ? TranslationService.translate(row.error) : ''
                )
            };
        }
    };

    private configureIsMigrated = (columns: IComponentTableColumn[]) => {
        const column = columns.find(col => col.dataField === 'isMigrated');
        if (column) {
            column.formatter = (value: any, row: IUserDetails) => {
                return <span className={`badge ${row.isMigrated ? 'badge-success':'badge-danger'}`}>
                    {TranslationService.translate(row.isMigrated ? 'Yes' : 'No')}
                </span>
            };
        }
    };

    private configureSquareCheckbox = (columns: IComponentTableColumn[]) => {
        columns.push({
            dataField: 'selected',
            formatter: (value: any, item: IUserDetails) => {
                return this.actionsRowData(item)
            },
            sort: false,
            text: ''
        } as IComponentTableColumn);
    };

    private actionsRowData = (item: IUserDetails) => {
        return !item.isMigrated ? <i className="far fa-square"/> : <></>;
    }
}

export default new UsersTableConfigurator();
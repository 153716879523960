import { AnyAction } from 'redux';
import { initialState } from './initialState';
import { IStore } from './IStore';
import * as ACTIONS from '../actions';
import SessionStorageService from '../core/services/SessionStorageService';

export const rootReducer = (state = initialState, action: AnyAction): IStore => {
    switch (action.type) {
        case ACTIONS.FETCH_APPLICATION_SETTINGS_SUCCEEDED:
            return {
                ...state,
                applicationSettings: action.appSettings,
                currency: action.appSettings['Currency']
            };
        case ACTIONS.FETCH_APPLICATION_SETTINGS_FAILED:
            return {
                ...state,
                applicationSettings: {}
            };
        case ACTIONS.CHANGE_LANGUAGE:
            return {
                ...state,
                currentCultureCode: action.language
            };
        case ACTIONS.CHANGE_CURRENT_CREDITOR:
            return {
                ...state,
                currentCreditors: action.crediors
            };
        case ACTIONS.UPDATE_CONFIGURATION: {
            return {
                ...state,
                configurations: action.configurations
            }
        }
        case ACTIONS.FETCH_PAGES_SUCCEEDED: {
            return {
                ...state,
                pages: action.pages,
            }
        }
        case ACTIONS.FETCH_CREDITORS_IN_PROGRESS: {
            return {
                ...state,
                creditorsLoading: true
            }
        }
        case ACTIONS.FETCH_CREDITORS_SUCCEEDED: {
            const sessionCreditor = SessionStorageService.get('selectedCreditor');
            let currentCreditors;

            if (sessionCreditor && sessionCreditor !== 'all') {
                currentCreditors = [sessionCreditor];
            } else {
                currentCreditors = action.creditors.filter((c: any) => c.isAuthorized).map((c: any) => c.domainId);
            }
            return {
                ...state,
                currentCreditors,
                creditors: action.creditors,
                creditorsLoading: false
            }
        }
        case ACTIONS.FETCH_CREDITORS_FAILED: {
            return {
                ...state,
                creditorsLoading: false
            }
        }
        case ACTIONS.FETCH_PERMISSIONS_FAILED: {
            return {
                ...state,
                permissions: {}
            }
        }
        case ACTIONS.FETCH_PERMISSIONS_IN_PROGRESS: {
            return {
                ...state,
                permissions: {}
            }
        }
        case ACTIONS.FETCH_PERMISSIONS_SUCCEEDED: {
            return {
                ...state,
                permissions: action.permissions
            }
        }
        case ACTIONS.FETCH_LEFTMENU_SUCCEEDED: {
            return {
                ...state,
                leftMenuModel: action.leftMenuModel,
            }
        }
        case ACTIONS.FETCH_LEFTMENU_FAILED: {
            return {
                ...state,
                leftMenuModel: { items: [], groups: [] },
            }
        }
        case ACTIONS.FETCH_LANGUAGES_SUCCEEDED: {
            return {
                ...state,
                languages: action.languages,
            }
        }
        case ACTIONS.FETCH_STYLING_SETTINGS_SUCCEEDED: {
            return {
                ...state,
                stylingSettings: action.stylingSettings
            }
        }
        case ACTIONS.FETCH_USER_CREDITOR_ROLES_SUCCEEDED: {
            return {
                ...state,
                userCreditorRoles: action.userCreditorRoles
            }
        }
        case ACTIONS.FETCH_TRANSLATIONS_SUCCEEDED: {
            return {
                ...state,
                translations: action.translations
            }
        }
        case ACTIONS.FETCH_TRANSLATIONS_FAILED: {
            return {
                ...state,
                translations: {}
            }
        }
        case ACTIONS.FETCH_TILES_COUNTERS_SUCCEEDED: {
            return {
                ...state,
                tilesCounters: action.tilesCounters
            }
        }
        case ACTIONS.FETCH_TILES_COUNTERS_FAILED: {
            return {
                ...state,
                tilesCounters: {}
            }
        }
        default:
            return state;
    }
};

import * as React from 'react';
import { Link } from 'react-router-dom';
import './CardWithIcon.scss';
import { ICardWithIcon } from './ICardWithIcon';

const getLinkWrapper = (children: any, props: any) => {
    return <Link 
                to={props.redirectUrl} 
                className="info-cards__el info-cards__el--link">
                    {/* <span className="info-cards__el-decor" style={{ 'backgroundColor': `${props.color}`}}></span> */}
                    {children}
            </Link>
}

const getWrapper = (children: any) => {
    return <div className="info-cards__el">{children}</div>
}

const getCardWithIcon = (props: ICardWithIcon) => {
    const linkStyle = props.redirectUrl ? { 'color': props.color } : {};

    const cardBody = (
        <>
            <div className="info-cards__el-icon" style={{ 'backgroundColor': props.color }}>
                <span className={`${props.icon} fa-3x`} />
            </div>
            <div className="info-cards__el-container">
                <div className="info-cards__el-counter">
                    <span className="info-cards__el-counter-primary-number">{props.counter.primary}</span>
                </div>
                <h6 className="info-cards__el-title" style={linkStyle}>{props.title}</h6>
            </div>
        </>
    );

    let wrapper = getWrapper(cardBody);

   if (props.redirectUrl) {
        wrapper = getLinkWrapper(cardBody, props);
   }

   return wrapper;
}

const CardWithIcon = (props: ICardWithIcon) => {
    return getCardWithIcon(props);
};

export default CardWithIcon;

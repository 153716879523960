import RestApiClient from '../../../core/api/RestApiClient';
import { ITransferableInvoicesSummary } from '../types/ITransferableInvoicesSummary';
import { ITransferInvoicesRequest } from '../types/ITransferInvoicesRequest';
import { ISetInvoicesStatusRequest } from '../types/ISetInvoicesStatusRequest';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class TransferToDebtCollectionApiClient
{
    public async getTransferableInvoicesTable(request: ITransferInvoicesRequest): Promise<ITransferableInvoicesSummary> {
        return await RestApiClient.post<ITransferableInvoicesSummary>(`modules/TransferToDebtCollection/getTransferableInvoices`, request);
    }

    public async updateInvoicesStatus(request: ISetInvoicesStatusRequest): Promise<IActionStatus> {
        return await RestApiClient.post<IActionStatus>(`modules/TransferToDebtCollection/transferInvoices`, request);
    }
}

export default new TransferToDebtCollectionApiClient();
import * as React from 'react';
import { Formik, Form } from 'formik';
import FormGroupField from '../../../core/components/Forms/FormGroupField';
import TranslationService from '../../../core/services/TranslationService';
import * as yup from 'yup';
import { IContactsProps } from '../types/IContactsProps';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import StepListWizardButtons from '../../../modules/StepListWizard/StepListWizardButtons';
import RegisterUserApiClient from '../RegisterUserApiClient';
import { IContactsState } from '../types/IContactsState';
import { phoneRegex } from '../../../core/helpers/utils';

class Contacts extends React.Component<IContactsProps> {
    public state : IContactsState = {
        emailSpinner: false,
        prevEmailValue: '',
        emailFieldFocused: false,
        emailExistingState: true,
    }

    private validationMessages = {
        atLeastOneLowercase: TranslationService.translate("ValidationMessagePasswordAtLeastOneLowercaseLetter"),
        atLeastOneNumber: TranslationService.translate("ValidationMessagePasswordAtLeastOneNumber"),
        atLeastOneSpecial: TranslationService.translate("ValidationMessagePasswordAtLeastOneSpecial"),
        atLeastOneUppercase: TranslationService.translate("ValidationMessagePasswordAtLeastOneUpercaseLetter"),
        email: TranslationService.translate("ValidationMessageIncorrentEmailFormat"),
        emailExists: TranslationService.translateModule("ValidationMessageEmailExists", ModuleNamesList.RegisterUserModuleName),
        incorrectPhoneNoPhormat: TranslationService.translate("ValidationMessageIncorrectPhoneNoFormat"),
        passwordMinimumLength: TranslationService.translate("ValidationMessagePasswordMinimumLength"),
        repeat: TranslationService.translate("ValidationMessageRepeatedIncorrectly"),
        required: TranslationService.translate("ValidationMessageRequiredField"),
    }

    private validationSchema = yup.object().shape({
        firstName: yup.string().required(this.validationMessages.required),
        lastName: yup.string().required(this.validationMessages.required),
        phone: yup.string().required(this.validationMessages.required)
            .matches(phoneRegex, this.validationMessages.incorrectPhoneNoPhormat),
        email: yup.string().email(this.validationMessages.email)
            .required(this.validationMessages.required)
            .test('email-exists', this.validationMessages.emailExists , async(value) => {
            if(value !== undefined && this.state.emailFieldFocused === false && this.state.prevEmailValue !== value)
            {
                const emailExists = await this.emailExists(value);
                this.state.prevEmailValue = value;
                this.state.emailExistingState = !emailExists;
            }
            return this.state.emailExistingState;
        }),
        emailRepeat: yup.string().oneOf([yup.ref('email'), null], this.validationMessages.repeat).email(this.validationMessages.email).required(this.validationMessages.required),
        password: yup.string().required(this.validationMessages.required)
            .min(6,  this.validationMessages.passwordMinimumLength)
            .matches(/[a-z]+/, this.validationMessages.atLeastOneLowercase)
            .matches(/[A-Z]+/, this.validationMessages.atLeastOneUppercase)
            .matches(/[0-9]+/, this.validationMessages.atLeastOneNumber)
            .matches(/[!@#$%&]+/, this.validationMessages.atLeastOneSpecial),
        passwordRepeat: yup.string().oneOf([yup.ref('password'), null], this.validationMessages.repeat).required(this.validationMessages.required),
    });

    public emailExists = async (email: string): Promise<boolean> =>
    {
        this.setState({
            emailSpinner: true
        });
        const emailExists = await RegisterUserApiClient.ValidateIsEmailExists(email);
        this.setState({
            emailSpinner: false
        });
        return emailExists;
    }

    public render() {
        return (
            <div className="step-content">
                <header className="step-header">
                    <h2 className="step-title">
                        {TranslationService.translateModule('ContactsHeader', ModuleNamesList.RegisterUserModuleName)}
                    </h2>
                    
                    <p>
                        {TranslationService.translateModule('ContactsContent',ModuleNamesList.RegisterUserModuleName)}
                    </p>
                </header>

                <Formik
                    initialValues={{ ...this.props.user }}
                    onSubmit={(values) => {
                        this.props.onUpdateUser(values);
                        this.props.goToNextStep();
                    }}
                    validationSchema={this.validationSchema}
                    >
                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur
                    }) => {
                        return ( 
                            <Form className="C-form" autoComplete="off">
                                <div className="contacts-form">
                                    <FormGroupField
                                        errors={errors} 
                                        fieldName="firstName" 
                                        handleChange={handleChange} 
                                        label={TranslationService.translateModule('ContactsFirstName',ModuleNamesList.RegisterUserModuleName)} 
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        required={true}
                                        disabled={this.props.disablePrefilledFields}
                                        value={values.firstName}
                                    />
                                    
                                    <FormGroupField 
                                        errors={errors} 
                                        fieldName="lastName" 
                                        handleChange={handleChange} 
                                        label={TranslationService.translateModule('ContactsLastName',ModuleNamesList.RegisterUserModuleName)} 
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        required={true}
                                        disabled={this.props.disablePrefilledFields}
                                        value={values.lastName}
                                    />
                                    
                                    <FormGroupField 
                                        errors={errors} 
                                        fieldName="phone" 
                                        handleChange={handleChange} 
                                        label={TranslationService.translateModule('ContactsPhone',ModuleNamesList.RegisterUserModuleName)} 
                                        handleBlur={handleBlur}
                                        placeholder={TranslationService.translate('PhoneNumberFieldPlaceholder')}
                                        touched={touched}
                                        required={true}
                                        value={values.phone}
                                    />
                                    
                                    <FormGroupField 
                                        errors={errors} 
                                        fieldName="email" 
                                        handleChange={(e: any) => { this.setState({...this.state, emailFieldFocused: true }); handleChange(e);}} 
                                        label={TranslationService.translateModule('ContactsEmail',ModuleNamesList.RegisterUserModuleName)} 
                                        handleBlur={(e) => { this.setState({...this.state, emailFieldFocused: false }); handleBlur(e); }}
                                        touched={touched}
                                        loadingSpinner={this.state.emailSpinner}
                                        required={true}
                                        autoComplete='new-password'
                                        disabled={this.props.disablePrefilledFields}
                                        value={values.email}
                                    />
                                    
                                    <FormGroupField 
                                        errors={errors} 
                                        fieldName="emailRepeat" 
                                        handleChange={handleChange} 
                                        label={TranslationService.translateModule('ContactsEmailRepeat',ModuleNamesList.RegisterUserModuleName)} 
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        type="email" 
                                        required={true}
                                        value={values.emailRepeat}
                                    />
                                    
                                    <FormGroupField 
                                        errors={errors} 
                                        fieldName="password" 
                                        handleChange={handleChange} 
                                        label={TranslationService.translateModule('ContactsPassword',ModuleNamesList.RegisterUserModuleName)} 
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        type="password"
                                        required={true}
                                        value={values.password}
                                    />
                                    
                                    <FormGroupField 
                                        errors={errors} 
                                        fieldName="passwordRepeat" 
                                        handleChange={handleChange} 
                                        label={TranslationService.translateModule('ContactsPasswordRepeat',ModuleNamesList.RegisterUserModuleName)} 
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        type="password"
                                        required={true}
                                        value={values.passwordRepeat}
                                    />
                                </div>
                                <hr/>
                                <StepListWizardButtons
                                    leftButtonOnClick={this.props.backToPrevStep}
                                    leftButtonClassName="float-left"
                                    leftButtonText={<>
                                        <i className="fas fa-angle-double-left back-button-arrow left"/>
                                        {" " + TranslationService.translate('Back')}
                                    </>}
                                    rightButtonClassName="float-right"
                                    rightButtonText={<>
                                        {TranslationService.translate('Next') + " "}
                                        <i className="fas fa-angle-double-right next-button-arrow right"/>
                                    </>}
                                />
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        )
    }
}

export default Contacts;

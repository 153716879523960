import { IEditableInvoiceListElement } from '../types/IEditableInvoiceListElement';
import { PaymentMethod } from '../types/PaymentMethod';

export const creditNoteAtLeastOneInvoiceHavePayAmount = (invoices: IEditableInvoiceListElement[]): boolean => {
    return invoices.filter(i => i.payAmount > 0).length > 0;
}

export const sumOfAllAmountsShouldBeSmallerThanMaximum = (invoices: IEditableInvoiceListElement[], maximumAmount: number): boolean => {
    const amount = invoices.map(i => parseFloat(i.payAmount.toString())).reduce((sum, value) => sum + value);
    return amount <= maximumAmount;
}

export const isPaymentDateValid = (invoices: IEditableInvoiceListElement[], date: Date): boolean => {
    if (!invoices.length) { return true; }
    const sortedDates = invoices.map(i => i.invoiceDate).sort((a,b)=> a.valueOf() - b.valueOf());
    if (sortedDates.length) {
        return date >= new Date(sortedDates[0]) && date <= new Date();
    }

    return false;
}

export const payedAmountMustNotExceedMaximum = (payedAmount: number, maximumAmount: number): boolean => payedAmount <= maximumAmount;

export const payedAmountIsPositive = (payedAmount: number): boolean => payedAmount > 0;

export const invoiceOrdersAreNotDuplicated = (invoices: IEditableInvoiceListElement[]): boolean => {
    const distinct = (value: number, index: number, self: number[]) => {
        return self.indexOf(value) === index;
    }

    const orders = invoices.map(i => i.order);
    const distinctOrders = orders.filter(distinct);

    return orders.length === distinctOrders.length;
}

export const validatePayment = (invoices: IEditableInvoiceListElement[], maxAmount: number, payedAmount: number, date: Date, paymentOption: PaymentMethod): string[] => {
    const errors = [];
    if (paymentOption === PaymentMethod.CreditNote) {
        if (!creditNoteAtLeastOneInvoiceHavePayAmount(invoices)) { errors.push('AtLeastOneInvoiceMustHaveAmountError') }
        if (!sumOfAllAmountsShouldBeSmallerThanMaximum(invoices, maxAmount)) { errors.push('AllInvoiceAmountsMustNotExceedMaximumError') }
    } else {
        if (!payedAmountIsPositive(payedAmount)) { errors.push('PaidAmountMustBePositiveError') }
        if (!payedAmountMustNotExceedMaximum(payedAmount, maxAmount)) { errors.push('PaidAmountMustNotExceedMaximumError') }
        if (!isPaymentDateValid(invoices, date)) { errors.push('PaymentDateBetweenLowestInvoiceDateAndTodayError') }
        if (!invoiceOrdersAreNotDuplicated(invoices)) { errors.push('InvoiceOrderNumbersDuplicatedError') }
    }

    return errors;
}
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { Card } from '../../core/components/Card/Card';
import { CardContent } from '../../core/components/Card/components/CardContent';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';
import useEffectAsync from '../../core/hooks/useEffectAsync';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import { IModuleProps } from '../../core/types/IModuleProps';
import LicenseExpirationApi from './api/LicenseExpirationApi';

const LicenseExpiration = (props: IModuleProps & AbortRequestPropsType) => {
    const [expirationDate, setExpDate] = useState<Moment>();
    const api = new LicenseExpirationApi(props.cancelTokenSource.token);
    const module: any = flatModuleOptions<any>(props.module as any);

    useEffectAsync(async () => {
        const expDate = await api.getLicenseExpirationDate();
        if (expDate) {
            setExpDate(moment(expDate));
        }
    }, []);

    const getExpDays = () => {
        if (expirationDate) {
            return expirationDate.diff(moment(), 'days').toString()
        }

        return NaN.toString();
    }

    if (parseInt(module.ExpirationDays) <= parseInt(getExpDays()) || isNaN(parseInt(getExpDays()))) {
        return null;
    }

    return (
        <Card class="mt-3">
            <>
                <CardContent>
                    <>
                        {expirationDate && <span><i className="fas fa-exclamation-triangle"/> {
                            TranslationService.translateModule(
                                "LicenseExpireSoon",
                                ModuleNamesList.LicenseExpiration)
                                .replace('{0}', getExpDays())}</span>}
                    </>
                </CardContent>
            </>
        </Card>
    )
}

export default withAbortRequest(LicenseExpiration);
import * as React from 'react';
import { connect } from 'react-redux';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import Loader from '../../core/components/Loading/Loader';
import { CaseProgressType } from '../../core/Enums/CaseProgressType';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../core/lists/EventsList';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import RouteParamsService from '../../core/services/RouteParamsService';
import TranslationService from '../../core/services/TranslationService';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { IDictionary } from '../../core/types/IDictionary';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IStore } from '../../reducers/IStore';
import CaseListService from './CaseListService';
import CaseListFilter from './components/CaseListFilter';
import CaseListFooter from './components/CaseListFooter';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import { CaseActivity } from './enums/CaseActivity';
import { CaseListFolderType } from './enums/CaseListFolderType';
import CasesListTableConfigurator from './helpers/CasesListTableConfigurator';
import { ICaseListElemet } from './types/ICaseListElement';
import { emptyCaseListFilterRequest, ICaseListFilterRequest } from './types/ICaseListFilterRequest';
import { emptyCaseListSummary, ICaseListSummary } from './types/ICaseListSummary';
import { ICasesRequest } from './types/ICasesRequest';
import './_CaseList.scss';
import { saveAs } from 'file-saver';
import { CaseListSelectFilter, getCaseListSelectFilter } from './enums/CaseListSelectFilter';
import { ICaseInitialSettings, emptyCaseInitialSettings } from './types/ICaseInitialSettings';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';

interface IState {
    casesSummary: ICaseListSummary;
    casesTable: IComponentTable<ICaseListElemet>;
    currentPage: number;
    moduleSettings: ICaseInitialSettings,
    filterRequest: ICaseListFilterRequest;
    folderType: CaseListFolderType;
    isFilterSectionLoading: boolean;
    isDataLoading: boolean;
    sizePerPage: number;
    sortBy: string;
    sortDirection: string;
}

interface IProps extends IModuleProps, AbortRequestPropsType {
    folder: string,
    locale: string,
    currency: string
    filteredBy?: CaseListSelectFilter, 
    filteredByValue?: string
}

class CaseList extends React.Component<IProps, IState> {
    public module: any = flatModuleOptions<any>(this.props.module as any);
    public state: IState = {
        casesSummary: { ...emptyCaseListSummary },
        casesTable: emptyComponentTable,
        currentPage: 1,
        moduleSettings: {...emptyCaseInitialSettings},
        filterRequest: { ...emptyCaseListFilterRequest },
        folderType: CaseListFolderType.AllActive,
        isDataLoading: false,
        isFilterSectionLoading: false,
        sizePerPage: 10,
        sortBy: 'caseNumber',
        sortDirection: 'desc',
    };
    private moduleEvents: IModuleEventSubscription[] | undefined;

    constructor(props: IProps) {
        super(props);
        this.mapCurrentFolder();
    }

    public componentDidMount = async () => {
        this.registerModuleEvents();
        await this.fetchSettings();
        await this.fetchCases();
    };

    public componentWillUnmount() {
        if (this.moduleEvents)
            ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
    }

    public fetchSettings = async () => {
        this.setState({ isFilterSectionLoading: true })
        const settings = await CaseListService.getInitialSettings(this.props.module.id, this.props.cancelTokenSource.token);
        this.setState({
            moduleSettings: settings,
            isFilterSectionLoading: false
        })
    }

    public fetchCases = async () => {
        const request: ICasesRequest = {
            moduleInstanceId: this.props.module.id,
            filterRequest: this.state.filterRequest,
            page: this.state.currentPage,
            resultsPerPage: this.state.sizePerPage,
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
        };
        this.setState({
            isDataLoading: true
        });
        const response = await CaseListService.getCasesList(request, this.props.cancelTokenSource.token);
        this.setState({
            casesTable: response.table,
            casesSummary: response.summary,
            isDataLoading: false
        })
    };

    public filterCases = (filter: ICaseListFilterRequest) => {
        this.setState({
            filterRequest: filter,
            currentPage: 1
        }, this.fetchCases);
    };

    public onTableChange = (type: any, { page, sizePerPage, sortField, sortOrder }: any) => {
        this.setState({
            currentPage: page,
            sizePerPage,
            sortBy: sortField ? sortField : this.state.sortBy,
            sortDirection: sortOrder ? sortOrder : this.state.sortDirection
        }, this.fetchCases)
    };

    public render() {
        const isCaseClosedVisible =
            this.state.filterRequest.caseActivity.includes(CaseActivity[CaseActivity.Closed.toString()]) ||
            this.state.filterRequest.caseActivity.includes(CaseActivity.Closed);
        const exportEnabled = this.module.ExportEnabled && this.module.ExportEnabled === "true";

        return (
            <article className="l-module l-module--case-list">
                <section className="l-module__section l-module__section--head">
                    <h1 className="l-module__title">
                        <i className="fas fa-folder-open mr-2" />
                        {TranslationService.translateModule('CasesHeader', ModuleNamesList.CaseList)}:
                        <strong className="l-module__title-highlighted">{this.getCurrentFolderName()}</strong>
                    </h1>
                </section>

                { this.state.moduleSettings.enableFiltersSection &&
                <section className="l-module__section l-module__section--filter mb-5">
                    {this.state.isFilterSectionLoading &&
                        <Loader opacity={0.5} />
                    }
                    <CaseListFilter
                        filterCases={this.filterCases}
                        baseRequest={this.state.filterRequest}
                        filterByOptions={this.state.moduleSettings.filterByOptions}
                    />
                </section> }

                <section className="l-module__section">
                    {this.state.isDataLoading &&
                        <Loader opacity={0.5} />
                    }

                    <BootstrapTableContainer
                        remote={true}
                        wrapperClasses="bt"
                        paginationProps={{
                            page: this.state.currentPage,
                            sizePerPage: this.state.sizePerPage,
                            totalSize: this.state.casesSummary.count
                        }}
                        onExport={exportEnabled ? this.onExport : undefined}
                        classes="bt__table bt-table"
                        rowClasses={this.rowClasses}
                        keyField='caseNumber'
                        data={this.state.casesTable.values}
                        onTableChange={this.onTableChange}
                        columns={CasesListTableConfigurator.getTableColumns(
                            this.state.casesTable,
                            isCaseClosedVisible,
                            this.openDirectPaymentModal,
                            this.openWithdrawCaseModal,
                            this.state.moduleSettings)
                        }
                    />
                </section>
                {this.state.moduleSettings.enableSummarySection}
                { this.state.moduleSettings.enableSummarySection && 
                <section className="l-module__section l-module__section--p-0 mt-3">
                    <CaseListFooter summary={this.state.casesSummary} currency={this.props.currency} />
                </section> }
            </article>
        );
    }

    rowClasses = (row: any) => {
        return `caseNumber-${row.caseNumber}`;
    };

    updateCaseData = () => {
        this.fetchCases();
    };

    private onExport = async() => {
        const blob = await CaseListService.getCasesListFile({
            moduleInstanceId: this.props.module.id,
            filterRequest: this.state.filterRequest,
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection,
            page: -1,
            resultsPerPage: -1
        })
        
        saveAs(blob, `${TranslationService.translateModule('CasesHeader', this.props.module.name)} - ${this.getCurrentFolderName()}.csv`, { autoBom: true });
    }

    private openDirectPaymentModal = (caseNumber: string) => {
        ModuleEventSubscriber.emitEvent({
            name: getModalOpenEvent(this.module.DirectPaymentModalModuleInstanceId), data: {
                modalData: {
                    MODAL_HEADER_TEXT: TranslationService.translateModule('ActionsDirectPayment', ModuleNamesList.CaseList) + ' ' + caseNumber,
                    MODAL_HEADER_ICON: 'fas fa-credit-card',
                    onClose: (actionSuccess?: boolean) => { if (actionSuccess) { this.updateCaseData(); } }

                },
                otherData: {
                    caseId: caseNumber
                }
            }
        });
    };

    private openWithdrawCaseModal = (caseNumber: string) => {
        ModuleEventSubscriber.emitEvent({
            name: getModalOpenEvent(this.module.WithdrawCaseModalModuleInstanceId), data: {
                modalData: {
                    MODAL_HEADER_TEXT: TranslationService.translateModule('ActionsWithdrawCase', ModuleNamesList.CaseList) + ' ' + caseNumber,
                    MODAL_HEADER_ICON: 'fas fa-ban',
                },
                otherData: {
                    caseId: caseNumber
                }
            }
        });
    };

    private registerModuleEvents() {
        this.moduleEvents = [
            { name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.fetchCases }
        ];
        ModuleEventSubscriber.registerEvents(this.moduleEvents);
    }

    private getCurrentFolderName = () => {
        const folderNames: IDictionary<string> = {
            [CaseListFolderType.AllActive]: TranslationService.translateModule('HeaderAllActive', ModuleNamesList.CaseList),
            [CaseListFolderType.PreLegal]: TranslationService.translateModule('HeaderPreLegal', ModuleNamesList.CaseList),
            [CaseListFolderType.LastClosed]: TranslationService.translateModule('HeaderLastClosed', ModuleNamesList.CaseList),
            [CaseListFolderType.Legal]: TranslationService.translateModule('HeaderLegal', ModuleNamesList.CaseList),
            [CaseListFolderType.OnHold]: TranslationService.translateModule('HeaderOnHold', ModuleNamesList.CaseList),
            [CaseListFolderType.Reminder]: TranslationService.translateModule('HeaderReminder', ModuleNamesList.CaseList),
            [CaseListFolderType.Surveillance]: TranslationService.translateModule('HeaderSurveillance', ModuleNamesList.CaseList),
        };

        return folderNames[this.state.folderType];
    };

    /*eslint-disable */
    private mapCurrentFolder = () => {
        this.state.filterRequest.caseActivity = [];
        this.state.filterRequest.caseProgress = [];

        switch (this.props.folder) {
            case 'prelegal':
                this.state.folderType = CaseListFolderType.PreLegal;
                this.state.filterRequest.caseProgress.push(CaseProgressType.PreLegal);
                break;
            case 'lastclosed':
                this.state.filterRequest.caseActivity.push(CaseActivity.Closed);
                this.state.folderType = CaseListFolderType.LastClosed;
                this.state.sortBy = "closeDate";
                break;
            case 'legal':
                this.state.folderType = CaseListFolderType.Legal;
                this.state.filterRequest.caseProgress.push(CaseProgressType.Legal);
                break;
            case 'onhold':
                this.state.folderType = CaseListFolderType.OnHold;
                break;
            case 'reminder':
                this.state.folderType = CaseListFolderType.Reminder;
                this.state.filterRequest.caseProgress.push(CaseProgressType.Reminder);
                break;
            case 'surveillance':
                this.state.folderType = CaseListFolderType.Surveillance;
                this.state.filterRequest.caseProgress.push(CaseProgressType.Surveillance);
                break;
            default:
                this.state.folderType = CaseListFolderType.AllActive;
                break;
        }

        if (this.state.folderType !== CaseListFolderType.LastClosed) {
            this.state.filterRequest.caseActivity.push(CaseActivity.Opened);
        }

        if(this.props.routeParameters.debtorNumber){
            this.state.filterRequest.filteredBy = CaseListSelectFilter.DebtorNumber;
            this.state.filterRequest.filteredByValue = this.props.routeParameters.debtorNumber.toString();
        }

        if(this.props.routeParameters.filteredBy && this.props.routeParameters.filteredByValue)
        {
            const filteredBy: CaseListSelectFilter = getCaseListSelectFilter(this.props.routeParameters.filteredBy.toString());
            this.state.filterRequest.filteredBy = filteredBy;
            this.state.filterRequest.filteredByValue = this.props.routeParameters.filteredByValue.toString(); 
            this.state.filterRequest.caseActivity = [];

            if(filteredBy === CaseListSelectFilter.ExternalVoucherNo || filteredBy === CaseListSelectFilter.NameId || filteredBy === CaseListSelectFilter.DebtorName)
            {
                this.state.filterRequest.caseActivity.push(CaseActivity.Opened);
            }
        }
    }
    /*eslint-enable */
}

const mapStateToProps = (state: IStore) => {
    const queryparams = RouteParamsService.getQueryParameters();
    return {
        folder: queryparams.folder,
        locale: state.currentCultureCode,
        currency: state.currency
    }
};

export default connect(mapStateToProps)(withAbortRequest(CaseList));
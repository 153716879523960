import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { IFormGroupFieldProps } from '../../types/IFormGroupFieldProps';

const FormGroupCheckbox = (props: IFormGroupFieldProps) => (
    <div className={`${props.inputContainerClass ? props.inputContainerClass : ''} c-control c-control--checkbox`}>
        <Field type={props.type ? props.type : "text"} 
            name={props.fieldName} 
            id={props.fieldName} 
            checked={props.value} 
            className={"form-check-input" + ((props.errors[props.fieldName] && props.touched[props.fieldName]) ? " error" : "")} 
            onChange={props.handleChange} />
        <label className="form-check-label c-control__label" htmlFor={props.fieldName}>
            {props.label}
            {props.required && <span className="c-control__required-sign" />}
        </label>
        <ErrorMessage name={props.fieldName} component="div" className="error error-message" />
    </div>
);

export default FormGroupCheckbox;
